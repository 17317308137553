import { IAccount } from 'Components/Accounts/types';
import {
  SET_ACCOUNTS,
  SET_ACCOUNTS_LOADING,
  SET_ACTIVE_ACCOUNT,
  SET_AGENCY_LOGO,
} from './accountsActions';
import { PayloadAction } from '@reduxjs/toolkit';

export interface ISubAccountsState {
  agencyLogo?: string;
  accounts: IAccount[];
  loading: boolean;
  activeAccount: IAccount | null;
}

const initialState: ISubAccountsState = {
  accounts: [],
  loading: false,
  activeAccount: null,
};

const accountReducer = (
  state = initialState,
  action: PayloadAction<IAccount[] | boolean | IAccount | null | string>
) => {
  switch (action.type) {
    case SET_ACCOUNTS:
      return {
        ...state,
        accounts: action.payload as IAccount[],
      };
    case SET_ACCOUNTS_LOADING:
      return {
        ...state,
        loading: action.payload as boolean,
      };
    case SET_ACTIVE_ACCOUNT:
      return {
        ...state,
        activeAccount: action.payload as IAccount | null,
      };
    case SET_AGENCY_LOGO:
      return {
        ...state,
        agencyLogo: action.payload as string,
      };
    default:
      return state;
  }
};

export { initialState, accountReducer };
