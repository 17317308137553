export const SEND_EMAIL = {
  operationName: 'mailGunSendMessage',
  query: `mutation mailGunSendMessage(
        $to: String!
        $from: String!
        $subject: String!
        $html: String!
        $text: String!
        $domain: String!
      ) {
        mailGunSendMessage(
          to: $to
          from: $from
          subject: $subject
          html: $html
          text: $text
          domain: $domain
        ) {
          id
          message
      }
    }`,
};
