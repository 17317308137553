export const FINISH_SIGNUP = {
  operationName: 'finishSignUp',
  query: `mutation finishSignUp(
      $email: String!
      $name: String!
      $token: String!
      $password: String!
    ) {
      finishSignUp(
        email: $email
        name: $name
        token: $token
        password: $password
      ) {
        _id
        role
        subdomain
      }
    }`,
};
