export const DELETE_DOMAIN_MUTATION = {
  operationName: 'deleteDomain',
  query: `mutation deleteDomain(
      $id: String!
    ) {
      deleteDomain(
        id: $id
      ) {
        message
      }
    }`,
};
