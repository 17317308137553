import React, { useState } from 'react';
import Modal from 'Components/Common/Modal2/Modal';
import Select from 'Components/Common/Select/Select';
import Button from 'Styled/Button2';
import WordIcon from 'Assets/icons/worldBlack.svg';
import s from './Popups.module.scss';
import { IAccount } from 'Components/Accounts/types';
import { graphQlCall } from 'graphql/utils';
import QUERIES from 'graphql/queries';
import { IDuplicatePageOtherAccountPayload } from 'types';

interface IProps {
  owner: string;
  pageId: string;
  open: boolean;
  accounts: IAccount[];
  onClose: () => void;
  onDuplicate: (payload: IDuplicatePageOtherAccountPayload) => void;
}

interface ISelectOption {
  value: string;
  label: string;
  disabled?: boolean;
}

const initialFunnelOption = {
  value: '0',
  label: 'Select funnel',
  disabled: true,
};

const DuplicatePageOtherAccountPopup = (props: IProps) => {
  const [selectedAccountSub, setSelectedAccountSub] = useState('0');
  const [selectedFunnelId, setSelectedFunnelId] = useState('0');
  const [funnelsOptions, setFunnelsOptions] = useState<ISelectOption[]>([
    initialFunnelOption,
  ]);

  const accountOptions: ISelectOption[] = props.accounts.map((account) => ({
    label: account.workspace ? account.workspace : account.name,
    value: account.owner,
  }));

  accountOptions.push({
    label: 'Select Account',
    value: '0',
    disabled: true,
  });

  const handleSelectAccount = async (sub: string) => {
    setFunnelsOptions([
      {
        value: '0',
        label: 'No funnels in the account',
        disabled: true,
      },
    ]);
    const funnels = await graphQlCall({
      queryTemplateObject: QUERIES.GET_FUNNELS_FROM_SUB_ACCOUNT,
      values: {
        sub,
      },
      headerType: 'AGENCY_AUTH',
    });

    if (!funnels.length) {
      setFunnelsOptions([
        {
          value: '0',
          label: 'No funnels in the account',
          disabled: true,
        },
      ]);
    } else {
      const options: ISelectOption[] = [initialFunnelOption];
      funnels.forEach((funnel: any) => {
        options.push({
          value: funnel._id,
          label: funnel.name,
        });
      });
      setFunnelsOptions(options);
    }
    setSelectedAccountSub(sub);
  };

  const processDuplicatePage = async () => {
    if (selectedFunnelId === '0') {
      return;
    }
    props.onDuplicate({
      targetAccountOwner: selectedAccountSub,
      sourceAccountOwner: props.owner,
      funnelId: selectedFunnelId,
      pageId: props.pageId,
    });
    handleClose();
  };

  const handleClose = () => {
    setSelectedAccountSub('0');
    setSelectedFunnelId('0');
    setFunnelsOptions([initialFunnelOption]);
    props.onClose();
  };

  return (
    <Modal displayModal={props.open} closeModal={() => handleClose()}>
      <div className={s.modal}>
        <img src={WordIcon} alt="" className={s.mainImage} />
        <h2 className={s.header}>Copy to Another Funnel</h2>
        <p className={s.additionalText}>
          Choose the funnel you want to copy this page
        </p>
        <Select
          options={accountOptions}
          onChange={(account: string) => handleSelectAccount(account)}
          value={selectedAccountSub}
        />
        <Select
          options={funnelsOptions}
          onChange={(funnelId: string) => setSelectedFunnelId(funnelId)}
          value={selectedFunnelId}
        />
        <div className={s.buttonsBlock}>
          <Button onClick={() => handleClose()}>Cancel</Button>
          <Button
            onClick={() => processDuplicatePage()}
            disabled={selectedFunnelId === '0'}
          >
            Copy
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DuplicatePageOtherAccountPopup;
