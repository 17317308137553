import { RootState } from 'store/rootReducer';
import { connect } from 'react-redux';
import logo from 'Assets/images/loginPage/AutofunnelLogo.svg';

import styles from './OnboardingLogo.module.scss';

interface IProps {
  autosaasLogo: string;
}

const OnboardingLogo = ({ autosaasLogo }: IProps) => {
  return (
    <div className={styles.container}>
      <img src={autosaasLogo || logo} alt="logo thumbnail" />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  autosaasLogo: state.autosaas.logoUrl,
});

export default connect(mapStateToProps)(OnboardingLogo);
