import { useEffect, useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { Descendant, Element } from 'slate';
import { CustomText } from 'Editors/types';
import { graphQlCall } from 'graphql/utils';
import { EpisodeStatus } from 'Pages/PodcastGenerator/constants';
import { ReactComponent as PodcastIcon } from 'Assets/icons/Podcaster64.svg';
import { getBySocket, getToken, createSocket, validateUser } from 'utils/Utils';
import { IPodcastEpisode, IPodcastEpisodeSection } from 'types';
import EditorSidebar from 'Components/Common/EditorSidebar/EditorSidebar';
import EditHeader from 'Components/Common/EditHeader/EditHeader';
import TextEditor from 'Editors/TextEditor/TextEditor';
import queries from 'graphql/queries';
import Loader from 'UILib/Loader/Loader';

import styles from './EditPodcastEpisode.module.scss';

const EditPodcastEpisode = () => {
  const { episodeId } = useParams<{ episodeId: string }>();

  const [episodeSections, setEpisodeSections] = useState<Descendant[][]>([]);
  const [sections, setSections] = useState<
    (IPodcastEpisodeSection & { hasChanges?: boolean })[]
  >([]);
  const [episode, setEpisode] = useState<IPodcastEpisode>();
  const [loading, setLoading] = useState<boolean>(false);
  const [podcastInfo, setPodcastInfo] = useState<{
    podcastId: string;
    hostName: string;
    podcastName: string;
  }>();
  const [isConfirming, setIsConfirming] = useState<boolean>(false);
  const [generatingPercent, setGeneratingPercent] = useState<number>(0);
  const [generatingSection, setGeneratingSection] = useState(false);
  const [sectionsGenerating, setSectionsGenerating] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (
      episode?.status === EpisodeStatus.GENERATING_AUDIO &&
      !episode.audioUrl &&
      podcastInfo?.podcastId
    ) {
      setIsConfirming(true);
      generateAudio();
    }
  }, [episode?.status, episode?.audioUrl, podcastInfo?.podcastId]);

  useEffect(() => {
    setLoading(true);
    graphQlCall({
      queryTemplateObject: queries.GET_EPISODE_INFO,
      values: { id: episodeId },
    })
      .then(
        (data: {
          episode: IPodcastEpisode;
          podcastInfo: { id: string; name: string; hostName: string };
        }) => {
          if (!data) return;
          setEpisode(data.episode);
          setPodcastInfo({
            podcastId: data.podcastInfo.id,
            hostName: data.podcastInfo.hostName,
            podcastName: data.podcastInfo.name,
          });
          const sectionsWithIndex = data.episode.sections.map(
            (section, index) => {
              return { ...section, index };
            }
          );
          setSections(sectionsWithIndex);
          triggerEditorUpdate(sectionsWithIndex, data.episode);
        }
      )
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, [episodeId]);

  useEffect(() => {
    const generateText = async (section: IPodcastEpisodeSection) => {
      if (episode) {
        let prompt;
        let notes = '';
        if (section.notes && section.notes !== '') {
          notes = `Use this notes while writing: ${section.notes}`;
        }
        if (section.index === 0) {
          prompt = `You are writing script for podcast episode with a name: ${episode.name}. This is a very first section that is titled: ${section.title}. Make sure to welcome listeners and mention your name is ${podcastInfo?.hostName} and podcast name is ${podcastInfo?.podcastName}. ${notes}  While writing make sure using ${episode.tone} tone. Only return text.`;
        } else if (section.index === sections.length - 1) {
          prompt = `You are writing script for podcast episode with a name: ${episode.name}. This is a last section that titled: ${section.title}. Make sure to say goodby to listeners at the end of this section. ${notes} While writing please make sure using ${episode.tone} tone. Only return text.`;
        } else {
          prompt = `You are writing script for podcast episode with a name: ${episode.name}. This is a middle section that titled: ${section.title}. Do not welcome back listeners you are continuing your script.${notes} While writing please make sure using ${episode.tone} tone. This script is for one person only. Do not mention section title. Only return text. `;
        }

        const payload = {
          episodeId: episode._id,
          sectionId: section._id,
          prompt: prompt,
          jsonMode: true,
          token: getToken(),
        };

        const response: any = await getBySocket({
          emitEventName: 'generate-episode-section-text',
          resultEventName: 'episode-section-response',
          payload,
        });

        return { sectionId: section._id, text: response.result };
      }
    };

    const unfinishedSections = sections.filter(
      (section) => section.state === 'INITIAL'
    );
    if (unfinishedSections.length === 0) return;

    setSectionsGenerating(true);
    Promise.all(
      unfinishedSections.map((section) => generateText(section))
    ).then((results) => {
      for (const result of results) {
        const section = sections.find((el) => el._id === result?.sectionId);
        if (!section) continue;

        section.text = result?.text;
        section.state = 'TEXT_COMPLETED';
      }

      const newSections = [...sections];
      setSections(newSections);
      setSectionsGenerating(false);
      triggerEditorUpdate(newSections, episode);
    });
  }, [sections, episode, podcastInfo]);

  const triggerEditorUpdate = (
    rawSections: IPodcastEpisodeSection[],
    episodeData?: IPodcastEpisode
  ) => {
    if (sectionsGenerating || !episodeData) return;

    const mappedSections = rawSections.map((section, index) => {
      const sectionParts: Descendant[] = [];

      if (section.title) {
        sectionParts.push({
          type: 'title',
          children: [{ text: section.title }],
          id: section?._id,
        });
      }
      if (index === 0 && episodeData.music.introUrl) {
        sectionParts.push({
          type: 'audio',
          data: {
            audioUrl: episodeData.music.introUrl,
            imageSrc: `https://cdn.autofunnel.ai/podcaster_music/${episodeData.music.id}.jpg`,
            label: 'Intro',
            id: episodeData.music.introUrl + index,
          },
          children: [{ text: '' }],
        });
      }
      if (section.text) {
        sectionParts.push({
          type: 'paragraph',
          children: [{ text: section.text }],
        });
      }
      if (index !== rawSections.length - 1 && episodeData.music.transitionUrl) {
        sectionParts.push({
          type: 'audio',
          data: {
            audioUrl: episodeData.music.transitionUrl,
            imageSrc: `https://cdn.autofunnel.ai/podcaster_music/${episodeData.music.id}.jpg`,
            label: 'Transition',
            id: episodeData.music.transitionUrl + index,
          },
          children: [{ text: '' }],
        });
      }
      if (index === rawSections.length - 1 && episodeData.music.outroUrl) {
        sectionParts.push({
          type: 'audio',
          data: {
            audioUrl: episodeData.music.outroUrl,
            imageSrc: `https://cdn.autofunnel.ai/podcaster_music/${episodeData.music.id}.jpg`,
            label: 'Outro',
            id: episodeData.music.outroUrl + index,
          },
          children: [{ text: '' }],
        });
      }
      return sectionParts;
    });
    setEpisodeSections([...mappedSections]);
  };

  const generateAudio = () => {
    if (!episode) {
      return;
    }

    const socket = createSocket();

    socket.emit('generate-episode-audio-2', {
      episodeId: episode._id,
      token: getToken(),
    });

    socket.on('episode-audio-response-2', (res) => {
      if (res.percent) {
        setGeneratingPercent(res.percent);

        if (res.percent >= 100) {
          setTimeout(() => handleRedirectToPodcasterPage(), 2000);
        }
      }
    });
  };

  const handleEpisodeConfirm = async () => {
    try {
      setIsConfirming(true);
      if (!episode) {
        return;
      }

      const changedSections = sections.filter((section) => section.hasChanges);
      if (changedSections.length > 0) {
        await graphQlCall({
          queryTemplateObject: queries.UPDATE_EPISODE_SECTIONS_MUTATION,
          values: {
            episodeId,
            sections: JSON.stringify(
              changedSections.map((section) => ({
                id: section._id,
                title: section.title,
                text: section.text,
              }))
            ),
          },
          headerType: 'USER-AUTH',
        });
      }

      await graphQlCall({
        queryTemplateObject: queries.REORDER_EPISODE_SECTIONS,
        values: {
          episodeId,
          sectionIds: sections.map((section) => section._id),
        },
        headerType: 'USER-AUTH',
      });

      generateAudio();
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddNewSection = async () => {
    setGeneratingSection(true);
    const response: any = await getBySocket({
      emitEventName: 'ai-single-prompt',
      resultEventName: 'response-ai-single-prompt',
      payload: {
        text: `I have podcast with "${
          podcastInfo?.podcastName
        }" name and an episode in it with "${episode?.name}" name. 
        In that episode there are ${sections.length} sections of content and 
        the last one named ${sections[sections.length - 1].title}. 
        Generate a new section name that will be at the end of sections list. Just return only one name option without any additions from your side`,
      },
    });
    const data = await graphQlCall({
      queryTemplateObject: queries.ADD_EPISODE_SECTION,
      values: {
        episodeId,
        section: JSON.stringify({
          title: response.result.text.replaceAll('"', ''),
        }),
      },
      headerType: 'USER-AUTH',
    });
    const newSections = [
      ...sections,
      {
        ...data.sections[data.sections.length - 1],
        index: data.sections.length - 1,
      },
    ];
    setSections(newSections);
    triggerEditorUpdate(newSections, episode);
    setGeneratingSection(false);
  };

  const onSectionElementClick = (index: number) => {
    const section = document.getElementById('section ' + (index + 1));
    if (!section) return;

    section.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'start',
    });
  };

  const mergeChildrenText = (children: Element[]) => {
    let output = '';
    for (let i = 0; i < children.length; i++) {
      const child = children[i];
      output += (child.children[0] as CustomText).text;
      if (i !== children.length - 1) output += '\n';
    }
    return output;
  };

  const handleTextChange = (index: number, editorValues: Element[]) => {
    const section = sections[index];
    if (!section) return;

    const newSectionTitles = editorValues.filter((el) => el.type === 'title');
    if (
      newSectionTitles.length &&
      section.title !== mergeChildrenText(newSectionTitles)
    ) {
      section.title = mergeChildrenText(newSectionTitles);
      section.hasChanges = true;
    }

    const newSectionParagraphs = editorValues.filter(
      (el) => el.type === 'paragraph'
    );
    if (
      newSectionParagraphs.length > 0 &&
      section.text !== mergeChildrenText(newSectionParagraphs)
    ) {
      section.text = mergeChildrenText(newSectionParagraphs);
      section.hasChanges = true;
    }

    if (section.hasChanges) setSections(Array.from(sections));
  };

  const handleRedirectToPodcasterPage = () => {
    history.push(`/console/podcaster/${podcastInfo?.podcastId}`);
  };

  if (loading) {
    return (
      <div className={styles.loaderContainer}>
        <Loader color="#d0d0d0" />
        Generation in progress...
      </div>
    );
  }

  const handleChangingSectionTitle = (index: number, value: string) => {
    const updatedSections = [...sections];
    updatedSections[index].title = value;
    updatedSections[index].hasChanges = true;

    setSections(updatedSections);
    triggerEditorUpdate(updatedSections, episode);
  };

  const userAuthed = validateUser();
  if (!userAuthed) {
    return <Redirect to={{ pathname: '/console/login' }} />;
  }

  return (
    <div>
      <EditHeader
        showConfirmButton={!isConfirming}
        pageName={episode?.name || ''}
        title="Episode name"
        handleConfirm={handleEpisodeConfirm}
        handleGoBack={handleRedirectToPodcasterPage}
        buttonPlaceholder="Confirm"
      />
      <div className={styles.wrapper}>
        {isConfirming && (
          <div className={styles.confirmLoading}>
            <Loader size={30} color="#d0d0d0" />
            <div className={styles.loaderText}>
              In progress... {generatingPercent.toFixed()}%
            </div>
          </div>
        )}
        <EditorSidebar
          header={
            <div className={styles.sidebarTitle}>
              <PodcastIcon />
              Episode Sections
            </div>
          }
          elements={sections}
          elementName="section"
          isElementGenerating={generatingSection}
          onElementClick={onSectionElementClick}
          onAddButtonClick={handleAddNewSection}
          onElementTitleChange={handleChangingSectionTitle}
          onElementDragEnd={(orderedSections) => {
            setSections(orderedSections);
            triggerEditorUpdate(orderedSections, episode);
          }}
          tooltipElements={[
            {
              type: 'regenerate',
              onClick: (section) => {
                section.state = 'INITIAL';
                const newSections = Array.from(sections);
                setSections(newSections);
                triggerEditorUpdate(newSections, episode);
              },
            },
            {
              type: 'remove',
              onClick: (section) => {
                graphQlCall({
                  queryTemplateObject: queries.DELETE_EPISODE_SECTION_MUTATION,
                  values: { episodeId, sectionId: section._id },
                  headerType: 'USER-AUTH',
                }).then(() => {
                  const newSections = sections.filter(
                    (el) => el._id !== section._id
                  );
                  setSections(newSections);
                  triggerEditorUpdate(newSections, episode);
                });
              },
            },
          ]}
        />
        <div className={styles.contentWrapper}>
          {sectionsGenerating ? (
            <div className={styles.loaderContainer}>
              <Loader color="#d0d0d0" />
              Generation in progress...
            </div>
          ) : (
            episodeSections.map((section, index) => {
              return (
                <div key={sections[index]?._id}>
                  <div className={styles.sectionContent}>
                    <div
                      id={'section ' + (index + 1)}
                      className={styles.indexIndicator}
                    >
                      {index + 1}
                    </div>
                    <div className={styles.textWrapper}>
                      <TextEditor
                        keepDefaultLayout
                        initialValue={section}
                        onChange={(editorValues) =>
                          handleTextChange(index, editorValues as Element[])
                        }
                      />
                    </div>
                  </div>
                  {index !== episodeSections.length - 1 && (
                    <hr className={styles.separator} />
                  )}
                </div>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

export default EditPodcastEpisode;
