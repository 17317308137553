export const GET_BROADCASTS_STATISTICS = {
  operationName: 'getBroadcastsStatistic',
  query: `query getBroadcastsStatistic($ids: [String!]!) {
            getBroadcastsStatistic(ids: $ids) {
                delivered
                opened
                clicked
            }
          }`,
};
