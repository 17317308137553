export const UPDATE_MAIL_DRAFT_MUTATION = {
  operationName: 'updateMailDraft',
  query: `mutation updateMailDraft($id:String!, $html: String!, $text: String!, $name: String!, $data: String!, $subject: String!) {
                updateMailDraft(id: $id, html: $html, text: $text, name: $name, data: $data, subject: $subject) {
                  _id
                  html
                  text
                  name
                  data
                  createdAt
                  updatedAt 
                }
              }`,
};
