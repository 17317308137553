import React from 'react';
import Button from './Button';
import AlertIcon from '../icons/alert.svg';
import Popup from 'UILib/Popup/Popup';
import s from './popups.module.scss';

interface IProps {
  confirmDelete: () => void;
  onClose: () => void;
}

const DeletePopup = (props: IProps) => {
  return (
    <Popup onClose={props.onClose}>
      <div className={s.modalBody}>
        <img src={AlertIcon} className={s.personIcon} />
        <h2 className={s.popupHeader}>Delete Account</h2>
        <p className={s.popupText}>
          Are you sure that you want to delete the account? It will no longer be
          available to you. <br />
          All data will be lost!
        </p>
        <Button name="Delete" onClick={() => props.confirmDelete()} />
      </div>
    </Popup>
  );
};

export default DeletePopup;
