export const CHANGE_USERS_SUBSCRIPTION = {
  operationName: 'changeUsersSubscription',
  query: `mutation changeUsersSubscription(
        <KEYS>
      ) {
        changeUsersSubscription(
          <VALUES>
        ) 
      }`,
};
