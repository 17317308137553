import { HfInferenceEndpoint } from '@huggingface/inference';

export const createSSEConnection = (url) => {
  return new HfInferenceEndpoint(url);
};

export const createSSEStream = (connection, prompts, parameters) => {
  if (!connection) {
    throw new Error('no SSE connection');
  }
  return connection.textGenerationStream({
    inputs: prompts,
    parameters,
  });
};
