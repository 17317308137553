import { PayloadAction } from '@reduxjs/toolkit';
import { BaseSelection, Editor } from 'slate';
import { IUserProduct, BookleTemplateBlock } from 'types';
import {
  UPDATE_BOOKLE_TEMPLATE_BLOCKS,
  UPDATE_SETTINGS_POPUP,
  UPDATE_BOOKLE_TEMPLATE_HOVERED_BLOCK,
  UPDATE_BOOKLE_TEMPLATE_TEXT_EDITOR,
  UPDATE_BOOKLE_TEMPLATE_BLOCK_STYLES,
  UPDATE_ACTIVE_BOOKLE_TEMPLATE,
} from './booksActions';

export interface INewPopup {
  open: boolean;
  initialState?: IUserProduct;
}

export interface IBookleTemplateEditor {
  editor?: Editor;
  selection?: BaseSelection;
}

export interface IBookleTemplateBlockStyles {
  bodyColor?: string;
  bodyWidth?: number;
  emailBg?: string;
}

export const defaultTemplateStyles = {
  bodyWidth: 600,
  bodyColor: '#ffffff',
  emailBg: '#ffffff',
};

export interface IBooksState {
  settingsPopup: {
    open: boolean;
    initialState?: IUserProduct;
  };
  bookleTemplateBlocks: BookleTemplateBlock[];
  bookleTemplateHoveredBlock: BookleTemplateBlock | undefined;
  bookleTemplateTextEditor: IBookleTemplateEditor;
  bookleTemplateBlockStyles: IBookleTemplateBlockStyles;
  activeBlock: string | null;
}

const initialState: IBooksState = {
  settingsPopup: {
    open: false,
    initialState: undefined,
  },
  bookleTemplateBlocks: [],
  bookleTemplateHoveredBlock: undefined,
  bookleTemplateTextEditor: {
    editor: undefined,
    selection: undefined,
  },
  bookleTemplateBlockStyles: {
    ...defaultTemplateStyles,
  },
  activeBlock: null,
};

const booksReducer = (
  state = initialState,
  action: PayloadAction<
    | INewPopup
    | BookleTemplateBlock[]
    | BookleTemplateBlock
    | IBookleTemplateEditor
    | IBookleTemplateBlockStyles
    | string
    | null
  >
): IBooksState => {
  switch (action.type) {
    case UPDATE_SETTINGS_POPUP:
      let newPopup = { ...initialState.settingsPopup };
      if (typeof action.payload !== 'boolean') {
        newPopup = {
          ...newPopup,
          ...(action.payload as INewPopup),
        };
      }
      return {
        ...state,
        settingsPopup: newPopup,
      };
    case UPDATE_BOOKLE_TEMPLATE_BLOCKS:
      return {
        ...state,
        bookleTemplateBlocks: action.payload as BookleTemplateBlock[],
      };
    case UPDATE_BOOKLE_TEMPLATE_HOVERED_BLOCK:
      return {
        ...state,
        bookleTemplateHoveredBlock: action.payload as BookleTemplateBlock,
      };
    case UPDATE_BOOKLE_TEMPLATE_TEXT_EDITOR:
      return {
        ...state,
        bookleTemplateTextEditor: action.payload as IBookleTemplateEditor,
      };
    case UPDATE_BOOKLE_TEMPLATE_BLOCK_STYLES:
      return {
        ...state,
        bookleTemplateBlockStyles: action.payload as IBookleTemplateBlockStyles,
      };
    case UPDATE_ACTIVE_BOOKLE_TEMPLATE:
      return {
        ...state,
        activeBlock: action.payload as string | null,
      };
    default:
      return state;
  }
};

export { initialState, booksReducer };
