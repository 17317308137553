export const GET_ALL_BOOK_REQUESTS = {
  operationName: 'getAllBookRequests',
  query: `query getAllBookRequests (
      $skip: Float
      $limit: Float
      $tone: String
    ) {
      getAllBookRequests (
        skip: $skip
        limit: $limit
        tone: $tone
      ) {
        requests {
          _id
          title
          description
          tone
          createdAt
        }
        count
      }
    }`,
};
