// import ManipulatorActions from 'Components/Toolbar/ToolbarManipulator/ManipulatorActions/ManipulatorActions';
import { Block } from 'CoverEditor/types';

import styles from './Overlay.module.scss';

interface IProps {
  block: Block;
}

const Overlay = (props: IProps) => {
  const handleToggleManipulatorVisibility = (value: boolean) => {};

  return (
    <div className={styles.container}>
      {/* <ManipulatorActions
        block={props.block}
        alignment="end"
        onToggleManipulatorVisibility={handleToggleManipulatorVisibility}
      /> */}
    </div>
  );
};

export default Overlay;
