import axios, { AxiosRequestConfig, Method } from 'axios';
import {
  AUTH_USER_TOKEN_KEY,
  StripeProducts,
  SOCKET_URL,
  NO_SOCKET_CREDENTIALS,
  ENCRYPTION_KEY,
} from '../Constants';
import { createBrowserHistory } from 'history';
import { Auth } from 'aws-amplify';
import { io, Socket } from 'socket.io-client';
import { graphQlCall } from 'graphql/utils';
import queries from 'graphql/queries';
import aes from 'crypto-js/aes';

const jwtDecode = require('jwt-decode');
const history = createBrowserHistory();
const SUB_TOKEN_NAME = 'SUB_TOKEN_NAME';
const SUB_REFRESH_TOKEN_NAME = 'SUB_REFRESH_TOKEN_NAME';
const ACTIVE_SUB_ID_NAME = 'ACTIVE_SUB_ID_NAME';

export const validateToken = async (
  token: string,
  hasAgency?: boolean
): Promise<string> => {
  return new Promise(async (resolve, reject) => {
    if (!token) {
      reject('Token is Empty');
    }
    try {
      let pureToken = token;
      if (token.includes('Bearer')) {
        const arr = token.split(' ');
        pureToken = arr[1];
      }
      const decodedJwt = jwtDecode(pureToken);

      if (decodedJwt.exp >= Date.now() / 1000) {
        resolve(token);
      } else {
        let newToken = await refreshToken(hasAgency);
        if (newToken) {
          resolve(String(newToken));
        } else {
          reject('Token Refresh Error ');
        }
      }
    } catch (e) {
      return false;
    }
  });
};

export const username = (token: string) => {
  if (!token) {
    return '';
  }
  try {
    const decodedJwt = jwtDecode(token);
    return decodedJwt.sub;
    // return decodedJwt.username;
  } catch (e) {
    return '';
  }
};

interface IRefSession {
  accessToken: {
    jwtToken: string;
  };
}

export const refreshToken = (hasAgencyToken?: boolean) => {
  return new Promise(async (resolve, reject) => {
    let user;
    try {
      const activeSubAccount = getActiveSubId();
      if (hasAgencyToken || !activeSubAccount) {
        user = await Auth.currentAuthenticatedUser();
        let refreshToken = user.getSignInUserSession().getRefreshToken();
        user.refreshSession(
          refreshToken,
          (refErr: Error, refSession: IRefSession) => {
            if (refErr) {
              console.error(refErr);
              reject(refErr);
            } else {
              localStorage.setItem(
                AUTH_USER_TOKEN_KEY,
                refSession.accessToken.jwtToken
              );
              resolve(refSession.accessToken.jwtToken);
            }
          }
        );
      } else if (activeSubAccount) {
        const oldRefreshToken = getSubRefreshToken();
        const res = await graphQlCall({
          queryTemplateObject: queries.REFRESH_TOKEN,
          values: {
            refreshToken: oldRefreshToken,
          },
        });
        const { token, refreshToken } = res;
        storeSubToken(token);
        storeSubRefreshToken(refreshToken);
        resolve(token);
      }
    } catch (error) {
      console.error(error);
      reject(error);

      if (!window.location.href.includes('login')) {
        window.location.replace('/console/login');
      }
    }
  });
};

export const getUserName = () => {
  return username(localStorage.getItem(AUTH_USER_TOKEN_KEY) ?? '');
};

export const validateUser = () => {
  let token = localStorage.getItem(AUTH_USER_TOKEN_KEY);
  if (token === null) {
    return false;
  }
  return validateToken(token);
};

interface IStringMap {
  [key: string]: any;
}

export const api = async <T = any>(
  url: string,
  method: Method = 'GET',
  data: IStringMap | null = null,
  headers: IStringMap = {}
): Promise<T> => {
  let hasAgency = false;
  if (headers.authorization) {
    if (getAgencyToken() === headers.authorization) {
      hasAgency = true;
    }

    headers.authorization = await validateToken(
      headers.authorization,
      hasAgency
    );
  }

  if (data?.token) {
    if (getAgencyToken() === data.token) {
      hasAgency = true;
    }
    data.token = await validateToken(data.token, hasAgency);
  }

  if (data?.token && !validateToken(data.token)) {
    localStorage.removeItem('USER_ID');
    localStorage.removeItem(AUTH_USER_TOKEN_KEY);
    localStorage.removeItem('USER_EMAIL');
    localStorage.removeItem('USER_SUBDOMAIN');
    history.replace({ pathname: '/console/login' });
  }

  const payload: AxiosRequestConfig = {
    method,
    url,
    headers: {
      ...headers,
    },
    withCredentials: false,
    data: data ?? undefined,
  };

  if (data) {
    payload.data = data;
  }

  return new Promise((resolve, reject) => {
    axios(payload)
      .then((data) => {
        if (data.data && data.data.error) {
          const error = data.data.error;
          if (error === 'token expire' || error === 'only for admin') {
            localStorage.getItem('USER_ID');
            history.replace({ pathname: '/console/login' });
          }
          console.error('API ERROR', error, 'PAYLOAD', payload);
          reject(error);
        }
        resolve(data.data);
      })
      .catch((err) => reject(err));
  });
};

export const get12Hours = (hours: number) => {
  return hours > 12 ? hours - 12 : hours === 12 ? 0 : hours === 0 ? 12 : hours;
};
export const getUserId = () => {
  return localStorage.getItem('USER_ID');
};

export const getToken = () => {
  const subToken = getSubToken();
  if (subToken) {
    return subToken;
  }
  return localStorage.getItem(AUTH_USER_TOKEN_KEY) ?? '';
};

export const getUserRole = () => {
  return localStorage.getItem('USER_ROLE');
};

export const isAdmin = () => {
  return getUserRole() === 'admin';
};

export function findProductByKey(productId: string) {
  for (const productKey in StripeProducts) {
    const product = StripeProducts[productKey as keyof typeof StripeProducts];

    for (const pricingKey in product) {
      const pricing = product[pricingKey as keyof typeof product];

      for (const idKey in pricing) {
        const item: { id: string; price: string } =
          pricing[idKey as keyof typeof pricing];

        if (item.id === productId) {
          return productKey;
        }
      }
    }
  }

  return false;
}

interface IGetSocketArgs {
  emitEventName: string;
  resultEventName: string;
  payload: any;
}

interface IGetSocketImageArgs {
  payload: any;
  callback?: (progress: any) => void;
}

export const generateAiImageBySocket = async (args: IGetSocketImageArgs) => {
  const { payload, callback } = args;
  return new Promise((resolve) => {
    const socket = createSocket();
    socket.emit('generate-openai-image', payload);
    socket.on('open-ai-image-generated', (data) => {
      console.log('open ai image response', data);
      if (data.imageUrls?.length) {
        resolve(data);
      } else if (callback) {
        callback({ id: data.id, error: data.error });
      }
    });
  });
};

export const getBySocket = async ({
  emitEventName,
  resultEventName,
  payload,
}: IGetSocketArgs) => {
  const socket = createSocket();
  return new Promise((resolve) => {
    socket.emit(emitEventName, payload);
    socket.on(resultEventName, (res: any) => {
      resolve(res);
    });
  });
};

export const getSubToken = () => {
  return localStorage.getItem(SUB_TOKEN_NAME);
};

export const getSubRefreshToken = () => {
  const token = localStorage.getItem(SUB_REFRESH_TOKEN_NAME);
  if (!token) {
    throw new Error('sub refresh token not exists');
  }
  return token;
};

export const storeSubToken = (token: string) => {
  localStorage.setItem(SUB_TOKEN_NAME, token);
};

export const storeSubRefreshToken = (refreshToken: string) => {
  localStorage.setItem(SUB_REFRESH_TOKEN_NAME, refreshToken);
};

export const getActiveSubId = () => {
  return localStorage.getItem(ACTIVE_SUB_ID_NAME);
};

export const storeActiveSubId = (id: string) => {
  localStorage.setItem(ACTIVE_SUB_ID_NAME, id);
};

export const getAgencyToken = () => {
  return localStorage.getItem(AUTH_USER_TOKEN_KEY);
};

export const switchSubAccount = async (id: string, owner: string) => {
  const { token, refreshToken } = await graphQlCall({
    queryTemplateObject: queries.CREATE_TOKEN,
    headerType: 'AGENCY_AUTH',
    values: {
      sub: owner,
    },
  });
  storeActiveSubId(id);
  storeSubRefreshToken(refreshToken);
  storeSubToken(token);
};

export const clearSubAccountStorage = () => {
  localStorage.removeItem(ACTIVE_SUB_ID_NAME);
  localStorage.removeItem(SUB_REFRESH_TOKEN_NAME);
  localStorage.removeItem(SUB_TOKEN_NAME);
};

export const createSocket = () => {
  return io(SOCKET_URL, {
    transports: ['websocket'],
  });
};

export const encrypt = (text: string): string => {
  return aes.encrypt(text, ENCRYPTION_KEY).toString();
};

export const decrypt = (text: string): string => {
  return aes.decrypt(text, ENCRYPTION_KEY).toString();
};
