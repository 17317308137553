import { useEffect, useState } from 'react';
import { notification } from 'antd';
import { ReactComponent as CloseSvg } from 'Assets/icons/book/close.svg';
import { ReactComponent as GiftSvg } from 'Assets/icons/gift.svg';
import { ReactComponent as CoinSvg } from 'Assets/icons/coin.svg';

import styles from './FreeCreditsPopup.module.scss';

export enum FreeCreditsPopupModes {
  Default,
  OutOfCredits,
  FreeLargeBook,
}

interface IProps {
  credits?: number;
  mode: FreeCreditsPopupModes;
  invitationLink: string;
  onPlanOpen: () => void;
  onClose: () => void;
}

const FreeCreditsPopup = ({
  invitationLink,
  onClose,
  credits,
  mode,
  onPlanOpen,
}: IProps) => {
  const [timeString, setTimeString] = useState('00:00:00');
  let _targetTime: number = 0;
  let _interval: any;

  useEffect(() => {
    const targetTimeString = localStorage.getItem('BOOKLE_FREE_LARGE_BOOK');
    if (targetTimeString !== null) {
      _targetTime = parseInt(targetTimeString);
    }

    updateTimer();
    _interval = setInterval(() => {
      updateTimer();
    }, 1000);
    return () => {
      clearInterval(_interval);
    };
  }, []);

  const updateTimer = () => {
    const delta = Math.floor((_targetTime - Date.now()) / 1000);
    if (delta < 0) {
      setTimeString('00:00:00');
      return;
    }

    const hours = Math.floor(delta / 60 / 60);
    const minutes = Math.floor(delta / 60) - hours * 60;
    const seconds = delta % 60;

    const hoursStr = hours.toString().padStart(2, '0');
    const minutesStr = minutes.toString().padStart(2, '0');
    const secondsStr = seconds.toString().padStart(2, '0');

    setTimeString(`${hoursStr}:${minutesStr}:${secondsStr}`);
  };

  const handleCopyInvitationLink = async () => {
    const shareText =
      'Hey! Check out this awesome tool to write books in minutes with AI!';
    if (navigator.share) {
      navigator
        .share({
          title: 'Writing Books with AI',
          text: shareText,
          url: invitationLink,
        })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    } else {
      await navigator.clipboard.writeText(`${shareText}\n${invitationLink}`);
      notification.success({
        message: 'Invitation Copied!',
        description:
          'Share this message with a friend to get some free credits.',
        placement: 'topRight',
        duration: 2,
      });
    }
  };

  const renderPopupBody = (mode: FreeCreditsPopupModes) => {
    switch (mode) {
      case FreeCreditsPopupModes.Default:
        return (
          <>
            <CloseSvg className={styles.closeIcon} onClick={onClose} />
            <div className={styles.bannerSection + ' ' + styles.hiddenOnMobile}>
              <CoinSvg width={27} height={27} className={styles.smallCoin} />
              <CoinSvg width={40} height={40} className={styles.mediumCoin} />
              <CoinSvg width={93} height={93} className={styles.bigCoin} />
              <GiftSvg width={40} height={40} className={styles.giftIcon} />
              <div className={styles.subtitle}>
                Invite your friends or colleagues and get
              </div>
              <div className={styles.title}>FREE CREDITS!</div>
            </div>
            <div className={styles.contentSection}>
              <div className={styles.title}>
                Get free credits when your friends generate their first page,
                book or subscribe!
              </div>
              <div className={styles.howWorks}>
                <div />
                How it works
                <div />
              </div>
              <ul>
                <li>
                  Invite your friends to try out Bookle using your special link
                  below.
                </li>
                <li>
                  For each friend that signs up and generates their first book,
                  you get <b> 1 credit</b>, up to <b>3 credits</b> per month.
                </li>
                <li>
                  For each friend that subscribes to a monthly plan, you get
                  <b> 3 credits</b>, with no monthly limit.
                </li>
                <li>Referral credits expire after 1 month.</li>
              </ul>
              <button
                className={styles.copyInvButton}
                onClick={handleCopyInvitationLink}
              >
                Copy Invitation
              </button>
            </div>
          </>
        );
      case FreeCreditsPopupModes.OutOfCredits:
        return (
          <>
            <CloseSvg className={styles.closeIcon} onClick={onClose} />
            <div className={styles.bannerSection}>
              <div className={styles.subtitle}>
                You're out of credits.
                <br />
                Current Credits: {credits}
              </div>
              <GiftSvg width={40} height={40} className={styles.giftIcon} />
              <div className={styles.title}>GET FREE CREDITS!</div>
              <div className={styles.subtitle}>
                You can receive a free credit when a friend signs up for Bookle!
                <br />
                Just send them your special link here:
              </div>

              <div className={styles.contentSection}>
                <button
                  className={styles.copyInvButton}
                  onClick={handleCopyInvitationLink}
                >
                  {navigator.share !== undefined
                    ? 'Share Now'
                    : 'Copy Invitation'}
                </button>
                <div className={styles.howWorks}>
                  <div style={{ opacity: 0.3 }} />
                </div>
                <div className={styles.subtitle} style={{ marginTop: '0px' }}>
                  Rather upgrade instead?
                  <br />
                  View your available plan options!
                </div>
                <button
                  className={
                    styles.copyInvButton + ' ' + styles.copyInvButtonBordered
                  }
                  onClick={onPlanOpen}
                >
                  Plan Options
                </button>
              </div>
            </div>
          </>
        );
      case FreeCreditsPopupModes.FreeLargeBook:
        return (
          <>
            <CloseSvg className={styles.closeIcon} onClick={onClose} />
            <div className={styles.bannerSection}>
              <GiftSvg width={60} height={60} className={styles.giftIcon} />
              <div className={styles.subtitle}>
                <b>Claim Your FREE Large Book</b>
                <br />
                <b>Before the timer runs out!</b>
              </div>
              <div className={styles.title}>{timeString}</div>
              <div className={styles.subtitle}>
                Invite a friend to join and receive a <b>Free Large Book</b>!
              </div>
              <div className={styles.subtitle}>
                Click below to share with a friend:
              </div>
              <div className={styles.contentSection}>
                <button
                  className={styles.copyInvButton}
                  onClick={handleCopyInvitationLink}
                >
                  Share Now!
                </button>
              </div>
            </div>
          </>
        );
    }
  };

  return (
    <div className={styles.popup}>
      <div className={styles.overlay} onClick={onClose} />
      <div className={styles.wrapper}>{renderPopupBody(mode)}</div>
    </div>
  );
};

export default FreeCreditsPopup;
