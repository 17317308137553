import Spinner from 'Components/Common/Spinner/Spinner';
import QUERIES from 'graphql/queries';
import { graphQlCall } from 'graphql/utils';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import s from './ConfirmTransfer.module.scss';

const ConfirmTransfer = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const token = params.get('token');
  const initiator = params.get('initiator');

  useEffect(() => {
    if (token && initiator) {
      processConfirmTransfer(token, initiator);
    }
  }, [token, initiator]);

  const processConfirmTransfer = async (token: string, initiator: string) => {
    await graphQlCall({
      queryTemplateObject: QUERIES.CONFIRM_TRANSFER_TO_AGENCY,
      values: {
        initiator,
        token,
      },
    });
    window.open('/console/login', '_self');
  };

  return (
    <main className={s.content}>
      <h2 className={s.header}>Account transition in progress ...</h2>
      <div className={s.spinnerBlock}>
        <Spinner size={300} />
      </div>
    </main>
  );
};

export default ConfirmTransfer;
