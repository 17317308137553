import Domains from './Domains/Domains';
import Senders from './Senders/Senders';

import s from './DomainsAndSenders.module.scss';

const DomainsAndSenders = () => {
  return (
    <>
      <Domains />
      <hr className={s.splitLine} />
      <Senders />
    </>
  );
};

export default DomainsAndSenders;
