import React from 'react';
import { connect } from 'react-redux';
import { Alert } from 'antd';
import styled from 'styled-components';
import { ReactComponent as ErrorIcon } from '../Assets/alertsIcons/error.svg';

import { RootState } from 'store/rootReducer';
import { IAlert } from 'types';

const AlertsIcons = {
  error: <ErrorIcon />,
  success: null,
  info: null,
  warning: null,
};

const StyledAlerts = styled.div`
  position: fixed;
  bottom: 50px;
  left: 10px;
  z-index: 1;

  .ant-alert {
    border-radius: 10px;
    margin-bottom: 10px;

    .ant-alert-description,
    .ant-alert-message {
      color: #fff;
    }

    &.ant-alert-error {
      background-color: #ff5000;
      border-color: #ff5000;
    }

    &.ant-alert-success {
      .ant-alert-description,
      .ant-alert-message {
        color: #000;
      }
    }
  }
`;

const getText = (value: any): string => {
  return typeof value === 'object' ? JSON.stringify(value) : String(value);
};

interface IProps {
  alerts: IAlert[];
}

const Alerts = (props: IProps) => {
  return (
    <StyledAlerts>
      {props.alerts.map((item, index) => (
        <Alert
          message={getText(item.text)}
          type={item.type}
          description={getText(item.description)}
          key={`alert-${index}`}
          showIcon={true}
          icon={AlertsIcons[item.type]}
        />
      ))}
    </StyledAlerts>
  );
};

const mapStateToProps = (state: RootState) => ({
  alerts: state.alerts.items,
});

export default connect(mapStateToProps, null)(Alerts);
