import { ReactComponent as CoinSvg } from 'Assets/icons/coin.svg';
import { ReactComponent as AddSvg } from 'Assets/images/PageProjectsNavigation/add.svg';
import { ReactComponent as GiftSvg } from 'Assets/icons/gift2.svg';
import s from './Credits.module.scss';
import clsx from 'clsx';

interface Iprops {
  aiCredits: number | undefined;
  isSidebarOpen: boolean;
  onFreeCreditsClick: () => void;
  onUpgradePlanClick: () => void;
}

const Credits = ({
  aiCredits,
  isSidebarOpen,
  onFreeCreditsClick,
  onUpgradePlanClick,
}: Iprops) => {
  return (
    <div
      className={clsx(s.creditsContainer, {
        [s.creditsContainerHidden]: !isSidebarOpen,
        [s.creditsContainerCloseNawbar]: !isSidebarOpen,
      })}
    >
      <div className={s.creditsInfo}>
        <CoinSvg width={20} />
        <span className={s.credit}>
          {aiCredits}
          {aiCredits === 1 ? 'Credit' : 'Credits'}
        </span>
        <div></div>
        <AddSvg className={s.addSvgBackground} onClick={onFreeCreditsClick} />
      </div>
      <button className={s.freeCreditsButton} onClick={onUpgradePlanClick}>
        <span>
          <GiftSvg />
        </span>
        <span>Free Credits</span>
      </button>
    </div>
  );
};

export default Credits;
