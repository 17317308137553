export const INITIATE_TRANSFER_BY_USER = {
  operationName: 'initiateTransferToAgencyByUser',
  query: `mutation initiateTransferToAgencyByUser(
      $email: String!
    ) {
      initiateTransferToAgencyByUser(
        email: $email
      ) {
        _id
        name
        owner
        email
        notes
        thumbnail
        requestedAgencyId
      }
    }`,
};
