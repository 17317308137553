export const CHECK_DOMAIN_STATUS_QUERY = {
  operationName: 'checkDomainStatus',
  query: `query checkDomainStatus(
      $id: String!
    ) {
      checkDomainStatus(
        id: $id
      ) {
        _id
        name
        projectId
        status
      }
    }`,
};
