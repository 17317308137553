// import { ReactComponent as PointerIcon } from 'Assets/icons/pointer.svg';
import Pagination from 'UILib/Pagination/Pagination';
import Dropdown from 'UILib/Dropdown/Dropdown';

import s from 'Pages/PageContacts/Footer/Footer.module.scss';

interface FooterProps {
  pageSize: number;
  dropdownOptions: { value: string; label: string }[];
  handleDropdownChange: (value: string) => void;
  currentPage: number;
  totalPages: number;
  handlePageChange: (page: number) => void;
}

export function Footer(props: FooterProps) {
  return (
    <div className={s.head}>
      <div className={s.block}>.</div>
      <div className={s.headRight}>
        <div className={s.totalColumn}>
          {/* Columns
          <PointerIcon /> */}
          Page {props.currentPage} of {props.totalPages}
        </div>
        <div className={s.hr} />
        <div className={s.total}>
          <Dropdown
            className={s.listPage}
            label={`Page Size: ${props.pageSize}`}
            options={props.dropdownOptions}
            onChange={props.handleDropdownChange}
            hasErrors={false}
          />
        </div>
        <Pagination
          currentPage={props.currentPage}
          totalPages={props.totalPages}
          onPageChange={props.handlePageChange}
        />
      </div>
    </div>
  );
}
