import React from 'react';
import s from './popups.module.scss';

interface IProps {
  onChange: (value: string) => void;
  value: string;
  placeholder: string;
}

const Textarea = (props: IProps) => {
  return (
    <div className={s.textareaBlock}>
      <textarea
        value={props.value}
        onChange={(e) => props.onChange(e.target.value)}
        placeholder={props.placeholder}
      />
    </div>
  );
};

export default Textarea;
