export const CREATE_AI_TEXT_TOPIC_MUTATION = {
  operationName: 'createAiTextTopic',
  query: `mutation createAiTextTopic($name: String!) {
      createAiTextTopic(name: $name) {
        _id
        name
        createdAt
        updatedAt
      }
    }`,
};
