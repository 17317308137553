import { BookleTemplateBlock } from 'types';
import Tabs from 'Components/Templates/Tabs/Tabs';
import DividerTab from './DividerTab/DividerTab';
import ContainerStyleBlock from '../ContainerStyleBlock/ContainerStyleBlock';

import styles from './DividerBlockStyleContainer.module.scss';

interface IProps {
  item: BookleTemplateBlock;
}

const DividerBlockStyleContainer = ({ item }: IProps) => {
  return (
    <div className={styles.container}>
      <Tabs
        className={styles.tabsWrapper}
        tabsHeader={styles.tabsHeader}
        contentClassName={styles.contentContainer}
        titleClassName={styles.titleContainer}
        hideAdditionalLines
        tabs={[
          {
            title: 'Divider',
            content: <DividerTab item={item} />,
          },
          {
            title: 'Container',
            content: <ContainerStyleBlock item={item} />,
          },
        ]}
      />
    </div>
  );
};

export default DividerBlockStyleContainer;
