import React, { useState, useEffect } from 'react';
import closeSvg from '../../Assets/icons/close.svg';
import s from './popup.module.scss';
interface IProps {
  isOpen: boolean;
  onClose: () => void;
}
function DomainHasBeenDeletePopup(props: IProps) {
  useEffect(() => {
    if (props.isOpen) {
      setTimeout(() => {
        handleClose();
      }, 3000);
    }
  }, [props.isOpen]);

  const handleClose = () => {
    props.onClose();
  };
  return (
    <div
      className={`${s.domainHasBeenDeletePopup} ${props.isOpen ? s.show : ''}`}
    >
      <img
        className={s.closeIcon}
        src={closeSvg}
        alt="x"
        onClick={() => handleClose()}
      />
      <p>Your domain has been removed</p>
    </div>
  );
}

export default DomainHasBeenDeletePopup;
