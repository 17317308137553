export const GET_BOOKS_WITH_PAGINATION = {
  operationName: 'getBooksWithPagination',
  query: `query getBooksWithPagination($skip: Float!, $limit: Float!, $search: String, $sortAsc: Boolean, $sortBy: String,) {
        getBooksWithPagination(skip: $skip, limit: $limit, search: $search, sortAsc: $sortAsc, sortBy: $sortBy) {
          books {
            _id
            title
            coverImageUrl
            pdfFile
            progress
            createdAt
            updatedAt
          }
          total
        }
      }`,
};
