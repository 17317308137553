export const UPDATE_PAGE_MUTATION = {
  operationName: 'updatePage',
  query: `mutation updatePage(
    $id: String!
    $name: String
    $headerScript: String
    $footerScript: String
    $enabled: Boolean
    $archived: Boolean
    $tagsId: [String!]
    ) {
      updatePage(
        id: $id
        name: $name
        headerScript: $headerScript
        footerScript: $footerScript
        enabled: $enabled
        archived: $archived
        tagsId: $tagsId
      ) {
        _id
        title
        description
        name
        enabled
        visited
        conversion
        archived
        general {
          _id
        }
        createdAt
        headerScript
        footerScript
        updatedAt
      }
    }`,
};
