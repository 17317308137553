export const ADD_SAAS_PLAN_PRODUCT = {
  operationName: 'addSaasPlanProduct',
  query: `mutation addSaasPlanProduct ($name: String!) {
                  addSaasPlanProduct (name: $name) {
                         _id
                        products {
                            name
                            stripeId
                            prices {
                                name
                                subHeader
                                description
                                price
                                paymentPeriod
                                currency
                                stripeId
                                apps {
                                    key
                                    enabled
                                    name
                                    items {
                                        name
                                        limit
                                        option
                                        unlimited
                                    }
                                }
                            }
                        }
                    }
                }`,
};
