import { DetailedHTMLProps, ButtonHTMLAttributes } from 'react';
import s from './PrimaryButton.module.scss';

interface IProps
  extends Omit<
    DetailedHTMLProps<
      ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    'name'
  > {
  width?: number;
  height?: number;
  fontSize?: number;
  borderRadius?: number;
  prefixImage?: React.ReactElement;
  postfixImage?: React.ReactElement;
  children?: React.ReactNode;
  isBlackBtn?: boolean;
}

function PrimaryButton({
  onClick,
  width,
  height,
  fontSize,
  borderRadius,
  isBlackBtn,
  prefixImage,
  postfixImage,
  children,
  ...res
}: IProps) {
  return (
    <div className={s.container}>
      <button
        style={{
          width: `${width}px`,
          height: `${height}px`,
          fontSize: `${fontSize}px`,
          borderRadius: `${borderRadius}px`,
        }}
        className={isBlackBtn ? s.buttonBlack : s.button}
        onClick={onClick}
        {...res}
      >
        {prefixImage}
        {children}
        {postfixImage}
      </button>
    </div>
  );
}

export default PrimaryButton;
