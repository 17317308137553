import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { getSessionInfo } from '../store/stripe/stripeActions';
import { RootState } from 'store/rootReducer';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

type DispatchType = ThunkDispatch<RootState, void, Action>;

interface IProps {
  email: string | null;
  getSessionInfo: (session: string) => void;
}

const SubmitSubscription = (props: IProps) => {
  interface IParams {
    session: string;
  }

  const history = useHistory();
  const { session } = useParams<IParams>();

  useEffect(() => {
    props.getSessionInfo(session);
  });

  useEffect(() => {
    if (props.email) {
      history.replace(`/console/signup/${props.email}`);
    }
  }, [props.email]);

  return (
    <Layout>
      <h2>Subscribed</h2>
      <p>Thank you for subscription!</p>
    </Layout>
  );
};

const mapStateToProps = (state: RootState) => ({
  email: state.stripe.email,
});

const mapDispatchToProps = (dispatch: DispatchType) => ({
  getSessionInfo: (sessionId: string) => dispatch(getSessionInfo(sessionId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubmitSubscription);
