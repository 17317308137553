export const SET_PAGES_ORDER_MUTATION = {
  operationName: 'setPagesOrder',
  query: `mutation setPagesOrder(
      $id: String!
      $order: String!
    ) {
      setPagesOrder(
        id: $id
        order: $order
      ) {
        _id
        order {
          id
          enabled
        }
      }
    }`,
};
