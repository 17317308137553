import {
  SYNC_STATUS,
  ADD_QUEUE,
  DONE_QUEUE,
  QUEUE_STATUSES,
} from './syncActions';
import { IQueue } from 'types';
import { PayloadAction } from '@reduxjs/toolkit';

export interface ISyncState {
  queue: IQueue[];
  status: string;
  info: string;
}

const initialState: ISyncState = {
  queue: [],
  status: QUEUE_STATUSES.READY,
  info: '',
};

const syncReducer = (
  state = initialState,
  action: PayloadAction<string | IQueue>
) => {
  switch (action.type) {
    case SYNC_STATUS:
      return {
        ...state,
        status: action.payload as string,
        info: '',
      };
    case ADD_QUEUE: {
      const queue = [...state.queue];
      queue.push(action.payload as IQueue);
      return {
        ...state,
        queue,
      };
    }
    case DONE_QUEUE: {
      const queue = state.queue.filter(
        (item) => item.queueId !== action.payload
      );
      return {
        ...state,
        queue,
        status: QUEUE_STATUSES.READY,
        info: '',
      };
    }
    default:
      return state;
  }
};

export { initialState, syncReducer };
