export const UPDATE_USER_MUTATION = {
  operationName: 'updateUser',
  query: `mutation updateUser(
      <KEYS>
    ) {
      updateUser(
        <VALUES>
      ) {
        _id
        name
        owner
        email
        role
        subdomain
        stripePublicKey
        stripeSecretKey
        stripeClientId
        active
        customData
      }
    }`,
};
