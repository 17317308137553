import { ReactNode, useEffect, useState } from 'react';
import { ReactComponent as PlusIcon } from 'Assets/icons/plus.svg';
import { ReactComponent as ReloadIcon } from 'Assets/icons/reload.svg';
import { ReactComponent as RemoveIcon } from 'Assets/icons/remove-icon.svg';
import clsx from 'clsx';
import Loader from 'UILib/Loader/Loader';
import Button from 'UILib/Button/Button';
import DragAndDrop from 'UILib/DragAndDrop/DragAndDrop';
import SectionElement from 'UILib/SectionElement/SectionElement';

import styles from './EditorSidebar.module.scss';

interface IProps<T> {
  header: ReactNode;
  elements: T[];
  elementName: string;
  onElementClick?: (index: number) => void;
  onElementDragEnd: (newElements: T[]) => void;
  onAddButtonClick?: () => void;
  onElementTitleChange: (index: number, newTitle: string) => void;
  isElementGenerating: boolean;
  tooltipElements?: {
    type: 'regenerate' | 'remove';
    title?: string;
    icon?: ReactNode;
    onClick: (element: T) => void;
  }[];
  borderType?: 'rounded' | 'round';
}

const tooltips = {
  regenerate: {
    title: 'Regenerate',
    icon: <ReloadIcon />,
  },
  remove: {
    title: 'Remove',
    icon: <RemoveIcon />,
  },
};

const EditorSidebar = <T extends { title: string }>({
  header,
  elements,
  elementName,
  onElementClick,
  onElementDragEnd,
  onAddButtonClick,
  onElementTitleChange,
  isElementGenerating,
  tooltipElements,
  borderType = 'rounded',
}: IProps<T>) => {
  const [selectedElement, setSelectedElement] = useState<number>();

  useEffect(() => {
    const checkTopSection = () => {
      elements.forEach((_, index) => {
        const element = document.getElementById(`${elementName} ${index + 1}`);
        if (element) {
          const rect = element.getBoundingClientRect();

          if (
            rect.top >= 0 &&
            rect.top <= (index === elements.length - 1 ? 700 : 400)
          ) {
            setSelectedElement(index);
          }
        }
      });
    };

    window.addEventListener('wheel', checkTopSection);

    return () => {
      window.removeEventListener('wheel', checkTopSection);
    };
  }, [elements, elementName]);

  const handleElementClick = (index: number) => {
    setSelectedElement(index);
    if (onElementClick) onElementClick(index);
  };

  return (
    <div className={styles.sidebar}>
      {header}
      <div className={styles.elementsWrapper}>
        <DragAndDrop
          droppableId="podcast"
          elements={elements}
          onDragEnd={(orderedSections) =>
            onElementDragEnd(orderedSections as T[])
          }
          draggableNodes={elements.map((element, index) => (
            <SectionElement
              key={index}
              indicator={index + 1}
              selected={index === selectedElement}
              onClick={() => handleElementClick(index)}
              onSectionTitleChange={(newValue) =>
                onElementTitleChange(index, newValue)
              }
              text={element.title}
              tooltipElements={tooltipElements?.map((tooltipElement) => ({
                title:
                  tooltipElement.title ?? tooltips[tooltipElement.type].title,
                icon: tooltipElement.icon ?? tooltips[tooltipElement.type].icon,
                onClick: () => {
                  tooltipElement.onClick(element);
                },
              }))}
              borderType={borderType}
            />
          ))}
        />
        {isElementGenerating && (
          <div className={styles.loadingContainer}>
            <div className={styles.loaderContainer}>
              <Loader size={12} color="#d0d0d0" />
            </div>
            Generation in Progress
          </div>
        )}
        {onAddButtonClick && (
          <Button
            width={40}
            height={40}
            theme="dark"
            appearance="stroke"
            className={clsx(styles.addButton, {
              [styles.round]: borderType === 'round',
            })}
            prefixIcon={<PlusIcon />}
            onClick={onAddButtonClick}
          />
        )}
      </div>
    </div>
  );
};

export default EditorSidebar;
