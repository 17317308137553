import Modal from 'Components/Common/Modal2/Modal';
import AlertIcon from 'Assets/icons/alert.svg';
import Button from 'Styled/Button2';

import s from './DeleteBookPopup.module.scss';

interface IProps {
  confirmDelete: () => void;
  onClose: () => void;
}

const DeletePopup = (props: IProps) => {
  return (
    <Modal displayModal={true} width={600} closeModal={props.onClose}>
      <div className={s.modalBody}>
        <img src={AlertIcon} className={s.alertIcon} alt="alert" />
        <h2 className={s.popupHeader}>Delete Book</h2>
        <p className={s.popupText}>
          Are you sure that you want to delete this book? It will no longer be
          available to you. <br />
          All data will be lost!
        </p>
        <div className={s.buttonsBlock}>
          <Button onClick={() => props.confirmDelete()} width={200} height={50}>
            Delete
          </Button>
          <Button onClick={() => props.onClose()} width={200} height={50}>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeletePopup;
