import clsx from 'clsx';
import { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import {
  Slate,
  Editable,
  withReact,
  RenderElementProps,
  RenderLeafProps,
} from 'slate-react';
import {
  Transforms,
  createEditor,
  Descendant,
  Editor,
  Selection,
  Element as SlateElement,
  Node,
} from 'slate';
import { withHistory } from 'slate-history';
import { DispatchType, RootState } from 'store/rootReducer';
import { CustomText } from 'Editors/types';
import { CustomElement } from 'Components/TextEditorToolbar/types';
import { FontFamilies } from 'Components/FontSelector/FontFamilies';
import { updateCurrentAudioAction } from 'store/podcasts/podcastActions';
import { onTextKeyDown } from 'utils/helpers';
import AudioBlock from 'UILib/AudioBlock/AudioBlock';

import styles from './TextEditor.module.scss';

interface IProps {
  keepDefaultLayout?: boolean;
  hardRender?: boolean;
  editorClassName?: string;
  initialValue: Descendant[];
  onChange: (newValue: Descendant[]) => void;
  onHardRender?: () => void;
  currentPlayingAudio: string | null;
  updateCurrentAudio: (audioKey: string | null) => void;
  setEditor?: (editor?: Editor, selection?: Selection) => void;
  canEdit?: boolean;
  onKeydown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
}

const withEmbeds = (editor: Editor) => {
  const { isVoid } = editor;
  editor.isVoid = (element) =>
    element.type === 'audio' ? true : isVoid(element);
  return editor;
};

const withLayout = (editor: Editor) => {
  const { normalizeNode } = editor;

  editor.normalizeNode = ([node, path]) => {
    if (path.length === 0) {
      for (const [child, childPath] of Node.children(editor, path)) {
        let type: 'paragraph' | 'title' | 'audio';
        const slateIndex = childPath[0];
        const enforceType = (type: 'paragraph' | 'title' | 'audio') => {
          if (SlateElement.isElement(child) && child.type !== type) {
            const newProperties: Partial<SlateElement> = { type };
            Transforms.setNodes<SlateElement>(editor, newProperties, {
              at: childPath,
            });
          }
        };

        switch (slateIndex) {
          case 0:
            type = 'title';
            enforceType(type);
            break;
          default:
            break;
        }
      }
    }

    return normalizeNode([node, path]);
  };

  return editor;
};

const TextEditor = ({
  keepDefaultLayout,
  initialValue,
  onChange,
  editorClassName,
  currentPlayingAudio,
  updateCurrentAudio,
  setEditor,
  canEdit = true,
  onKeydown,
}: IProps) => {
  const editor = useMemo(() => {
    if (keepDefaultLayout) {
      return withEmbeds(withLayout(withHistory(withReact(createEditor()))));
    }

    return withEmbeds(withHistory(withReact(createEditor())));
  }, [keepDefaultLayout]);

  useEffect(() => {
    const handleMouseDown = (event: MouseEvent) => {
      let target = event.target as HTMLElement | null;

      while (target && target.nodeName !== 'BODY') {
        if (
          ['BUTTON', 'INPUT', 'TEXTAREA', 'SELECT'].includes(target.nodeName)
        ) {
          return;
        }

        if (target.nodeName === 'DIV' && target.id?.includes('header')) {
          event.preventDefault();
          break;
        }

        target = target.parentElement;
      }
    };

    document.addEventListener('mousedown', handleMouseDown);

    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, []);

  useEffect(() => {
    if (initialValue.length <= 0) return;

    const handleUpdateItems = () => {
      const totalNodes = editor.children;

      initialValue.forEach((value: any, index) => {
        if (totalNodes[index]) {
          const existingNode: any = totalNodes[index];

          if (existingNode.type !== value.type) {
            Transforms.removeNodes(editor, { at: [index] });
            Transforms.insertNodes(editor, value as any, { at: [index] });
          } else {
            if (
              (value.type === 'paragraph' || value.type === 'title') &&
              value.children[0].text !== existingNode.children[0].text
            ) {
              Transforms.insertText(editor, value.children[0].text as any, {
                at: [index],
              });
            } else if (JSON.stringify(existingNode) !== JSON.stringify(value)) {
              Transforms.setNodes(editor, value as any, {
                at: [index],
              });
            }
          }
        } else {
          Transforms.insertNodes(editor, value as any, { at: [index] });
        }
      });

      if (totalNodes.length > initialValue.length) {
        for (let i = totalNodes.length - 1; i >= initialValue.length; i--) {
          Transforms.removeNodes(editor, { at: [i] });
        }
      }
    };

    handleUpdateItems();
  }, [editor, initialValue]);

  const renderElement = useCallback(
    (props: RenderElementProps) => (
      <Element
        {...props}
        currentPlayingAudio={currentPlayingAudio}
        updateCurrentAudio={updateCurrentAudio}
        contentEditable={canEdit}
      />
    ),
    [currentPlayingAudio]
  );

  const renderLeaf = useCallback(
    (props: RenderLeafProps) => <Leaf {...props} />,
    []
  );

  return (
    <Slate
      editor={editor}
      initialValue={initialValue}
      onChange={(e) => {
        if (!editor.operations.every((op) => op.type === 'set_selection'))
          onChange(e);
      }}
    >
      <Editable
        renderElement={renderElement}
        className={clsx(styles.editor, editorClassName)}
        renderLeaf={renderLeaf}
        spellCheck
        onSelect={() => {
          if (setEditor) setEditor(editor, editor.selection);
        }}
        onKeyDown={(event) => onTextKeyDown(event, editor)}
      />
    </Slate>
  );
};

const Leaf: React.FC<RenderLeafProps> = ({ attributes, children, leaf }) => {
  if ((leaf as CustomText).bold) {
    children = <strong>{children}</strong>;
  }

  if ((leaf as CustomText).italic) {
    children = <em>{children}</em>;
  }

  if ((leaf as any).underline) {
    children = <u>{children}</u>;
  }
  if ((leaf as any).link) {
    children = (
      <a className={styles.link} href={(leaf as any).link}>
        {children}
      </a>
    );
  }

  const style = {
    color: (leaf as any).color || 'inherit',
    fontFamily: FontFamilies.find((e) => e.value === (leaf as any)?.font)
      ?.label,
    fontSize: (leaf as any)?.fontSize,
    fontWeight: (leaf as any)?.weight,
    lineHeight: (leaf as any)?.lineHeight,
  };

  return (
    <span {...attributes} style={style}>
      {children}
    </span>
  );
};
const Element = (
  props: RenderElementProps & {
    contentEditable?: boolean;
    currentPlayingAudio: string | null;
    updateCurrentAudio: (audio: string | null) => void;
  }
) => {
  const { attributes, children, element, contentEditable } = props;

  const style = {
    textAlign:
      (element as CustomElement).align || children?.[0]?.props?.text?.align,
  } as React.CSSProperties;

  switch (element.type) {
    case 'title':
      return (
        <h2
          className={styles.title}
          style={style}
          contentEditable={contentEditable === false ? false : undefined}
          {...attributes}
        >
          {children}
        </h2>
      );
    case 'paragraph':
      return (
        <p
          className={styles.paragraph}
          style={style}
          contentEditable={contentEditable === false ? false : undefined}
          {...attributes}
        >
          {children}
        </p>
      );
    case 'list-item':
      return (
        <li
          style={style}
          {...attributes}
          contentEditable={contentEditable === false ? false : undefined}
        >
          {children}
        </li>
      );
    case 'number-list':
      return (
        <ol
          style={style}
          {...attributes}
          contentEditable={contentEditable === false ? false : undefined}
        >
          {children}
        </ol>
      );
    case 'bulleted-list':
      return (
        <ul
          {...attributes}
          contentEditable={contentEditable === false ? false : undefined}
        >
          {children}
        </ul>
      );
    case 'audio':
      return (
        <div className={styles.audio} contentEditable={false}>
          <AudioBlock
            {...element.data}
            currentPlayingAudio={props.currentPlayingAudio}
            updateCurrentAudio={props.updateCurrentAudio}
            audioId={element.data.id}
          />
          {children}
        </div>
      );
    case 'image':
      return (
        <div className={styles.image} contentEditable={false}>
          <img src={element.src} alt="" />
        </div>
      );
    default:
      return null;
  }
};

const mapStateToProps = (state: RootState) => ({
  currentPlayingAudio: state.podcasts.currentPlayingAudio,
});

const mapDispatchToProps = (dispatch: DispatchType) => ({
  updateCurrentAudio: (audio: string | null) =>
    dispatch(updateCurrentAudioAction(audio)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TextEditor);
