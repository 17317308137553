export const GET_PODCAST_EPISODE = {
  operationName: 'getEpisode',
  query: `query getEpisode ($id: String!) {
            getEpisode(id: $id) {
              _id
              name
              tone
              voice
              prompt
              text
              audioUrl
              coverData
              image
              sections {
                _id
                state
                title
                text
              }
              music {
                id
                introUrl
                outroUrl
                transitionUrl
                introStart
                transitionStart
                transitionEnd
                outroEnd
              }
              createdAt
              updatedAt
            }
          }`,
};
