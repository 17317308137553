import { SET_SECTIONS, SET_SECTIONS_MENU } from './sectionsActions';
import { PayloadAction } from '@reduxjs/toolkit';
import { ISectionMenu, ISection } from 'types';

export interface ISectionsState {
  items: ISection[];
  menu: ISectionMenu;
}

const initialState: ISectionsState = {
  items: [],
  menu: {
    name: 'default',
    items: [],
    createdAt: new Date(),
    updatedAt: new Date(),
  },
};

const sectionReducer = (
  state = initialState,
  action: PayloadAction<ISectionMenu | ISection[]>
) => {
  switch (action.type) {
    case SET_SECTIONS:
      return {
        ...state,
        items: action.payload as ISection[],
      };
    case SET_SECTIONS_MENU:
      return {
        ...state,
        menu: action.payload as ISectionMenu,
      };
    default:
      return state;
  }
};

export { initialState, sectionReducer };
