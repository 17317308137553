import SearchInput from 'UILib/SearchInput/SearchInput';
import s from 'Pages/PageContacts/Search/Search.module.scss';
import { Person, SmartList, Tag } from '../Helper/types';

type Search = {
  searchText: string;
  setSearchText: (text: string) => void;
  filteredPeople: Person[];
  handleClearText: () => void;
  smartLists: SmartList[];
  smartSwitch: boolean;
  totalCount: number;
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  filteredTags: Tag[];
};

export const SearchBar = (props: Search) => {
  return (
    <div className={s.head}>
      <div className={s.headLeft}>
        <div
          className={
            props.smartLists.length < 1
              ? s.searchBlockExpanded
              : s.searchBlockExpandedSmart
          }
        >
          {!props.smartSwitch ? (
            <div className={s.searchContent}>
              <SearchInput
                theme={props.smartLists.length < 1 ? 'light' : 'dark'}
                value={props.searchText}
                onChange={props.setSearchText}
                className={s.search}
              />
            </div>
          ) : (
            <div className={s.searchContentTag}>
              <SearchInput
                theme={props.smartLists.length < 1 ? 'light' : 'dark'}
                value={props.searchTerm}
                onChange={props.setSearchTerm}
                className={s.searchTag}
              />
            </div>
          )}
        </div>

        <div
          className={
            props.smartLists.length < 1 ? s.searchResults : s.searchResultsSmart
          }
        >
          <div className={s.total}>Total: {props.totalCount}</div>
        </div>
      </div>
    </div>
  );
};
