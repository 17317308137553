import clsx from 'clsx';
import { Dropdown, MenuProps } from 'antd';
import { ReactComponent as More } from 'Assets/icons/copyCraft/dots.svg';

import styles from './OptionMenu.module.scss';

const OptionMenu = ({
  menuProps,
  className,
}: {
  menuProps: MenuProps;
  className?: string;
}) => {
  return (
    <Dropdown
      menu={menuProps}
      trigger={['click']}
      overlayClassName={clsx(styles.menu, 'custom')}
    >
      <div
        className={clsx(styles.more, className)}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <More />
      </div>
    </Dropdown>
  );
};

export default OptionMenu;
