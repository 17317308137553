export const DELETE_SUB_ACCOUNT = {
  operationName: 'deleteSubAccount',
  query: `mutation deleteSubAccount(
      $id: String!
    ) {
      deleteSubAccount(
        id: $id
      ) {
        message
      }
    }`,
};
