export const DELETE_MAIL_SENDER_MUTATION = {
  operationName: 'deleteMailSender',
  query: `mutation deleteMailSender(
      $id: String!
    ) {
      deleteMailSender(
        id: $id
      ) {
        message
      }
    }`,
};
