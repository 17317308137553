import { useEffect, useRef, useState } from 'react';
import { ReactComponent as ImageIcon } from 'Assets/icons/rounded-image.svg';
import { ReactComponent as ArrowIcon } from 'Assets/icons/arrowLeft.svg';
import { UseOnClickOutside } from 'utils/UseOnClickOutside';
import { createSocket, getUserId } from 'utils/Utils';
import TextArea from 'UILib/TextArea/TextArea';
import ActionButton from '../ActionButton/ActionButton';
import ImageCardsSelect from '../ImageCardsSelect/ImageCardsSelect';

import styles from './BookAIImageRerollAction.module.scss';

interface IProps {
  initialImageUrl: string;
  onChange: (value: string) => void;
}

interface IGetSocketImageArgs {
  payload: any;
  callback?: (progress: any) => void;
}

const BookAIImageRerollAction = (props: IProps) => {
  const [showPopup, setShowPopup] = useState(false);
  const [imagesUrls, setImagesUrls] = useState<string[]>(
    props.initialImageUrl ? [props.initialImageUrl] : []
  );
  const [text, setText] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedUrl, setSelectedUrl] = useState<string>(imagesUrls[0] ?? '');
  const ref = useRef<HTMLDivElement>(null);

  UseOnClickOutside(ref, () => {
    showPopup && setShowPopup(false);
  });

  useEffect(() => {
    const handleScroll = () => {
      setShowPopup(false);
    };

    if (showPopup) {
      window.addEventListener('wheel', handleScroll);
    }

    return () => {
      window.removeEventListener('wheel', handleScroll);
    };
  }, [showPopup]);

  const handleStartGeneration = async () => {
    if (!text || text.trim().length === 0 || loading) {
      return;
    }

    setLoading(true);
    const fetchedUrls: string[] = await getNewImageUrlsWithAI(text);
    const newUrls = [...imagesUrls, ...fetchedUrls];
    setSelectedUrl(newUrls[0]);
    setImagesUrls(newUrls);
    setLoading(false);

    props.onChange(newUrls[0]);
  };

  const getNewImageUrlsWithAI = async (text: string): Promise<string[]> => {
    const currentUrl = window.location.href;

    const payload: any = {
      text,
      imageCount: 4,
      ratio: '1:1',
    };

    const userId = getUserId();

    if (userId) {
      payload.userId = userId;
    }

    const urlParts = currentUrl.split('/');
    if (currentUrl.includes('/edit/edit/')) {
      const pageId = urlParts[urlParts.length - 2];
      payload.pageId = pageId;
    } else if (currentUrl.includes('/edit/book-editor/')) {
      const bookId = urlParts[urlParts.length - 1];
      payload.bookId = bookId;
    }

    const response: any = await generateAiImageBySocket({
      payload,
      callback: (response: any) => handleAiCallback(response.error),
    });

    return response.imageUrls as string[];
  };

  const generateAiImageBySocket = async (args: IGetSocketImageArgs) => {
    const { payload, callback } = args;
    return new Promise((resolve) => {
      const socket = createSocket();
      socket.emit('generate-openai-image', payload);
      socket.on('open-ai-image-generated', (data) => {
        if (data.imageUrls?.length) {
          resolve(data);
        } else if (callback) {
          callback({ id: data.id, error: data.error });
        }
      });
    });
  };

  const handleAiCallback = (error?: string) => {
    if (error) {
      console.error('IMAGE GENERATION ERROR', error);
      setLoading(false);
    }
  };

  const handleCardSelect = (index: number) => {
    const newUrl = imagesUrls[index];
    if (!newUrl) return;

    setSelectedUrl(newUrl);
    props.onChange(newUrl);
  };

  const handleCardRemove = (index: number) => {
    if (index < 0 || index >= imagesUrls.length) return;

    const newImageUrls = [...imagesUrls];
    newImageUrls.splice(index, 1);

    const selectedIndex = imagesUrls.findIndex((url) => selectedUrl === url);
    if (index === selectedIndex) {
      const newUrl = newImageUrls[0] ?? '';
      setSelectedUrl(newUrl);
      props.onChange(newUrl);
    }

    setImagesUrls(newImageUrls);
  };

  return (
    <div className={styles.container} ref={ref}>
      <ActionButton onClick={() => setShowPopup(!showPopup)}>
        <ImageIcon />
      </ActionButton>
      {showPopup && (
        <div className={styles.popup}>
          <div className={styles.textAreaContent}>
            <TextArea
              className={styles.textArea}
              placeholder="Type here to generate a new image"
              onChange={(text: string) => setText(text)}
            />
            <div
              className={styles.submitButton}
              onClick={handleStartGeneration}
            >
              <ArrowIcon />
            </div>
          </div>
          <div className={styles.imagesContainer}>
            {(imagesUrls.length > 0 || loading) && (
              <ImageCardsSelect
                images={imagesUrls}
                onCardSelect={handleCardSelect}
                removable
                loading={loading}
                onCardRemove={handleCardRemove}
                selectedCard={imagesUrls.findIndex(
                  (url) => selectedUrl === url
                )}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default BookAIImageRerollAction;
