export const GET_GENERATION_TEMPLATES = {
  operationName: 'getGenerationTemplates',
  query: `query getGenerationTemplates {
            getGenerationTemplates {
              _id
              name
              createdAt
              updatedAt
              thumbnail
            }
          }`,
};
