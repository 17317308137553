import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from 'store/rootReducer';
import { fetchAllGenerationTemplatesAction } from 'store/generationTemplates/generationTemplatesActions';
import { IGenerationTemplate } from 'types';
import DashboardHeader from 'Components/DashboardHeader/DashboardHeader';
import Pagination from 'UILib/Pagination/Pagination';
import Loader from 'UILib/Loader/Loader';
import WorkflowItem from './WorkflowItem/WorkflowItem';

import styles from './Workflows.module.scss';

const sortOptions = [
  {
    label: 'Last Update',
    value: 'updatedAt',
    sortAsc: false,
  },
  {
    label: 'Workflow Name',
    value: 'name',
    sortAsc: true,
  },
];

const LIMIT = 20;

interface IProps {
  fetchTemplates: () => void;
  loading: boolean;
  generationTemplates: IGenerationTemplate[];
}

const Workflows = (props: IProps) => {
  const [sortBy, setSortBy] = useState<string>('updatedAt');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [searchedText, setSearchedText] = useState<string>('');

  const history = useHistory();

  useEffect(() => {
    props.fetchTemplates();
  }, []);

  const handleCreateNewWorkflow = () => {
    history.push(`/console/workflow/create`);
  };

  const handleSearch = (text: string) => {
    setCurrentPage(1);
    setSearchedText(text);
  };

  return (
    <div className={styles.container}>
      {props.loading ? (
        <div className={styles.loader}>
          <Loader />
        </div>
      ) : (
        (props.generationTemplates?.length || searchedText) && (
          <div className={styles.contentContainer}>
            <DashboardHeader
              handleAddNewItem={handleCreateNewWorkflow}
              buttonText="New Workflow"
              sortOptions={sortOptions}
              sortBy={sortBy}
              handleSort={(value: string) => setSortBy(value)}
              handleSearch={handleSearch}
              title="Your Workflows"
              total={`${totalCount} Workflows`}
            />
            <div className={styles.items}>
              {props.generationTemplates.map((item) => (
                <WorkflowItem
                  item={item}
                  key={item._id}
                  onDelete={() => {}}
                  onUpdate={() => {}}
                />
              ))}
            </div>
            <Pagination
              currentPage={currentPage}
              totalPages={Math.ceil(totalCount / LIMIT)}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        )
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  generationTemplates: state.generationTemplates.items,
  loading: state.generationTemplates.loading,
});

const mapDispatchToProps = {
  fetchTemplates: () => fetchAllGenerationTemplatesAction({ isLoading: false }),
};

export default connect(mapStateToProps, mapDispatchToProps)(Workflows);
