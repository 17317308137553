import * as Yup from 'yup';
import { connect } from 'react-redux';
import { useState } from 'react';
import { notification } from 'antd';
import { Form, Formik } from 'formik';
import { graphQlCall } from 'graphql/utils';
import { RootState } from 'store/rootReducer';
import PrimaryButton from 'Components/Common/PrimaryButton/PrimaryButton';
import SpinnerWhite from 'Components/Common/SpinnerWhite/SpinnerWhite';
import InputV2 from 'Components/Common/Inputs/InputV2/InputV2';
import OnboardingLogo from 'Components/Common/OnboardingLogo/OnboardingLogo';
import QUERIES from 'graphql/queries';

import s from './PageResetPassword.module.scss';

interface IProps {
  autosaasLogo?: string;
}

const PageStartResetPassword = ({ autosaasLogo }: IProps) => {
  const [loading, setLoading] = useState(false);

  interface IValues {
    email: string;
  }

  const onFinish = async (values: IValues) => {
    setLoading(true);
    try {
      await graphQlCall({
        queryTemplateObject: QUERIES.START_RECOVERY_PASSWORD,
        values: {
          email: values.email,
        },
      });
      setLoading(false);
      notification.info({
        message: 'Thanks!',
        description: `If your account is in our system, you'll receive an access email in the next 24 hours.`,
        placement: 'topRight',
      });
    } catch (e: any) {
      handleError(e);
    }
  };

  const handleError = (err: any) => {
    notification.info({
      message: 'Thanks!',
      description: `If your account is in our system, you'll receive an access email in the next 24 hours.`,
      placement: 'topRight',
    });
    setLoading(false);
  };

  const validationSchemaShort = Yup.object({
    email: Yup.string()
      .required('Email is required')
      .email('Please, enter valid email'),
  });

  return (
    <div className={s.container}>
      <OnboardingLogo />
      <div className={s.innerContainer}>
        {/* <img
          alt="'CREATE' logo"
          className={s.logo}
          src={autosaasLogo || logo}
        /> */}
        <div className={s.header}>
          <p>Please, enter your email so we can send recovery link</p>
        </div>
        <Formik
          initialValues={{ email: '' }}
          validationSchema={validationSchemaShort}
          onSubmit={(values, { setSubmitting }) => {
            onFinish(values);
            setSubmitting(false);
          }}
        >
          <Form className={s.form}>
            <div className={s.field1}>
              <InputV2
                width={370}
                placeholder="Email"
                name="email"
                type="text"
              />
            </div>
            <div className={s.submitButton}>
              <PrimaryButton width={230} type="submit" isBlackBtn={true}>
                {loading ? <SpinnerWhite size={27} /> : 'Send'}
              </PrimaryButton>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  autosaasLogo: state.autosaas.logoUrl,
});

export default connect(mapStateToProps, null)(PageStartResetPassword);
