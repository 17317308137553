import React, { useState, useEffect } from 'react';
import { getDomain } from 'utils/helpers';
import styles from './StartVideoBlock.module.scss';
import Player from '../Player/Player';

interface StartVideoBlockProps {
  src: string;
  name: string;
  redirectUrl?: string;
}

const StartVideoBlock: React.FC<StartVideoBlockProps> = (
  props: StartVideoBlockProps
) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isAnimatingOut, setIsAnimatingOut] = useState(false);

  useEffect(() => {
    const { isAutoFunnel } = getDomain();

    if (isAutoFunnel) {
      const videoHasAlreadyBeenPlayed = localStorage.getItem(props.name);
      if (videoHasAlreadyBeenPlayed !== null) {
        const parsedVideoHasAlreadyBeenPlayed = JSON.parse(
          videoHasAlreadyBeenPlayed
        );
        if (!parsedVideoHasAlreadyBeenPlayed) {
          setIsOpen(true);
        }
      } else {
        setIsOpen(true);
      }
    }
  }, []);

  const handleClose = () => {
    setIsAnimatingOut(true);
    localStorage.setItem(props.name, JSON.stringify(true));
    setTimeout(() => {
      setIsOpen(false);
      setIsAnimatingOut(false);
      if (props.redirectUrl) {
        window.open(props.redirectUrl, '_self');
      }
    }, 1000);
  };

  if (!isOpen) return null;

  return (
    <div
      onClick={() => handleClose()}
      className={`${styles.startVideoBlockWrapper} ${
        isAnimatingOut ? styles.fadeOut : styles.fadeIn
      } `}
    >
      <Player src={props.src} handleClose={() => handleClose()} />
    </div>
  );
};

export default StartVideoBlock;
