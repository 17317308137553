export const INITIATE_TRANSFER_BY_AGENCY = {
  operationName: 'initiateTransferToAgencyByAgency',
  query: `mutation initiateTransferToAgencyByAgency(
      $email: String!
    ) {
      initiateTransferToAgencyByAgency(
        email: $email
      ) {
        _id
        name
        owner
        email
        notes
        thumbnail
        requestedAgencyId
      }
    }`,
};
