export const UPDATE_BROADCAST_MUTATION = {
  operationName: 'updateBroadcast',
  query: `mutation updateBroadcast($id: String!, $name: String, $smartListId: String, $senderId: String, $mailDraftId: String, $sendAt: Float) {
                  updateBroadcast(id: $id, name: $name, smartListId: $smartListId, senderId: $senderId, mailDraftId: $mailDraftId, sendAt: $sendAt) {
                        _id
                        name
                        workflow { _id }
                        sender { _id }
                        mailDraft { _id }
                        sendAt
                        nextSendAt
                        status
                        createdAt
                        updatedAt
                  }
            }`,
};
