export const GET_ALL_AI_TEMPLATES = {
  operationName: 'getAllAiTemplates',
  query: `query getAllAiTemplates {
      getAllAiTemplates {
        _id
        name
        settings {
          chaptGptModel
          imageServer
        }
        thumbnail
      }
    }`,
};
