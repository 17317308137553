export const REFRESH_MAIL_DOMAIN_RECORDS = {
  operationName: 'refreshMailDomainRecords',
  query: `mutation refreshMailDomainRecords($id: String!) {
            refreshMailDomainRecords(id: $id) {
                _id
                name
                status
                createdAt
                updatedAt
                dnsRecords {
                    cached
                    name
                    priority
                    record_type
                    valid
                    value
                }
            }
        }`,
};
