export const GET_USER_PODCASTS = {
  operationName: 'getUserPodcasts',
  query: `query getUserPodcasts {
        getUserPodcasts {
          _id
          name
          image
          colorsSchemeType
          templateType
          coverData
          image
          hostName
          createdAt
          updatedAt
        }
      }`,
};
