import { useState, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { IDomain } from 'types';
import { ReactComponent as InfoSvg } from 'Assets/icons/info.svg';
import { ReactComponent as MoreIcon } from 'Assets/icons/more.svg';
import { ReactComponent as CloseSvg } from 'Assets/icons/close.svg';
import { ReactComponent as ClockSvg } from 'Assets/icons/waiting.svg';
import { ReactComponent as ReloadIcon } from 'Assets/icons/reload.svg';
import { ReactComponent as CheckMarkIcon } from 'Assets/icons/checkMarkInnerCircle.svg';
import {
  dnsPopupAction,
  refreshMailDomainRecords,
  removeSenderPopup,
  selectSender,
} from 'store/senders/sendersActions';
import { RootState } from 'store/rootStore';
import DropdownBox from 'Components/Common/dropdownBox/DropdownBox';
import ListItem from '../../ListItem/ListItem';

import s from './SenderItem.module.scss';

interface IProps {
  name: string;
  email: string;
  id: string;
  domain?: {
    _id: string;
  };
  domains: IDomain[];
  domainLoading: boolean;
  selectedSender?: string;
  selectSender: (id: string) => void;
  toggleDnsPopup: (payload: boolean) => void;
  toggleRemoveSenderPopup: (payload: boolean) => void;
  refreshMailDomainRecords: (id: string) => void;
}

const SenderItem = (props: IProps) => {
  const [isPointsBlockOpen, setIsPointsBlockOpen] = useState(false);

  const pointsBlock = useRef(null);

  const domain = useMemo(() => {
    return props.domains.find((dom) => dom._id === props.domain?._id);
  }, [props.domain?._id, props.domains]);

  return (
    <ListItem>
      <div className={s.senderName}>{props.name}</div>
      <div className={s.senderEmail}>{props.email}</div>
      <div
        className={clsx(s.domainStatus, {
          [s.success]: domain?.status === 'Verified',
        })}
      >
        {domain?.status !== 'Verified' ? <ClockSvg /> : <CheckMarkIcon />}
        {domain?.status}
        {domain?.status !== 'Verified' && (
          <ReloadIcon
            className={clsx(s.reloadIcon, {
              [s.loading]:
                props.domainLoading && props.selectedSender === props.id,
            })}
            onClick={() => {
              if (!props.domain || props.domainLoading) return;
              props.selectSender(props.id);
              props.refreshMailDomainRecords(props.domain._id);
            }}
          />
        )}
      </div>

      <DropdownBox
        isOpen={isPointsBlockOpen}
        onClose={() => setIsPointsBlockOpen(false)}
        pointerEvents={true}
        componentRef={pointsBlock}
      >
        <div className={s.dropdownBlock}>
          <div
            className={s.item}
            onClick={() => {
              props.selectSender(props.id);
              props.toggleDnsPopup(true);
              setIsPointsBlockOpen(false);
            }}
          >
            <InfoSvg /> Record Details
          </div>
          <div
            className={s.item}
            onClick={() => {
              props.selectSender(props.id);
              props.toggleRemoveSenderPopup(true);
              setIsPointsBlockOpen(false);
            }}
          >
            <CloseSvg className={s.closeIcon} /> Remove
          </div>
        </div>
      </DropdownBox>
      <div>
        <div
          className={s.pointsBlock}
          onClick={() => {
            setIsPointsBlockOpen(true);
          }}
          ref={pointsBlock}
        >
          <MoreIcon />
        </div>
      </div>
    </ListItem>
  );
};

const mapStateToProps = (state: RootState) => ({
  domains: state.senders.domains,
  domainLoading: state.senders.domainLoading,
  selectedSender: state.senders.selectedSender,
});

const mapDispatchToProps = {
  selectSender: (id: string) => selectSender(id),
  toggleDnsPopup: (payload: boolean) => dnsPopupAction(payload),
  toggleRemoveSenderPopup: (payload: boolean) => removeSenderPopup(payload),
  refreshMailDomainRecords: (id: string) => refreshMailDomainRecords(id),
};

export default connect(mapStateToProps, mapDispatchToProps)(SenderItem);
