import { IGenerationTemplate } from 'types';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  GENERATION_TEMPLATES_LOADING,
  SET_ALL_GENERATION_TEMPLATES,
} from './generationTemplatesActions';

export interface IGenerationTemplateState {
  items: IGenerationTemplate[];
  loading: boolean;
}
const initialState: IGenerationTemplateState = {
  items: [],
  loading: false,
};

const generationTemplateReducer = (
  state = initialState,
  action: PayloadAction<IGenerationTemplate[] | boolean>
): IGenerationTemplateState => {
  switch (action.type) {
    case GENERATION_TEMPLATES_LOADING:
      return {
        ...state,
        loading: action.payload as boolean,
      };
    case SET_ALL_GENERATION_TEMPLATES:
      const items = action.payload as IGenerationTemplate[];
      return {
        ...state,
        items,
      };
    default:
      return state;
  }
};

export { initialState, generationTemplateReducer };
