import clsx from 'clsx';
import { useEffect, useState } from 'react';
import Spinner from 'Components/Common/Spinner/Spinner';
import { ReactComponent as AIBadge } from 'Assets/icons/AIBadge.svg';
import { IFunnelTemplate } from 'types';

import s from './TemplatePreview.module.scss';

interface IProps {
  getTemplateImage: (template: IFunnelTemplate) => string;
  template: IFunnelTemplate;
  handleSubmit: (template: IFunnelTemplate) => void;
  handleSpinnerOnRedirect: () => void;
  setFirstLoading: (isFirst: boolean) => void;
}

const TemplatePreview = (props: IProps) => {
  useEffect(() => {
    setIsLoading(true);
  }, []);

  let templateImage = props.getTemplateImage(props.template);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div
      className={clsx(s.container, {
        [s.withAIBadgeBorder]: props.template.type === 'aipage',
      })}
    >
      <div
        className={s.templateBlock}
        onClick={() => {
          props.handleSubmit(props.template);
          props.handleSpinnerOnRedirect();
        }}
      >
        {isLoading && (
          <div className={s.spinner}>
            <Spinner size={80} />
          </div>
        )}
        <div className={s.image}>
          <div className={s.imageCover}>
            <img
              alt=""
              src={templateImage}
              onLoad={(e) => {
                setIsLoading(false);
                props.setFirstLoading(false);
              }}
            />
          </div>
          {props.template.type === 'aipage' && (
            <AIBadge className={s.aiBadge} />
          )}
        </div>
        <div className={s.templateDescription}>
          <p>{props.template.name}</p>
          {/* <p>{props.template.description}</p> */}
        </div>
      </div>
    </div>
  );
};

export default TemplatePreview;
