export const DELETE_SAAS_PLAN_PRICE = {
  operationName: 'deleteSaasPlanPrice',
  query: `mutation deleteSaasPlanPrice ($planIndex: Float!, $priceIndex: Float!) {
                    deleteSaasPlanPrice (planIndex: $planIndex, priceIndex: $priceIndex) {
                           _id
                          products {
                              name
                              stripeId
                              prices {
                                  name
                                  subHeader
                                  description
                                  price
                                  paymentPeriod
                                  currency
                                  stripeId
                                  apps {
                                      key
                                      enabled
                                      name
                                      items {
                                          name
                                          limit
                                          option
                                          unlimited
                                      }
                                  }
                              }
                          }
                      }
                  }`,
};
