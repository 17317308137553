export const GET_TOTAL_GRAPH_DATA = {
  operationName: 'getTotalGraphData',
  query: `query getTotalGraphData(
      $from: Float!
      $to: Float!
    ) {
      getTotalGraphData(
        from: $from
        to: $to
      ) {
        _id
        total
        count
        createdAt
        type
      }
    }`,
};
