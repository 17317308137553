import React, { useEffect, useState } from 'react';
import Modal from 'Components/Common/Modal2/Modal';
import { ITopicValues } from 'types';
import s from './CopyCraft.module.scss';
import Button from 'Styled/Button3';

interface IProps {
  values: ITopicValues;
  open: boolean;
  onEditTopic: (values: ITopicValues) => void;
  onClose: () => void;
}

const EditTopicModal = (props: IProps) => {
  const [name, setName] = useState('');

  useEffect(() => {
    setName(props.values.name);
  }, [props.values]);

  const handleSubmit = () => {
    props.onEditTopic({ name });
    setName('');
  };

  return (
    <Modal displayModal={props.open} closeModal={() => props.onClose()}>
      <div className={s.modal}>
        <h3>Edit Name</h3>
        <div>Name:</div>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className={s.modalInput}
        />
        <div className={s.buttonBlock}>
          <Button onClick={() => handleSubmit()}>Save</Button>
        </div>
      </div>
    </Modal>
  );
};

export default EditTopicModal;
