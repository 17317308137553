import React from 'react';
import s from './InputSubdomain.module.scss';
import { useField } from 'formik';

interface IProps {
  name: string;
  placeholder: string;
  width: number;
}

function InputSubdomain(props: IProps) {
  const [field, meta] = useField({ ...props });

  return (
    <div className={s.container}>
      {/* <div className={s.https}>
        <p>https://</p>
      </div> */}
      <input
        autoComplete="off"
        maxLength={20}
        {...field}
        name={props.name}
        type={'text'}
        placeholder={props.placeholder}
        className={s.input}
        style={{ width: `${props.width}px` }}
      />
      {meta.touched && meta.error ? (
        <div className={s.error}>{meta.error}</div>
      ) : null}
      {/* <div className={s.mech}>
        <p>.mech.com</p>
      </div> */}
    </div>
  );
}

export default InputSubdomain;
