import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import QUERIES from 'graphql/queries';
import { IGenerationTemplate } from 'types';
import { graphQlCall } from 'graphql/utils';
import { RootState } from '../rootReducer';

export const GENERATION_TEMPLATES_LOADING = 'GENERATION_TEMPLATES_LOADING';
export const SET_ALL_GENERATION_TEMPLATES = 'SET_ALL_GENERATION_TEMPLATES';

type DispatchType = ThunkDispatch<RootState, void, Action>;

export const fetchAllGenerationTemplatesAction = ({
  // search,
  // sortBy,
  // limit,
  // skip,
  // sortAsc,
  isLoading,
}: {
  // search?: string;
  // sortBy: string;
  // limit: number;
  // skip: number;
  // sortAsc: boolean;
  isLoading: boolean;
}) => async (dispatch: DispatchType) => {
  if (isLoading) {
    dispatch(generationTemplatesLoading(true));
  }
  try {
    const templates: IGenerationTemplate[] = await graphQlCall({
      queryTemplateObject: QUERIES.GET_ALL_GENERATION_TEMPLATES,
      headerType: 'USER-AUTH',
      values: {
        //   search,
        //   sortBy,
        //   limit,
        //   skip,
        //   sortAsc,
      },
    });

    const stateAction = {
      type: SET_ALL_GENERATION_TEMPLATES,
      payload: templates,
    };
    dispatch(stateAction);
  } catch (error) {
    console.error(error);
  }
  dispatch(generationTemplatesLoading(false));
};

export const generationTemplatesLoading = (payload: boolean) => ({
  type: GENERATION_TEMPLATES_LOADING,
  payload,
});
