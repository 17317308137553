import React, { useState } from 'react';
import Modal from 'Components/Common/Modal2/Modal';
import s from './CopyCraft.module.scss';
import Button from 'Styled/Button3';

interface IProps {
  open: boolean;
  addTopic: (name: string) => void;
  onClose: () => void;
}

const AddTopicModal = (props: IProps) => {
  const [name, setName] = useState('');

  const handleSubmit = () => {
    props.addTopic(name);
    setName('');
  };

  return (
    <Modal displayModal={props.open} closeModal={() => props.onClose()}>
      <div className={s.modal}>
        <h3>Add Theme</h3>
        <div>Name:</div>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className={s.modalInput}
        />
        <div className={s.buttonBlock}>
          <Button onClick={() => handleSubmit()}>Add</Button>
        </div>
      </div>
    </Modal>
  );
};

export default AddTopicModal;
