export const GET_PODCAST_BY_ID = {
  operationName: 'getPodcast',
  query: `query getPodcast(
        $id: String!
      ) {
        getPodcast(
          id: $id
        ) {
          _id
          name
          image
          episodesCount
          colorsSchemeType
          templateType
          createdAt
          updatedAt
          coverData
        }
      }`,
};
