import React from 'react';
import Button from '../../Components/Common/DomainButton/DomainButton';
import closeSvg from '../../Assets/icons/close.svg';
import worldSvg from '../../Assets/icons/world.svg';
import s from './popup.module.scss';

interface IProps {
  onClose: () => void;
  open: boolean;
  domainName: string;
  funnelName?: string;
}

const AlreadyExistsPopup = (props: IProps) => {
  return props.open ? (
    <div className={s.wrapper}>
      <div className={s.modal}>
        <img
          className={s.closeIcon}
          src={closeSvg}
          alt="x"
          onClick={() => props.onClose()}
        />
        <img className={s.worldIcon} src={worldSvg} alt="O" />
        <h1 className={s.headerText}>Already Exist</h1>
        <p className={s.alreadyExistsText}>
          {props.funnelName ? (
            <span>
              Your domain <b>{props.domainName}</b> has already been connected
              some time ago and associated with the funnel{' '}
              <b>{props.funnelName}</b>.<br />
              You can setup or re-associate it in “My Domains”
            </span>
          ) : (
            <span>
              Your domain <b>{props.domainName}</b> has already been used by
              other user.
            </span>
          )}
        </p>
        <div className={s.buttonBlock}>
          <Button
            name="Go to My Domains"
            onClick={() => props.onClose()}
            isBlackBtn={true}
          />
        </div>
      </div>
    </div>
  ) : null;
};

export default AlreadyExistsPopup;
