export const CREATE_PRODUCT_MUTATION = {
  operationName: 'createProduct',
  query: `mutation createProduct(
      $name: String!
      $description: String!
      $currency: String
      $price: Float
    ) {
      createProduct(
        name: $name,
        description: $description
        currency: $currency
        price: $price
      ) {
        _id
        name
        description
        currency
        price
        createdAt
        updatedAt
      }
    }`,
};
