export const DELETE_SMART_LIST = {
  operationName: 'deleteSmartList',
  query: `mutation deleteSmartList(
    $id: String!
  ) {
    deleteSmartList(
      id: $id
    ) {
      message
    }
  }`,
};
