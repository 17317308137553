export const GET_USER_TOPICS_WITH_PAGINATION = {
  operationName: 'getUserTopicsWithPagination',
  query: `query getUserTopicsWithPagination($limit: Float!, $skip: Float!, $sortAsc: Boolean, $sortBy: String, $search: String) {
      getUserTopicsWithPagination (skip: $skip, limit: $limit, sortAsc: $sortAsc, sortBy: $sortBy, search: $search) {
        topics {
        _id
        name
        prompt
        type
        createdAt
        updatedAt
        }
        total
      }
    }`,
};
