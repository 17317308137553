import { connect } from 'react-redux';
import {
  removeSmartListPopup,
  setSelectedSmartList,
} from 'store/contacts/contactsActions';
import { RootState } from 'store/rootReducer';
import { ReactComponent as CrossIcon } from 'Assets/icons/cross.svg';
import { SmartList } from '../Helper/types';

import s from 'Pages/PageContacts/SmartList/SmartList.module.scss';

interface SmartListComponentProps {
  smartLists: SmartList[];
  selectedAll: boolean;
  selectedSmartList: string | null;
  setSelectedAll: (value: boolean) => void;
  setSearchText: (value: string) => void;
  setSelectedSmartList: (value: string | null) => void;
  setCurrentPage: (value: number) => void;
  setSmartLists: (value: SmartList[]) => void;
  selectedSmartListId: string | undefined;
  openDeleteSmartListPopup: boolean;
  setSmartList: (payload: string) => void;
  removeSmartListPopup: (payload: boolean) => void;
}
const SmartListComponent = (props: SmartListComponentProps) => {
  const handleAllClick = () => {
    props.setSelectedAll(true);
    props.setSearchText('');
    props.setSelectedSmartList(null);
    props.setCurrentPage(1);
  };

  const handleSmartListClick = (listId: string) => {
    props.setSelectedAll(false);
    props.setSearchText('');
    props.setSelectedSmartList(listId);
    props.setCurrentPage(1);
  };

  const handleDeleteSmartList = async (id: string) => {
    props.removeSmartListPopup(true);
    props.setSmartList(id);
  };

  return (
    <div className={s.smart}>
      <div className={s.pageAll} onClick={handleAllClick}>
        All
        <span className={props.selectedAll ? s.selectedOption : s.pageOption} />
      </div>
      {props.smartLists.map((list: any) => (
        <div
          key={list._id}
          className={s.pageNew}
          onClick={() => handleSmartListClick(list._id)}
        >
          <div className={s.pageNewInner}>
            {list.name}
            <button
              className={s.cross}
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteSmartList(list._id);
              }}
            >
              <CrossIcon />
            </button>
          </div>
          <span
            className={
              props.selectedSmartList === list._id
                ? s.selectedOption
                : s.pageOption
            }
          />
        </div>
      ))}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  selectedSmartListId: state.contact.selectedSmartList,
  openDeleteSmartListPopup: state.contact.openDeleteSmartListPopup,
});

const mapDispatchToProps = {
  setSmartList: (payload: string) => setSelectedSmartList(payload),
  removeSmartListPopup: (payload: boolean) => removeSmartListPopup(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(SmartListComponent);
