import { useState } from 'react';
import clsx from 'clsx';
import Tab from './Tab/Tab';

import styles from './Tabs.module.scss';

interface IProps {
  className?: string;
  tabs: {
    title: string;
    content: React.ReactNode;
  }[];
  titleClassName?: string;
  tabsHeader?: string;
  contentClassName?: string;
  hideAdditionalLines?: boolean;
}

const Tabs = ({
  className,
  tabs,
  titleClassName,
  tabsHeader,
  contentClassName,
  hideAdditionalLines,
}: IProps) => {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <div className={clsx(styles.container, className)}>
      {tabs.length && (
        <>
          <div className={clsx(styles.tabsList, tabsHeader)}>
            {!hideAdditionalLines && <div className={styles.additionalLine} />}
            {tabs.map((tab, index) => (
              <Tab
                key={`${tab.title}_${index}`}
                className={titleClassName}
                title={tab.title}
                isSelected={selectedTab === index}
                onTabClick={() => setSelectedTab(index)}
              />
            ))}
            {!hideAdditionalLines && <div className={styles.additionalLine} />}
          </div>
          <div className={clsx(styles.tabContent, contentClassName)}>
            {tabs[selectedTab].content}
          </div>
        </>
      )}
    </div>
  );
};

export default Tabs;
