export const CHANGE_FUNNEL_POSITION_MUTATION = {
  operationName: 'changeFunnelPosition',
  query: `mutation changeFunnelPosition(
      <KEYS>
    ) {
      changeFunnelPosition(
        <VALUES>
      ) {
        _id
        position
      }
    }`,
};

export const CHANGE_FUNNEL_POSITIONS_MUTATION = {
  operationName: 'setFunnelsPositions',
  query: `mutation setFunnelsPositions(
      <KEYS>
    ) {
      setFunnelsPositions(
        <VALUES>
      ) {
        description
        name
        title
        _id
        archived
        mailchimpAPI
        mailchimpAudienceId
        mailchimpUsername
        domain
        headerScript
        footerScript
        stripePublicKey
        stripeSecretKey
        customSubDomain
        position
        products {
          name
          descriptor
          price
          currency
          isSubscription
          isSevenDayTrial
        },
        pages {
          _id
          name
          enabled
          visited
          conversion
          archived 
          headerScript
          footerScript
          createdAt
          updatedAt
          thumbnail
        }
      }
    }`,
};
