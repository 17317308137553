import React from 'react';
import s from './SpinnerWhite.module.scss';
import spinnerWhite from '../../../Assets/images/spinnerWhite.svg';

interface IProps {
  size: number;
}

function SpinnerWhite({ size }: IProps) {
  return (
    <div className={s.container}>
      <img
        src={spinnerWhite}
        alt="spinner"
        style={{ height: `${size}px`, width: `${size}px` }}
      />
    </div>
  );
}

export default SpinnerWhite;
