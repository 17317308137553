export const GET_USER_CONTACT_TAGS = {
  operationName: 'getUserContactTags',
  query: `query getUserContactTags {
    getUserContactTags {
      _id
      name
      createdAt
    }
  }`,
};
