export const DUPLICATE_PAGE_MUTATION = {
  operationName: 'duplicatePage',
  query: `mutation duplicatePage(
      $id: String!
      $funnelId: String
    ) {
      duplicatePage(
        id: $id
        funnelId: $funnelId
      ) {
        _id
        name
        enabled
        visited
        conversion
        archived 
        headerScript
        footerScript
        thumbnail
        pages {
          name
          image
          enabled
          exitpopupdata
          exitpopupenabled
        }
        order {
          id
          previous
          next
        }
      }
    }`,
};
