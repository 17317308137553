import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import Link from 'UILib/Link/Link';
import Input from 'UILib/Input/Input';
import Button from 'UILib/Button/Button';
import Dropdown from 'UILib/Dropdown/Dropdown';
import TimeInput from 'UILib/TimeInput/TimeInput';
import RadioButton from 'UILib/RadioButton/RadioButton';
import Calendar from 'Components/Common/DatePicker/Calendar/Calendar';
import EmailTestModal from 'Components/EmailTestModal/EmailTestModal';
import queries from 'graphql/queries';
import Loader from 'UILib/Loader/Loader';
import { timezones } from 'utils/constants';
import { graphQlCall } from 'graphql/utils';
import { ISender, ISmartList } from 'types';
import { ReactComponent as Back } from 'Assets/arrow-left-back.svg';
import { ReactComponent as MailIcon } from 'Assets/icons/mail.svg';
import { ReactComponent as EditIcon } from 'Assets/icons/edit2.svg';
import { ReactComponent as ClockIcon } from 'Assets/icons/clockNotFill.svg';
import { ReactComponent as CalendarIcon } from 'Assets/icons/calendar.svg';

import styles from './CompleteBroadcastCampaign.module.scss';

interface IProps {
  onGoBack: () => void;
  subject: string;
  maildraftId: string;
  htmlContent: string;
  broadcastId: string;
}

const CompleteBroadcastCampaign = ({
  onGoBack,
  subject,
  maildraftId,
  htmlContent,
  broadcastId,
}: IProps) => {
  const [senders, setSenders] = useState<ISender[]>([]);
  const [smartLists, setSmartLists] = useState<ISmartList[]>([]);
  const [selectedOption, setSelectedOption] = useState<'send-now' | 'send-on'>(
    'send-now'
  );
  const [openCalendar, setOpenCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedTime, setSelectedTime] = useState<string>('');
  const [selectedTimezone, setSelectedTimezone] = useState<string>(
    localStorage.getItem('preferredTimezone') ?? ''
  );
  const [selectedSmartList, setSelectedSmartList] = useState<string>('');
  const [selectedSender, setSelectedSender] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [showTestEmailModal, setShowTestEmailModal] = useState<boolean>(false);

  const history = useHistory();

  useEffect(() => {
    graphQlCall({
      queryTemplateObject: queries.GET_MAIL_SENDERS_QUERY,
      headerType: 'USER-AUTH',
    }).then(setSenders);
    graphQlCall({
      queryTemplateObject: queries.GET_USER_SMART_LISTS,
      headerType: 'USER-AUTH',
    }).then(setSmartLists);
  }, []);

  const checkValidation = () => {
    const isSendingInfoValid = !!selectedSender && !!selectedSmartList;

    if (selectedOption === 'send-on') {
      return isSendingInfoValid && !!selectedDate && !!selectedTime;
    }

    return isSendingInfoValid;
  };

  const updateBroadcast = async () => {
    try {
      const isValid = checkValidation();

      if (!isValid) return;

      let sendAt = Date.now();
      if (selectedOption === 'send-on') {
        const timezoneOffsetMatch = selectedTimezone.match(
          /([+-]\d{2}):(\d{2})/
        );
        const timezoneOffsetHours = parseFloat(timezoneOffsetMatch?.[1] || '0');
        const timezoneOffsetMinutes = parseFloat(
          timezoneOffsetMatch?.[2] || '0'
        );
        const sendAtDate = new Date(
          `${selectedDate?.toLocaleDateString('en-GB', {
            timeZone: 'UTC',
          })} ${selectedTime}`
        );
        sendAtDate.setHours(sendAtDate.getHours() + timezoneOffsetHours);
        sendAtDate.setMinutes(sendAtDate.getMinutes() + timezoneOffsetMinutes);
        sendAt = sendAtDate.getTime();
      }

      const broadcast = await graphQlCall({
        queryTemplateObject: queries.UPDATE_BROADCAST_MUTATION,
        values: {
          id: broadcastId,
          name: subject,
          senderId: selectedSender,
          smartListId: selectedSmartList,
          mailDraftId: maildraftId,
          sendAt: sendAt / 1000, //NOTE: we need to send EPOCH in seconds
        },
        headerType: 'USER-AUTH',
      });

      return broadcast?._id;
    } catch (error) {
      console.error(error);
    }
  };

  const handleBroadcastLaunch = async () => {
    try {
      setLoading(true);
      const result = await updateBroadcast();
      if (!result) return;

      localStorage.setItem('preferredTimezone', selectedTimezone);
      const response = await graphQlCall({
        queryTemplateObject: queries.START_BROADCAST_MUTATION,
        values: {
          id: result,
        },
        headerType: 'USER-AUTH',
      });
      console.log('response:', response);
      if (response.status === 'ACTIVE' || response.status === 'WAITING') {
        return history.push('/console/outreach/broadcasts');
      }

    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <EmailTestModal
        htmlScript={htmlContent}
        emailSubject={subject}
        open={showTestEmailModal}
        onClose={() => setShowTestEmailModal(false)}
      />
      <div className={styles.container}>
        <Link to="" className={styles.backLink}>
          <div
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onGoBack();
            }}
            className={styles.backButton}
          >
            <Back />
            Back
          </div>
        </Link>
        <div className={styles.formWrapper}>
          <div className={clsx(styles.section, styles.firstSection)}>
            <MailIcon className={styles.icon} width={32} height={24} />
            <div className={styles.title}>Complete Campaign</div>
            <div className={styles.subtitle}>
              Check everything and set final settings
            </div>
            <div className={styles.formInput}>
              Sender:
              <Dropdown
                type="stroke"
                label="Select Sender"
                value={selectedSender}
                className={styles.dropdown}
                options={senders.map((sender) => ({
                  label: sender.email,
                  value: sender._id,
                }))}
                onChange={setSelectedSender}
              />
            </div>
            <div className={styles.formInput}>
              Smart List:
              <Dropdown
                type="stroke"
                label="Select List"
                value={selectedSmartList}
                className={styles.dropdown}
                options={smartLists.map((list) => ({
                  label: list.name,
                  value: list._id,
                }))}
                onChange={setSelectedSmartList}
              />
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.secondSectionWrapper}>
              <div className={clsx(styles.formInput, styles.noMargin)}>
                Email Subject
                <Input
                  border="stroke"
                  className={styles.input}
                  readOnly
                  value={subject}
                />
              </div>
              <div className={styles.radioButtonGroup}>
                <RadioButton
                  name="Send Now"
                  value="send-now"
                  label="Send Now"
                  checked={selectedOption === 'send-now'}
                  onChange={(value) =>
                    setSelectedOption(value as 'send-now' | 'send-on')
                  }
                />
                <RadioButton
                  name="Send On"
                  value="send-on"
                  label="Send On"
                  checked={selectedOption === 'send-on'}
                  onChange={(value) =>
                    setSelectedOption(value as 'send-now' | 'send-on')
                  }
                />
              </div>
              {selectedOption === 'send-on' && (
                <div className={styles.dateContainer}>
                  <Input
                    readOnly
                    border="stroke"
                    placeholder="DD:MM:YYYY"
                    className={styles.dateInput}
                    onClick={() => setOpenCalendar(true)}
                    prefixIcon={<CalendarIcon width={16} height={16} />}
                    value={
                      selectedDate
                        ? selectedDate
                            .toLocaleDateString('en-GB')
                            .split('/')
                            .join(':')
                        : ''
                    }
                  />
                  <TimeInput
                    value={selectedTime}
                    className={styles.timeInput}
                    onTimeChange={setSelectedTime}
                    prefixIcon={<ClockIcon width={16} height={16} />}
                  />
                  <Dropdown
                    type="stroke"
                    size="medium"
                    label={timezones[7]}
                    value={selectedTimezone}
                    className={styles.timezoneDropdown}
                    options={timezones.map((tz) => ({ label: tz, value: tz }))}
                    onChange={(timezone) => setSelectedTimezone(timezone)}
                  />
                  <Calendar
                    selectionType="single"
                    startDate={selectedDate}
                    setDate={(newDate: Date) => setSelectedDate(newDate)}
                    isOpen={openCalendar}
                    onClose={() => setOpenCalendar(false)}
                  />
                </div>
              )}
            </div>
            <Button
              width={160}
              height={50}
              appearance="highlighted"
              postfixIcon={!loading && <MailIcon />}
              className={styles.launchButton}
              onClick={handleBroadcastLaunch}
              disabled={loading}
            >
              {loading ? <Loader size={20} color="#ffffff" /> : 'Launch'}
            </Button>
          </div>
        </div>
        <div className={styles.previewWrapper}>
          <div className={styles.title}>Email Preview</div>
          <div className={styles.buttonsWrapper}>
            <Button
              width={180}
              height={40}
              appearance="solid"
              prefixIcon={<MailIcon />}
              onClick={() => setShowTestEmailModal(true)}
            >
              Send Test Email
            </Button>
            <Button
              width={40}
              height={40}
              appearance="stroke"
              prefixIcon={<EditIcon />}
            />
          </div>
          <div className={styles.previewContainer} id="templatePreview">
            <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
          </div>
        </div>
      </div>
    </>
  );
};

export default CompleteBroadcastCampaign;
