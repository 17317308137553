export const CREATE_TOKEN = {
  operationName: 'createToken',
  query: `query createToken(
      $sub: String!
    ) {
      createToken(
        sub: $sub
      ) {
        token
        refreshToken
      }
    }`,
};
