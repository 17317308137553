import React, { useState } from 'react';
import s from './Tabs.module.scss';
import Tab from './Tab/Tab';

interface IProps {
  children: JSX.Element[];
}

function Tabs(props: IProps) {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <div className={s.container}>
      {props.children.length && (
        <div className={s.tabsList}>
          {props.children.map((item, index) => (
            <Tab
              key={index}
              title={item.props.title}
              index={index}
              setSelectedTab={setSelectedTab}
              selectedTab={selectedTab}
            />
          ))}
        </div>
      )}
      <div className={s.tabContent}>
        {props.children.length && props.children[selectedTab].props.children}
      </div>
    </div>
  );
}

export default Tabs;
