export const CREATE_SAAS_CATEGORY = {
  operationName: 'createSassCategory',
  query: `mutation createSassCategory ($name: String!, $description: String) {
            createSassCategory (name: $name, description: $description) {
                _id
                name
                description
                updatedAt
                createdAt
            }
        }`,
};
