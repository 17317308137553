export const CREATE_AI_TEXT_REQUEST = {
  operationName: 'createAiTextRequest',
  query: `mutation createAiTextRequest(
      $topicId: String!
      $request: String!
      $response: String!
      $requestType: String!
    ) {
      createAiTextRequest(
        topicId: $topicId
        request: $request
        response: $response
        requestType: $requestType
      ) {
        _id
        request
        requestType
        response
        hasLiked
        createdAt
        updatedAt
    }
  }`,
};
