export const ADD_CONTACT_TAG = {
  operationName: 'addContactTag',
  query: `mutation addContactTag($tagName: String!) {
    addContactTag(tagName: $tagName) {
      _id
      name
      createdAt
    }
  }`,
};
