import { useDrag } from 'react-dnd';

import styles from './Sidebar.module.scss';

const SidebarItem = ({ item }: any) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'MENU_ITEM',
    item,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <div
      className={styles.itemContainer}
      ref={drag}
      style={{ opacity: isDragging ? 0.5 : 1 }}
    >
      <img src={item.icon} alt={item?.text} />
      <p className={styles.itemText}>{item?.text}</p>
    </div>
  );
};

export default SidebarItem;
