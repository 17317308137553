import { ADD_ALERT, DELETE_ALERT } from './alertsActions';
import { IAlert } from 'types';
import { PayloadAction } from '@reduxjs/toolkit';

export interface IActionState {
  items: IAlert[];
}

const initialState: IActionState = {
  items: [],
};

export const alertsReducer = (
  state = initialState,
  action: PayloadAction<IAlert>
) => {
  let items = [...state.items];

  switch (action.type) {
    case ADD_ALERT:
      items.push({
        type: action.payload.type,
        text: action.payload.text,
        description: action.payload.description,
      });
      return {
        ...state,
        items,
      };

    case DELETE_ALERT:
      items = items.length > 0 ? items.slice(1) : items;
      return {
        ...state,
        items,
      };

    default:
      return state;
  }
};
