import React from 'react';
import Button from '../../Components/Common/DomainButton/DomainButton';
import closeSvg from '../../Assets/icons/close.svg';
import clockSvg from '../../Assets/icons/clock.svg';
import s from './popup.module.scss';

interface IProps {
  onClose: () => void;
  open: boolean;
}

const NotAtAllPopup = (props: IProps) => {
  return props.open ? (
    <div className={s.wrapper}>
      <div className={s.modal}>
        <img
          className={s.closeIcon}
          src={closeSvg}
          alt="x"
          onClick={() => props.onClose()}
        />
        <img className={s.worldIcon} src={clockSvg} alt="O" />
        <h1 className={s.headerText}>Verifying...</h1>
        <p className={s.notAtAllText}>
          We've received your request and are updating your domain. Please note,
          this will only work if you updated the CNAME records for your domain
          correctly as listed in our
          <a
            href="https://support.google.com/a/answer/47283?hl=en#zippy=%2Cstep-sign-in-to-your-domain-host%2Cstep[…]-add-the-cname-record-to-your-domains-dns-records"
            target="_blank"
          >
            instructions
          </a>
          . Finalizing your domain can take 2-24 hours. Also, you can use a
          custom subdomain now which is already tied to your account.
        </p>
        <div className={s.buttonBlock}>
          <Button
            name="Back"
            onClick={() => props.onClose()}
            isBlackBtn={true}
          />
        </div>
      </div>
    </div>
  ) : null;
};

export default NotAtAllPopup;
