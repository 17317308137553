import domtoimage from './dom-to-image';

export const classnames = (...args) => args.filter(Boolean).join(' ');

export const generateSSEStream = (hf, text) => {
  return hf.textGenerationStream({
    inputs: [
      {
        role: 'user',
        content: text,
      },
    ],
    parameters: {},
  });
};

export const resizeImageToWidth = (width, imageBase64) => {
  const promise = new Promise((resolve, reject) => {
    const oc = document.createElement('canvas');
    const octx = oc.getContext('2d');
    if (octx) {
      const img = new Image();
      img.src = imageBase64;
      img.onload = () => {
        const w = width;
        const h = (img.height * width) / img.width;
        oc.width = w;
        oc.height = h;
        octx.drawImage(img, 0, 0, w, h);
        const dataUrl = oc.toDataURL('image/jpeg', 0.9);
        const outputBase64 = dataUrl.replace(/^.+,/, '');
        resolve(outputBase64);
      };
    } else {
      reject('2d context not found');
    }
  });

  return promise;
};

export const generateCoverImage = (width, coverElement, scale = 1) => {
  const promise = new Promise((resolve, reject) => {
    // const coverElement = document.getElementById('CoverEditor');
    if (coverElement) {
      const rect = coverElement.getBoundingClientRect();
      domtoimage
        .toJpeg(coverElement, {
          width: rect.width * scale,
          height: rect.height * scale,
          style: {
            transform: `scale(${scale}`,
            transformOrigin: 'top left',
          },
          filter: (node) => {
            const className = node.className;
            if (className && className.search('Manipulator') >= 0) {
              //ignore all Manipulator elements
              return false;
            }
            return true;
          },
        })
        .then((imageBase64) => {
          if (rect.width !== width) {
            resizeImageToWidth(width, imageBase64).then((resultBase64) => {
              resolve(resultBase64);
            });
          } else {
            resolve(imageBase64.replace(/^.+,/, ''));
          }
        });
    } else {
      console.log('warning: CoverEditor tag is not found');
      resolve(null);
    }
  });
  return promise;
};

export const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};
