import * as Yup from 'yup';
import { Auth } from 'aws-amplify';
import { connect } from 'react-redux';
import { notification } from 'antd';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { graphQlCall } from 'graphql/utils';
import { RootState } from 'store/rootReducer';
import InputPassword from 'Components/Common/Inputs/InputPassword/InputPassword';
import PrimaryButton from 'Components/Common/PrimaryButton/PrimaryButton';
import SpinnerWhite from 'Components/Common/SpinnerWhite/SpinnerWhite';
import QUERIES from 'graphql/queries';
import OnboardingLogo from 'Components/Common/OnboardingLogo/OnboardingLogo';

import s from './PageResetPassword.module.scss';

interface IProps {
  autosaasLogo?: string;
}

const PageSetPassword = ({ autosaasLogo }: IProps) => {
  const { token } = useParams<{ token: string }>();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');

  interface IValues {
    password: string;
  }

  const getTokenData = async () => {
    const res = await graphQlCall({
      queryTemplateObject: QUERIES.GET_PASSWORD_RECOVERY_TOKEN_INFO,
      values: {
        token,
      },
    });

    setEmail(res.email as string);
  };

  useEffect(() => {
    getTokenData();
  }, []);

  const onFinish = async (values: IValues) => {
    setLoading(true);
    try {
      await graphQlCall({
        queryTemplateObject: QUERIES.CHANGE_USER_PASSWORD,
        values: {
          password: values.password,
          token,
        },
      });
      const cognitoUser = await Auth.signIn(email, values.password);
      Document.prototype.cognitoUser = cognitoUser;
      notification.success({
        message: 'Password was reset successfully',
        placement: 'topRight',
        duration: 1.5,
        onClose: () => {
          history.replace({ pathname: '/console/autologin' });
        },
      });

      setLoading(false);
    } catch (e: any) {
      handleError(e);
    }
  };

  const handleError = (err: any) => {
    notification.error({
      message: 'Error',
      description: err.message,
      placement: 'topRight',
    });
    setLoading(false);
  };

  const validationSchemaFull = Yup.object({
    password: Yup.string()
      .required('Password is required')
      .min(8, 'Please, enter minimum 8 characters'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm password is required'),
  });

  return (
    <div className={s.container}>
      <OnboardingLogo />
      <div className={s.innerContainer}>
        <div className={s.header}>
          <p>Please, enter your new password</p>
        </div>
        <Formik
          initialValues={{ password: '' }}
          validationSchema={validationSchemaFull}
          onSubmit={(values, { setSubmitting }) => {
            onFinish(values);
            setSubmitting(false);
          }}
        >
          <Form className={s.form}>
            <div className={s.field3}>
              <InputPassword
                width={370}
                placeholder="Password"
                name="password"
                showError={true}
              />
            </div>
            <div className={s.field4}>
              <InputPassword
                width={370}
                placeholder="Confirm Password"
                name="confirmPassword"
                showError={true}
              />
            </div>
            <div className={s.submitButton}>
              <PrimaryButton width={230} type="submit" isBlackBtn={true}>
                {loading ? <SpinnerWhite size={27} /> : 'Reset Password'}
              </PrimaryButton>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  autosaasLogo: state.autosaas.logoUrl,
});

export default connect(mapStateToProps, null)(PageSetPassword);
