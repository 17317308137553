export const REORDER_EPISODE_SECTIONS = {
  operationName: 'reorderEpisodeSections',
  query: `mutation reorderEpisodeSections($episodeId: String!, $sectionIds: [String!]!) {
        reorderEpisodeSections(episodeId: $episodeId, sectionIds: $sectionIds) {
            _id
            name
            tone
            voice
            prompt
            text
            audioUrl
            coverData
            image
            sections {
                title
                text
                notes
                state
            }
            music {
                id
                introUrl
                outroUrl
                transitionUrl
                introStart
                transitionStart
                transitionEnd
                outroEnd
            }
            createdAt
            updatedAt
        }
      }`,
};
