import { pushAlert } from '../alerts/alertsActions';
import { setQueueStatus, QUEUE_STATUSES, doneQueue } from '../sync/syncActions';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../rootReducer';
import { Action } from 'redux';
import { IOrder, IGeneralProject } from 'types';
import QUERIES from '../../graphql/queries';
import { graphQlCall } from '../../graphql/utils';

export const PROJECTS_FETCHED = 'PROJECTS_FETCHED';
export const ACTIVE_PROJECT = 'ACTIVE_PROJECT';
export const ARCHIVE_PROJECT = 'ARCHIVE_PROJECT';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const LOADING = 'PROJECTS_LOADING';
export const DRAW_FETCHED = 'DRAW_FETCHED';

interface ISetPagesOrderPayload {
  projectId: string;
  order: IOrder[];
}

type DispatchType = ThunkDispatch<RootState, void, Action>;

export const apiSetPagesOrder = (
  { projectId, order }: ISetPagesOrderPayload,
  queueId: string
) => async (dispatch: DispatchType) => {
  try {
    await graphQlCall({
      queryTemplateObject: QUERIES.SET_PAGES_ORDER_MUTATION,
      values: {
        id: projectId,
        order: JSON.stringify(order),
      },
      headerType: 'USER-AUTH',
    });
    dispatch(doneQueue(queueId));
  } catch (error: any) {
    dispatch(pushAlert('error', 'API fetch data error', error.message));
    dispatch(setQueueStatus(QUEUE_STATUSES.ERROR));
  }
  dispatch(loading(false));
};

const getTimestamp = (stringDate: string): number => {
  const date = new Date(stringDate);
  return date.getTime();
};

export const activeProject = (payload: string) => ({
  type: ACTIVE_PROJECT,
  payload,
});

interface IFetchProjectsPayload {
  projectId: string;
}

export const apiFetchProjects = (
  { projectId }: IFetchProjectsPayload,
  queueId: string
) => async (dispatch: DispatchType) => {
  try {
    let projects: IGeneralProject[] = await graphQlCall({
      queryTemplateObject: QUERIES.FETCH_FUNNELS_QUERY,
      headerType: 'USER-AUTH',
    });

    let fetchAction = {
      type: PROJECTS_FETCHED,
      payload: projects,
    };

    dispatch(fetchAction);
    if (projectId) {
      dispatch(activeProject(projectId));
    }
  } catch (error) {
    console.error(error);
  }
};

export const loading = (payload: boolean) => ({
  type: LOADING,
  payload,
});

interface IEditNamePayload {
  projectId: string;
  newName: string;
}

export const apiEditName = (
  { projectId, newName }: IEditNamePayload,
  queueId: string
) => async (dispatch: DispatchType) => {
  try {
    await graphQlCall({
      queryTemplateObject: QUERIES.UPDATE_FUNNEL_MUTATION,
      headerType: 'USER-AUTH',
      values: {
        name: newName,
        id: projectId,
      },
    });
    dispatch(doneQueue(queueId));
  } catch (error: any) {
    dispatch(setQueueStatus(QUEUE_STATUSES.ERROR));
    dispatch(pushAlert('error', 'API error', error.message));
  }
  dispatch(loading(false));
};

interface IArchiveProjectPayload {
  projectId: string;
}

export const apiArchiveProject = (
  { projectId }: IArchiveProjectPayload,
  queueId: string
) => async (dispatch: DispatchType) => {
  try {
    await graphQlCall({
      queryTemplateObject: QUERIES.UPDATE_FUNNEL_MUTATION,
      headerType: 'USER-AUTH',
      values: {
        archived: true,
        id: projectId,
      },
    });
    dispatch(doneQueue(queueId));
  } catch (error: any) {
    dispatch(pushAlert('error', 'API error', error.message));
    dispatch(setQueueStatus(QUEUE_STATUSES.ERROR));
  }
  dispatch(loading(false));
};

interface IArchiveFunnelPayload {
  funnelId: string;
}

export const apiArchiveFunnel = (
  { funnelId }: IArchiveFunnelPayload,
  queueId: string
) => async (dispatch: DispatchType) => {
  try {
    await graphQlCall({
      queryTemplateObject: QUERIES.UPDATE_PAGE_MUTATION,
      headerType: 'USER-AUTH',
      values: {
        id: funnelId,
        archived: true,
      },
    });
    dispatch(doneQueue(queueId));
  } catch (error: any) {
    dispatch(pushAlert('error', 'API error', error.message));
    dispatch(setQueueStatus(QUEUE_STATUSES.ERROR));
  }
  dispatch(loading(false));
};

interface IUpdateProjectPayload {
  fields: any;
  projectId: string;
}

export const apiUpdateProject = (
  { fields, projectId }: IUpdateProjectPayload,
  queueId: string
) => async (dispatch: DispatchType) => {
  delete fields._id;
  try {
    let values: any = {
      ...fields,
      id: projectId,
    };

    for (const key in values) {
      if (values[key] === null) {
        delete values[key];
      }
    }

    if (values.products) {
      values.products = JSON.stringify(values.products);
    }

    await graphQlCall({
      queryTemplateObject: QUERIES.UPDATE_FUNNEL_MUTATION,
      headerType: 'USER-AUTH',
      values,
    });
    dispatch(doneQueue(queueId));
  } catch (error: any) {
    dispatch(pushAlert('error', 'API error', error.message));
    dispatch(setQueueStatus(QUEUE_STATUSES.ERROR));
  }
  dispatch(loading(false));
};

interface IUpdateFunnelPayload {
  fields: any;
  funnelId: string;
}

export const apiUpdateFunnel = (
  { fields, funnelId }: IUpdateFunnelPayload,
  queueId: string
) => async (dispatch: DispatchType) => {
  try {
    await graphQlCall({
      queryTemplateObject: QUERIES.UPDATE_PAGE_MUTATION,
      headerType: 'USER-AUTH',
      values: {
        ...fields,
        id: funnelId,
      },
    });
    dispatch(doneQueue(queueId));
  } catch (error: any) {
    dispatch(setQueueStatus(QUEUE_STATUSES.ERROR));
    dispatch(pushAlert('error', 'API error', error.message));
  } finally {
    dispatch(loading(false));
  }
};
