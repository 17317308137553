import ColorSelector from 'Components/ColorSelector/ColorSelector';

import styles from './ColorSelectionBox.module.scss';

interface IProps {
  label: string;
  value: string;
  onChange: (selectedColor: string) => void;
}

const ColorSelectionBox = ({ label, value, onChange }: IProps) => {
  return (
    <div className={styles.ColorSelectionBox}>
      <span className={styles.colorSelectorLabel}>{label}</span>
      <ColorSelector
        onChange={onChange}
        color={value || '#fff'}
        pickerClassName={styles.colorPicker}
      />
    </div>
  );
};

export default ColorSelectionBox;
