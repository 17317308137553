export const UPDATE_PRODUCT_MUTATION = {
  operationName: 'updateProduct',
  query: `mutation updateProduct(
      $id: String!
      $name: String!
      $description: String!
      $currency: String
      $price: Float
    ) {
      updateProduct(
        id: $id
        name: $name
        description: $description
        currency: $currency
        price: $price
      ) {
        _id
        name
        description
        currency
        price
        createdAt
        updatedAt
      }
    }`,
};
