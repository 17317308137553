export const GET_OWN_SAAS_TEMPLATES_AND_CATEGORIES = {
  operationName: 'getOwnSaasTemplatesAndCategories',
  query: `query getOwnSaasTemplatesAndCategories {
            getOwnSaasTemplatesAndCategories {
                categories {
                    _id
                    name
                }
                templates {
                    _id
                    name
                    image
                    enabled
                    category
                }
            }
        }`,
};
