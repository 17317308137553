export const DISCONNECT_AGENCY = {
  operationName: 'disconnectAgency',
  query: `mutation disconnectAgency {
      disconnectAgency {
        _id
        name
        owner
        email
        notes
        thumbnail
        requestedAgencyId
      }
    }`,
};
