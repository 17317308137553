export const DUPLICATE_FUNNEL_MUTATION = {
  operationName: 'duplicateFunnel',
  query: `mutation duplicateFunnel(
      $id: String!
    ) {
      duplicateFunnel(
        id: $id
      ) {
        funnel{
          _id
          name
          position
          domain
        }
      }
    }`,
};
