export const GET_STATISTIC_GRAPH_DATA_QUERY = {
  operationName: 'getStatisticGraphData',
  query: `query getStatisticGraphData(
      $pageId: String!
      $from: DateTime!
      $to: DateTime!
    ) {
      getStatisticGraphData(
        pageId: $pageId
        from: $from
        to: $to

      ) {
        eventType
        actualStatisticData {
          value
          from
          to
        }
        previousStatisticData {
          value
          from
          to
        }
      }
    }`,
};
