export const CREATE_GENERATION_TASK_MUTATION = {
  operationName: 'createGenerationTask',
  query: `mutation createGenerationTask(
            $templateId: String!
          ) {
            createGenerationTask(
              templateId: $templateId
            ) {
              task{
                _id
              }    
              forms {
                type
                #name
                variables {
                  id
                  type
                }
              }    
              path
              message    
            }
          }`,
};
