import { useEffect, useState } from 'react';
import ToggleSwitch from 'UILib/ToggleSwitch/ToggleSwitch';
import Dropdown from 'UILib/Dropdown/Dropdown';
import TextArea from 'UILib/TextArea/TextArea';
import Input from 'UILib/Input/Input';

import styles from './CreateEpisodeForm.module.scss';

const tones = [
  { label: 'Professional', value: 'professional' },
  { label: 'Semi-Professional', value: 'semi-professional' },
  { label: 'Casual', value: 'casual' },
  { label: 'Friendly', value: 'friendly' },
  { label: 'Inspirational', value: 'inspirational' },
  { label: 'Humorous', value: 'humorous' },
  { label: 'Urgent', value: 'urgent' },
  { label: 'Confident', value: 'confident' },
  { label: 'Empathetic', value: 'emphatic' },
  { label: 'Playful', value: 'playful' },
  { label: 'Authoritative', value: 'Authoritative' },
  { label: 'Custom', value: 'custom' },
];

interface IProps {
  handleChange: (key: string, value: string | string[]) => void;
  validation: { validate: () => boolean };
  data: { [key: string]: string | string[] };
}

const CreateEpisodeForm = ({ handleChange, data, validation }: IProps) => {
  const [selectedOption, setSelectedOption] = useState('Generate with AI');
  const [isCustomSelected, setIsCustomSelected] = useState<boolean>(
    !tones.some((el) => el.value === data.episodeTone) && !!data.episodeTone
  );
  const [error, setError] = useState({
    name: '',
    description: '',
    tone: '',
  });

  useEffect(() => {
    validation.validate = () => {
      let errorsObj = {
        name: '',
        description: '',
        tone: '',
      };

      if (!data.episodeName) {
        errorsObj = {
          ...errorsObj,
          name: 'Episode Name Is Required',
        };
      }
      if (!data.episodeTone) {
        errorsObj = {
          ...errorsObj,
          tone: 'Tone Is Required',
        };
      }
      if (!data.episodeDescription && !data.episodeTranscription) {
        errorsObj = {
          ...errorsObj,
          description: 'Description Is Required',
        };
      }

      setError(errorsObj);

      return (
        (!!data.episodeName &&
          !!data.episodeTone &&
          !!data.episodeDescription) ||
        (!!data.episodeName && !!data.episodeTranscription)
      );
    };
  }, [
    validation,
    data.episodeName,
    data.episodeTone,
    data.episodeDescription,
    data.episodeTranscription,
  ]);

  const handleSelectTone = (tone: string) => {
    if (tone === 'custom') {
      setIsCustomSelected(true);
      handleChange('episodeTone', '');
    } else {
      setIsCustomSelected(false);
      handleChange('episodeTone', tone);
    }
  };

  return (
    <div className={styles.container}>
      {/* <ToggleSwitch
        className={styles.toggleSwitch}
        selectedValue={selectedOption}
        options={['Generate with AI', 'My Transcription']}
        onSelectOption={(value) => setSelectedOption(value)}
      /> */}
      {selectedOption === 'Generate with AI' ? (
        <>
          <div className={styles.elementContainer}>
            Episode Name
            <Input
              value={data.episodeName}
              error={error.name}
              className={styles.input}
              border="stroke"
              placeholder="What's your episode called?"
              onChange={(e) => handleChange('episodeName', e.target.value)}
            />
          </div>
          <div className={styles.elementContainer}>
            Tone
            <Dropdown
              type="stroke"
              label="Select Tone"
              hasErrors={!!error.tone && !isCustomSelected}
              value={isCustomSelected ? 'Custom' : data.episodeTone}
              options={tones}
              onChange={(newValue) => handleSelectTone(newValue as string)}
            />
            {!!error.tone && !isCustomSelected && (
              <div className={styles.errorMessage}>{error.tone}</div>
            )}
          </div>
          {isCustomSelected && (
            <div className={styles.elementContainer}>
              <Input
                className={styles.input}
                value={data.episodeTone as string}
                placeholder="Custom Tone"
                border="stroke"
                onChange={(e) =>
                  e.target.value.length <= 1500 &&
                  handleChange('episodeTone', e.target.value)
                }
                error={isCustomSelected ? error.tone : ''}
                maxLength={1500}
              />
            </div>
          )}
          <div className={styles.elementContainer}>
            Description
            <TextArea
              rows={5}
              placeholder="What's your episode about?"
              hasErrors={!!error.description}
              value={data.episodeDescription}
              onChange={(newValue) =>
                handleChange('episodeDescription', newValue)
              }
            />
            {!!error.description && (
              <div className={styles.errorMessage}>{error.description}</div>
            )}
          </div>
        </>
      ) : (
        <>
          <div className={styles.elementContainer}>
            Episode Name
            <Input
              value={data.episodeName}
              className={styles.input}
              border="stroke"
              placeholder="Name Your Episode"
              onChange={(e) => handleChange('episodeName', e.target.value)}
            />
          </div>
          <div className={styles.elementContainer}>
            Description
            <TextArea
              rows={10}
              className={styles.transcription}
              placeholder="Write a transcription of your episode"
              value={data.episodeTranscription}
              onChange={(newValue) =>
                handleChange('episodeTranscription', newValue)
              }
            />
          </div>
        </>
      )}
    </div>
  );
};

export default CreateEpisodeForm;
