export const DELETE_AI_REQUEST = {
  operationName: 'deleteAiTextRequest',
  query: `mutation deleteAiTextRequest(
      $id: String!
    ) {
      deleteAiTextRequest(
        id: $id
      ) {
        message
    }
  }`,
};
