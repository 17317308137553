import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { ReactComponent as SelectedIcon } from 'Assets/icons/checkMark.svg';
import { getActiveSubId, getUserId } from 'utils/Utils';
import { truncateString } from 'utils/helpers';
import { IAccount } from '../types';
import { IAgencyDetails } from 'types';
import EditIcon from 'Assets/icons/sideMenu/edit.svg';
import AccountIcon from 'Assets/icons/blankThumbnail.svg';
import ClockIcon from '../icons/clock.svg';

import s from './AgenciesDropDown.module.scss';

interface IProps {
  onManageClick: () => void;
  accounts: IAccount[];
  onSwitchAccount: (account: IAccount) => void;
  onSwitchToAgencyAccount: () => void;
  userDetails?: IAgencyDetails;
}

const AgenciesDropDown = (props: IProps) => {
  const [activeSubAccount, setActiveSubAccount] = useState('');
  useEffect(() => {
    const subAccountId = getActiveSubId() || '';
    setActiveSubAccount(subAccountId);
  }, []);

  const hasInitialAccountActive =
    !activeSubAccount || activeSubAccount === getUserId();

  const handleClickSwitch = (account: IAccount) => {
    if (account.requestedAgencyId) {
      return;
    }
    props.onSwitchAccount(account);
  };

  return (
    <div className={s.panel}>
      <div className={s.accountsList}>
        <div
          onClick={() => props.onSwitchToAgencyAccount()}
          className={clsx(s.account, s.userAccountt)}
        >
          <span className={s.thumbnailBox}>
            {hasInitialAccountActive && (
              <span className={s.activeAccount}>
                <SelectedIcon />
              </span>
            )}
            <img
              src={props.userDetails?.thumbnail ?? AccountIcon}
              alt=""
              className={
                props.userDetails?.thumbnail ? s.thumbnail : s.photoPlaceholder
              }
            />
          </span>
          <span>
            {truncateString(props.userDetails?.name ?? 'Your Account', 25)}
          </span>
        </div>
        <div className={s.agency}>
          {props.accounts.map((account) => (
            <div
              onClick={() => handleClickSwitch(account)}
              className={s.account}
              key={account._id}
            >
              <span className={s.thumbnailBox}>
                {activeSubAccount === account._id && (
                  <span className={s.activeAccount}>
                    <SelectedIcon />
                  </span>
                )}
                <img
                  src={account.thumbnail ?? AccountIcon}
                  alt=""
                  className={
                    account.thumbnail ? s.thumbnail : s.photoPlaceholder
                  }
                />
              </span>
              <span>
                {truncateString(account.workspace || account.name, 25)}
              </span>
              {account.requestedAgencyId && (
                <img src={ClockIcon} alt="" className={s.littleClocks} />
              )}
            </div>
          ))}
        </div>
      </div>
      <div onClick={() => props.onManageClick()} className={s.manage}>
        <div className={s.manageIcon}>
          <img src={EditIcon} alt="" />
        </div>
        <span>Manage Account</span>
      </div>
    </div>
  );
};

export default AgenciesDropDown;
