export const GET_SAAS_USERS_LIST = {
  operationName: 'saasUsersList',
  query: `query saasUsersList ($skip: Float!, $limit: Float!, $sortBy: String, $search: String, $sortAsc: Boolean) {
            saasUsersList (skip: $skip, limit: $limit, sortBy: $sortBy, search: $search, sortAsc: $sortAsc) {
                users {
                    _id
                    email
                    name
                    role
                    customData    
                }
                total
            }
          }`,
};
