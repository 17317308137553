import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as PlayIcon } from '../../../Assets/icons/play-button.svg';
import { ReactComponent as PauseIcon } from '../../../Assets/icons/pause-button.svg';
import { ReactComponent as FullScreen } from '../../../Assets/icons/full-screen.svg';
import { ReactComponent as LessScreen } from '../../../Assets/icons/less-screen.svg';
import { ReactComponent as SoundOn } from '../../../Assets/icons/sound-on.svg';
import { ReactComponent as SoundOff } from '../../../Assets/icons/sound-off.svg';
import { ReactComponent as RestarVideo } from '../../../Assets/icons/restar-button.svg';
import { ReactComponent as ContinueVideo } from '../../../Assets/icons/continue-play.svg';
import xSvg from '../../../Assets/icons/copyCraft/x2.svg';
import styles from './Player.module.scss';
// import Spinner from '../Spinner/Spinner';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

interface Props {
  src: string;
  autoPlay?: boolean;
  handleClose?: () => void;
}

const Player = (props: Props) => {
  const { src, autoPlay, handleClose } = props;
  const [isWaiting, setIsWaiting] = useState<boolean>(false);
  const [isPlaying, setIsPlaying] = useState<boolean | undefined>(autoPlay);
  const [rewind, setRewind] = useState<boolean>(false);
  const [isScreenFull, setIsScreenFull] = useState<boolean>(false);
  const [isSoundOn, setIsSoundOn] = useState<boolean>(false);
  const [isModalBlockShow, setIsModalBlockShow] = useState<boolean>(false);
  const [isTimelineVisible, setIsTimelineVisible] = useState<boolean>(false);

  const videoRef = useRef<HTMLVideoElement>(null);
  const progressRef = useRef<HTMLDivElement>(null);
  const bufferRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }

    const onWaiting = () => {
      if (isPlaying) setIsPlaying(false);
      setIsWaiting(true);
    };

    const onPlay = () => {
      if (isWaiting) setIsWaiting(false);
      setIsPlaying(true);
      setIsModalBlockShow(false);
    };

    const onPause = () => {
      setIsPlaying(false);
      setIsWaiting(false);
      setIsModalBlockShow(true);
    };

    const element = videoRef.current;

    const onProgress = () => {
      if (!element.buffered || !bufferRef.current) return;
      if (!element.buffered.length) return;
      const bufferedEnd = element.buffered.end(element.buffered.length - 1);
      const duration = element.duration;
      if (bufferRef && duration > 0) {
        bufferRef.current.style.width = (bufferedEnd / duration) * 100 + '%';
      }
    };

    const onTimeUpdate = () => {
      setIsWaiting(false);
      if (!element.buffered || !progressRef.current) return;
      const duration = element.duration;
      if (progressRef && duration > 0) {
        progressRef.current.style.width =
          (element.currentTime / duration) * 100 + '%';
      }
    };

    element.addEventListener('progress', onProgress);
    element.addEventListener('timeupdate', onTimeUpdate);

    element.addEventListener('waiting', onWaiting);
    element.addEventListener('play', onPlay);
    element.addEventListener('playing', onPlay);
    element.addEventListener('pause', onPause);

    return () => {
      element.removeEventListener('waiting', onWaiting);
      element.removeEventListener('play', onPlay);
      element.removeEventListener('playing', onPlay);
      element.removeEventListener('pause', onPause);
      element.removeEventListener('progress', onProgress);
      element.removeEventListener('timeupdate', onTimeUpdate);
    };
  }, [videoRef.current]);

  const handlePlayPauseClick = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
    }
  };
  const handleBeginStart = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      videoRef.current.play();
    }
  };

  const seekToPosition = (pos: number) => {
    if (!videoRef.current) return;
    if (pos < 0 || pos > 1) return;

    const durationMs = videoRef.current.duration * 1000 || 0;

    const newElapsedMs = durationMs * pos;
    const newTimeSec = newElapsedMs / 1000;
    videoRef.current.currentTime = newTimeSec;
  };

  const handleTimelineClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    const { left, width } = e.currentTarget.getBoundingClientRect();
    const clickedPos = (e.clientX - left) / width;
    setRewind(true);
    seekToPosition(clickedPos);
    setTimeout(() => {
      setRewind(false);
    }, 300);
  };

  const handleScreenSize = () => {
    setIsScreenFull(!isScreenFull);
  };

  const handleMuteSound = () => {
    setIsSoundOn(!isSoundOn);
  };

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null;

    const handleMouseMove = () => {
      setIsTimelineVisible(true);
      if (timeoutId !== null) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        setIsTimelineVisible(false);
      }, 2500);
    };

    const playerContainer = document.querySelector(
      `.${styles.playerContainer}`
    );
    const playerContainerFull = document.querySelector(
      `.${styles.playerContainerFull}`
    );

    playerContainer?.addEventListener('mousemove', handleMouseMove);
    playerContainerFull?.addEventListener('mousemove', handleMouseMove);

    return () => {
      playerContainer?.removeEventListener('mousemove', handleMouseMove);
      playerContainerFull?.removeEventListener('mousemove', handleMouseMove);
      if (timeoutId !== null) {
        clearTimeout(timeoutId);
      }
    };
  }, []);

  return (
    <>
      <div
        onClick={(e) => e.stopPropagation()}
        className={isModalBlockShow ? styles.modalBlockShow : styles.modalBlock}
      >
        <div className={styles.modalBlockTitle}>
          You've already started watching this video
        </div>
        <div className={styles.modalBlockButton}>
          <div
            className={styles.modalBlockContinue}
            onClick={handlePlayPauseClick}
          >
            <ContinueVideo />
            <span>
              Continue
              <br />
              Watching
            </span>
          </div>
          <div
            className={styles.modalBlockBeginStart}
            onClick={handleBeginStart}
          >
            <RestarVideo />
            <span>
              Start from
              <br /> Beginning
            </span>
          </div>
        </div>
      </div>
      <div className={styles.mainContainer}>
        <div className={styles.spinnerPlace}>
          {isWaiting && <LoadingSpinner />}
        </div>
        <div
          onClick={(e) => e.stopPropagation()}
          className={
            isScreenFull ? styles.playerContainerFull : styles.playerContainer
          }
        >
          <div
            className={`${styles.closeIconVisible} ${
              isTimelineVisible || isModalBlockShow
                ? ''
                : styles.closeIconHidden
            }`}
            onClick={handleClose}
          >
            <img src={xSvg} alt="x" />
          </div>
          <video
            id="myVideo"
            autoPlay={autoPlay}
            muted={isSoundOn}
            src={src}
            onClick={handlePlayPauseClick}
            onEnded={handleClose}
            ref={videoRef}
          >
            <source src={src} />
          </video>
          <div
            className={`${styles.timelineContainer} ${
              isTimelineVisible ? '' : styles.timelineContainerHidden
            }`}
          >
            <div className={styles.buttonContainer}>
              <button
                className={styles.playPauseButton}
                onClick={handlePlayPauseClick}
              >
                {!isPlaying ? (
                  <PlayIcon className={styles.playPause} />
                ) : (
                  <PauseIcon className={styles.playPause} />
                )}
              </button>
            </div>

            <div className={styles.timeline} onClick={handleTimelineClick}>
              <div className={styles.progressBarContainer}>
                <div
                  className={
                    rewind ? styles.playProgress : styles.playProgressTransition
                  }
                  ref={progressRef}
                />
                <div className={styles.bufferProgress} ref={bufferRef} />
              </div>
            </div>
            <div className={styles.soundScaleBlock}>
              <span onClick={handleMuteSound}>
                {isSoundOn ? (
                  <SoundOff className={styles.muteSound} />
                ) : (
                  <SoundOn className={styles.muteSound} />
                )}
              </span>
              <span onClick={handleScreenSize}>
                {isScreenFull ? (
                  <LessScreen className={styles.screenButton} />
                ) : (
                  <FullScreen className={styles.screenButton} />
                )}
              </span>
            </div>
          </div>
        </div>
        <div className={styles.getStartedButton} onClick={handleClose}>
          Get Started
        </div>
      </div>
    </>
  );
};

export default Player;
