export const GET_FUNNELS_FROM_SUB_ACCOUNT = {
  operationName: 'getFunnelsFromSubAccount',
  query: `query getFunnelsFromSubAccount(
      $sub: String!
    ) {
      getFunnelsFromSubAccount(
        sub: $sub
      ) {
        _id
        name
    }
  }`,
};
