export const ADD_SAAS_PLAN_PRICE = {
  operationName: 'addSaasPlanPrice',
  query: `mutation addSaasPlanPrice ($planIndex: Float!, $name: String!, $subHeader: String!, $description: String!, $price: Float!, $apps:String!, $paymentPeriod: String!, $currency: String!) {
                addSaasPlanPrice (planIndex: $planIndex, name: $name, subHeader: $subHeader, description: $description, price: $price, apps: $apps, paymentPeriod: $paymentPeriod, currency: $currency) {
                    _id
                    products {
                        name
                        stripeId
                        prices {
                            name
                            subHeader
                            description
                            price
                            paymentPeriod
                            currency
                            stripeId
                            apps {
                                key
                                enabled
                                name
                                items {
                                    name
                                    limit
                                    option
                                    unlimited
                                }
                            }
                        }
                    }
                }
            }`,
};
