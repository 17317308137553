import OptionMenu from 'Components/OptionMenu/OptionMenu';
import { ReactComponent as DeleteIcon } from '../icons/delete.svg';
import { ReactComponent as EditIcon } from '../icons/edit.svg';
import { useCallback, useMemo } from 'react';

interface IProps {
  onEdit: () => void;
  onTransfer: () => void;
  onDelete: () => void;
  isUserCard: boolean;
}

enum MenuItems {
  Edit,
  Delete,
}

const AccountMenu = (props: IProps) => {
  const { isUserCard, onEdit, onDelete } = props;

  const handleMenuClick = useCallback(
    (e: any) => {
      e.domEvent.stopPropagation();
      switch (+e.key) {
        case MenuItems.Edit: {
          onEdit();
          break;
        }
        case MenuItems.Delete: {
          onDelete();
          break;
        }
      }
    },
    [onEdit, onDelete]
  );

  const menuProps = useMemo(() => {
    const menuItems = [
      {
        label: 'Edit',
        key: MenuItems.Edit,
        icon: <EditIcon />,
      },
    ];

    if (!isUserCard)
      menuItems.push({
        label: 'Delete',
        key: MenuItems.Delete,
        icon: <DeleteIcon />,
      });

    return {
      items: menuItems,
      onClick: handleMenuClick,
    };
  }, [isUserCard, handleMenuClick]);

  return <OptionMenu menuProps={menuProps} />;
};

export default AccountMenu;
