import { ReactComponent as ChangeWidth } from 'Assets/icons/changeWidth.svg';

import styles from './DropdownLabel.module.scss';

interface IProps {
  value: string | number;
}

const DropdownLabel = ({ value }: IProps) => {
  return (
    <div className={styles.WidthDropdownLabel}>
      <ChangeWidth />
      Width: <div className={styles.selectedValue}>{value}</div>
    </div>
  );
};

export default DropdownLabel;
