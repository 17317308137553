import { connect } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { IBookleTemplateEditor } from 'store/books/booksReducer';
import {
  updateBookleTemplateBlocks,
  updateBookleTemplateTextEditor,
} from 'store/books/booksActions';
import { Descendant } from 'slate';
import { BookleTemplateBlock } from 'types';
import { findItemById } from '../utils';
import { FontFamilies } from 'Components/FontSelector/FontFamilies';
import clsx from 'clsx';
import FontManager from 'CoverEditor/FontManager';
import TextEditor from 'Editors/TextEditor/TextEditor';

import styles from './TextBlock.module.scss';

interface IProps {
  item: BookleTemplateBlock;
  updateBlocks: (payload: BookleTemplateBlock[]) => void;
  templateBlocks: BookleTemplateBlock[];
  updateTextEditor: (payload: IBookleTemplateEditor) => void;
}

const __tmp__initialValue: Descendant[] = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
];

const TextBlock = ({
  item,
  updateBlocks,
  templateBlocks,
  updateTextEditor,
}: IProps): JSX.Element => {
  const onChange = (i: Descendant[]) => {
    i.map((e: any) => {
      e.children.map((item: any) => {
        if (item.font) {
          FontManager.getInstance().loadFont(
            FontFamilies.find((e) => e.value === item.font)?.label || '',
            item.weight
          );
        }
      });
    });
    const newArray = [...templateBlocks];
    const textItem: BookleTemplateBlock = findItemById(
      newArray,
      item.id as string
    );
    textItem.text = i;
    updateBlocks(newArray);
  };

  return (
    <div
      className={styles.container}
      id={`text_editor_${item.id}`}
      style={{
        ...(item.hasColor ? { backgroundColor: item.color } : {}),
        ...(item.hasSpacing
          ? {
              paddingBottom: item.styles?.blockSpacing?.paddingBottom,
              paddingTop: item.styles?.blockSpacing?.paddingTop,
              paddingLeft: item.styles?.blockSpacing?.paddingLeft,
              paddingRight: item.styles?.blockSpacing?.paddingRight,
            }
          : {}),
      }}
    >
      <TextEditor
        onChange={onChange}
        initialValue={item.text || __tmp__initialValue}
        setEditor={(editor, selection) =>
          updateTextEditor({ editor, selection })
        }
      />
      {item.generating && <div className={styles.generating}></div>}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  templateBlocks: state.books.bookleTemplateBlocks,
});

const mapDispatchToProps = {
  updateBlocks: (payload: BookleTemplateBlock[]) =>
    updateBookleTemplateBlocks(payload),
  updateTextEditor: (payload: IBookleTemplateEditor) =>
    updateBookleTemplateTextEditor(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(TextBlock);
