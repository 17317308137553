import TextArea from 'UILib/TextArea/TextArea';
import Input from 'UILib/Input/Input';

import styles from './GeneralInfoForm.module.scss';

interface IProps {
  fields: Record<string, { label: string; name: string; required?: boolean }>;
  data: Record<string, string | number | boolean>;
  validationFailed: boolean;
  onValueChange: (key: string, value: string | number | boolean) => void;
}

const GeneralInfoForm = ({
  data,
  fields,
  validationFailed,
  onValueChange,
}: IProps) => {
  const inputData = data[fields.input.name];

  let textareaData;
  if (fields.textarea) {
    textareaData = data[fields.textarea.name];
  }

  return (
    <div className={styles.container}>
      <Input
        className={styles.input}
        border="one-line"
        placeholder={fields.input.label}
        value={typeof inputData !== 'boolean' ? inputData : ''}
        onChange={(event) =>
          onValueChange(`${fields.input.name}`, event.target.value)
        }
        error={
          validationFailed && fields.input?.required && !inputData
            ? 'Required Field'
            : undefined
        }
      />
      {fields.textarea && (
        <TextArea
          value={typeof textareaData === 'string' ? textareaData : ''}
          placeholder={fields.textarea.label}
          onChange={(newValue) =>
            onValueChange(`${fields.textarea.name}`, newValue)
          }
          hasErrors={
            validationFailed && fields.textarea?.required && !textareaData
          }
          autoComplete="off"
          height={180}
          maxLength={5000}
        />
      )}
    </div>
  );
};

export default GeneralInfoForm;
