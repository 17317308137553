import { useEffect, useState } from 'react';

import styles from './FreeBookBanner.module.scss';
import { ReactComponent as GiftSvg } from 'Assets/icons/gift.svg';
import { ReactComponent as CloseSvg } from 'Assets/icons/close2.svg';
import clsx from 'clsx';

interface IProps {
  onOpen: () => void;
  isSidebarOpen: boolean;
}

const FreeBookBanner = ({ onOpen, isSidebarOpen }: IProps) => {
  const [open, setOpen] = useState(false);
  const [timeString, setTimeString] = useState('00:00:00');

  let _targetTime: number = 0;
  let _interval: any;

  useEffect(() => {
    const targetTimeString = localStorage.getItem('BOOKLE_FREE_LARGE_BOOK');

    if (targetTimeString === null) {
      _targetTime = Date.now() + 24 * 60 * 60 * 1000;
      localStorage.setItem('BOOKLE_FREE_LARGE_BOOK', String(_targetTime));
    } else {
      _targetTime = parseInt(targetTimeString);
    }

    updateTimer();
    _interval = setInterval(() => {
      updateTimer();
    }, 1000);
    return () => {
      clearInterval(_interval);
    };
  }, []);

  const updateTimer = () => {
    const delta = Math.floor((_targetTime - Date.now()) / 1000);
    if (delta < 0) {
      setTimeString('00:00:00');
      setOpen(false);
      return;
    } else {
      setOpen(true);
    }

    const hours = Math.floor(delta / 60 / 60);
    const minutes = Math.floor(delta / 60) - hours * 60;
    const seconds = delta % 60;

    const hoursStr = hours.toString().padStart(2, '0');
    const minutesStr = minutes.toString().padStart(2, '0');
    const secondsStr = seconds.toString().padStart(2, '0');

    setTimeString(`${hoursStr}:${minutesStr}:${secondsStr}`);
  };

  return (
    <>
      {open ? (
        <div
          className={clsx([
            styles.banner,
            { [styles.slideBanner]: isSidebarOpen },
          ])}
        >
          <GiftSvg width={40} height={40} className={styles.giftIcon} />
          <div className={styles.textContainer}>
            <div className={styles.text}>Get a FREE Large Book</div>
            <div className={styles.spacer}></div>
            <div className={styles.timerText}>This offer expires in:</div>
            <div className={styles.timer}>{timeString}</div>
          </div>
          <div className={styles.buttonContainer}>
            <button className={styles.button} onClick={onOpen}>
              Get It now
            </button>
            <CloseSvg
              className={styles.buttonClose}
              onClick={() => setOpen(false)}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default FreeBookBanner;
