export const DISCONNECT_FUNNEL_DOMAIN_MUTATION = {
  operationName: 'disconnectFunnelFromDomain',
  query: `mutation disconnectFunnelFromDomain(
      $id: String!
    ) {
      disconnectFunnelFromDomain(
        id: $id
      ) {
        _id
        name
        projectId
        status
      }
    }`,
};
