import { useEffect, useRef } from 'react';

type eventHandlerType = () => void;

export default function useElementClickedOutside(
  handleFunction: eventHandlerType
) {
  const ref: React.RefObject<HTMLDivElement> = useRef(null);

  const handleClickOutside = (e: MouseEvent) => {
    if (ref.current && !ref.current.contains(e.target as Node)) {
      handleFunction();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return { ref };
}
