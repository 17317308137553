import { Dropdown } from 'antd';
import DotsIcon from 'Assets/icons/moreIcon.svg';
import { ReactComponent as SettingsIcon } from 'Assets/icons/contextMenu/settingsIcon.svg';
import { ReactComponent as DuplicateIcon } from 'Assets/icons/contextMenu/duplicateIcon.svg';
import { ReactComponent as DuplicateInAnotherFunnelIcon } from 'Assets/icons/contextMenu/duplicateInAnotherFunnelIcon.svg';
import { ReactComponent as ArchiveIcon } from 'Assets/icons/contextMenu/archiveIcon.svg';
import { ReactComponent as AnalyticsIcon } from 'Assets/icons/contextMenu/analyticsIcon.svg';
import { ReactComponent as UrlIcon } from 'Assets/icons/book/copy.svg';

import s from './RenderPage.module.scss';

interface IProps {
  onSettings: () => void;
  onDuplicate: () => void;
  onDuplicateToOtherFunnel: () => void;
  onCopyPageURL: () => void;
  onArchive: () => void;
  onStatistics: () => void;
}

enum MenuItems {
  Settings,
  Duplicate,
  DuplicateOtherFunnel,
  CopyPageURL,
  Archive,
  Statistics,
}

const PageSubMenu = (props: IProps) => {
  const menuItems = [
    {
      label: 'Settings',
      key: MenuItems.Settings,
      icon: <SettingsIcon />,
    },
    {
      label: 'Duplicate',
      key: MenuItems.Duplicate,
      icon: <DuplicateIcon />,
    },
    {
      label: 'Copy to Another Funnel',
      key: MenuItems.DuplicateOtherFunnel,
      icon: <DuplicateInAnotherFunnelIcon />,
    },
    {
      label: 'Copy page URL',
      key: MenuItems.CopyPageURL,
      icon: <UrlIcon />,
    },
    {
      label: 'Delete',
      key: MenuItems.Archive,
      icon: <ArchiveIcon />,
    },
    {
      label: 'Statistics',
      key: MenuItems.Statistics,
      icon: <AnalyticsIcon />,
    },
  ];

  const handleMenuClick = (e: any) => {
    e.domEvent.stopPropagation();
    switch (+e.key) {
      case MenuItems.Settings: {
        props.onSettings();
        break;
      }
      case MenuItems.Duplicate: {
        props.onDuplicate();
        break;
      }
      case MenuItems.DuplicateOtherFunnel: {
        props.onDuplicateToOtherFunnel();
        break;
      }
      case MenuItems.CopyPageURL: {
        props.onCopyPageURL();
        break;
      }
      case MenuItems.Archive: {
        props.onArchive();
        break;
      }
      case MenuItems.Statistics: {
        props.onStatistics();
        break;
      }
    }
  };

  const menuProps = {
    items: menuItems,
    onClick: handleMenuClick,
  };

  return (
    <Dropdown menu={menuProps} trigger={['click']} overlayClassName={'custom'}>
      <div
        className={s.dots}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <img src={DotsIcon} alt="..." />
      </div>
    </Dropdown>
  );
};

export default PageSubMenu;
