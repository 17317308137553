import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { useDrag } from 'react-dnd';
import { ReactComponent as MoreIcon } from 'Assets/icons/more.svg';
import { ReactComponent as Move } from 'Assets/icons/move2.svg';
import { ReactComponent as Settings } from 'Assets/icons/contextMenu/settingsIcon.svg';
import { BookleTemplateBlock } from 'types';
import { MenuItems } from '../Sidebar/Sidebar';
import ButtonBlockStylesContainer from './ButtonBlockStylesContainer/ButtonBlockStylesContainer';
import ContainerStyleBlock from './ContainerStyleBlock/ContainerStyleBlock';
import ImageBlockStyleContainer from './ImgageBlockStyleContainer/ImageBlockStyleContainer';
import DividerBlockStyleContainer from './DividerBlockStyleContainer/DividerBlockStyleContainer';
import ToolbarManipulatorPopup from '../toolbarManipulatorPopup/toolbarManipulatorPopup';
import More from './toolbarMore/more';

import styles from './toolbar.module.scss';

interface IProps {
  item: BookleTemplateBlock;
  setDragging: (item: boolean) => void;
}

const SettingsModal = ({ item }: { item: BookleTemplateBlock }) => {
  switch (item?.type) {
    case MenuItems.ONE_SECTION:
    case MenuItems.TWO_SECTION:
    case MenuItems.THREE_SECTION:
    case MenuItems.FOUR_SECTION:
    case MenuItems.TEXT_BLOCK:
      return <ContainerStyleBlock item={item} />;
    case MenuItems.IMAGE_BLOCK:
      return <ImageBlockStyleContainer item={item} />;
    case MenuItems.DIVIDER_BLOCK:
      return <DividerBlockStyleContainer item={item} />;
    case MenuItems.BUTTON_BLOCK:
      return <ButtonBlockStylesContainer item={item} />;
    default:
      return <></>;
  }
};

const Toolbar = ({ item, setDragging }: IProps) => {
  const [toolbarType, setToolbarType] = useState<
    'settings' | 'more' | undefined
  >(undefined);
  const divRef = useRef(null);
  const [isAtTop, setIsAtTop] = useState(false);
  const [toolbarHeight, setToolbarTopHeight] = useState<number>(0);

  const moveButtonRef = useRef<HTMLDivElement | null>(null);

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'MENU_ITEM',
    item,
    collect: (monitor) => {
      setDragging(monitor.isDragging());
      return {
        isDragging: monitor.isDragging(),
      };
    },
  }));

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setToolbarTopHeight(entry.boundingClientRect.height);
        setIsAtTop(
          entry.intersectionRatio >= 0 && entry.boundingClientRect.top <= 80
        );
      },
      {
        root: null,
        rootMargin: '0px 0px -100% 0px',
        threshold: [1],
      }
    );

    if (divRef.current) {
      observer.observe(divRef.current);
    }

    return () => {
      if (divRef.current) {
        observer.unobserve(divRef.current);
      }
    };
  }, [divRef]);

  const handleMoveStart = () => {
    setToolbarType(undefined);
  };

  const onClickToolbarButton = (key: 'settings' | 'more') => {
    setToolbarType(toolbarType === key ? undefined : key);
  };

  const onMoreClick = () => {
    setToolbarType(undefined);
  };

  if (isDragging) return null;

  return (
    <div
      className={`${styles.container} ${isDragging ? styles.dragging : ''}`}
      ref={divRef}
      style={isAtTop ? { top: 'initial', bottom: -50 } : {}}
      id={`toolbar-${item.id}`}
    >
      {!!toolbarType && (
        <ToolbarManipulatorPopup
          toolbarHeight={toolbarHeight}
          type={toolbarType === 'more' ? 'sm' : 'lg'}
          popupContent={
            toolbarType === 'more' ? (
              <More item={item} onClick={onMoreClick} />
            ) : toolbarType === 'settings' ? (
              <SettingsModal item={item} />
            ) : null
          }
        />
      )}
      <div className={styles.toolbarItems}>
        <div
          className={styles.iconContainer}
          onMouseDown={handleMoveStart}
          onClick={() => setToolbarType(undefined)}
          ref={(node) => {
            drag(node);
            moveButtonRef.current = node;
          }}
        >
          <Move />
        </div>
        <div
          className={clsx(styles.iconContainer, {
            [styles.activeButton]: toolbarType === 'settings',
          })}
          onClick={() => onClickToolbarButton('settings')}
        >
          <Settings />
        </div>
        <div
          className={clsx(styles.iconContainer, {
            [styles.activeButton]: toolbarType === 'more',
          })}
          onClick={() => onClickToolbarButton('more')}
        >
          <MoreIcon />
        </div>
      </div>
    </div>
  );
};

export default Toolbar;
