import Loader from 'UILib/Loader/Loader';

import styles from './PageImagePlaceholder.module.scss';

const PageImagePlaceholder = () => {
  return (
    <div className={styles.placeholder}>
      <div className={styles.logo}>
        <Loader size={20} />
      </div>
    </div>
  );
};

export default PageImagePlaceholder;
