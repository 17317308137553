export const GET_ONE_MAILDRAFT = {
  operationName: 'getOneMailDraft',
  query: `query getOneMailDraft ($id: String!){
            getOneMailDraft(id: $id) {
                _id
                html
                text
                name
                data
                createdAt
                updatedAt
            }
          }`,
};
