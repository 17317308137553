import clsx from 'clsx';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useEffect, useRef, useState } from 'react';
import { RootState } from 'store/rootReducer';
import { IBookleTemplateBlockStyles } from 'store/books/booksReducer';
import {
  updateBookleTemplateBlocks,
  updateBookleTemplateBlockStyles,
} from 'store/books/booksActions';
import { ReactComponent as SettingsIcon } from 'Assets/icons/contextMenu/settingsIcon.svg';
import { ReactComponent as PlusIcon } from 'Assets/icons/plus.svg';
import { ReactComponent as MagicIcon } from 'Assets/icons/Magic.svg';
import { BookleTemplateBlock } from 'types';
import { UseOnClickOutside } from 'utils/UseOnClickOutside';
import Button from 'UILib/Button/Button';
import Content from '../Draggable/Content/Content';
import SettingsModal from './SettingsModal/SettingsModal';
import { IGenerationBlock } from './GenerationBlock/GenerationBlock';
import { MenuItems, Sidebar } from '../Draggable/Sidebar/Sidebar';
import { findItemById, Preview2HTML } from '../Draggable/utils';

import styles from './GenerationPreview.module.scss';
import { isAdmin } from '../../../utils/Utils';

interface IProps {
  templateBlocks: BookleTemplateBlock[];
  updateBlocks: (payload: BookleTemplateBlock[]) => void;
  isSidebarHidden?: boolean; // this attribute will hide the sidebar toggle
  onSidebarToggle?: (isSidebarOpen: boolean) => void; // even't which should be handled once toggle sidebar
  templateBlockStyles: IBookleTemplateBlockStyles;
  updateBlockStyles: (payload: IBookleTemplateBlockStyles) => void;
}

const GenerationPreview = (props: IProps) => {
  const history = useHistory();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);

  const settingsModalRef = useRef<HTMLDivElement | null>(null);
  const sidebarRef = useRef<HTMLDivElement | null>(null);

  UseOnClickOutside(settingsModalRef, (event) => {
    if (isSettingsModalOpen) handleCloseSettings(event as MouseEvent);
  });

  UseOnClickOutside(sidebarRef, () => {
    if (isSidebarOpen) toggleSidebar();
  });

  useEffect(() => {}, [props.templateBlocks]);

  useEffect(() => {
    if (props.isSidebarHidden) {
      setIsSidebarOpen(false);
    }
  }, [props.isSidebarHidden]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    if (props.onSidebarToggle) props.onSidebarToggle(!isSidebarOpen);
  };

  const generatePreviewHtml = () => {
    const convertor = new Preview2HTML();
    const blocksHtmlContent = convertor.setCorrespondingBlocks(
      props.templateBlocks,
      props.templateBlockStyles,
      false
    );
    console.log(blocksHtmlContent);
  };

  const handleCloseSettings = (event: MouseEvent) => {
    if (
      settingsModalRef.current &&
      !settingsModalRef.current.contains(event.target as Node)
    ) {
      setIsSettingsModalOpen(false);
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Button
        className={clsx(styles.addNewFormButton, styles.openSidebarButton, {
          [styles.hiddenSidebar]: props.isSidebarHidden,
        })}
        prefixIcon={<PlusIcon />}
        appearance="solid"
        width={40}
        height={40}
        onClick={toggleSidebar}
      />
      {isSettingsModalOpen ? (
        <div ref={settingsModalRef}>
          <SettingsModal
            styles={props.templateBlockStyles}
            updateStyles={props.updateBlockStyles}
          />
        </div>
      ) : (
        <>
          <Button
            className={clsx(styles.addNewFormButton, styles.settingsButton)}
            prefixIcon={<SettingsIcon className={styles.settingsIcon} />}
            appearance="solid"
            width={40}
            height={40}
            onClick={() => {
              setIsSettingsModalOpen(!isSettingsModalOpen);
            }}
          />

          {isAdmin() && (
            <Button
              className={clsx(
                styles.aiNodesFlowEditorButton,
                styles.settingsButton
              )}
              prefixIcon={<MagicIcon className={styles.settingsIcon} />}
              appearance="solid"
              width={40}
              height={40}
              onClick={() => {
                history.push('?editor=true');
              }}
            />
          )}
        </>
      )}
      <div className={styles.container}>
        <div
          ref={sidebarRef}
          className={clsx(styles.sidebar, { [styles.closed]: !isSidebarOpen })}
        >
          <Sidebar />
        </div>
        <Content />
      </div>
    </DndProvider>
  );
};

const mapStateToProps = (state: RootState) => ({
  templateBlockStyles: state.books.bookleTemplateBlockStyles,
  templateBlocks: state.books.bookleTemplateBlocks,
});

const mapDispatchToProps = {
  updateBlocks: (payload: BookleTemplateBlock[]) =>
    updateBookleTemplateBlocks(payload),
  updateBlockStyles: (payload: IBookleTemplateBlockStyles) =>
    updateBookleTemplateBlockStyles(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(GenerationPreview);
