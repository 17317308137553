import { ThunkDispatch } from 'redux-thunk';
import { combineReducers, Action } from 'redux';
import { funnelBuilderReducer } from './funnelBuilder/funnelBuilderReducer';
import { generationTemplateReducer } from './generationTemplates/generationTemplatesReducer';
import { templateReducer } from './templates/templatesReducer';
import { projectsReducer } from './projects/projectsReducer';
import { sectionReducer } from './sections/sectionsReducer';
import { accountReducer } from './accounts/accountsReducer';
import { domainsReducer } from './domains/domainsReducer';
import { productReducer } from './products/productsReducer';
import { podcastReducer } from './podcasts/podcastReducer';
import { alertsReducer } from './alerts/alertsReducer';
import { stripeReducer } from './stripe/stripeReducer';
import { senderReducer } from './senders/sendersReducer';
import { booksReducer } from './books/booksReducer';
import { syncReducer } from './sync/syncReducer';
import { userReducer } from './user/userReducer';
import { contactsReducer } from './contacts/contactsReducer';
import { autosaasReducer } from './autosaas/autosaasReducer';

const rootReducer = combineReducers({
  funnelBuilder: funnelBuilderReducer,
  projects: projectsReducer,
  templates: templateReducer,
  autosaas: autosaasReducer,
  sections: sectionReducer,
  accounts: accountReducer,
  products: productReducer,
  podcasts: podcastReducer,
  generationTemplates: generationTemplateReducer,
  senders: senderReducer,
  domains: domainsReducer,
  books: booksReducer,
  alerts: alertsReducer,
  stripe: stripeReducer,
  sync: syncReducer,
  user: userReducer,
  contact: contactsReducer,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
export type DispatchType = ThunkDispatch<RootState, void, Action>;
