import React, { useState } from 'react';
import Modal from 'Components/Common/Modal2/Modal';
import Select from 'Components/Common/Select/Select';
import Button from 'Styled/Button2';
import WordIcon from 'Assets/icons/worldBlack.svg';
import s from './Popups.module.scss';
import { IAccount } from 'Components/Accounts/types';
import { IDuplicateFunnelOtherAccountPayload } from 'types';

interface IProps {
  owner: string;
  funnelId: string;
  open: boolean;
  accounts: IAccount[];
  onClose: () => void;
  onDuplicate: (payload: IDuplicateFunnelOtherAccountPayload) => void;
}

interface ISelectOption {
  value: string;
  label: string;
  disabled?: boolean;
}

const DuplicateFunnelOtherAccountPopup = (props: IProps) => {
  const [selectedAccountSub, setSelectedAccountSub] = useState('0');

  const accountOptions: ISelectOption[] = props.accounts.map((account) => ({
    label: account.workspace ? account.workspace : account.name,
    value: account.owner,
  }));

  accountOptions.push({
    label: 'Select Account',
    value: '0',
    disabled: true,
  });

  const processDuplicateFunnel = async () => {
    if (selectedAccountSub === '0') {
      return;
    }
    props.onDuplicate({
      targetAccountOwner: selectedAccountSub,
      sourceAccountOwner: props.owner,
      funnelId: props.funnelId,
    });

    handleClose();
  };

  const handleClose = () => {
    setSelectedAccountSub('0');
    props.onClose();
  };

  return (
    <Modal displayModal={props.open} closeModal={() => handleClose()}>
      <div className={s.modal}>
        <img src={WordIcon} alt="" className={s.mainImage} />
        <h2 className={s.header}>Copy to Another Account</h2>
        <p className={s.additionalText}>
          Choose the account you want to copy this funnel
        </p>
        <Select
          options={accountOptions}
          onChange={(account: string) => setSelectedAccountSub(account)}
          value={selectedAccountSub}
        />
        <div className={s.buttonsBlock}>
          <Button onClick={() => handleClose()}>Cancel</Button>
          <Button
            onClick={() => processDuplicateFunnel()}
            disabled={selectedAccountSub === '0'}
          >
            Copy
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DuplicateFunnelOtherAccountPopup;
