export const GET_USER_BOOKS = {
  operationName: 'getUserBooks',
  query: `query getUserBooks {
      getUserBooks {
        _id
        images
        title
        coverImageUrl
        pdfFile
        createdAt
        progress
      }
    }`,
};
