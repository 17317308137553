import React, { useState, useEffect } from 'react';
import BigConnect from '../icons/bigConnect.svg';
import s from './ConnectAgency.module.scss';
import Input from '../popups/Input';
import Button from '../popups/Button';
import CheckedIcon from '../icons/checked.svg';
import ClockSvg from '../icons/clock.svg';
import { graphQlCall } from 'graphql/utils';
import QUERIES from 'graphql/queries';
import Spinner from 'Components/Common/Spinner/Spinner';

const ConnectAgency = () => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [connectedStatus, setConnectedStatus] = useState<
    'Connected' | 'Pending' | 'Free'
  >('Free');
  const [connectedAgency, setConnectedAgency] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchUserDetails();
  }, []);

  const handleSubmitConnection = async () => {
    setEmailError(false);
    const regex = /^(\w|\d)+(\.(\w|\d)+)?@(\w|\d)+(\.(\w|\d)+)+$/gi;
    if (!regex.test(email)) {
      setEmailError(true);
      return;
    }
    setLoading(true);
    try {
      await graphQlCall({
        queryTemplateObject: QUERIES.INITIATE_TRANSFER_BY_USER,
        values: {
          email,
        },
        headerType: 'USER-AUTH',
      });
      setConnectedStatus('Pending');
      setConnectedAgency(email);
      setEmail('');
    } catch (err: any) {
      console.error(err);
    }
    setLoading(false);
  };

  const handleDisconnectAgency = async () => {
    setLoading(true);
    try {
      await graphQlCall({
        queryTemplateObject: QUERIES.DISCONNECT_AGENCY,
        headerType: 'USER-AUTH',
      });
      setConnectedStatus('Free');
      setEmail('');
      setConnectedAgency('');
    } catch (err: any) {
      console.error(err);
    }
    setLoading(false);
  };

  const fetchUserDetails = async () => {
    setLoading(true);
    const user = await graphQlCall({
      queryTemplateObject: QUERIES.GET_CURRENT_USER_QUERY,
      headerType: 'USER-AUTH',
    });

    if (!user.agencyId && !user.requestedAgencyId) {
      setConnectedStatus('Free');
    } else if (user.agencyId) {
      setConnectedStatus('Connected');
      setConnectedAgency(user.agencyEmail);
    } else if (user.requestedAgencyId) {
      setConnectedStatus('Pending');
    }
    setLoading(false);
  };

  return (
    <main className={s.content}>
      <img src={BigConnect} alt="" className={s.mainImage} />
      <h2 className={s.header}>Connect Agency</h2>
      {loading ? (
        <div className={s.spinnerBlock}>
          <Spinner size={300} />
        </div>
      ) : (
        <section>
          {connectedStatus === 'Free' && (
            <div>
              <p className={s.text}>
                If you want to share permission with somebody to control your
                account, enter email below.
              </p>
              <Input
                type="email"
                onChange={setEmail}
                value={email}
                error={emailError}
              />

              <Button name="Connect" onClick={() => handleSubmitConnection()} />
            </div>
          )}

          {connectedStatus === 'Pending' && (
            <div>
              <p className={s.text}>
                You requested the agency to manage your account. Once you
                request will confirmed your account will be transferred to
                agency:
              </p>
              <div className={s.agencyBlock}>
                <img src={ClockSvg} alt="" />
                <span>{connectedAgency}</span>
              </div>
              <Button
                name="Disconnect"
                onClick={() => handleDisconnectAgency()}
              />
            </div>
          )}

          {connectedStatus === 'Connected' && (
            <div>
              <p className={s.text}>
                You authorize the agency to manage your account:
              </p>
              <div className={s.agencyBlock}>
                <img src={CheckedIcon} alt="" />
                <span>{connectedAgency}</span>
              </div>
              <Button
                name="Disconnect"
                onClick={() => handleDisconnectAgency()}
              />
            </div>
          )}
        </section>
      )}
    </main>
  );
};

export default ConnectAgency;
