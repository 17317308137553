import clsx from 'clsx';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { IUserProduct } from 'types';
import { RootState } from 'store/rootStore';
import { bookSettingsPopupAction } from 'store/books/booksActions';
import currencies from 'Assets/data/currencies.json';
import Dropdown from 'UILib/Dropdown/Dropdown';
import TextArea from 'UILib/TextArea/TextArea';
import Button from 'UILib/Button/Button';
import Input from 'UILib/Input/Input';
import Popup from 'UILib/Popup/Popup';

import styles from './SettingsPopup.module.scss';

interface IProps {
  popupData: { open?: boolean; initialState?: IUserProduct };
  triggerPopup: (payload: {
    open?: boolean;
    initialState?: IUserProduct;
  }) => void;
}

const SettingsPopup = ({ popupData, triggerPopup }: IProps) => {
  const [settingsData, setSettingsData] = useState<Partial<IUserProduct>>({});

  useEffect(() => {
    setSettingsData(popupData.initialState ?? { currency: 'USD' });
  }, [popupData.initialState]);

  if (!popupData.open) return null;

  const handleSubmit = () => {
    triggerPopup({ initialState: undefined, open: false });
    setSettingsData({ currency: 'USD' });
  };

  return (
    <Popup
      onClose={() => triggerPopup({ initialState: undefined, open: false })}
      wrapperClassName={styles.container}
    >
      <div className={styles.content}>
        <div className={styles.title}>Settings</div>
        <div className={styles.formBlock}>
          <span>
            Name <sup className={styles.asterisk}>*</sup>
          </span>
          <Input
            border="stroke"
            placeholder="Name of your product"
            defaultValue={settingsData?.name}
            onChange={(e) =>
              setSettingsData({ ...settingsData, name: e.target.value })
            }
          />
        </div>
        <div className={styles.secondRow}>
          <div className={styles.formBlock}>
            <span>
              Price <sup className={styles.asterisk}>*</sup>
            </span>
            <Input
              hideArrows
              className={styles.priceInput}
              type="number"
              border="stroke"
              placeholder="Price value"
              defaultValue={settingsData?.price}
              onChange={(e) =>
                setSettingsData({
                  ...settingsData,
                  price: Number(e.target.value),
                })
              }
            />
          </div>
          <div className={styles.formBlock}>
            <span>Currency</span>
            <Dropdown
              label="Currency"
              type="stroke"
              className={styles.currencyDropdown}
              value={settingsData.currency}
              options={currencies.map((currency) => ({
                label: currency.name,
                value: currency.name,
              }))}
              onChange={(newValue) =>
                setSettingsData((prev) => ({
                  ...prev,
                  currency: newValue,
                }))
              }
            />
          </div>
        </div>
        <div className={clsx(styles.formBlock, styles.textareaBlock)}>
          <span>Description</span>
          <span className={styles.charactersAmount}>
            Characters:{' '}
            <span
              className={clsx({
                [styles.error]: (settingsData.description?.length ?? 0) > 250,
              })}
            >
              {settingsData.description?.length ?? 0}/250
            </span>
          </span>
          <TextArea
            placeholder="Describe your product"
            height={180}
            value={settingsData.description}
            onChange={(newValue) =>
              setSettingsData((prev) => ({
                ...prev,
                description: newValue,
              }))
            }
          />
        </div>
      </div>
      <div className={styles.buttonsWrapper}>
        <Button appearance="highlighted" onClick={handleSubmit}>
          Save
        </Button>
        <Button
          appearance="stroke"
          onClick={() => triggerPopup({ initialState: undefined, open: false })}
        >
          Cancel Changes
        </Button>
      </div>
    </Popup>
  );
};

const mapStateToProps = (state: RootState) => ({
  popupData: state.books.settingsPopup,
});

const mapDispatchToProps = {
  triggerPopup: (payload: { open?: boolean; initialState?: IUserProduct }) =>
    bookSettingsPopupAction(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPopup);
