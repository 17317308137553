export const GET_TEMPLATES_LIST = {
  operationName: 'getTemplatesList',
  query: `query getTemplatesList {
      getTemplatesList {
        _id
        type
        name
        image
        category
        funnel
        page
      }
    }`,
};
