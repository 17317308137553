export const CREATE_BROADCAST_MUTATION = {
  operationName: 'createBroadcast',
  query: `mutation createBroadcast($name: String!, $smartListId: String, $senderId: String, $mailDraftId: String!, $sendAt: Float) {
                createBroadcast(name: $name, smartListId: $smartListId, senderId: $senderId, mailDraftId: $mailDraftId, sendAt: $sendAt) {
                  _id
                 name
                 status
                 createdAt
                 updatedAt
        }
    }`,
};
