import styles from './StatisticsPlaceholders.module.scss';

export const TotalStatsPlaceholder = () => {
  return (
    <div className={styles.placeholderWrapper}>
      <div className={styles.placeholderTotalStats} />
      <div className={styles.placeholderTotalStats} />
      <div className={styles.placeholderTotalStats} />
    </div>
  );
};

export const TableStatsPlaceholder = () => {
  return (
    <div className={styles.placeholderWrapper}>
      <div className={styles.placeholderTableStats} />
    </div>
  );
};

export const GraphicStatsPlaceholder = () => {
  return (
    <div className={styles.placeholderWrapper}>
      <div className={styles.placeholderGraphicStatsWrapper}>
        <div className={styles.placeholderGraphicStats} />
        <div className={styles.placeholderGraphicStats} />
        <div className={styles.placeholderGraphicStats} />
      </div>
      <div className={styles.placeholderGraphicStatsWrapper}>
        <div className={styles.placeholderGraphicStats} />
        <div className={styles.placeholderGraphicStats} />
        <div className={styles.placeholderGraphicStats} />
      </div>
    </div>
  );
};
