export const UPDATE_EPISODE_SECTIONS_MUTATION = {
  operationName: 'updateEpisodeSections',
  query: `
        mutation updateEpisodeSections(<KEYS>) {
          updateEpisodeSections(<VALUES>) {
            _id
            sections {
              _id
              title
              text
              notes
            }
          }
        }
      `,
};
