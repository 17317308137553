import React from 'react';
import s from './InputV2.module.scss';
import { useField } from 'formik';

interface IProps {
  name: string;
  width: number;
  type: string;
  placeholder: string;
  readOnly?: boolean;
}

function InputV2(props: IProps) {
  const [field, meta] = useField({ ...props });

  return (
    <div className={s.container}>
      <input
        autoComplete="off"
        {...field}
        name={props.name}
        readOnly={props.readOnly}
        type={props.type}
        placeholder={props.placeholder}
        className={s.input}
        style={{ width: `${props.width}px` }}
      />
      {meta.touched && meta.error ? (
        <p className={s.error}>{meta.error}</p>
      ) : null}
    </div>
  );
}

export default InputV2;
