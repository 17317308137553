export const GET_STATISTIC_FUNNEL_GRAPH_DATA_QUERY = {
  operationName: 'getStatisticFunnelGraphData',
  query: `query getStatisticFunnelGraphData(
      $funnelId: String!
      $from: DateTime!
      $to: DateTime!
    ) {
      getStatisticFunnelGraphData(
        funnelId: $funnelId
        from: $from
        to: $to

      ) {
        eventType
        actualStatisticData {
          value
          from
          to
        }
        previousStatisticData {
          value
          from
          to
        }
      }
    }`,
};
