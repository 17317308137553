export const UPDATE_SAAS_CATEGORY = {
  operationName: 'updateSassCategory',
  query: `mutation updateSassCategory ($id: String!, $name: String!, $description: String) {
              updateSassCategory (id: $id, name: $name, description: $description) {
                  _id
                  name
                  description
                  updatedAt
                  createdAt
              }
          }`,
};
