export const UPDATE_AI_TEXT_TOPIC_MUTATION = {
  operationName: 'updateAiTextTopic',
  query: `mutation updateAiTextTopic($name: String!, $id: String!) {
      updateAiTextTopic(name: $name, id: $id) {
        _id
        name
        createdAt
        updatedAt
      }
    }`,
};
