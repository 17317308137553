import { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Row, Table, Pagination, Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import type { TableColumnsType, TableProps } from 'antd';
import { ReactComponent as PersonIcon } from 'Assets/icons/person.svg';
import { ReactComponent as ChooseIcon } from 'Assets/icons/choose.svg';
import { ReactComponent as ReloadIcon } from 'Assets/icons/reload.svg';
import { ReactComponent as MailIcon } from 'Assets/icons/mail.svg';
import { ReactComponent as DeleteIcon } from '../../Accounts/icons/delete.svg';
import { graphQlCall } from 'graphql/utils';
import queries from 'graphql/queries';
import SearchInput from 'UILib/SearchInput/SearchInput';
import OptionMenu from 'Components/OptionMenu/OptionMenu';
import CircleAvatar from 'Pages/PageContacts/Helper/Avatar';

import styles from './CustomersTable.module.scss';

interface DataType {
  name: string;
  email: string;
  product: string;
  plan: string;
}

enum MenuItems {
  Delete,
}

enum ColumnNames {
  name = 'name',
  email = 'email',
  product = 'customData.product',
  plan = 'customData.plan',
}

const columnsData = [
  { title: 'Full Name', dataIndex: 'name', key: 'name', icon: <PersonIcon /> },
  { title: 'Email', dataIndex: 'email', key: 'email', icon: <MailIcon /> },
  {
    title: 'Product',
    dataIndex: 'product',
    key: 'product',
    icon: <ChooseIcon />,
  },
  { title: 'Plan', dataIndex: 'plan', key: 'plan', icon: <ReloadIcon /> },
];

type UserInfo = {
  customData: {
    plan?: string;
    product?: string;
  };
  email: string;
  name: string;
  role: string;
  _id: string;
};

interface IProps {
  setCustomersCount: (count: number) => void;
}

const CustomersTable = ({ setCustomersCount }: IProps) => {
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [usersList, setUsersList] = useState<UserInfo[]>([]);
  const [totalUsers, setTotalUsers] = useState<number>(0);
  const [sortBy, setSortBy] = useState<{
    name: string;
    order: boolean;
  }>({
    name: '',
    order: false,
  });
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);

  const handleMenuClick = useCallback((e: any) => {
    e.domEvent.stopPropagation();
  }, []);

  const menuProps = useMemo(() => {
    const menuItems = [
      {
        label: 'Delete',
        key: MenuItems.Delete,
        icon: <DeleteIcon />,
      },
    ];

    return {
      items: menuItems,
      onClick: handleMenuClick,
    };
  }, [handleMenuClick]);

  const columns: TableColumnsType<DataType> = [
    {
      key: 'name',
      title: () => (
        <div className={styles.tableHeader}>
          <PersonIcon fill="#7d7c7c" width={14} height={16} /> Full Name
        </div>
      ),
      render: (name, record: DataType) => {
        return (
          <div className={styles.avatarContainer}>
            <CircleAvatar
              name={name}
              email={record?.email}
              className={styles.avatar}
            />
            <span>{name}</span>
          </div>
        );
      },
      onHeaderCell: () => ({ className: styles.tableHeaderWrapper }),
      dataIndex: 'name',
      sorter: true,
    },
    {
      key: 'email',
      title: () => (
        <div className={styles.tableHeader}>
          <MailIcon fill="#7d7c7c" width={16} height={16} /> Email
        </div>
      ),
      onHeaderCell: () => ({ className: styles.tableHeaderWrapper }),
      dataIndex: 'email',
      sorter: true,
    },
    {
      key: 'product',
      title: () => (
        <div className={styles.tableHeader}>
          <ChooseIcon fill="#7d7c7c" width={16} height={16} /> Product
        </div>
      ),
      onHeaderCell: () => ({ className: styles.tableHeaderWrapper }),
      dataIndex: 'product',
      sorter: true,
    },
    {
      key: 'plan',
      title: () => (
        <div className={styles.tableHeader}>
          <ReloadIcon fill="#7d7c7c" width={16} height={16} /> Plan
        </div>
      ),
      onHeaderCell: () => ({ className: styles.tableHeaderWrapper }),
      dataIndex: 'plan',
      sorter: true,
    },
    {
      key: 'options',
      render: (_, record: DataType) => (
        <OptionMenu menuProps={menuProps} className={styles.option} />
      ),
      onHeaderCell: () => ({ className: styles.optionWrapper }),
      dataIndex: 'options',
    },
  ];
  const [selectedColumns, setSelectedColumns] = useState(
    columns.map((col) => col.key)
  );

  const handleColumnChange = (checked: boolean, columnKey: string) => {
    setSelectedColumns((prev) =>
      checked ? [...prev, columnKey] : prev.filter((key) => key !== columnKey)
    );
  };

  const menu = (
    <Menu className={styles.columnsMenu}>
      {columnsData.map((col) => (
        <Menu.Item
          key={col.key}
          onClick={() =>
            handleColumnChange(!selectedColumns.includes(col.key), col.key)
          }
          className={styles.menuItem}
        >
          <input
            type="checkbox"
            className={styles.checkbox}
            checked={selectedColumns.includes(col.key)}
          />
          {col.icon}
          <span>{col.title}</span>
        </Menu.Item>
      ))}
    </Menu>
  );

  const filteredColumns = columns.filter(
    (col) => selectedColumns.includes(col.key) || col.key === 'options'
  );

  useEffect(() => {
    graphQlCall({
      queryTemplateObject: queries.GET_SAAS_USERS_LIST,
      values: {
        limit: pagination.pageSize,
        skip: (pagination.current - 1) * pagination.pageSize,
        sortBy: sortBy.name,
        sortAsc: sortBy.order,
        search: searchTerm,
      },
      headerType: 'USER-AUTH',
    })
      .then((data) => {
        setUsersList(data.users);
        setTotalUsers(data.total);
        setCustomersCount(data.total);
      })
      .catch((error) => console.error(error));
  }, [pagination, sortBy, searchTerm]);

  const onChange: TableProps<DataType>['onChange'] = (
    paginationInfo,
    filters,
    sorter,
    extra
  ) => {
    if (paginationInfo) {
      setPagination({
        current: paginationInfo.current ?? 1,
        pageSize: paginationInfo.pageSize ?? 10,
      });
    }

    if (sorter) {
      const { field, order } = sorter as {
        field: string;
        order: 'ascend' | 'descend' | undefined;
      };
      let sortOrder = false;
      let sortName = '';
      if (order) {
        sortOrder = order === 'ascend';
        sortName = field;
      }

      setSortBy({
        name: ColumnNames[sortName as keyof typeof ColumnNames] || '',
        order: sortOrder,
      });
    }
  };

  const PaginationContent = () => {
    return (
      <Pagination
        current={pagination.current}
        pageSize={pagination.pageSize}
        total={totalUsers}
        showSizeChanger
        onChange={(page, pageSize) => {
          onChange({ current: page, pageSize: pageSize }, {}, {}, [] as any);
        }}
      />
    );
  };

  return (
    <>
      <Row justify="space-between" align="middle" className={styles.header}>
        <Col className={styles.searchContainer}>
          <SearchInput
            onChange={(text) => setSearchTerm(text)}
            border="stroke"
            className={styles.search}
          />
          <span className={styles.total}>Total: {totalUsers}</span>
        </Col>
        <Col className={styles.pagination}>
          <Dropdown
            overlay={menu}
            trigger={['click']}
            visible={dropdownVisible}
            onVisibleChange={(visible) => setDropdownVisible(visible)}
            className={styles.columnsSelect}
          >
            <div>
              Columns <DownOutlined />
            </div>
          </Dropdown>
          <PaginationContent />
        </Col>
      </Row>
      <Table<DataType>
        columns={filteredColumns}
        className={styles.table}
        dataSource={usersList.map((user) => ({
          email: user.email,
          name: user.name,
          product: user.customData.product || '',
          plan: user.customData.plan || '',
        }))}
        showSorterTooltip
        onChange={onChange}
        pagination={false}
      />
      <div className={styles.footer}>
        <PaginationContent />
      </div>
    </>
  );
};

export default CustomersTable;
