import { useState } from 'react';
import Dropdown from 'UILib/Dropdown/Dropdown';
import TextArea from 'UILib/TextArea/TextArea';
import Button from 'UILib/Button/Button';
import Link from 'UILib/Link/Link';
import { ReactComponent as Back } from 'Assets/arrow-left-back.svg';
import { ReactComponent as MailIcon } from 'Assets/icons/mail.svg';
import { ReactComponent as ArrowRightIcon } from 'Assets/icons/arrowRight.svg';

import styles from './WorkflowBuilder.module.scss';
import { IWorkflowData } from '../types';
import Input from 'UILib/Input/Input';

const tones = [
  { label: '1-14 days', value: '1-14-days' },
  { label: '1 month', value: 'month' },
];

const WorkflowBuilder = () => {
  const [data, setData] = useState<IWorkflowData>({});
  const [showEditor, setShowEditor] = useState(false);

  const handleEditorRedirectBack = () => {
    setShowEditor(!showEditor);
  };

  return (
    <div className={styles.container}>
      <Link
        to="/console/outreach/workflows"
        className={styles.backLink}
        prefixIcon={<Back />}
      >
        Dashboard
      </Link>
      <div className={styles.header}>
        <div className={styles.title}>Adjust Selected Workflow</div>
        <div className={styles.subtitle}>
          Give us more information about your workflow.
        </div>
      </div>
      <div className={styles.form}>
        <div>
          Workflow Name:
          <Input
            value={data.name}
            className={styles.input}
            border="stroke"
            placeholder="What's your workflow called?"
            onChange={(e) => setData({ ...data, name: e.target.value })}
          />
        </div>
        <div>
          How long will your campaign run for?:
          <Dropdown
            label="Select a period"
            value={data.howLong}
            type="stroke"
            options={tones}
            onChange={(newValue) => setData({ ...data, howLong: newValue })}
          />
        </div>
        <div>
          Describe a bit:
          <TextArea
            height={230}
            value={data.description}
            placeholder="Describe here the offer you are promoting"
            onChange={(newValue) => setData({ ...data, description: newValue })}
          />
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <Button
          appearance="stroke"
          onClick={() => setShowEditor(true)}
          prefixIcon={<ArrowRightIcon className={styles.arrowLeft} />}
        >
          Previous
        </Button>
        <Button
          appearance="highlighted"
          theme="dark"
          onClick={() => setShowEditor(true)}
        >
          Create
        </Button>
      </div>
    </div>
  );
};

export default WorkflowBuilder;
