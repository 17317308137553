export const CREATE_EPISODE_MUTATION = {
  operationName: 'createEpisode',
  query: `mutation createEpisode(
            $podcastId: String!
            $name: String!
            $tone: String
            $prompt: String
            $text: String
            $voice: String
            $audioUrl: String
            $description: String
            $sections: String
            $coverData: String
            $music: String
            $image: String
          ) {
            createEpisode(
                podcastId: $podcastId
                name: $name
                tone: $tone
                prompt: $prompt
                text: $text
                description: $description
                voice: $voice
                audioUrl: $audioUrl
                coverData: $coverData
                sections: $sections
                music: $music
                image: $image
            ) {
                _id
                name
                tone
                voice
                prompt
                text
                audioUrl
                coverData
                image
                sections {
                  _id
                  title
                  text
                }
                description
                createdAt
                updatedAt
              }
          }`,
};
