import { useState } from 'react';
import { IGenerationTemplate } from 'types';
import { useHistory } from 'react-router-dom';
import { ReactComponent as Email } from 'Assets/icons/email.svg';
import { ReactComponent as Phone } from 'Assets/icons/phone.svg';
import { ReactComponent as DeleteIcon } from 'Components/Accounts/icons/delete.svg';
import { ReactComponent as Eye } from 'Assets/icons/eye2.svg';
import OptionMenu from 'Components/OptionMenu/OptionMenu';
import EditableLabel from 'UILib/EditableLabel/EditableLabel';
import ToggleSwitch from 'UILib/ToggleSwitch/ToggleSwitch';
import coverImg from 'Assets/images/hardcodeCover.png';

import styles from './WorkflowItem.module.scss';

interface IProps {
  item: IGenerationTemplate;
  onDelete: () => void;
  onUpdate: (updatedItem: IGenerationTemplate) => void;
}

enum MenuItems {
  DELETE,
}

const WorkflowItem = ({ item, onDelete, onUpdate }: IProps) => {
  const [itemName, setItemName] = useState(item.name);

  const history = useHistory();

  const menuItems = [
    {
      label: 'Delete',
      key: MenuItems.DELETE,
      icon: <DeleteIcon />,
    },
  ];

  const handleClick = () => {
    history.push(`/console/bookle/template/${item._id}/edit`);
  };

  const handleUpdateWorkflowName = async () => {
    if (itemName === item.name) return;
  };

  const handleDeleteWorkflow = async (): Promise<void> => {
    try {
      onDelete();
    } catch (error) {
      console.error(error);
    }
  };

  const handleMenuClick = (e: any) => {
    e.domEvent.stopPropagation();
    switch (+e.key) {
      case MenuItems.DELETE: {
        handleDeleteWorkflow();
        break;
      }
    }
  };

  const menuProps = {
    items: menuItems,
    onClick: handleMenuClick,
  };

  return (
    <div className={styles.container} onClick={handleClick}>
      <div className={styles.infoContainer}>
        <div className={styles.image}>
          <img
            crossOrigin="anonymous"
            src={item.thumbnail || coverImg}
            alt="cover"
          />
        </div>
        <div className={styles.info}>
          <EditableLabel
            size="large"
            value={(itemName as string) || 'Test'}
            onChange={setItemName}
            onOutsideClick={handleUpdateWorkflowName}
          />
          <div className={styles.sentCount}>
            <div className={styles.countItem}>
              <Email />
              <span>3 email</span>
            </div>
            <div className={styles.countItem}>
              <Phone />
              <span>3 SMS</span>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.settingsContainer}>
        <div className={styles.details}>
          <div className={styles.detailItem}>
            <Email />
            <span>4,234</span>
          </div>
          <div className={styles.detailItem}>
            <Eye />
            <span>4,234</span>
          </div>
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <ToggleSwitch isBinary={true} className={styles.toggle} />
          </div>
        </div>

        <OptionMenu menuProps={menuProps} />
      </div>
    </div>
  );
};

export default WorkflowItem;
