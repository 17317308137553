import { ReactNode } from 'react';

import s from './ListItem.module.scss';

interface IProps {
  children: ReactNode;
}

const ListItem = ({ children }: IProps) => {
  return <div className={s.itemBlock}>{children}</div>;
};

export default ListItem;
