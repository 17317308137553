import React from 'react';
import s from './Tab.module.scss';

interface IProps {
  index?: number;
  selectedTab?: number;
  title: string;
  setSelectedTab?: (index: number) => void;
  children?: React.ReactNode;
}

function Tab(props: IProps) {
  const handleSelectTab = () => {
    if (props.setSelectedTab && props.index !== undefined) {
      props.setSelectedTab(props.index);
    }
  };

  return (
    <div className={s.container} onClick={() => handleSelectTab()}>
      <button className={s.button}>{props.title}</button>
      <div
        className={
          props.index === props.selectedTab ? s.borderPrimary : s.border
        }
      />
    </div>
  );
}

export default Tab;
