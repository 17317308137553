import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { RootState } from '../rootReducer';
import { IAccount } from 'Components/Accounts/types';
import { graphQlCall } from 'graphql/utils';
import QUERIES from 'graphql/queries';
import {
  switchSubAccount,
  clearSubAccountStorage,
  getActiveSubId,
} from 'utils/Utils';

type GetStateType = () => RootState;
type DispatchType = ThunkDispatch<RootState, void, Action>;

export const SET_ACCOUNTS = 'SET_ACCOUNTS';
export const SET_ACCOUNTS_LOADING = 'SET_ACCOUNTS_LOADING';
export const SET_ACTIVE_ACCOUNT = 'SET_ACTIVE_ACCOUNT';
export const SET_AGENCY_LOGO = 'SET_AGENCY_LOGO;';

export const fetchAccountsAction = () => async (dispatch: DispatchType) => {
  dispatch({
    type: SET_ACCOUNTS_LOADING,
    payload: true,
  });
  const data = await graphQlCall({
    queryTemplateObject: QUERIES.GET_SUB_ACCOUNTS,
    headerType: 'AGENCY_AUTH',
  });
  dispatch({
    type: SET_ACCOUNTS,
    payload: data as IAccount[],
  });
  dispatch({
    type: SET_ACCOUNTS_LOADING,
    payload: false,
  });
};

export const fetchAgencyLogoAction = () => async (dispatch: DispatchType) => {
  const agency = await graphQlCall({
    queryTemplateObject: QUERIES.GET_CURRENT_USER_QUERY,
    headerType: 'AGENCY_AUTH',
  });

  if (agency.thumbnail) {
    dispatch({
      type: SET_AGENCY_LOGO,
      payload: agency.thumbnail,
    });
  }
};

export const setAccountsAction = (accounts: IAccount[]) => async (
  dispatch: DispatchType,
  getState: GetStateType
) => {
  const state = getState();
  const activeSub = getActiveSubId();
  const accountExists = accounts.find((account) => account.owner === activeSub);
  if (!accountExists && activeSub) {
    let nextAccount: IAccount | undefined;
    state.accounts.accounts.map((account) => {
      if (!nextAccount && account.agencyId) {
        nextAccount = account;
      }
    });
    if (nextAccount) {
      await switchSubAccount(nextAccount._id, nextAccount.owner);
    } else {
      clearSubAccountStorage();
    }
  }
  dispatch({
    type: SET_ACCOUNTS,
    payload: accounts as IAccount[],
  });
};

export const setActiveAccountAction = (account: IAccount | null) => async (
  dispatch: DispatchType
) => {
  if (account) {
    await switchSubAccount(account._id, account.owner);
  } else {
    clearSubAccountStorage();
  }
  dispatch({
    type: SET_ACTIVE_ACCOUNT,
    payload: account,
  });
  const link = `/console/projects`;
  window.open(link, '_self');
};
