import React from 'react';
import s from './InputV4.module.scss';
import clsx from 'clsx';

interface IProps {
  name: string;
  width: number;
  type: string;
  placeholder: string;
  icon?: React.ReactNode;
  readOnly?: boolean;
  value?: string;
  border?: string;
  error?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

//border prop can take values: full, bottom

function InputV4(props: IProps) {
  return (
    <div className={s.container}>
      {props.icon && <div className={s.icon}>{props.icon}</div>}
      <input
        value={props.value}
        readOnly={props.readOnly || false}
        autoComplete="off"
        name={props.name}
        type={props.type}
        placeholder={props.placeholder}
        className={clsx(
          s.input,
          props.border ? s[`inputBorder${props.border}`] : null,
          props.error ? s.Inputerror : ''
        )}
        style={{ width: `${props.width}px` }}
        onChange={props.onChange}
      />
      {props.error ? <p className={s.error}>{props.error}</p> : null}
    </div>
  );
}

export default InputV4;
