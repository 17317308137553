import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import s from './ChoosePlane.module.scss';
import clsx from 'clsx';
import { ReactComponent as InfoSvg } from '../../../Assets/icons/info.svg';
import { ReactComponent as CoinSvg } from '../../../Assets/icons/coin.svg';
import { ReactComponent as SmallBookSvg } from '../../../Assets/images/smallBook.svg';
import { ReactComponent as MediumBookSvg } from '../../../Assets/images/mediumBook.svg';
import { ReactComponent as LargeBookSvg } from '../../../Assets/images/largeBook.svg';
import { ReactComponent as DoneSvg } from '../../../Assets/icons/doneIcon.svg';
import { ReactComponent as GiftInvintationSvg } from '../../../Assets/icons/gift2.svg';
import { ReactComponent as PremiumSvg } from '../../../Assets/icons/Premium.svg';
import { RootState } from 'store/rootReducer';
import xSvg from '../../../Assets/icons/close.svg';
import { graphQlCall } from '../../../graphql/utils';
import QUERIES from '../../../graphql/queries';
import { getUserName } from '../../../utils/Utils';
import UpdatePaymentMethodPopup from '../../projects/popups/UpdatePaymentMethodPopup';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { fetchUserDetails, loading } from '../../../store/user/userActions';
import { IUserDetails } from 'types';
import { BOOKLE_PLANES } from 'Constants';
import ToggleSwitch from '../../../UILib/ToggleSwitch/ToggleSwitch';

enum BillingPeriod {
  Monthly = 'monthly',
  Annually = 'annually',
}

interface IProps {
  userDetails: IUserDetails;
  isLoading: boolean;
  fetchUserDetails: () => void;
  setLoading: (state: boolean) => void;
}

const ChoosePlan = (props: IProps) => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const [billingPeriod, setBillingPeriod] = useState<BillingPeriod>(
    BillingPeriod.Monthly
  );
  const modalRoot = document.getElementById('modal');
  const [updatePaymentOpenPopup, setUpdatePaymentOpenPopup] = useState<
    boolean | null
  >(null);
  const [isLoading, setIsLoading] = useState(false);
  const [changedPlan, setChangedPlan] = useState(false);
  const percent = 20;
  const [currentTitle, setCurrentTitle] = useState<string | null>(null);

  const [planPendingPurchase, setPlanPendingPurchase] = useState<string>('');
  const [upsellPlan, setUpsellPlan] = useState<any | null>(null);
  const [visibleText, setVisibleText] = useState<boolean>(true);

  useEffect(() => {
    const message = params.get('message');
    if (message) {
      setCurrentTitle(decodeURIComponent(message));
    }

    props.fetchUserDetails();
  }, []);

  useEffect(() => {
    if (updatePaymentOpenPopup === false) {
      handleUpdatePaymentPopupClose();
    }
  }, [updatePaymentOpenPopup]);

  useEffect(() => {
    const checkForUnfinishedPurchase = async () => {
      if (planPendingPurchase) {
        if (props.userDetails.paymentMethod && planPendingPurchase !== '') {
          props.setLoading(true);
          const result = await updateUserPlan(planPendingPurchase);
          if (result == 'ok') {
            props.setLoading(false);
            handlePlanPurchaseComeplete(planPendingPurchase);
            setPlanPendingPurchase('');
          }
        }
      }
    };
    checkForUnfinishedPurchase();
  }, [props.userDetails.paymentMethod]);

  useEffect(() => {
    setVisibleText(!props.userDetails.plans);
  }, [props.userDetails.plans]);

  const reportConversion = (value: number) => {
    console.log('rc: ', value);
    try {
      window.gtag('event', 'conversion', {
        send_to: 'AW-11447100790/DQsaCKWNi50ZEPbKs9Iq',
        value: value,
        currency: 'USD',
        transaction_id: '',
      });
    } catch (error) {
      console.error('Report Conversion Error: ', error);
    }
  };

  const handlePlanPurchaseComeplete = (planId: string) => {
    if (billingPeriod == BillingPeriod.Monthly) {
      const originalPlan = getMonthlyPlanById(planId);
      if (originalPlan) {
        reportConversion(originalPlan.value);

        const name = originalPlan.name;
        const upsells = BOOKLE_PLANES['upsells'] as any;

        const plan = upsells[name];
        if (plan) {
          setUpsellPlan(plan);
        } else {
          setChangedPlan(true);
        }
      }
    } else {
      setChangedPlan(true);
    }
  };

  const getMonthlyPlanById = (id: string) => {
    for (const plan of BOOKLE_PLANES[BillingPeriod.Monthly]) {
      if (plan.stripePriceId === id) {
        return plan;
      }
    }
    return null;
  };

  const handleUpsellPurchase = async () => {
    props.setLoading(true);
    const result = await updateUserPlan(upsellPlan.stripePriceId);
    if (result == 'ok') {
      reportConversion(upsellPlan.value);
      props.setLoading(false);
      setChangedPlan(true);
    }
  };

  const handleClose = () => {
    window.parent.postMessage({ status: 'close' });
  };

  const handleUpdatePaymentPopupClose = async () => {
    setUpdatePaymentOpenPopup(false);
    await props.fetchUserDetails();
  };

  const handlePlanChange = async (plan: any) => {
    if (plan.name !== 'Free' && !props.userDetails.paymentMethod) {
      setPlanPendingPurchase(plan.stripePriceId);
      setUpdatePaymentOpenPopup(true);
      return;
    }
    props.setLoading(true);
    const result = await updateUserPlan(plan.stripePriceId);
    if (result == 'ok') {
      props.setLoading(false);
      handlePlanPurchaseComeplete(plan.stripePriceId);
    }
  };

  const getNameButtonLabel = (value: number) => {
    return value === 0 ? `Try for Free` : `Get Started`;
  };

  const updateUserPlan = async (stripePriceId: string) => {
    return await graphQlCall({
      queryTemplateObject: QUERIES.CHANGE_USERS_SUBSCRIPTION,
      values: {
        owner: getUserName(),
        productId: ' ',
        priceId: stripePriceId,
      },
    });
  };

  const priceHandler = (value: number) => {
    return value === 0 ? 'FREE' : `$${value}`;
  };

  const getBillingPeriodLabel = (isFreePlan: boolean) => {
    return isFreePlan
      ? 'mo'
      : billingPeriod === BillingPeriod.Annually
      ? 'year'
      : 'mo';
  };
  const isPlanCurrent = (planName: string) => {
    if (!props.userDetails.plans) {
      return '';
    }
    return planName === props.userDetails.plans[0]; //eventually need to handle multiple plans;
  };
  const iconHandler = (plan: string) => {
    if (plan === 'Medium') return <MediumBookSvg />;
    if (plan === 'Large') return <LargeBookSvg />;
    return <SmallBookSvg />;
  };

  useEffect(() => {
    if (props.userDetails && props.userDetails.plans) {
      const hasAnnualPlan = props.userDetails.plans.some((plan) =>
        plan.toLowerCase().includes('annual')
      );
      if (hasAnnualPlan) {
        setBillingPeriod(BillingPeriod.Annually);
      } else {
        setBillingPeriod(BillingPeriod.Monthly);
      }
    }
  }, [props.userDetails]);

  const upsellScreen = () => {
    return (
      upsellPlan && (
        <div>
          <div className={s.congratulationBlock}>
            <h3>{upsellPlan.promoTextHeader}</h3>
            <div className={s.successDescription}>
              {upsellPlan.promoText.map((text: string) => {
                return <span dangerouslySetInnerHTML={{ __html: text }}></span>;
              })}
            </div>
            <button
              className={clsx(s.planButton, s.successButton)}
              onClick={() => handleUpsellPurchase()}
            >
              Upgrade Now
            </button>
            <div
              className={s.secondaryButton}
              onClick={() => setChangedPlan(true)}
            >
              No Thanks
            </div>
          </div>
        </div>
      )
    );
  };

  const congratulationScreen = () => {
    return (
      <div>
        <div className={s.congratulationBlock}>
          <DoneSvg fill="#25bb73" />
          <h3>Congratulation!</h3>
          <div className={s.successDescription}>
            <span>Your order is complete!</span>
            <span>We've successfully processed your payment!</span>
          </div>
          <button
            className={clsx(s.planButton, s.successButton)}
            onClick={() => handleClose()}
          >
            Back
          </button>
        </div>
      </div>
    );
  };

  return modalRoot
    ? ReactDOM.createPortal(
        <>
          <div className={s.screenContainer} onClick={handleClose}>
            <div
              className={s.modalContainer}
              onClick={(e) => e.stopPropagation()}
            >
              <div className={s.close} onClick={handleClose}>
                <img src={xSvg} alt="close" />
              </div>

              {props.isLoading && (
                <div className={s.overlay}>
                  <div className={s.loadingContainer}>
                    <div className={s.spiner}>
                      <LoadingSpinner />
                    </div>
                    Loading...
                  </div>
                </div>
              )}
              {isLoading ? (
                <div className={s.spiner}>
                  {' '}
                  <LoadingSpinner />
                </div>
              ) : changedPlan === false && upsellPlan === null ? (
                <>
                  <h3 className={s.title}>
                    {visibleText
                      ? 'Not Available in Your plan'
                      : 'Choose a Plan'}
                  </h3>
                  <div className={s.contentBlock}>
                    <div className={s.subTitle}>
                      {visibleText ? (
                        <>
                          <span className={s.titleCurrentPlan}>
                            Your Balance: {props.userDetails.aiCredits}{' '}
                            <CoinSvg />
                          </span>
                          <span className={s.upgrade}>
                            {' '}
                            Upgrade your plan to be able to generate large books{' '}
                          </span>
                        </>
                      ) : (
                        <span className={s.titleCurrentPlan}>
                          {' '}
                          Your Plan: {props.userDetails.plans}
                        </span>
                      )}
                      {currentTitle && <span>{currentTitle}</span>}
                    </div>
                    <div className={s.hrRight}></div>
                    <div className={s.hrLeft}></div>
                    <div className={s.timeBlock}>
                      <div className={s.salePercent}>Save {percent}%</div>
                      <ToggleSwitch
                        options={['Monthly', 'Annually']}
                        selectedValue={
                          billingPeriod === BillingPeriod.Monthly
                            ? 'Monthly'
                            : 'Annually'
                        }
                        onSelectOption={(value) => {
                          const newPeriod =
                            value === 'Monthly'
                              ? BillingPeriod.Monthly
                              : BillingPeriod.Annually;
                          setBillingPeriod(newPeriod);
                        }}
                      />
                    </div>
                    <div className={s.bookSize}>
                      <div>Only Small Books</div>
                      <div>
                        <PremiumSvg />
                        Up to Medium Books
                      </div>
                      <div>
                        <PremiumSvg />
                        Up to Large Books
                      </div>
                    </div>
                    <div className={s.plansContainer}>
                      {BOOKLE_PLANES[billingPeriod].map((plan) => (
                        <div
                          key={plan.name}
                          className={clsx(
                            s.plan,
                            isPlanCurrent(plan.name)
                              ? s.planActive
                              : s.planCurent
                          )}
                        >
                          <div className={s.planTitle}>{plan.name}</div>
                          <div className={s.planSubTitle}>
                            {plan.descriptions}
                          </div>
                          <div className={s.planName}>
                            {priceHandler(plan.value)}{' '}
                            {plan.value > 0 && (
                              <span>
                                {' '}
                                /{getBillingPeriodLabel(plan.value === 0)}
                              </span>
                            )}
                          </div>
                          <div className={s.planCreditsGet}>
                            <CoinSvg /> Credits: {plan.creditsCount}{' '}
                            <span>
                              {' '}
                              /{getBillingPeriodLabel(plan.value === 0)}{' '}
                            </span>
                            <InfoSvg className={s.infoSvg} />
                          </div>
                          {iconHandler(plan.type)}
                          {/*<div className={s.planFeatures}>{plan.features}</div>*/}
                          <div className={s.planDescription}>
                            Up to {plan.pageCount} pages per Book
                          </div>
                          {isPlanCurrent(plan.name) ? (
                            <button
                              className={clsx(s.planButton, s.planActive)}
                              type="button"
                              disabled
                            >
                              Your Plan
                            </button>
                          ) : (
                            <button
                              className={
                                plan.value ? s.planButton : s.planButtonFree
                              }
                              type="button"
                              onClick={() => handlePlanChange(plan)}
                            >
                              {getNameButtonLabel(plan.value)}
                            </button>
                          )}
                        </div>
                      ))}
                      <div className={s.free}>
                        <GiftInvintationSvg />
                        <div className={s.freeCover}>
                          <div className={s.freeText}>
                            Invite your friend and get
                          </div>
                          <p className={s.freeCredits}>FREE CREDITS!</p>
                        </div>
                        <hr className={s.hr} />
                        <div className={s.freeContainer}>
                          <div className={s.freeWorks}>How it works</div>
                          <ul className={s.freeInf}>
                            <li>
                              When each invitee signs up via your referral and
                              makes their first Book or page, you get{' '}
                              <a className={s.freeYellow}>1 credit</a>, up to{' '}
                              <a className={s.freeYellow}>3 credits</a> per
                              month.
                            </li>
                            <li>
                              When each invitee subscribes, you get{' '}
                              <a className={s.freeYellow}>3 credits</a> with no
                              limit.
                            </li>
                            <li>Referral credits expire after 1 month.</li>
                          </ul>
                          <hr className={s.hr} />
                          <button className={s.freeButton} type="button">
                            Copy Invation
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : changedPlan ? (
                congratulationScreen()
              ) : (
                upsellScreen()
              )}
            </div>

            <UpdatePaymentMethodPopup
              open={updatePaymentOpenPopup!}
              onClose={() => setUpdatePaymentOpenPopup(false)}
            />
          </div>
          {/* //Workaround to make this component inside popup iframe transaprent  */}
          <style>{'body { background-color: transparent; }'}</style>
        </>,
        modalRoot
      )
    : null;
};

const mapStateToProps = (state: RootState) => ({
  userDetails: state.user.details,
  isLoading: state.user.isLoading,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchUserDetails: () => dispatch(fetchUserDetails()),
  setLoading: (state: boolean) => dispatch(loading(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChoosePlan);
