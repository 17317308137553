import { connect } from 'react-redux';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { IDomain, ISender } from 'types';
import { RootState } from 'store/rootStore';
import {
  addDomainPopupAction,
  clearDomainError,
} from 'store/domains/domainsActions';
import { addSender, sendersPopupAction } from 'store/senders/sendersActions';
import Dropdown from 'UILib/Dropdown/Dropdown';
import Button from 'UILib/Button/Button';
import Input from 'UILib/Input/Input';
import Popup from 'UILib/Popup/Popup';
import ErrorPopup from 'Components/Domains/DomainErrorPopup';

import styles from './AddSenderPopup.module.scss';

interface IProps {
  openAddSenderPopup: boolean;
  toggleAddSenderPopup: (payload: boolean) => void;
  toggleAddDomainPopup: (payload: boolean) => void;
  addSender: (senderName: string, domainId: string, email: string) => void;
  domains: IDomain[];
  domainError: string | null;
  clearDomainError: () => void;
}

const AddSenderPopup = ({
  openAddSenderPopup,
  toggleAddSenderPopup,
  toggleAddDomainPopup,
  addSender,
  domains,
  domainError,
  clearDomainError,
}: IProps) => {
  const [senderData, setSenderData] = useState<Partial<ISender>>({});
  const [domain, setDomain] = useState<string>('yourdomain.com');

  useEffect(() => {
    const splitDomain = domains
      .find((e) => e._id === senderData.mailDomain?._id)
      ?.name.split('www.');

    if (!!splitDomain?.length) {
      setDomain(splitDomain.length > 1 ? splitDomain[1] : splitDomain[0]);
    }
  }, [domains, senderData]);

  const domainOptions = useMemo(() => {
    const options: {
      label: ReactNode;
      value: string | undefined;
    }[] = domains.map((domain) => ({
      label: domain.name,
      value: domain._id,
    }));
    options.unshift({
      label: (
        <div
          onClick={(e) => {
            e.stopPropagation();
            toggleAddDomainPopup(true);
            toggleAddSenderPopup(false);
          }}
          className={styles.addOption}
        >
          + Add Domain
        </div>
      ),
      value: 'add_product',
    });

    return options;
  }, [domains, toggleAddDomainPopup, toggleAddSenderPopup]);

  if (!openAddSenderPopup) return null;

  const handleSubmit = () => {
    const { mailDomain, name, email } = senderData;
    if (!(mailDomain && name && email)) return;

    const domain = domains.find((e) => e._id === mailDomain._id);
    if (!domain) return;
    let domainName = domain.name;
    if (domainName.includes('www.')) domainName = domainName.split('www.')[1];
    const emailAddress = email + '@' + domainName;

    addSender(name, domain._id, emailAddress);
    toggleAddSenderPopup(false);
    setSenderData({});
  };

  return (
    <Popup
      onClose={() => toggleAddSenderPopup(false)}
      wrapperClassName={styles.container}
    >
      <div className={styles.content}>
        <div className={styles.title}>Add Sender</div>
        <div className={styles.formBlock}>
          <span>Sender Domain</span>
          <Dropdown
            label="Select Domain"
            onChange={(item) => {
              setSenderData({ ...senderData, mailDomain: { _id: item ?? '' } });
            }}
            value={senderData.mailDomain?._id}
            options={domainOptions}
            className={styles.dropdownBlock}
          />
        </div>
        <div className={styles.formBlock}>
          <span>Sender’s Name</span>
          <Input
            border="stroke"
            placeholder="Sender’s Name"
            defaultValue={senderData?.name}
            onChange={(e) =>
              setSenderData({ ...senderData, name: e.target.value })
            }
          />
        </div>
        <div className={styles.formBlock}>
          <span>From: “email address”</span>
          <div className={styles.domainBlock}>
            <Input
              border="stroke"
              placeholder="example"
              defaultValue={senderData?.email}
              onChange={(e) =>
                setSenderData({ ...senderData, email: e.target.value })
              }
            />
            <div className={styles.selectedDomain}>@{domain}</div>
          </div>
        </div>
      </div>
      <div className={styles.buttonsWrapper}>
        <Button appearance="highlighted" onClick={handleSubmit}>
          Add Sender
        </Button>
      </div>
      <ErrorPopup error={domainError} onClose={() => clearDomainError()} />
    </Popup>
  );
};

const mapStateToProps = (state: RootState) => ({
  openAddSenderPopup: state.senders.openAddSenderPopup,
  domains: state.domains.items,
  domainError: state.domains.error,
});

const mapDispatchToProps = {
  toggleAddSenderPopup: (payload: boolean) => sendersPopupAction(payload),
  toggleAddDomainPopup: (payload: boolean) => addDomainPopupAction(payload),
  addSender: (senderName: string, domainId: string, email: string) =>
    addSender(senderName, domainId, email),
  clearDomainError: () => clearDomainError(),
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSenderPopup);
