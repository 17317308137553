import clsx from 'clsx';
import { createPortal } from 'react-dom';
import { useEffect, useRef, useState } from 'react';
import { ReactComponent as RemoveIcon } from 'Assets/icons/remove-icon.svg';
import { ReactComponent as PlusIcon } from 'Assets/icons/plus.svg';
import Dropdown from 'UILib/Dropdown/Dropdown';
import Button from 'UILib/Button/Button';
import Input from 'UILib/Input/Input';

import styles from './UserInputDropdown.module.scss';

interface IProps {
  dropdownLabel: string;
  options: string[];
  onOptionAdd: (option: string) => void;
  onOptionRemove: (option: string) => void;
}

const UserInputDropdown = ({
  dropdownLabel,
  options,
  onOptionAdd,
  onOptionRemove,
}: IProps) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [newOption, setNewOption] = useState('');

  const containerRef = useRef<HTMLDivElement>(null);
  const optionsContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (
        optionsContainerRef.current &&
        optionsContainerRef.current.contains(e.target as Node)
      ) {
        e.stopPropagation();
        return;
      }
      if (
        containerRef.current &&
        !containerRef.current.contains(e.target as Node)
      ) {
        setOpenMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  return (
    <>
      <div ref={containerRef} className={styles.container}>
        <Dropdown
          size="small"
          options={[]}
          type="stroke"
          className={styles.dropdown}
          label={dropdownLabel}
          onChange={() => {}}
        />
        <Button
          width={30}
          height={30}
          appearance="stroke"
          onClick={() => setOpenMenu(!openMenu)}
          prefixIcon={<PlusIcon width={13} height={13} />}
        />
      </div>
      {createPortal(
        <div
          ref={optionsContainerRef}
          className={clsx(styles.optionsContainer, { [styles.open]: openMenu })}
          style={{
            width: containerRef.current?.getBoundingClientRect().width,
            top:
              (containerRef.current?.getBoundingClientRect().top || 0) +
              (containerRef.current?.getBoundingClientRect().height || 0),
            left: containerRef.current?.getBoundingClientRect().left,
          }}
        >
          <div className={styles.addOption}>
            <Input
              border="stroke"
              value={newOption}
              className={styles.input}
              onChange={(e) => setNewOption(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && newOption) {
                  onOptionAdd(newOption);
                  setNewOption('');
                }
              }}
              postfixIcon={
                <Button
                  width={30}
                  height={30}
                  appearance="solid"
                  prefixIcon={<PlusIcon />}
                  onClick={() => {
                    onOptionAdd(newOption);
                    setNewOption('');
                  }}
                />
              }
            />
          </div>
          {options.map((option) => (
            <div className={styles.option}>
              {option}
              <RemoveIcon
                className={styles.removeIcon}
                onClick={() => onOptionRemove(option)}
              />
            </div>
          ))}
        </div>,
        document.body
      )}
    </>
  );
};

export default UserInputDropdown;
