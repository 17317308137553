import clsx from 'clsx';
import { ReactComponent as ArrowDown } from 'Assets/icons/ArrowDown.svg';

import styles from './CustomArrows.module.scss';

interface IProps {
  onIncrement: () => void;
  onDecrement: () => void;
  className?: string;
}

const CustomArrowsForSizes = ({
  onIncrement,
  onDecrement,
  className,
}: IProps) => {
  return (
    <div className={clsx(styles.sizeInputCustomArrows, className)}>
      <ArrowDown
        width={12}
        height={12}
        className={styles.arrowUp}
        onClick={onIncrement}
      />
      <ArrowDown width={12} height={12} onClick={onDecrement} />
    </div>
  );
};

export default CustomArrowsForSizes;
