let coverTemplates = [];

coverTemplates.push({
  id: 8,
  width: 1024,
  height: 1024,
  colors: [
    ['#FFFFFF', '#FFF500', '#D32D2D', '#D32D2D'],
    ['#000000', '#C92B2B', '#FFE500', '#FFE500'],
    ['#FFFFFF', '#FF7878', '#5D5D5D', '#5D5D5D'],
    ['#FFFFFF', '#FF6565', '#193A75', '#4D75B0'],
  ],
  selectedColors: 0,
  items: [
    {
      id: 0,
      type: 'Image',
      image: '#IMAGE',
      x: 0,
      y: 0,
      width: 1024,
      height: 1024,
      scale: 1.0,
    },
    {
      id: 3,
      type: 'Text',
      text: '#TITLE',
      x: 0,
      y: 924,
      font: { family: 'Inter', weight: 800, style: 'normal' },
      textHAlign: 'center',
      scale: 1,
      width: 1024,
      style: {
        background: '#COLOR_2',
        width: '100%',
        fontSize: '40px',
        padding: '20px',
        color: '#COLOR_3',
        opacity: '1.0',
        fontWeight: '800',
        textTransform: 'uppercase',
        lineHeight: '1.0',
      },
    },
  ],
});

coverTemplates.push({
  id: 9,
  width: 1024,
  height: 1024,
  colors: [
    ['#FFFFFF', '#FFF500', '#D32D2D', '#D32D2D'],
    ['#000000', '#C92B2B', '#FFE500', '#FFE500'],
    ['#FFFFFF', '#FF7878', '#5D5D5D', '#5D5D5D'],
    ['#FFFFFF', '#FF6565', '#193A75', '#4D75B0'],
  ],
  selectedColors: 0,
  items: [
    {
      id: 0,
      type: 'Image',
      image: '#IMAGE',
      x: 0,
      y: 0,
      width: 1024,
      height: 1024,
      scale: 1.0,
    },
    {
      id: 3,
      type: 'Text',
      text: '#TITLE',
      x: 0,
      y: 0,
      font: { family: 'Inter', weight: 800, style: 'normal' },
      textHAlign: 'center',
      scale: 1,
      width: 1024,
      style: {
        background: '#COLOR_2',
        width: '100%',
        fontSize: '40px',
        padding: '20px',
        color: '#COLOR_3',
        opacity: '1.0',
        fontWeight: '800',
        textTransform: 'uppercase',
        lineHeight: '1.0',
      },
    },
  ],
});

// coverTemplates.push({
//   id: 10,
//   width: 1024,
//   height: 1024,
//   colors: [
//     ['#FFFFFF', '#FFF500', '#D32D2D', '#D32D2D'],
//     ['#000000', '#C92B2B', '#FFE500', '#FFE500'],
//     ['#FFFFFF', '#FF7878', '#5D5D5D', '#5D5D5D'],
//     ['#FFFFFF', '#FF6565', '#193A75', '#4D75B0'],
//   ],
//   selectedColors: 0,
//   items: [
//     {
//       id: 0,
//       type: 'Image',
//       image: '#IMAGE',
//       x: 0,
//       y: 0,
//       width: 1024,
//       height: 1024,
//       scale: 1.0,
//     },
//     {
//       id: 1,
//       type: 'Gradient',
//       style: {
//         background: 'linear-gradient(180deg, #COLOR_3 0%, #COLOR_4 100%)',
//         mixBlendMode: 'color',
//       },
//     },
//     {
//       id: 2,
//       type: 'Gradient',
//       style: {
//         background: 'linear-gradient(180deg, #COLOR_3 0%, #COLOR_4 100%)',
//         opacity: '0.8',
//       },
//     },
//     {
//       id: 3,
//       type: 'Text',
//       text: '#SUBTITLE_1',
//       x: 0,
//       y: 0,
//       font: { family: 'Inter', weight: 800, style: 'normal' },
//       textHAlign: 'center',
//       scale: 1,
//       width: 1024,
//       style: {
//         background: '#COLOR_2',
//         width: '100%',
//         fontSize: '40px',
//         padding: '20px',
//         color: '#COLOR_3',
//         fontWeight: '800',
//         lineHeight: '1.0',
//       },
//     },
//     {
//       id: 4,
//       type: 'Text',
//       text: '#TITLE',
//       x: 50,
//       y: 200,
//       font: { family: 'Inter', weight: 800, style: 'normal' },
//       scale: 1,
//       textHAlign: 'left',
//       width: 924,
//       height: 500,
//       style: {
//         fontSize: '150px',
//         color: '#COLOR_1',
//         fontWeight: '800',
//         lineHeight: '0.9',
//         textTransform: 'uppercase',
//       },
//     },
//     {
//       id: 5,
//       type: 'Text',
//       text: '#SUBTITLE_2',
//       x: 50,
//       y: 900,
//       font: { family: 'Inter', weight: 600, style: 'normal' },
//       scale: 1,
//       textHAlign: 'left',
//       width: 924,
//       height: 80,
//       style: {
//         margin: 'auto',
//         fontSize: '40px',
//         color: '#COLOR_1',
//         fontWeight: '800',
//         lineHeight: '1.1',
//       },
//     },
//   ],
// });

// coverTemplates.push({
//   id: 11,
//   width: 1024,
//   height: 1024,
//   colors: [
//     ['#E8C486', '#FFFFFF', '#193B56', '#193B56'],
//     ['#273F64', '#796848', '#E5C58C', '#E5C58C'],
//     ['#1E5953', '#FFFFFF', '#FF7F7F', '#FF7F7F'],
//     ['#306684', '#FFFFFF', '#BBBBBB', '#BBBBBB'],
//   ],
//   selectedColors: 0,
//   items: [
//     {
//       id: 0,
//       type: 'Image',
//       image: '#IMAGE',
//       x: 0,
//       y: 0,
//       width: 1024,
//       height: 1024,
//       scale: 1,
//     },
//     {
//       id: 1,
//       type: 'Gradient',
//       style: {
//         background: 'linear-gradient(180deg, #COLOR_3 0%, #COLOR_4 100%)',
//         mixBlendMode: 'color',
//       },
//     },
//     {
//       id: 2,
//       type: 'Gradient',
//       style: {
//         background: 'linear-gradient(180deg, #COLOR_3 0%, #COLOR_4 100%)',
//         opacity: '0.85',
//       },
//     },
//     {
//       id: 3,
//       type: 'Gradient',
//       maskImage: 'https://cdn.autofunnel.ai/covers/frame8.png',
//       style: {
//         background: '#COLOR_1',
//       },
//     },
//     {
//       id: 4,
//       type: 'Text',
//       text: '#SUBTITLE_1',
//       x: 70,
//       y: 90,
//       font: { family: 'Cinzel', weight: 700, style: 'normal' },
//       textHAlign: 'center',
//       scale: 1,
//       height: 65,
//       width: 884,
//       style: {
//         margin: 'auto',
//         fontSize: '40px',
//         color: '#COLOR_2',
//         lineHeight: '0.8',
//       },
//     },
//     {
//       id: 5,
//       type: 'Text',
//       text: '#TITLE',
//       x: 70,
//       y: 200,
//       font: { family: 'Cinzel', weight: 600, style: 'normal' },
//       scale: 1,
//       textHAlign: 'center',
//       width: 884,
//       height: 520,
//       style: {
//         fontSize: '150px',
//         color: '#COLOR_1',
//         fontWeight: '800',
//         lineHeight: '0.9',
//         margin: 'auto',
//         textTransform: 'uppercase',
//       },
//     },
//     {
//       id: 6,
//       type: 'Text',
//       text: '#SUBTITLE_2',
//       x: 70,
//       y: 860,
//       font: { family: 'Cinzel', weight: 700, style: 'normal' },
//       scale: 1,
//       textHAlign: 'center',
//       width: 884,
//       height: 80,
//       style: {
//         margin: 'auto',
//         fontSize: '40px',
//         color: '#COLOR_2',
//         lineHeight: '0.9',
//       },
//     },
//   ],
// });

// coverTemplates.push({
//   id: 12,
//   width: 560,
//   height: 560,
//   colors: [
//     ['#F05354', '#000000', '#F6ED26', '#F6ED26'],
//     ['#FFE76A', '#FFFFFF', '#B52727', '#B52727'],
//     ['#6AFFC9', '#FFFFFF', '#2746B5', '#2746B5'],
//     ['#FF9CDD', '#FFFFFF', '#656565', '#656565'],
//   ],
//   selectedColors: 0,
//   items: [
//     {
//       id: 0,
//       type: 'Image',
//       image: '#IMAGE',
//       x: 0,
//       y: 0,
//       width: 1024,
//       height: 1024,
//       scale: 1,
//     },
//     {
//       id: 1,
//       type: 'Gradient',
//       style: {
//         background: 'linear-gradient(180deg, #COLOR_3 0%, #COLOR_4 100%)',
//         mixBlendMode: 'color',
//       },
//     },
//     {
//       id: 2,
//       type: 'Gradient',
//       style: {
//         background: 'linear-gradient(180deg, #COLOR_3 0%, #COLOR_4 100%)',
//         opacity: '0.85',
//       },
//     },
//     {
//       id: 3,
//       type: 'Gradient',
//       maskImage: 'https://cdn.autofunnel.ai/covers/frame9.png',
//       style: {
//         background: '#COLOR_1',
//       },
//     },
//     {
//       id: 4,
//       type: 'Text',
//       text: '#SUBTITLE_1',
//       x: 70,
//       y: 90,
//       font: { family: 'Inter', weight: 700, style: 'normal' },
//       textHAlign: 'center',
//       scale: 1,
//       height: 50,
//       width: 420,
//       style: {
//         margin: 'auto',
//         fontSize: '30px',
//         color: '#COLOR_2',
//         lineHeight: '1.0',
//       },
//     },
//     {
//       id: 5,
//       type: 'Text',
//       text: '#TITLE',
//       x: 70,
//       y: 150,
//       font: { family: 'Dela Gothic One', weight: 400, style: 'normal' },
//       scale: 1,
//       textHAlign: 'center',
//       width: 420,
//       height: 420,
//       style: {
//         fontSize: '80px',
//         color: '#COLOR_1',
//         fontWeight: '800',
//         lineHeight: '0.8',
//         textTransform: 'uppercase',
//       },
//     },
//     {
//       id: 6,
//       type: 'Text',
//       text: '#SUBTITLE_2',
//       x: 70,
//       y: 430,
//       font: { family: 'Inter', weight: 400, style: 'normal' },
//       scale: 1,
//       textHAlign: 'center',
//       width: 420,
//       height: 80,
//       style: {
//         margin: 'auto',
//         fontSize: '30px',
//         color: '#COLOR_2',
//         lineHeight: '1.1',
//       },
//     },
//   ],
// });

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// coverTemplates.push({
//   id: 13,
//   width: 560,
//   height: 800,
//   colors: [
//     ['#FFFFFF', '#B8E181', '#000000', '#000000'],
//     ['#000000', '#DC6B6B', '#FFFFFF', '#FFFFFF'],
//     ['#FFFFFF', '#F0FF48', '#1D3E79', '#1D3E79'],
//     ['#FFFFFF', '#FFAAAA', '#28536C', '#28536C'],
//   ],
//   selectedColors: 0,
//   items: [
//     {
//       id: 0,
//       type: 'Image',
//       image: '#IMAGE',
//       x: -256,
//       y: -128,
//       width: 1024,
//       height: 1024,
//       scale: 0.82,
//     },
//     {
//       id: 1,
//       type: 'Gradient',
//       style: {
//         background: 'linear-gradient(180deg, #COLOR_3 0%, #COLOR_4 100%)',
//         mixBlendMode: 'color',
//       },
//     },
//     {
//       id: 2,
//       type: 'Gradient',
//       style: {
//         background: 'linear-gradient(180deg, #COLOR_3 0%, #COLOR_4 100%)',
//         opacity: '0.85',
//       },
//     },
//     {
//       id: 3,
//       type: 'Gradient',
//       maskImage: 'https://cdn.autofunnel.ai/covers/frame4.png',
//       style: {
//         background: '#COLOR_2',
//       },
//     },
//     {
//       id: 4,
//       type: 'Text',
//       text: '#SUBTITLE_1',
//       x: 70,
//       y: 140,
//       font: { family: 'Inter', weight: 700, style: 'normal' },
//       textHAlign: 'center',
//       scale: 1,
//       height: 50,
//       width: 420,
//       style: {
//         margin: 'auto',
//         fontSize: '15px',
//         color: '#COLOR_2',
//         lineHeight: '1.0',
//       },
//     },
//     {
//       id: 5,
//       type: 'Text',
//       text: '#TITLE',
//       x: 70,
//       y: 200,
//       font: { family: 'Bebas Neue', weight: 400, style: 'normal' },
//       scale: 1,
//       textHAlign: 'center',
//       width: 420,
//       height: 310,
//       style: {
//         fontSize: '80px',
//         color: '#COLOR_1',
//         fontWeight: '800',
//         lineHeight: '0.8',
//         textTransform: 'uppercase',
//       },
//     },
//     {
//       id: 6,
//       type: 'Text',
//       text: '#SUBTITLE_2',
//       x: 70,
//       y: 570,
//       font: { family: 'Inter', weight: 800, style: 'normal' },
//       scale: 1,
//       textHAlign: 'center',
//       width: 420,
//       height: 80,
//       style: {
//         margin: 'auto',
//         fontSize: '13px',
//         color: '#COLOR_2',
//         lineHeight: '1.0',
//       },
//     },
//     {
//       id: 7,
//       type: 'Text',
//       text: 'Author Name',
//       x: 70,
//       y: 520,
//       scale: 1,
//       textHAlign: 'center',
//       width: 420,
//       height: 40,
//       font: { family: 'Inter', weight: 700, style: 'normal' },
//       style: {
//         fontSize: '15px',
//         color: '#COLOR_1',
//         fontWeight: '800',
//         lineHeight: '1.0',
//       },
//     },
//   ],
// });

// coverTemplates.push({
//   id: 14,
//   width: 560,
//   height: 800,
//   colors: [
//     ['#FFFFFF', '#E8D871', '#88302C', '#88302C'],
//     ['#FFFFFF', '#FAFF00', '#2E882C', '#2E882C'],
//     ['#FFFFFF', '#FF6E6E', '#003757', '#003757'],
//     ['#3B6493', '#008D7C', '#FFF4CD', '#FFF4CD'],
//   ],
//   selectedColors: 0,
//   items: [
//     {
//       id: 0,
//       type: 'Image',
//       image: '#IMAGE',
//       x: -256,
//       y: -128,
//       width: 1024,
//       height: 1024,
//       scale: 0.82,
//     },
//     {
//       id: 1,
//       type: 'Gradient',
//       style: {
//         background: 'linear-gradient(180deg, #COLOR_3 0%, #COLOR_4 100%)',
//         mixBlendMode: 'color',
//       },
//     },
//     {
//       id: 2,
//       type: 'Gradient',
//       style: {
//         background: 'linear-gradient(180deg, #COLOR_3 0%, #COLOR_4 100%)',
//         opacity: '0.85',
//       },
//     },
//     {
//       id: 3,
//       type: 'Text',
//       text: 'BOOKLE BEST SELLER',
//       x: 0,
//       y: 0,
//       font: { family: 'Inter', weight: 800, style: 'normal' },
//       textHAlign: 'center',
//       scale: 1,
//       width: 560,
//       style: {
//         background: '#COLOR_2',
//         width: '100%',
//         fontSize: '15px',
//         padding: '20px',
//         color: '#COLOR_4',
//         fontWeight: '800',
//         lineHeight: '0.8',
//       },
//     },
//     {
//       id: 4,
//       type: 'Text',
//       text: '#SUBTITLE_1',
//       x: 50,
//       y: 100,
//       font: { family: 'Inter', weight: 800, style: 'normal' },
//       textHAlign: 'center',
//       scale: 1,
//       width: 460,
//       style: {
//         width: '100%',
//         fontSize: '15px',
//         color: '#COLOR_1',
//         fontWeight: '800',
//         lineHeight: '0.8',
//       },
//     },
//     {
//       id: 5,
//       type: 'Text',
//       text: '#TITLE',
//       x: 50,
//       y: 170,
//       font: { family: 'Antonio', weight: 400, style: 'normal' },
//       scale: 1,
//       textHAlign: 'center',
//       width: 460,
//       height: 400,
//       style: {
//         margin: 'auto',
//         fontSize: '80px',
//         color: '#COLOR_1',
//         fontWeight: '800',
//         lineHeight: '1.0',
//         textTransform: 'uppercase',
//       },
//     },
//     {
//       id: 6,
//       type: 'Text',
//       text: '#SUBTITLE_2',
//       x: 50,
//       y: 600,
//       font: { family: 'Inter', weight: 400, style: 'normal' },
//       scale: 1,
//       textHAlign: 'center',
//       width: 460,
//       height: 80,
//       style: {
//         margin: 'auto',
//         fontSize: '15px',
//         color: '#COLOR_2',
//         fontWeight: '800',
//         lineHeight: '1.1',
//       },
//     },
//     {
//       id: 7,
//       type: 'Text',
//       text: 'Author Name',
//       x: 50,
//       y: 710,
//       scale: 1,
//       textHAlign: 'left',
//       width: 460,
//       height: 40,
//       font: { family: 'Inter', weight: 800, style: 'normal' },
//       style: {
//         textTransform: 'uppercase',
//         fontSize: '15px',
//         color: '#COLOR_1',
//         fontWeight: '800',
//         lineHeight: '0.8',
//       },
//     },
//   ],
// });

// coverTemplates.push({
//   id: 15,
//   width: 560,
//   height: 800,
//   colors: [
//     ['#000000', '#ffffff', '#CF5425', '#CF5425'],
//     ['#FFFFFF', '#FFF500', '#3751AF', '#3751AF'],
//     ['#FFE866', '#7CA6BB', '#24495B', '#24495B'],
//     ['#FF88CF', '#7ADBC4', '#008179', '#008179'],
//   ],
//   selectedColors: 0,
//   items: [
//     {
//       id: 0,
//       type: 'Image',
//       image: '#IMAGE',
//       x: -256,
//       y: -128,
//       width: 1024,
//       height: 1024,
//       scale: 0.82,
//     },
//     {
//       id: 1,
//       type: 'Gradient',
//       style: {
//         background: 'linear-gradient(180deg, #COLOR_3 0%, #COLOR_4 100%)',
//         mixBlendMode: 'color',
//       },
//     },
//     {
//       id: 2,
//       type: 'Gradient',
//       style: {
//         background: 'linear-gradient(180deg, #COLOR_3 0%, #COLOR_4 100%)',
//         opacity: '0.85',
//       },
//     },
//     {
//       id: 3,
//       type: 'Text',
//       text: '#SUBTITLE_1',
//       x: 0,
//       y: 30,
//       font: { family: 'Inter', weight: 800, style: 'normal' },
//       textHAlign: 'center',
//       scale: 1,
//       width: 560,
//       style: {
//         background: '#COLOR_1',
//         width: '100%',
//         fontSize: '15px',
//         padding: '10px',
//         color: '#COLOR_4',
//         fontWeight: '800',
//         lineHeight: '0.8',
//       },
//     },
//     {
//       id: 4,
//       type: 'Text',
//       text: '#TITLE',
//       x: 50,
//       y: 110,
//       font: { family: 'Montserrat', weight: 800, style: 'normal' },
//       scale: 1,
//       textHAlign: 'left',
//       width: 460,
//       height: 470,
//       style: {
//         fontSize: '70px',
//         color: '#COLOR_1',
//         fontWeight: '800',
//         lineHeight: '0.9',
//         textTransform: 'uppercase',
//       },
//     },
//     {
//       id: 5,
//       type: 'Text',
//       text: '#SUBTITLE_2',
//       x: 50,
//       y: 600,
//       font: { family: 'Inter', weight: 600, style: 'normal' },
//       scale: 1,
//       textHAlign: 'left',
//       width: 460,
//       height: 80,
//       style: {
//         margin: 'auto',
//         fontSize: '15px',
//         color: '#COLOR_2',
//         lineHeight: '1.1',
//       },
//     },
//     {
//       id: 6,
//       type: 'Text',
//       text: 'Author Name',
//       x: 50,
//       y: 710,
//       scale: 1,
//       textHAlign: 'left',
//       width: 460,
//       height: 40,
//       font: { family: 'Inter', weight: 800, style: 'normal' },
//       style: {
//         fontSize: '20px',
//         width: '100%',
//         textTransform: 'uppercase',
//         color: '#COLOR_1',
//         lineHeight: '0.8',
//       },
//     },
//   ],
// });

// coverTemplates.push({
//   id: 16,
//   width: 560,
//   height: 800,
//   colors: [
//     ['#7F2642', '#FFFFFF', '#A5DAEA', '#A5DAEA'],
//     ['#FF8978', '#FFFFFF', '#1A315F', '#1A315F'],
//     ['#37437E', '#FFFFFF', '#90D8BF', '#90D8BF'],
//     ['#000000', '#FFFFFF', '#FF4975', '#FF4975'],
//   ],
//   selectedColors: 0,
//   items: [
//     {
//       id: 0,
//       type: 'Image',
//       image: '#IMAGE',
//       x: -256,
//       y: -128,
//       width: 1024,
//       height: 1024,
//       scale: 0.82,
//     },
//     {
//       id: 1,
//       type: 'Gradient',
//       style: {
//         background: 'linear-gradient(180deg, #COLOR_3 0%, #COLOR_4 100%)',
//         mixBlendMode: 'color',
//       },
//     },
//     {
//       id: 2,
//       type: 'Gradient',
//       style: {
//         background: 'linear-gradient(180deg, #COLOR_3 0%, #COLOR_4 100%)',
//         opacity: '0.85',
//       },
//     },
//     {
//       id: 3,
//       type: 'Text',
//       text: '#SUBTITLE_1',
//       x: 0,
//       y: 0,
//       font: { family: 'Barlow', weight: 400, style: 'normal' },
//       textHAlign: 'center',
//       scale: 1,
//       width: 560,
//       style: {
//         background: '#COLOR_1',
//         width: '100%',
//         fontSize: '15px',
//         padding: '10px',
//         paddingTop: '40px',
//         color: '#COLOR_2',
//         lineHeight: '1.0',
//         textTransform: 'uppercase',
//       },
//     },
//     {
//       id: 4,
//       type: 'Text',
//       text: '#TITLE',
//       x: 50,
//       y: 110,
//       font: { family: 'Staatliches', weight: 400, style: 'normal' },
//       scale: 1,
//       textHAlign: 'center',
//       width: 460,
//       height: 470,
//       style: {
//         fontSize: '80px',
//         color: '#COLOR_1',
//         fontWeight: '800',
//         lineHeight: '0.9',
//         textTransform: 'uppercase',
//       },
//     },
//     {
//       id: 5,
//       type: 'Text',
//       text: '#SUBTITLE_2',
//       x: 50,
//       y: 600,
//       font: { family: 'Barlow', weight: 600, style: 'normal' },
//       scale: 1,
//       textHAlign: 'center',
//       width: 460,
//       height: 80,
//       style: {
//         margin: 'auto',
//         fontSize: '15px',
//         color: '#COLOR_2',
//         lineHeight: '1.1',
//       },
//     },
//     {
//       id: 6,
//       type: 'Text',
//       text: 'Author Name',
//       x: 50,
//       y: 710,
//       scale: 1,
//       textHAlign: 'left',
//       width: 460,
//       height: 40,
//       font: { family: 'Barlow', weight: 800, style: 'normal' },
//       style: {
//         fontSize: '30px',
//         textTransform: 'uppercase',
//         color: '#COLOR_1',
//         lineHeight: '0.8',
//       },
//     },
//   ],
// });

// coverTemplates.push({
//   id: 17,
//   width: 560,
//   height: 800,
//   colors: [
//     ['#FBA51A', '#D9E223', '#000000', '#011520'],
//     ['#000000', '#B70000', '#FFD463', '#FFD463'],
//     ['#FFF500', '#E089FF', '#542F79', '#542F79'],
//     ['#000000', '#725CA2', '#DAD0D7', '#DAD0D7'],
//   ],
//   selectedColors: 0,
//   items: [
//     {
//       id: 0,
//       type: 'Image',
//       image: '#IMAGE',
//       x: -256,
//       y: -128,
//       width: 1024,
//       height: 1024,
//       scale: 0.82,
//     },
//     {
//       id: 1,
//       type: 'Gradient',
//       style: {
//         background: 'linear-gradient(180deg, #COLOR_3 0%, #COLOR_4 100%)',
//         mixBlendMode: 'color',
//       },
//     },
//     {
//       id: 2,
//       type: 'Gradient',
//       style: {
//         background: 'linear-gradient(180deg, #COLOR_3 0%, #COLOR_4 100%)',
//         opacity: '0.85',
//       },
//     },
//     {
//       id: 3,
//       type: 'Gradient',
//       maskImage: 'https://cdn.autofunnel.ai/covers/frame7.png',
//       style: {
//         background: '#COLOR_2',
//       },
//     },
//     {
//       id: 4,
//       type: 'Text',
//       text: '#SUBTITLE_1',
//       x: 70,
//       y: 90,
//       font: { family: 'Barlow Condensed', weight: 400, style: 'normal' },
//       textHAlign: 'left',
//       scale: 1,
//       height: 50,
//       width: 420,
//       style: {
//         textTransform: 'uppercase',
//         margin: 'auto',
//         fontSize: '15px',
//         color: '#COLOR_2',
//         lineHeight: '1.0',
//       },
//     },
//     {
//       id: 5,
//       type: 'Text',
//       text: '#TITLE',
//       x: 70,
//       y: 150,
//       font: { family: 'Bebas Neue', weight: 400, style: 'normal' },
//       scale: 1,
//       textHAlign: 'left',
//       width: 420,
//       height: 420,
//       style: {
//         fontSize: '100px',
//         color: '#COLOR_1',
//         fontWeight: '800',
//         lineHeight: '0.8',
//         textTransform: 'uppercase',
//       },
//     },
//     {
//       id: 6,
//       type: 'Text',
//       text: '#SUBTITLE_2',
//       x: 70,
//       y: 630,
//       font: { family: 'Barlow Condensed', weight: 400, style: 'normal' },
//       scale: 1,
//       textHAlign: 'left',
//       width: 420,
//       height: 80,
//       style: {
//         textTransform: 'uppercase',
//         margin: 'auto',
//         fontSize: '13px',
//         color: '#COLOR_1',
//         lineHeight: '1.0',
//       },
//     },
//     {
//       id: 7,
//       type: 'Text',
//       text: 'Author Name',
//       x: 70,
//       y: 580,
//       scale: 1,
//       textHAlign: 'left',
//       width: 420,
//       height: 40,
//       font: { family: 'Barlow Condensed', weight: 400, style: 'normal' },
//       style: {
//         textTransform: 'uppercase',
//         fontSize: '20px',
//         color: '#COLOR_2',
//         fontWeight: '800',
//         lineHeight: '1.0',
//       },
//     },
//   ],
// });

// coverTemplates.push({
//   id: 18,
//   width: 560,
//   height: 800,
//   colors: [
//     ['#FFFFFF', '#EDEA2B', '#3DB9B9', '#3DB9B9'],
//     ['#FFFFFF', '#ED942B', '#C41324', '#C41324'],
//     ['#000000', '#FFF500', '#78C4B1', '#78C4B1'],
//     ['#26496A', '#FFFFFF', '#FF8686', '#FF8686'],
//   ],
//   selectedColors: 0,
//   items: [
//     {
//       id: 0,
//       type: 'Image',
//       image: '#IMAGE',
//       x: -256,
//       y: -128,
//       width: 1024,
//       height: 1024,
//       scale: 0.82,
//     },
//     {
//       id: 1,
//       type: 'Gradient',
//       style: {
//         background: 'linear-gradient(180deg, #COLOR_3 0%, #COLOR_4 100%)',
//         mixBlendMode: 'color',
//       },
//     },
//     {
//       id: 2,
//       type: 'Gradient',
//       style: {
//         background: 'linear-gradient(180deg, #COLOR_3 0%, #COLOR_4 100%)',
//         opacity: '0.85',
//       },
//     },
//     {
//       id: 3,
//       type: 'Gradient',
//       style: {
//         width: '100%',
//         height: '40px',
//         background: '#COLOR_2',
//       },
//     },
//     {
//       id: 4,
//       type: 'Text',
//       text: '#SUBTITLE_1',
//       x: 70,
//       y: 90,
//       font: { family: 'Barlow Condensed', weight: 400, style: 'normal' },
//       textHAlign: 'center',
//       scale: 1,
//       height: 50,
//       width: 420,
//       style: {
//         textTransform: 'uppercase',
//         margin: 'auto',
//         fontSize: '15px',
//         color: '#COLOR_2',
//         lineHeight: '1.0',
//       },
//     },
//     {
//       id: 5,
//       type: 'Text',
//       text: '#TITLE',
//       x: 70,
//       y: 150,
//       font: { family: 'Bebas Neue', weight: 400, style: 'normal' },
//       scale: 1,
//       textHAlign: 'center',
//       width: 420,
//       height: 420,
//       style: {
//         fontSize: '100px',
//         color: '#COLOR_1',
//         fontWeight: '800',
//         lineHeight: '0.8',
//         textTransform: 'uppercase',
//       },
//     },
//     {
//       id: 6,
//       type: 'Text',
//       text: '#SUBTITLE_2',
//       x: 70,
//       y: 630,
//       font: { family: 'Barlow Condensed', weight: 400, style: 'normal' },
//       scale: 1,
//       textHAlign: 'center',
//       width: 420,
//       height: 80,
//       style: {
//         textTransform: 'uppercase',
//         margin: 'auto',
//         fontSize: '13px',
//         color: '#COLOR_1',
//         lineHeight: '1.0',
//       },
//     },
//     {
//       id: 7,
//       type: 'Text',
//       text: 'Author Name',
//       x: 70,
//       y: 580,
//       scale: 1,
//       textHAlign: 'center',
//       width: 420,
//       height: 40,
//       font: { family: 'Barlow Condensed', weight: 400, style: 'normal' },
//       style: {
//         textTransform: 'uppercase',
//         fontSize: '20px',
//         color: '#COLOR_2',
//         fontWeight: '800',
//         lineHeight: '1.0',
//       },
//     },
//   ],
// });

// coverTemplates.push({
//   id: 19,
//   width: 560,
//   height: 800,
//   colors: [
//     ['#FF1A1B', '#FFFFFF', '#000000', '#000000'],
//     ['#FFFFFF', '#EAD200', '#AE0101', '#AE0101'],
//     ['#FFFFFF', '#00FF85', '#454545', '#454545'],
//     ['#FFDF3B', '#F9FFD1', '#314A61', '#314A61'],
//   ],
//   selectedColors: 0,
//   items: [
//     {
//       id: 0,
//       type: 'Image',
//       image: '#IMAGE',
//       x: -256,
//       y: -128,
//       width: 1024,
//       height: 1024,
//       scale: 0.82,
//     },
//     {
//       id: 1,
//       type: 'Gradient',
//       style: {
//         background: 'linear-gradient(180deg, #COLOR_3 0%, #COLOR_4 100%)',
//         mixBlendMode: 'color',
//       },
//     },
//     {
//       id: 2,
//       type: 'Gradient',
//       style: {
//         background: 'linear-gradient(180deg, #COLOR_3 0%, #COLOR_4 100%)',
//         opacity: '0.85',
//       },
//     },
//     {
//       id: 3,
//       type: 'Gradient',
//       maskImage: 'https://cdn.autofunnel.ai/covers/frame5.png',
//       style: {
//         background: '#COLOR_2',
//       },
//     },
//     {
//       id: 4,
//       type: 'Text',
//       text: '#SUBTITLE_1',
//       x: 70,
//       y: 90,
//       font: { family: 'Inconsolata', weight: 400, style: 'normal' },
//       textHAlign: 'center',
//       scale: 1,
//       height: 50,
//       width: 420,
//       style: {
//         textTransform: 'uppercase',
//         margin: 'auto',
//         fontSize: '15px',
//         color: '#COLOR_2',
//         lineHeight: '1.0',
//       },
//     },
//     {
//       id: 5,
//       type: 'Text',
//       text: '#TITLE',
//       x: 70,
//       y: 150,
//       font: { family: 'Staatliches', weight: 400, style: 'normal' },
//       scale: 1,
//       textHAlign: 'center',
//       width: 420,
//       height: 420,
//       style: {
//         fontSize: '100px',
//         color: '#COLOR_1',
//         fontWeight: '800',
//         lineHeight: '0.8',
//         textTransform: 'uppercase',
//       },
//     },
//     {
//       id: 6,
//       type: 'Text',
//       text: '#SUBTITLE_2',
//       x: 70,
//       y: 630,
//       font: { family: 'Inconsolata', weight: 400, style: 'normal' },
//       scale: 1,
//       textHAlign: 'center',
//       width: 420,
//       height: 80,
//       style: {
//         textTransform: 'uppercase',
//         margin: 'auto',
//         fontSize: '13px',
//         color: '#COLOR_2',
//         lineHeight: '1.0',
//       },
//     },
//     {
//       id: 7,
//       type: 'Text',
//       text: 'Author Name',
//       x: 70,
//       y: 580,
//       scale: 1,
//       textHAlign: 'center',
//       width: 420,
//       height: 40,
//       font: { family: 'Inconsolata', weight: 400, style: 'normal' },
//       style: {
//         textTransform: 'uppercase',
//         fontSize: '20px',
//         color: '#COLOR_1',
//         fontWeight: '800',
//         lineHeight: '1.0',
//       },
//     },
//   ],
// });

export const getTemplate = () => {
  // const out = [coverTemplates[0]];
  // return out;

  for (let i = coverTemplates.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [coverTemplates[i], coverTemplates[j]] = [
      coverTemplates[j],
      coverTemplates[i],
    ];
  }

  return coverTemplates.slice(0, 4);
};

export const getTemplateById = (templateId) => {
  for (const template of coverTemplates) {
    if (String(template.id) == templateId) {
      return template;
    }
  }
  return null;
};

export const getImageCoverTemplate = () => {
  return {
    id: 0,
    width: 560,
    height: 560,
    colors: [['#fff', '#fff', '#fff', '#fff']],
    selectedColors: 0,
    items: [
      {
        type: 'Image',
        image: '#IMAGE',
        x: 0,
        y: 0,
        width: 1024,
        height: 1024,
        scale: 1,
      },
    ],
  };
};
