import { useEffect } from 'react';
import { getDomain } from 'utils/helpers';

import s from './Popups.module.scss';

interface IProps {
  open: boolean;
  onClose: () => void;
}

const UpdatePaymentMethodPopup = (props: IProps) => {
  const handleClose = () => {
    props.onClose();
  };
  useEffect(() => {
    window.addEventListener('message', (event) => {
      const data = event.data;
      if (data.status && data.status == 'close') {
        handleClose();
      }
    });
  }, []);

  const { domain } = getDomain();
  const iframeSrc = `//${domain}/console/frame/update-payment-method`;

  return props.open ? (
    // <Modal displayModal={props.open} width={600} height={600} closeModal={() => handleClose()}>
    <div className={s.frameModalPayment} onClick={() => handleClose()}>
      <iframe
        src={iframeSrc}
        width="100%"
        height="100%"
        className={s.frame}
      ></iframe>
    </div>
  ) : // </Modal>
  null;
};

export default UpdatePaymentMethodPopup;
