import Modal from 'Components/Common/Modal2/Modal';
import AlertIcon from 'Assets/icons/alert.svg';
import Button from 'Styled/Button2';

import s from './DeleteTopicModal.module.scss';

interface IProps {
  open: boolean;
  onDelete: () => void;
  onClose: () => void;
}

const DeleteTopicModal = (props: IProps) => {
  return (
    <Modal displayModal={props.open} width={600} closeModal={props.onClose}>
      <div className={s.modalBody}>
        <img src={AlertIcon} className={s.alertIcon} alt="alert" />
        <h2 className={s.popupHeader}>Delete Copy</h2>
        <p className={s.popupText}>
          Are you sure you want to delete your generated copy? <br />
          It will not be available to you and all data will be lost.
        </p>
        <div className={s.buttonsBlock}>
          <Button onClick={props.onDelete} width={200} height={50}>
            Delete
          </Button>
          <Button onClick={props.onClose} width={200} height={50}>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteTopicModal;
