import { PayloadAction } from '@reduxjs/toolkit';
import {
  REMOVE_SMART_LIST_POPUP,
  SELECTED_SMART_LIST,
} from './contactsActions';

export interface IContactsState {
  selectedSmartList?: string;
  openDeleteSmartListPopup: boolean;
}

const initialState: IContactsState = {
  selectedSmartList: '',
  openDeleteSmartListPopup: false,
};

const contactsReducer = (
  state = initialState,
  action: PayloadAction<boolean | string>
): IContactsState => {
  switch (action.type) {
    case REMOVE_SMART_LIST_POPUP:
      if (typeof action.payload === 'boolean') {
        return {
          ...state,
          openDeleteSmartListPopup: action.payload,
        };
      }
      return state;
    case SELECTED_SMART_LIST:
      if (typeof action.payload === 'string') {
        return {
          ...state,
          selectedSmartList: action.payload,
        };
      }
      return state;
    default:
      return state;
  }
};

export { initialState, contactsReducer };
