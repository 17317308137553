export const ADD_SUB_ACCOUNT = {
  operationName: 'addSubAccount',
  query: `mutation addSubAccount(
      $name: String!
      $email: String!
      $notes: String
    ) {
      addSubAccount(
        name: $name
        email: $email
        notes: $notes
      ) {
        _id
        name
        owner
        email
        notes
        thumbnail
        workspace
      }
    }`,
};
