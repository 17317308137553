import React from 'react';
import s from './IntegrationsTab.module.scss';
import PrimaryButton from '../../../Common/PrimaryButton/PrimaryButton';
import Textarea from '../../../Common/Textarea/Textarea';

interface IProps {
  headerScript: string;
  footerScript: string;
  setHeaderScript: (text: string) => void;
  setFooterScript: (text: string) => void;
  handleSave: () => void;
  onClose: () => void;
}

const IntegrationsTab = (props: IProps) => {
  const handleSaveAndClose = () => {
    props.handleSave();
    props.onClose();
  };

  return (
    <div className={s.container}>
      <div className={s.textArea}>
        <Textarea
          width={760}
          label={'Header Block'}
          placeholder={'Add HTML Code for header'}
          value={props.headerScript}
          setState={props.setHeaderScript}
          onBlur={() => props.handleSave()}
        />
      </div>
      <div className={s.textArea}>
        <Textarea
          width={760}
          label={'Footer Block'}
          placeholder={'Add HTML Code for footer'}
          value={props.footerScript}
          setState={props.setFooterScript}
          onBlur={() => props.handleSave()}
        />
      </div>
      <div className={s.buttonBlock}>
        <PrimaryButton onClick={handleSaveAndClose}>Save</PrimaryButton>
      </div>
    </div>
  );
};

export default IntegrationsTab;
