export const CREATE_GENERATION_TEMPLATE_MUTATION = {
  operationName: 'createGenerationTemplate',
  query: `mutation createGenerationTemplate(
          <KEYS>
          #$name: String!
          #$actions: String!
          #$layout: String!
        ) {
          createGenerationTemplate(
          <VALUES>
           # name: $name
           # actions: $actions
           # layout: $layout
          ) {
            _id
            createdAt
            updatedAt
          }
        }`,
};
