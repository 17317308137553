import React from 'react';
import s from './Textarea.module.scss';

interface IProps {
  label: string;
  placeholder: string;
  width: number;
  value: string;
  setState: (newValue: string) => void;
  onBlur?: () => void;
}

function Textarea(props: IProps) {
  const handleOnBlur = (e: React.FocusEvent<HTMLTextAreaElement, Element>) => {
    props.setState(e.currentTarget.value);
    if (props.onBlur) {
      props.onBlur();
    }
  };

  return (
    <div className={s.container}>
      <p className={s.label}>{props.label}</p>
      <textarea
        spellCheck="false"
        placeholder={props.placeholder}
        className={s.textarea}
        style={{ width: `${props.width}px` }}
        value={props.value && props.value}
        onChange={(e) => props.setState(e.currentTarget.value)}
        onKeyPress={(e) => {
          e.key === 'Enter' && props.setState(e.currentTarget.value);
        }}
        onBlur={handleOnBlur}
      />
    </div>
  );
}

export default Textarea;
