export const GET_AI_REQUEST_BY_TOPIC = {
  operationName: 'getAiTextRequestByTopic',
  query: `query getAiTextRequestByTopic(
      $topicId: String!
    ) {
      getAiTextRequestByTopic(
        topicId: $topicId
      ) {
        _id
        request
        requestType
        response
        hasLiked
        createdAt
        updatedAt
    }
  }`,
};
