import React, { useState } from 'react';
import s from './InputPassword.module.scss';
import { useField } from 'formik';
import eye from '../../../../Assets/icons/eye.svg';
import eyeHover from '../../../../Assets/icons/eyeHover.svg';

interface IProps {
  name: string;
  placeholder: string;
  width: number;
  showError?: boolean;
}

function InputPassword(props: IProps) {
  const [icon, setIcon] = useState<string>(eye);
  const [field, meta] = useField({ ...props });
  const [passwordShown, setPasswordShown] = useState(false);

  return (
    <div className={s.container}>
      <input
        autoComplete="off"
        {...field}
        name={props.name}
        type={passwordShown ? 'text' : 'password'}
        placeholder={props.placeholder}
        className={s.input}
        style={{ width: `${props.width}px` }}
      />
      {meta.touched && meta.error && props.showError ? (
        <div className={s.error}>{meta.error}</div>
      ) : null}
      <img
        onMouseEnter={() => !passwordShown && setIcon(eyeHover)}
        onMouseLeave={() => !passwordShown && setIcon(eye)}
        className={s.eyeIcon}
        src={icon}
        onClick={() => setPasswordShown(!passwordShown)}
      />
    </div>
  );
}

export default InputPassword;
