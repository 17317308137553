export const CREATE_PAGE_FROM_AI_MUTATION = {
  operationName: 'createPageFromAi',
  query: `mutation createPageFromAi(
        <KEYS>
      ) {
        createPageFromAi(
          <VALUES>
        ) {
          _id
        }
      }`,
};
