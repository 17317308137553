import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { DispatchType, RootState } from 'store/rootReducer';
import { fetchDomains } from 'store/domains/domainsActions';
import { fetchFunnelsWithPaginationAction } from 'store/projects/projectsActions';
import { IDomain, IGeneralProject } from 'types';
import { toLocalISOString } from 'utils/helpers';
import AnalyticsService from 'services/analytics.service';
import DashboardHeader from 'Components/DashboardHeader/DashboardHeader';
import Button from 'Components/Common/DomainButton/DomainButton';
import DatePicker from 'Components/Common/DatePicker/DatePicker';
import Loader from 'UILib/Loader/Loader';
import Pagination from 'UILib/Pagination/Pagination';
import FunnelItem from '../FunnelItem/FunnelItem';

import styles from './FunnelsDashboard.module.scss';

interface IFunnelsDahsboardProps {
  fetchDomains: () => void;
  domains: IDomain[];
  fetchFunnelsWithPaginationAction: (
    skip?: number,
    limit?: number,
    search?: string,
    sortBy?: string,
    sortAsc?: boolean
  ) => void;
  funnels: {
    total: number;
    funnels: IGeneralProject[];
  };
}

const sortOptions = [
  {
    label: 'Last Update',
    value: 'updatedAt',
    sortAsc: false,
  },
  {
    label: 'Funnel Name',
    value: 'name',
    sortAsc: true,
  },
];

const LIMIT = 20;

const formatDate = (date: string) => {
  if (date) {
    const [datePart, timeZonePart] = date.split('.');

    return datePart + timeZonePart.slice(3);
  } else {
    return '';
  }
};

const FunnelsDahsboard = (props: IFunnelsDahsboardProps) => {
  const [funnels, setFunnels] = useState<IGeneralProject[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [sortBy, setSortBy] = useState<string>('updatedAt');
  const [searchedText, setSearchedText] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [startPeriod, setStartPeriod] = useState<string>('');
  const [endPeriod, setEndPeriod] = useState<string>('');
  const [statistics, setStatistics] = useState<any>();
  const history = useHistory();

  const getFunnels = async () => {
    try {
      if (!searchedText && !funnels.length) {
        setLoading(true);
      }

      props.fetchFunnelsWithPaginationAction(
        (currentPage - 1) * LIMIT,
        LIMIT,
        searchedText,
        sortBy,
        sortOptions.find((item) => item.value === sortBy)?.sortAsc
      );
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setFunnels(props.funnels.funnels ?? []);
    setTotal(props.funnels.total ?? 0);
  }, [props.funnels]);

  useEffect(() => {
    props.fetchDomains();
  }, []);

  useEffect(() => {
    getFunnels();
  }, [searchedText, sortBy, currentPage]);

  const handleCreateNewFunnel = () => {
    history.push('/console/new-funnel');
  };

  const getStats = async () => {
    try {
      if (!funnels?.length || !startPeriod || !endPeriod) return;

      const analyticsService = new AnalyticsService();
      const stats: any = await analyticsService.getFunnelsTotalStats({
        funnel_ids: funnels.map((item) => item._id),
        time_ranges: [
          {
            from_time: startPeriod,
            to_time: endPeriod,
          },
        ],
      });
      setStatistics(stats);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getStats();
  }, [funnels, endPeriod, startPeriod]);

  const handleDateChange = (start: any, end: any) => {
    setStartPeriod(toLocalISOString(start));
    setEndPeriod(toLocalISOString(end));
  };

  const handleSearch = async (text: string) => {
    setCurrentPage(1);
    setSearchedText(text);
  };

  return (
    <div className={styles.container}>
      <div
        style={{
          display:
            loading || (!funnels.length && !searchedText) ? 'none' : 'block',
        }}
      >
        <DashboardHeader
          handleAddNewItem={handleCreateNewFunnel}
          buttonText="New Funnel"
          sortOptions={sortOptions}
          title="Your Funnels"
          total={`${total} Funnels`}
          handleSearch={handleSearch}
          handleSort={(value: string) => setSortBy(value)}
          sortBy={sortBy}
          additionalSort={
            <div className={styles.statsSort}>
              <div className={styles.statsTitle}>Stats:</div>
              <div className={styles.datePicker}>
                <DatePicker
                  startDate={null}
                  endDate={null}
                  onChange={(start, end) => handleDateChange(start, end)}
                  isSmall={true}
                  startRange={'All Time'}
                  dropdownClassName={styles.dropdown}
                  className={styles.dropDownContainer}
                />
              </div>
            </div>
          }
        />
      </div>
      {loading ? (
        <div className={styles.loader}>
          <Loader />
        </div>
      ) : !funnels.length && !searchedText ? (
        <div className={styles.emptyProjectContainer}>
          <div className={styles.emptyProjectText}>
            It looks like you haven't created any funnels yet.
          </div>
          <Button onClick={handleCreateNewFunnel}>Create New Funnel</Button>
        </div>
      ) : (
        <>
          <div className={styles.itemsList}>
            {funnels?.map((item: IGeneralProject) => (
              <FunnelItem
                item={item}
                key={item._id}
                stats={
                  statistics?.funnels_statistic?.[item._id]?.[
                    formatDate(startPeriod)
                  ]?.total_stats
                }
              />
            ))}
          </div>
          <Pagination
            currentPage={currentPage}
            totalPages={Math.ceil(total / LIMIT)}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  domains: state.domains.items,
  funnels: state.projects.funnels,
});

const mapDispatchToProps = (dispatch: DispatchType) => ({
  fetchDomains: () => dispatch(fetchDomains()),
  fetchFunnelsWithPaginationAction: (
    skip?: number,
    limit?: number,
    search?: string,
    sortBy?: string,
    sortAsc?: boolean
  ) =>
    dispatch(
      fetchFunnelsWithPaginationAction(skip, limit, search, sortBy, sortAsc)
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(FunnelsDahsboard);
