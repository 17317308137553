import { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useDrop } from 'react-dnd';
import {
  updateBookleTemplateBlocks,
  updateBookleTemplateHoveredBlock,
} from 'store/books/booksActions';
import { RootState } from 'store/rootReducer';
import { findItemById, generateNewIndex } from '../utils';
import { BookleTemplateBlock } from 'types';
import DraggableContent from '../DraggableContent/DraggableContent';
import DropPlace from '../DropPlace/DropPlace';

import styles from './SectionBlock.module.scss';

interface IProps {
  item: BookleTemplateBlock;
  sectionsCount: number;
  updateHoveredItem: (payload: BookleTemplateBlock | undefined) => void;
  templateBlocks: BookleTemplateBlock[];
  updateBlocks: (payload: BookleTemplateBlock[]) => void;
  style?: React.CSSProperties;
}

const SectionBlock = ({
  item,
  sectionsCount,
  updateBlocks,
  templateBlocks,
  updateHoveredItem,
  style,
}: IProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  const addItemToContainer = (e: any) => {
    const newArray = [...templateBlocks];

    const parent = findItemById(newArray, item.id);
    let newItem: any;
    if (!!e.id) {
      newItem = e;
      findItemById(newArray, e.id, true);
    } else {
      newItem = {
        id: generateNewIndex(),
        type: e?.type,
      };

      if (newItem?.type?.includes('section')) {
        newItem = {
          ...newItem,
          items: Array.from({
            length: Number(e?.type.split('_')[1]),
          }).map((_) => ({ id: generateNewIndex(), items: [] })),
        };
      }
    }
    parent?.items.push(newItem);
    updateBlocks(newArray);
  };

  const combinedRef = (node: any) => {
    containerRef.current = node;
    drop(node);
  };

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: 'MENU_ITEM',
      drop: (e, monitor) => {
        if (monitor.isOver({ shallow: true })) {
          addItemToContainer(e);
        }
      },
      hover: (_, monitor) => {
        if (monitor.isOver({ shallow: true })) {
          updateHoveredItem(item);
        }
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver({ shallow: true }),
      }),
    }),
    [item, templateBlocks]
  );

  useEffect(() => {
    if (isOver) {
      updateHoveredItem(item);
    } else {
      updateHoveredItem(undefined);
    }
  }, [isOver, item, updateHoveredItem]);

  return (
    <div
      className={styles.section}
      key={item?.id}
      ref={combinedRef}
      style={{ maxWidth: `${100 / sectionsCount}%`, ...style }}
    >
      {item?.items?.map((nestedItem: any, nestedIndex: any) => {
        return (
          <div className={styles.setcion_item} key={nestedItem.id}>
            <div className={styles.section_item_contnent}>
              <div />
              <DraggableContent item={nestedItem} parentId={item.id} />
            </div>
          </div>
        );
      })}
      {isOver && <DropPlace />}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  templateBlocks: state.books.bookleTemplateBlocks,
});

const mapDispatchToProps = {
  updateBlocks: (payload: BookleTemplateBlock[]) =>
    updateBookleTemplateBlocks(payload),
  updateHoveredItem: (payload: BookleTemplateBlock | undefined) =>
    updateBookleTemplateHoveredBlock(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(SectionBlock);
