import React, { useRef, useState, useEffect } from 'react';
import s from './CopyToAnotherFunnelPopup.module.scss';
import ReactDom from 'react-dom';
import closeSvg from '../../../../Assets/icons/close.svg';
import worldSvg from '../../../../Assets/icons/worldBlack.svg';
import { ReactComponent as SelectFunnelArrowSvg } from '../../../../Assets/icons/domainSelectFunnelArrow.svg';
import { ReactComponent as CheckMarkSvg } from '../../../../Assets/icons/checkMark.svg';

import { connect } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { IGeneralProject, IGeneralProjectName } from 'types';
import DropdownBox from 'Components/Common/dropdownBox/DropdownBox';
import Button from 'Components/Common/DomainButton/DomainButton';

interface IProps {
  onClose: () => void;
  open: boolean;
  projects: IGeneralProjectName[];
  activeProject: any;
  onDuplicateToAnotherFunnel: (e: any, funnelId: string) => void;
}
interface IOption {
  value: string;
  label: string;
}
const portal = document.getElementById('portal');

function CopyToAnotherFunnelPopup(props: IProps) {
  const [isSelectFunnelOpen, setIsSelectFunnelOpen] = useState(false);

  const [options, setOptions] = useState<IOption[]>([]);
  const [selectedOption, setSelectedOption] = useState<IOption | undefined>(
    undefined
  );
  const selectFunnelBlock = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (props.activeProject) {
      const filteredFunnel = props.projects.filter(
        (funnel) => funnel._id !== props.activeProject._id
      );
      const newOptions = filteredFunnel.map((funnel) => ({
        value: funnel._id,
        label: funnel.name,
      }));
      setOptions(newOptions);
    }
  }, [props.projects, props.activeProject]);

  const handleSelectFunnel = (value: IOption) => {
    setSelectedOption(value);
  };

  const handleCopyClick = (e: any) => {
    if (selectedOption?.value) {
      props.onDuplicateToAnotherFunnel(e, selectedOption?.value);
    }
  };

  if (!props.open || !portal) {
    return null;
  }
  return ReactDom.createPortal(
    <>
      <div
        className={s.modalWrapper}
        onClick={(e: any) => {
          if (e.target.className === s.modalWrapper) {
            props.onClose();
          }
        }}
      >
        <div className={s.modal}>
          <img
            src={closeSvg}
            alt="close icon"
            className={s.closeIcon}
            onClick={() => props.onClose()}
          />

          <img src={worldSvg} alt="world icon" className={s.worldIcon} />
          <div className={s.headerText}>Copy to Another Funnel</div>
          <div className={s.subHeaderText}>
            Choose the tunnel you want to copy this page
          </div>
          <div
            className={s.selectFunnelBlock}
            ref={selectFunnelBlock}
            onClick={() => {
              setIsSelectFunnelOpen(!isSelectFunnelOpen);
            }}
          >
            {selectedOption?.label ? selectedOption.label : 'Select funnel'}
            <SelectFunnelArrowSvg
              className={
                isSelectFunnelOpen
                  ? s.selectFunnelArrowSvgRotate
                  : s.selectFunnelArrowSvg
              }
            />
          </div>
          <div className={s.buttonsBlock}>
            <Button
              name="Cancel"
              onClick={() => {
                setIsSelectFunnelOpen(false);
                props.onClose();
              }}
              width={180}
              borderRadius={25}
            />
            <Button
              name="Copy"
              onClick={(e: any) => {
                handleCopyClick(e);
              }}
              borderRadius={25}
              width={180}
              isBlackBtn={true}
            />
          </div>
          <DropdownBox
            isOpen={isSelectFunnelOpen}
            onClose={() => {
              setIsSelectFunnelOpen(false);
            }}
            pointerEvents={true}
            componentRef={selectFunnelBlock}
          >
            <div className={s.selectFunnelOptionsBlock}>
              {options.map((option: IOption) => (
                <div
                  className={s.selectFunnelOptionItem}
                  onClick={() => {
                    handleSelectFunnel(option);
                    setIsSelectFunnelOpen(false);
                  }}
                  key={option.label}
                >
                  {option.label === selectedOption?.label && <CheckMarkSvg />}
                  {option.label}
                </div>
              ))}
            </div>
          </DropdownBox>
        </div>
      </div>
    </>,
    portal
  );
}

const mapStateToProps = (state: RootState) => ({
  projects: state.projects.projectsName,
  activeProject: state.projects.active,
});
const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CopyToAnotherFunnelPopup);
