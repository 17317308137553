export const dateRangeArr = [
  'Today',
  'Yesterday',
  'Last 7 Days',
  'Last Month',
  'Last 3 Months',
  'All Time',
  'Custom',
] as const;

export type DateType = typeof dateRangeArr[number];

export const calculateDateRange = (dateRange: DateType) => {
  const today = new Date();
  let startDate, endDate;

  switch (dateRange) {
    case 'Today':
      startDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        0,
        0,
        0
      );
      endDate = today;
      break;
    case 'Yesterday':
      startDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - 1,
        0,
        0,
        0
      );
      endDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - 1,
        23,
        59,
        59
      );
      break;
    case 'Last 7 Days':
      startDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - 7
      );
      endDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - 1,
        23,
        59,
        59
      );
      break;
    case 'Last Month':
      startDate = new Date(today.getFullYear(), today.getMonth(), 1);
      endDate = new Date(
        today.getFullYear(),
        today.getMonth() + 1,
        0,
        23,
        59,
        59
      );
      break;
    case 'Last 3 Months':
      startDate = new Date(today.getFullYear(), today.getMonth() - 2, 1);
      endDate = new Date(
        today.getFullYear(),
        today.getMonth() + 1,
        0,
        23,
        59,
        59
      );
      break;
    case 'All Time':
      startDate = new Date('2023-01-01');
      endDate = today;
      break;
    default:
      startDate = null;
      endDate = null;
      break;
  }

  return { startDate, endDate };
};
