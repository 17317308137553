export const GET_ONE_BROADCAST = {
  operationName: 'getOneBroadcast',
  query: `query getOneBroadcast ($id: String!){
            getOneBroadcast(id: $id) {
              _id
              name
              mailDraft { _id }
              image
              sendAt
              status
              createdAt
              updatedAt
            }
          }`,
};
