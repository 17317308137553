import { IBook } from 'types';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { graphQlCall } from '../../graphql/utils';
import { RootState } from '../rootReducer';
import QUERIES from '../../graphql/queries';

export const BOOKS_FETCHED = 'books_fetched';

type DispatchType = ThunkDispatch<RootState, void, Action>;

export const booksFetched = (payload: IBook) => ({
  type: BOOKS_FETCHED,
  payload,
});

export const fetchFunnelBuilderBooks = () => async (dispatch: DispatchType) => {
  const values = { skip: 0, limit: 50 };

  const response = await graphQlCall({
    queryTemplateObject: QUERIES.GET_BOOKS_FOR_FUNNEL_BUILDER,
    headerType: 'USER-AUTH',
    values,
  });

  const { books } = response;

  dispatch(booksFetched(books as IBook));
};
