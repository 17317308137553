import React, { useEffect } from 'react';

type eventHandlerType = (event: MouseEvent | TouchEvent) => void;

export const UseOnClickOutside = (
  ref: React.RefObject<HTMLElement>,
  handler: eventHandlerType
) => {
  useEffect(() => {
    const mouseListener = (event: MouseEvent): void => {
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return;
      }

      handler(event);
    };

    const touchListener = (event: TouchEvent): void => {
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', mouseListener);
    document.addEventListener('touchstart', touchListener);

    return () => {
      document.removeEventListener('mousedown', mouseListener);
      document.removeEventListener('touchstart', touchListener);
    };
  }, [ref, handler]);
};
