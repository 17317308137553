import React from 'react';
import s from './Spinner.module.scss';
import spinner from '../../../Assets/images/spinner2.svg';
import spinnerLogo from '../../../Assets/images/spinnerLogo.svg';

interface IProps {
  size: number;
}

function Spinner({ size }: IProps) {
  return (
    <div
      className={s.container}
      style={{ height: `${size}px`, width: `${size}px` }}
    >
      <img className={s.spinner} src={spinner} alt="" />
      <img className={s.logo} src={spinnerLogo} alt="" />
    </div>
  );
}

export default Spinner;
