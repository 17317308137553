import { ReactComponent as Arrow } from 'Assets/icons/arrow.svg';
import clsx from 'clsx';
import Input from 'UILib/Input/Input';

import styles from './ContainerStyleBlock.module.scss';

const ControlInput = ({
  type,
  value,
  handleChange,
  disabled,
}: {
  type: 'vertical' | 'horizontal';
  value: string | number;
  handleChange: (value: string) => void;
  disabled?: boolean;
}) => {
  const isVertical = type === 'vertical';

  const handleIncrease = () => {
    Number(value) < 100 &&
      !disabled &&
      handleChange((Number(value) + 1).toString());
  };

  const handleDecrease = () => {
    Number(value) > 0 &&
      !disabled &&
      handleChange((Number(value) - 1).toString());
  };

  return (
    <div
      className={clsx(
        styles.marginInput,
        isVertical ? styles.vertical : styles.horizontal
      )}
    >
      <Arrow
        className={clsx(
          styles.arrow,
          isVertical ? styles.arrowTop : styles.arrowLeft,
          { [styles.disabledIcon]: disabled }
        )}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          isVertical ? handleIncrease() : handleDecrease();
        }}
      />
      <Input
        type="number"
        min={0}
        value={value}
        onChange={(e) => handleChange(e.target.value)}
        hideArrows
        className={styles.customInput}
        disabled={disabled}
      />
      <Arrow
        className={clsx(
          styles.arrow,
          isVertical ? styles.arrowBottom : styles.arrowRight,
          { [styles.disabledIcon]: disabled }
        )}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          isVertical ? handleDecrease() : handleIncrease();
        }}
      />
    </div>
  );
};

export default ControlInput;
