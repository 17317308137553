export const GET_USER_CONTACTS = {
  operationName: 'getUserContacts',
  query: `
    query getUserContacts {
      getUserContacts {
        _id
        user {
          _id
        }
        funnel {
          _id
        }
        page {
          _id
          title
        }
        email
        phone
        tags {
          _id
          name
        }
        data
        createdAt
        updatedAt
      }
    }
  `,
};
