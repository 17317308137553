import React from 'react';

import s from './SecondaryButton.module.scss';

interface IProps {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  width?: number;
  height?: number;
  fontSize?: number;
  prefixImage?: React.ReactElement;
  postfixImage?: React.ReactElement;
  children?: React.ReactNode;
}

function SecondaryButton(props: IProps) {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (props.onClick) {
      props.onClick(e);
    }
  };

  return (
    <div className={s.container}>
      <button
        style={{
          width: `${props.width}px`,
          height: `${props.height}px`,
          fontSize: `${props.fontSize}px`,
        }}
        className={s.button}
        onClick={handleClick}
      >
        {props.prefixImage}
        {props.children}
        {props.postfixImage}
      </button>
    </div>
  );
}

export default SecondaryButton;
