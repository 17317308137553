export const UPDATE_SAAS_PLAN_PRICE = {
  operationName: 'updateSaasPlanPrice',
  query: `mutation updateSaasPlanPrice ($planIndex: Float!,$priceIndex: Float!, $name: String!, $subHeader: String!, $description: String!, $apps:String!) {
                  updateSaasPlanPrice (planIndex: $planIndex, priceIndex: $priceIndex, name: $name, subHeader: $subHeader, description: $description, apps: $apps) {
                      _id
                      products {
                          name
                          stripeId
                          prices {
                              name
                              subHeader
                              description
                              price
                              paymentPeriod
                              currency
                              stripeId
                              apps {
                                  key
                                  enabled
                                  name
                                  items {
                                      name
                                      limit
                                      option
                                      unlimited
                                  }
                              }
                          }
  }}
                }`,
};
