import React from 'react';
import s from './DomainButton.module.scss';

interface IProps {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  width?: number;
  height?: number;
  fontSize?: number;
  image?: string;
  name?: string;
  children?: any;
  isBlackBtn?: boolean;
  borderRadius?: number;
  type?: 'button' | 'submit' | 'reset' | undefined;
}

function DomainButton(props: IProps) {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (props.onClick) {
      props.onClick(e);
    }
  };

  return (
    <div className={s.container}>
      <button
        style={{
          width: `${props.width}px`,
          height: `${props.height}px`,
          fontSize: `${props.fontSize}px`,
          borderRadius: `${props.borderRadius}px`,
        }}
        type={props.type ? props.type : undefined}
        className={props.isBlackBtn ? s.buttonBlack : s.button}
        onClick={handleClick}
      >
        {props.image && <img src={props.image} />}
        {props.name || props.children}
      </button>
    </div>
  );
}

export default DomainButton;
