export const GET_ONE_AI_PAGE = {
  operationName: 'getOneAiPage',
  query: `
    query getOneAiPage (
            $pageId: String!
        ) {
        getOneAiPage(
            pageId: $pageId
        ){
            _id
            questions {
                id
                label
                example
            }
            prompts {
                id
                type
                prompt
            }
            createdAt
            updatedAt
            pageId
            name
            image
        }
    }`,
};
