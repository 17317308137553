import { useEffect, useState } from 'react';
import { ITemplateCategory } from 'types';
import Button from 'UILib/Button/Button';
import Popup from 'UILib/Popup/Popup';
import Input from 'UILib/Input/Input';

import styles from './AddGroupPopup.module.scss';

interface IProps {
  onClose?: () => void;
  onAddGroup: (groupName: string, categoryId?: string) => void;
  category?: ITemplateCategory;
}

const AddGroupPopup = ({ onAddGroup, onClose, category }: IProps) => {
  const [newGroupName, setNewGroupName] = useState('');

  useEffect(() => {
    if (category?.name) {
      setNewGroupName(category.name);
    }
  }, [category]);

  return (
    <Popup
      onClose={() => {
        setNewGroupName('');
        if (onClose) onClose();
      }}
      wrapperClassName={styles.addGroupPopup}
    >
      <div className={styles.popupTitle}>
        {!category?._id ? 'New' : 'Rename'} Group
      </div>
      <div className={styles.popupSubtitle}>
        {!category?._id ? 'Create a new' : 'Rename the'} group and organize your
        templates.
      </div>
      <div className={styles.form}>
        <div className={styles.label}>
          Group Name <sup style={{ color: 'red' }}>*</sup>
        </div>
        <Input
          border="stroke"
          placeholder="Group Name"
          value={newGroupName}
          onChange={(e) => setNewGroupName(e.target.value)}
        />
      </div>
      <Button
        appearance="highlighted"
        width={180}
        height={50}
        onClick={() => onAddGroup(newGroupName, category?._id)}
        disabled={!newGroupName}
      >
        Save
      </Button>
    </Popup>
  );
};

export default AddGroupPopup;
