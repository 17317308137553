import React from 'react';
import { Redirect } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import styled from 'styled-components';
import { Steps, Typography } from 'antd';
import { LoadingOutlined, StopOutlined } from '@ant-design/icons';

const { Step } = Steps;
const { Title } = Typography;
const LayoutStyle = styled.div`
  max-width: 550px;
  border: 1px solid grey;
  margin: 7% auto !important;
  padding: 42px 24px 50px !important;
  background: white;
`;

export default class PageConfirmEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      type: 'message',
      title: 'Verification',
      message: 'In progress...',
      redirect: false,
    };
  }

  componentDidMount() {
    let splitData = this.props.confirmationData.split(':');
    let confirmationCode = splitData[0];
    let username = splitData[1];

    Auth.confirmSignUp(username, confirmationCode)
      .then(() => {
        this.setState({
          ...this.state,
          type: 'success',
          loading: false,
          title: 'Succesfully confirmed!',
          message: 'You will be redirected to login in a few!',
        });

        setTimeout(() => {
          this.setState({
            ...this.state,
            redirect: true,
          });
        }, 2000);
      })
      .catch((err) => {
        this.setState({
          ...this.state,
          type: 'error',
          title: 'Invalid code',
          message: err.message,
          loading: false,
        });
      });
  }

  render() {
    let loadingIcon;

    if (this.state.type === 'success') {
      loadingIcon = this.state.loading ? <LoadingOutlined /> : '';
    } else {
      loadingIcon = this.state.loading ? <LoadingOutlined /> : <StopOutlined />;
    }

    let step = this.state.type === 'success' ? 2 : 1;

    return (
      <LayoutStyle>
        <Title level={2}>{this.state.title}</Title>

        <Title level={3}>{this.state.message}</Title>
        <br />

        <Steps size="big" current={step}>
          <Step title="Account Creation" />
          <Step title="Verification" icon={loadingIcon} />
          <Step title="Complete" />
        </Steps>

        {this.state.redirect && (
          <Redirect to={{ pathname: '/console/login' }} />
        )}
      </LayoutStyle>
    );
  }
}
