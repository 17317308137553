import styles from './ActionButton.module.scss';

interface IProps {
  onClick: () => void;
  children: JSX.Element;
}

const ActionButton = ({ onClick, children }: IProps) => {
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
    onClick();
  };

  return (
    <div className={styles.button} onClick={handleClick}>
      {children}
    </div>
  );
};

export default ActionButton;
