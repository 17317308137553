import { connect } from 'react-redux';
import { RootState } from 'store/rootStore';
import { IBook } from 'types';
import { useEffect, useState } from 'react';
import currencies from 'Assets/data/currencies.json';
import ToggleSwitch from 'UILib/ToggleSwitch/ToggleSwitch';
import Dropdown from 'UILib/Dropdown/Dropdown';
import TextArea from 'UILib/TextArea/TextArea';
import Input from 'UILib/Input/Input';

import styles from './BookForm.module.scss';

interface IProps {
  funnelBuilderBooks: IBook[];
  data: Record<string, string | number | boolean>;
  validationFailed: boolean;
  onValueChange: (key: string, value: string | number | boolean) => void;
  fields: Record<string, { label: string; name: string; required?: boolean }>;
}

const ChooseBook = ({
  funnelBuilderBooks,
  data,
  fields,
  validationFailed,
  onValueChange,
}: IProps) => {
  const bookTitle = data[fields.bookTitle?.name];
  const bookPrice = data[fields.bookPrice?.name];
  const bookCurrency = data[fields.bookCurrency?.name];
  const bookDescription = data[fields.bookDescription?.name];

  const [selectedOption, setSelectedOption] = useState('Bookle Book');

  useEffect(() => {
    onValueChange(fields.bookCurrency?.name, currencies[0].name);
  }, [onValueChange, fields.bookCurrency?.name]);

  useEffect(() => {
    const selectedBook = funnelBuilderBooks.find(
      (book) => book.title === bookTitle
    );
    if (!selectedBook) return;

    onValueChange(fields.bookDescription?.name, selectedBook.description || '');
  }, [
    bookTitle,
    onValueChange,
    funnelBuilderBooks,
    fields.bookDescription?.name,
  ]);

  return (
    <div className={styles.container}>
      <ToggleSwitch
        height={40}
        className={styles.toggleSwitch}
        options={['Bookle Book', 'Other Book']}
        selectedValue={selectedOption}
        onSelectOption={setSelectedOption}
      />
      <div className={styles.firstRow}>
        <div className={styles.bookDropdown}>
          {selectedOption === 'Bookle Book' ? (
            <Dropdown
              label="Select a book"
              onChange={(newValue) =>
                onValueChange(fields.bookTitle?.name, newValue)
              }
              value={bookTitle}
              options={funnelBuilderBooks.map((book) => ({
                label: book.title || '',
                value: book.title || '',
              }))}
              hasErrors={
                validationFailed && fields.bookTitle?.required && !bookTitle
              }
            />
          ) : (
            <Input
              border="one-line"
              placeholder={fields.bookTitle?.label}
              value={typeof bookTitle !== 'boolean' ? bookTitle : ''}
              onChange={(event) =>
                onValueChange(fields.bookTitle?.name, event.target.value)
              }
              error={
                validationFailed && fields.bookTitle?.required && !bookTitle
                  ? 'Required Field'
                  : undefined
              }
            />
          )}
        </div>
        <Input
          className={styles.priceInput}
          hideArrows
          type="number"
          border="one-line"
          placeholder={fields.bookPrice?.label}
          value={typeof bookPrice !== 'boolean' ? bookPrice : ''}
          onChange={(event) =>
            onValueChange(fields.bookPrice?.name, event.target.value)
          }
          error={
            validationFailed && fields.bookPrice?.required && !bookPrice
              ? 'Required Field'
              : undefined
          }
        />
        <div className={styles.currencyDropDown}>
          <Dropdown
            label={fields.bookCurrency?.label}
            onChange={(newValue) =>
              onValueChange(fields.bookCurrency?.name, newValue)
            }
            value={bookCurrency}
            options={currencies.map((currency) => ({
              label: currency.name,
              value: currency.name,
            }))}
            hasErrors={
              validationFailed && fields.bookCurrency?.required && !bookCurrency
            }
          />
        </div>
      </div>
      <TextArea
        value={typeof bookDescription === 'string' ? bookDescription : ''}
        placeholder={fields.bookDescription?.label}
        onChange={(newValue) =>
          onValueChange(fields.bookDescription?.name, newValue)
        }
        hasErrors={
          validationFailed &&
          fields.bookDescription?.required &&
          !bookDescription
        }
        autoComplete="off"
        height={180}
        maxLength={5000}
      />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  funnelBuilderBooks: state.funnelBuilder.books,
});

export default connect(mapStateToProps, null)(ChooseBook);
