export const ADD_MAILGUN_SENDER = {
  operationName: 'createMailGunSender',
  query: `mutation createMailGunSender(<KEYS>) {
      createMailGunSender(
        <VALUES>
      ) {
        _id
        name
        status
        email
        mailDomain {
          _id
        }
      }
    }`,
};
