import React from 'react';
import Button from 'Styled/Button1';
import s from './CopyCraft.module.scss';

interface IProps {
  onAddClicked: () => void;
}

const CopyCraftEmptyPage = (props: IProps) => {
  return (
    <div className={s.emptyTopicContent}>
      <h1>Welcome to Copy Craft</h1>
      <p>Please create new theme to start</p>
      <Button onClick={() => props.onAddClicked()}>Create Theme</Button>
    </div>
  );
};

export default CopyCraftEmptyPage;
