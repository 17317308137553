import styled from 'styled-components';

interface IProps {
  disabled?: boolean;
  height?: number;
  width?: number;
  fontSize?: number;
}

export default styled.button`
  width: ${(props: IProps) => (props.width ? props.width : 128)}px;
  max-width: 100%;
  height: ${(props: IProps) => (props.height ? props.height : 35)}px;
  background: #fff;
  border-radius: 20px;

  font-style: normal;
  font-weight: 500;
  font-size: ${(props: IProps) => (props.fontSize ? props.fontSize : 14)}px;

  border: 1px #000 solid;
  color: #000;

  cursor: ${(props: IProps) => (props.disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    background: #000;
    color: #fff;
  }
`;
