import {
  APPS_NAMES_FETCHED,
  AUTOSASS_DESIGN_FETCHED,
  SET_AUTOSAAS_LOGO,
} from './autosaasActions';
import { PayloadAction } from '@reduxjs/toolkit';

export interface IAppsNames {
  key: string;
  name: string;
}

export interface IAutoSassDesign {
  domain: string;
  name: string;
  logoUrl: string;
}
export interface ISubAccountsState {
  logoUrl: string;
  appsNames?: IAppsNames[];
  autoSassDesign?: IAutoSassDesign;
}

const url = window.location.href;
const hostname = new URL(url).hostname;
const isAutoFunnel =
  hostname === 'localhost' ||
  hostname === 'app.dev.autofunnel.ai' ||
  hostname === 'app.autofunnel.ai';
const domain = isAutoFunnel ? 'app.autofunnel.ai' : hostname;

const initialState: ISubAccountsState = {
  logoUrl: `https://cdn.autofunnel.ai/autosaas/${domain}/logo?${new Date().getTime()}`,
};

const autosaasReducer = (
  state: ISubAccountsState = initialState,
  action: PayloadAction<string | IAppsNames[] | IAutoSassDesign>
): ISubAccountsState => {
  switch (action.type) {
    case SET_AUTOSAAS_LOGO:
      return {
        ...state,
        logoUrl: action.payload as string,
      };
    case APPS_NAMES_FETCHED:
      return {
        ...state,
        appsNames: action.payload as IAppsNames[],
      };
    case AUTOSASS_DESIGN_FETCHED:
      return {
        ...state,
        autoSassDesign: action.payload as IAutoSassDesign,
      };
    default:
      return state;
  }
};

export { initialState, autosaasReducer };
