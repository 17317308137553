export const CREATE_CONTACT_CONSOLE = {
  operationName: 'createContactConsole',
  query: `mutation createContactConsole(
    $email: String!
    $phone: String
    $tagsId: [String!]!
    $data: String
  ){
    createContactConsole(
      email: $email
      phone: $phone
      tagsId: $tagsId
      data: $data
    ) {
      _id
      user {
        _id
      }
      funnel {
        _id
      }
      page {
        _id
      }
      email
      phone
      tags {
        _id
        name
      }
      data
      createdAt
      updatedAt
    }
  }`,
};
