import { useState } from 'react';
import { ReactComponent as WarningIcon } from 'Assets/icons/warning.svg';
import { IFunnelTemplate } from 'types';
import { graphQlCall } from 'graphql/utils';
import queries from 'graphql/queries';
import Loader from 'UILib/Loader/Loader';
import Button from 'UILib/Button/Button';
import Popup from 'UILib/Popup/Popup';

import styles from './UngroupCategoryPopup.module.scss';

interface IProps {
  templates: IFunnelTemplate[];
  categoryId: string;
  onClose?: () => void;
  onUngroup: () => void;
}

const UngroupCategoryPopup = ({
  templates,
  categoryId,
  onClose,
  onUngroup,
}: IProps) => {
  const [loading, setLoading] = useState(false);

  const handleUngroup = async () => {
    setLoading(true);
    const templatesToUngroup = templates.filter(
      (template) => template.category === categoryId
    );
    await Promise.all(
      templatesToUngroup.map((template) => {
        return graphQlCall({
          queryTemplateObject: queries.UPDATE_SAAS_TEMPLATE,
          values: { id: template._id, category: null },
          headerType: 'USER-AUTH',
        });
      })
    );
    setLoading(false);

    onUngroup();
    if (onClose) onClose();
  };

  return (
    <Popup
      onClose={() => {
        if (onClose) onClose();
      }}
      wrapperClassName={styles.popupContainer}
    >
      <WarningIcon className={styles.warningIcon} />
      <div className={styles.popupTitle}>Ungroup</div>
      <div className={styles.popupInfo}>
        All templates from this group will be moved outside. To make them
        visible to customers, you will need to move them to other group.
      </div>
      <div className={styles.buttonsContainer}>
        <Button appearance="stroke" width={158} height={40} onClick={onClose}>
          Cancel
        </Button>
        <Button
          appearance="highlighted"
          width={158}
          height={40}
          onClick={handleUngroup}
        >
          {loading ? <Loader color="white" size={16} /> : 'Ungroup'}
        </Button>
      </div>
    </Popup>
  );
};

export default UngroupCategoryPopup;
