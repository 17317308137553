export const DELETE_BROADCAST = {
  operationName: 'deleteBroadcast',
  query: `mutation deleteBroadcast(
      $id: String!
    ) {
      deleteBroadcast(
        id: $id
      ) {
        message
      }
    }`,
};
