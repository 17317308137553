import React from 'react';
import s from './CopyCraft.module.scss';
import likeSvg from 'Assets/icons/copyCraft/like.svg';
import likeActiveSvg from 'Assets/icons/copyCraft/likeActive.svg';
import dislikeSvg from 'Assets/icons/copyCraft/dislike.svg';
import dislikeActiveSvg from 'Assets/icons/copyCraft/dislikeActive.svg';
import { ReactComponent as CopyIcon } from 'Assets/icons/copyCraft/duplicate.svg';
import xSvg from 'Assets/icons/copyCraft/x2.svg';

interface IAiRequestPrototype {
  request: string;
  requestType: string;
  response: string;
  hasLiked?: number;
}

interface IProps {
  request: IAiRequestPrototype;
  userScrolledUpDuringGeneration: boolean;
  inProgress?: boolean;
  onLike?: (like: number) => void;
  onDelete?: () => void;
}

const CopyCraftChatCard = (props: IProps) => {
  const hasLiked = props.request.hasLiked || 0;

  const handleLike = (like: number) => {
    if (!props.onLike) {
      return;
    }
    props.onLike(like);
  };

  const handleDelete = () => {
    if (props.onDelete) {
      props.onDelete();
    }
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(props.request.response);
  };

  const processTextFormat = (text: string) => {
    let out = text;

    //TODO Need to do this text CLEANUP on server side
    if (out.search('\n') === 0) {
      out = out.replace('\n', '');
    }
    if (out.search('===') === 0) {
      out = out.replace('===', '');
    }

    do {
      out = out.replace('\n', '');
    } while (out.search('\n') < 5 && out.search('\n') >= 0);

    let brakIndex = out.search('===');
    if (out.slice(brakIndex + 3).search('\n') === 0) {
      out = out.replace('\n', '');
    }

    //This one is ok though. not part of server clean up
    out = out.replace('===', '<br><hr>').replaceAll('\n', '<br>');
    out += '<br>';

    // custom headline
    let index = out.search('<br>');
    out = '<b>' + out;
    out = out.slice(0, index + 3) + '</b>' + out.slice(index + 3);

    out = out.replaceAll('<LINK>', '{link}');

    var objDiv = document.getElementById('chatSection');
    if (objDiv && !props.userScrolledUpDuringGeneration) {
      objDiv.scrollTop = objDiv.scrollHeight;
    }

    return out;
  };

  const processText = (text: string) => {
    let out = text;

    var objDiv = document.getElementById('chatSection');
    if (objDiv && !props.userScrolledUpDuringGeneration) {
      objDiv.scrollTop = objDiv.scrollHeight;
    }

    return out;
  };

  return (
    <div className={s.chatCardContent}>
      <div className={s.typeContent}>
        <span>{props.request.requestType}</span>
      </div>
      <div className={s.requestContent}>
        <span>{props.request.request}</span>
      </div>
      <div className={s.responseContent}>
        <span
          dangerouslySetInnerHTML={{
            __html: processText(props.request.response),
          }}
        ></span>
      </div>
      {!props.inProgress && (
        <div className={s.likesBlock}>
          <span onClick={() => handleCopyToClipboard()}>
            <CopyIcon fill="#a0a0a0" />
          </span>
          <span onClick={() => handleLike(1)}>
            {hasLiked === 1 ? (
              <img src={likeActiveSvg} />
            ) : (
              <img src={likeSvg} />
            )}
          </span>
          <span onClick={() => handleLike(-1)}>
            {hasLiked === -1 ? (
              <img src={dislikeActiveSvg} />
            ) : (
              <img src={dislikeSvg} />
            )}
          </span>

          {/* <span className={s.buttonSmall}>
            Make a Funnel
          </span> */}

          <span className={s.deleteIcon} onClick={() => handleDelete()}>
            <img src={xSvg} alt="x" />
          </span>
        </div>
      )}
    </div>
  );
};

export default CopyCraftChatCard;
