export const DUPLICATE_PAGE_TO_OTHER_ACCOUNT = {
  operationName: 'duplicatePageToOtherAccount',
  query: `mutation duplicatePageToOtherAccount(
      $targetAccountOwner: String!
      $sourceAccountOwner: String!
      $targetFunnelId: String!
      $pageId: String!
    ) {
      duplicatePageToOtherAccount(
        targetAccountOwner: $targetAccountOwner
        sourceAccountOwner: $sourceAccountOwner
        targetFunnelId: $targetFunnelId
        pageId: $pageId
      ) {
        _id
        name
      }
    }`,
};
