export const UPDATE_SAAS_TEMPLATE = {
  operationName: 'updateSaasTemplate',
  query: `mutation updateSaasTemplate ($id: String!, $name: String, $description: String, $pages: String, $category: String, $enabled: Boolean) {
                updateSaasTemplate (id: $id, name: $name, description: $description, pages: $pages, category: $category, enabled: $enabled) {
                    _id
                    name
                    description
                    image
                    category
                    createdAt
                    updatedAt
                    enabled
                    pages {
                        _id
                        name
                        enabled
                        data
                        image
                    }
                }
            }`,
};
