import { LegacyRef, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { ReactEditor } from 'slate-react';
import { BaseEditor, Range } from 'slate';

import styles from './TagSelector.module.scss';

interface IProps {
  tags: string[];
  editor: BaseEditor & ReactEditor;
  targetRange?: Range;
  search: string;
  index: number;
}

const TagSelector = ({ tags, targetRange, search, editor, index }: IProps) => {
  const ref: LegacyRef<HTMLDivElement> = useRef(null);

  useEffect(() => {
    if (ref.current && targetRange) {
      const el = ref.current;
      const domRange = ReactEditor.toDOMRange(editor, targetRange);
      const rect = domRange.getBoundingClientRect();
      el.style.top = `${rect.top + window.pageYOffset + 24}px`;
      el.style.left = `${rect.left + window.pageXOffset}px`;
    }
  }, [editor, targetRange, index, search]);

  if (!targetRange) {
    return null;
  }

  return createPortal(
    <div ref={ref} className={styles.popupContainer}>
      <div className={styles.textWrapper}>#{search}</div>
      {tags.map((tag, i) => (
        <div
          key={tag}
          className={styles.textWrapper}
          style={{
            backgroundColor: i === index ? '#4957d8' : 'transparent',
            color: i === index ? '#ffffff' : '#000000',
          }}
        >
          #{tag}
        </div>
      ))}
    </div>,
    document.body
  );
};

export default TagSelector;
