export const DELETE_PRODUCT_MUTATION = {
  operationName: 'deleteProduct',
  query: `mutation deleteProduct(
      $id: String!
    ) {
      deleteProduct(
        id: $id
      ) {
        message
      }
    }`,
};
