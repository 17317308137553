import styles from './ProjectPlaceholders.module.scss';

const ProjectPlaceholders = () => {
  return (
    <main className={styles.placeholderWrapper}>
      <div className={styles.placeholderItemWrapper}>
        <div className={styles.placeholderTopLine} />
      </div>
      <div className={styles.placeholderItemWrapper}>
        <div className={styles.placeholderTopRectangle} />
      </div>
      <div className={styles.placeholderItemWrapper}>
        <div className={styles.placeholderCenterRectangle} />
      </div>
      <div className={styles.placeholderItemWithRectangleWrapper}>
        <div className={styles.placeholderItemRectangle} />
        <div className={styles.placeholderItemRightWrapper}>
          <div className={styles.placeholderItemBigLine} />
          <div className={styles.placeholderItemSmallLine} />
        </div>
      </div>
      <div className={styles.placeholderItemWithRectangleWrapper}>
        <div className={styles.placeholderItemRectangle} />
        <div className={styles.placeholderItemRightWrapper}>
          <div className={styles.placeholderItemBigLine} />
          <div className={styles.placeholderItemSmallLine} />
        </div>
      </div>
      <div className={styles.placeholderItemWithRectangleWrapper}>
        <div className={styles.placeholderItemRectangle} />
        <div className={styles.placeholderItemRightWrapper}>
          <div className={styles.placeholderItemBigLine} />
          <div className={styles.placeholderItemSmallLine} />
        </div>
      </div>
    </main>
  );
};

export default ProjectPlaceholders;
