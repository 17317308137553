import { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { RootState } from 'store/rootStore';
import { IGeneralProjectName } from 'types';
import { ReactComponent as CloseSvg } from 'Assets/icons/close.svg';
import { ReactComponent as MoreIcon } from 'Assets/icons/more.svg';
import { ReactComponent as ClockSvg } from 'Assets/icons/waiting.svg';
import { ReactComponent as ReloadIcon } from 'Assets/icons/reload.svg';
import { ReactComponent as CheckMarkIcon } from 'Assets/icons/checkMarkInnerCircle.svg';
import {
  selectFunnel,
  selectDomain,
  refreshDomain,
  removeDomainPopup,
} from 'store/domains/domainsActions';
import DropdownBox from 'Components/Common/dropdownBox/DropdownBox';
import Dropdown from 'UILib/Dropdown/Dropdown';
import ListItem from '../../ListItem/ListItem';

import s from './DomainItem.module.scss';

interface IProps {
  name: string;
  status: string;
  id: string;
  projectId?: string;
  selectDomain: (id?: string) => void;
  funnels: IGeneralProjectName[];
  selectFunnel: (id: string, projectId: string) => void;
  refreshDomain: (id: string) => void;
  toggleRemoveDomainPopup: (value: boolean) => void;
  isLoading?: boolean;
  selectedDomain?: string;
}

interface IOption {
  value: string;
  label: string;
}

const DomainItem = (props: IProps) => {
  const [options, setOptions] = useState<IOption[]>([]);
  const [isPointsBlockOpen, setIsPointsBlockOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<IOption | undefined>(
    undefined
  );

  const pointsBlock = useRef(null);

  useEffect(() => {
    const newOptions = props.funnels.map((funnel) => ({
      value: funnel._id,
      label: funnel.name,
    }));
    setOptions(newOptions);

    props.funnels.forEach((funnel) => {
      if (funnel._id === props.projectId) {
        setSelectedOption({ value: funnel._id, label: funnel.name });
      }
    });
  }, [props.funnels, props.projectId]);

  const handleSelectFunnel = (value: string) => {
    const option = options.find((opt) => opt.value === value);
    if (!option) return;

    setSelectedOption(option);
    props.selectFunnel(props.id, value);
  };

  return (
    <ListItem>
      <div className={s.domainName}>{props.name}</div>
      <div className={s.connectedFunnel}>
        <Dropdown
          label="Select funnel"
          value={selectedOption?.value}
          options={options}
          onChange={handleSelectFunnel}
        />
      </div>
      <div
        className={clsx(s.domainItemStatus, {
          [s.success]: props.status === 'Verified',
        })}
      >
        {props.status !== 'Verified' ? <ClockSvg /> : <CheckMarkIcon />}
        {props.status}
        {props.status !== 'Verified' && (
          <ReloadIcon
            className={clsx(s.reloadIcon, {
              [s.loading]: props.isLoading && props.selectedDomain === props.id,
            })}
            onClick={() => {
              if (props.isLoading) return;
              props.selectDomain(props.id);
              props.refreshDomain(props.id);
            }}
          />
        )}
      </div>

      <DropdownBox
        isOpen={isPointsBlockOpen}
        onClose={() => setIsPointsBlockOpen(false)}
        pointerEvents={true}
        componentRef={pointsBlock}
      >
        <div className={s.dropdownBlock}>
          <div
            className={s.item}
            onClick={() => {
              props.selectDomain(props.id);
              props.toggleRemoveDomainPopup(true);
              setIsPointsBlockOpen(false);
            }}
          >
            <CloseSvg /> Remove
          </div>
        </div>
      </DropdownBox>

      <div>
        <div
          className={s.pointsBlock}
          onClick={() => {
            setIsPointsBlockOpen(true);
          }}
          ref={pointsBlock}
        >
          <MoreIcon />
        </div>
      </div>
    </ListItem>
  );
};

const mapStateToProps = (state: RootState) => ({
  isLoading: state.domains.domainLoading,
  selectedDomain: state.domains.selectedDomain,
});

const mapDispatchToProps = {
  selectFunnel: (id: string, projectId: string) => selectFunnel(id, projectId),
  refreshDomain: (id: string) => refreshDomain(id),
  toggleRemoveDomainPopup: (value: boolean) => removeDomainPopup(value),
  selectDomain: (id?: string) => selectDomain(id),
};

export default connect(mapStateToProps, mapDispatchToProps)(DomainItem);
