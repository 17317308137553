import { Descendant, Element } from 'slate';
import { TagElement } from 'Editors/types';

export const convertBlocksToString = (blocks: Descendant[]): string => {
  let finalText = '';
  for (const block of blocks as Element[]) {
    if (typeof block === 'string' || block.type !== 'paragraph') continue;

    for (const child of block.children) {
      const element = child as any;

      if (element.type === 'tag') finalText += '#' + element.value;
      else finalText += element.text;
    }
    finalText += '\n';
  }

  return finalText;
};

export const convertTextToBlocks = (text: string): Descendant[] => {
  const paragraphs = text.split('\n');
  const children: Descendant[] = [];
  for (const paragraph of paragraphs) {
    const words = paragraph.split(' ');
    const paragraphChildren: Descendant[] = [];
    for (const [index, word] of words.entries()) {
      if (word.startsWith('#')) {
        paragraphChildren.push({
          text: '',
        });
        const payload: TagElement = {
          type: 'tag',
          value: word.slice(1),
          children: [{ text: '' }],
        };
        paragraphChildren.push(payload);
        paragraphChildren.push({
          text: index === words.length - 1 ? '' : ' ',
        });
        continue;
      }
      paragraphChildren.push({
        text: word + (index === words.length - 1 ? '' : ' '),
      });
    }
    children.push({
      type: 'paragraph',
      children: paragraphChildren,
    });
  }

  return children;
};
