export const UPDATE_GENERATION_TEMPLATE_MUTATION = {
  operationName: 'updateGenerationTemplate',
  query: `mutation updateGenerationTemplate(
  <KEYS>
          # $id: String!
          # $name: String!
          # $actions: String!
          # $layout: String!
        ) {
          updateGenerationTemplate(
          <VALUES>
            # id: $id
            # name: $name
            # actions: $actions
            # layout: $layout
          ) {
              _id
              name
              layout
              createdAt
              updatedAt
              actions {
                id
                type
                forms {
                  type
                  name
                  variables {
                    id
                    type
                    label
                  }
                }
                metadata
                prompt
                postFunctions
              }
            }
          }`,
};
