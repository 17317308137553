import { DispatchType } from 'store/rootReducer';

export const REMOVE_SMART_LIST_POPUP = 'REMOVE_SMART_LIST_POPUP';
export const SELECTED_SMART_LIST = 'SELECTED_SMArt_LIST';

export const removeSmartListPopup = (payload: boolean) => (
  dispatch: DispatchType
) => {
  const stateAction = {
    type: REMOVE_SMART_LIST_POPUP,
    payload,
  };
  dispatch(stateAction);
};

export const setSelectedSmartList = (payload: string) => (
  dispatch: DispatchType
) => {
  const stateAction = {
    type: SELECTED_SMART_LIST,
    payload,
  };
  dispatch(stateAction);
};
