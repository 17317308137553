export const EDIT_SUB_ACCOUNT = {
  operationName: 'editSubAccount',
  query: `mutation editSubAccount(
      $id: String!
      $name: String
      $email: String
      $notes: String
    ) {
      editSubAccount(
        id: $id
        name: $name
        email: $email
        notes: $notes
      ) {
        _id
        name
        owner
        email
        workspace
        notes
        thumbnail
      }
    }`,
};
