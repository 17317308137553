export const SET_USER_PAYMENT_METHOD = {
  operationName: 'setUserPaymentMethod',
  query: `mutation setUserPaymentMethod(
        <KEYS>
      ) {
        setUserPaymentMethod(
          <VALUES>
        ) 
      }`,
};
