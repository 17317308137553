import { v4 as uuid } from 'uuid';
import { ThunkDispatch } from 'redux-thunk';
import {
  apiFetchProjects,
  apiEditName,
  apiArchiveProject,
  apiArchiveFunnel,
  // apiSetDomain,
  apiUpdateProject,
  apiUpdateFunnel,
  // apiDeleteSubPage,
  apiSetPagesOrder,
} from '../projects/projectsApiActions';
import { RootState } from '../rootReducer';
import { Action } from 'redux';
import { IQueueAction } from 'types';

export const SYNC_STATUS = 'SYNC_STATUS';
export const SYNC_INFO = 'SYNC_INFO';
export const ADD_QUEUE = 'ADD_QUEUE';
export const DONE_QUEUE = 'DONE_QUEUE';
export const QUEUE_STATUSES = {
  READY: 'ready',
  IN_PROCESS: 'in progress',
  ERROR: 'error',
};

export const SYNC_ACTIONS = {
  FETCH_PAGES: 'FETCH_PAGES',
  RENAME_PROJECT: 'RENAME_PROJECT',
  ARCHIVE_PROJECT: 'ARCHIVE_PROJECT',
  ARCHIVE_FUNNEL: 'ARCHIVE_FUNNEL',
  PROJECT_DOMAIN: 'PROJECT_DOMAIN',
  UPDATE_PROJECT: 'UPDATE_PROJECT',
  UPDATE_FUNNEL: 'UPDATE_FUNNEL',
  DELETE_SUBPAGE: 'DELETE_SUBPAGE',
  SET_PAGES_ORDER: 'SET_PAGES_ORDER',
};

let queueInterval: ReturnType<typeof setInterval> | undefined | null;

export const setQueueStatus = (status: string) => (
  dispatch: ThunkDispatch<RootState, void, Action>
) => {
  dispatch({
    type: SYNC_STATUS,
    payload: status,
  });
};

export const setQueueInfo = (text: string) => (
  dispatch: ThunkDispatch<RootState, void, Action>
) => {
  dispatch({
    type: SYNC_INFO,
    payload: text,
  });
};

export const doneQueue = (queueId: string) => (
  dispatch: ThunkDispatch<RootState, void, Action>
) => {
  dispatch({
    type: DONE_QUEUE,
    payload: queueId,
  });
};

export const pushToQueue = (payload: any) => (
  dispatch: ThunkDispatch<RootState, void, Action>,
  getState: () => RootState
) => {
  dispatch({
    type: ADD_QUEUE,
    payload: {
      ...payload,
      queueId: uuid(),
    },
  });
  dispatch(setQueueInfo('added to sync queue'));

  if (queueInterval) {
    return;
  }

  queueInterval = setInterval(() => {
    const state = getState();
    const queue = state.sync.queue;
    const status = state.sync.status;
    if (queue.length > 0 && status !== QUEUE_STATUSES.IN_PROCESS) {
      dispatch(setQueueStatus(QUEUE_STATUSES.IN_PROCESS));
      dispatch(apiAction(queue[0]));
    }
  }, 1000);
};

interface IApiActionPayload {
  action: IQueueAction;
  queueId: string;
}

export const apiAction = ({ action, queueId }: IApiActionPayload) => (
  dispatch: ThunkDispatch<RootState, void, Action>
) => {
  switch (action.type) {
    case SYNC_ACTIONS.FETCH_PAGES:
      dispatch(setQueueInfo('fetching projects...'));
      dispatch(apiFetchProjects(action.params, queueId));
      break;

    case SYNC_ACTIONS.RENAME_PROJECT:
      dispatch(apiEditName(action.params, queueId));
      break;

    case SYNC_ACTIONS.ARCHIVE_PROJECT:
      dispatch(apiArchiveProject(action.params, queueId));
      break;

    case SYNC_ACTIONS.ARCHIVE_FUNNEL:
      dispatch(apiArchiveFunnel(action.params, queueId));
      break;

    // case SYNC_ACTIONS.PROJECT_DOMAIN:
    //   dispatch(apiSetDomain(action.params, queueId));
    //   break;

    case SYNC_ACTIONS.UPDATE_PROJECT:
      dispatch(apiUpdateProject(action.params, queueId));
      break;

    case SYNC_ACTIONS.UPDATE_FUNNEL:
      dispatch(apiUpdateFunnel(action.params, queueId));
      break;

    // case SYNC_ACTIONS.DELETE_SUBPAGE:
    //   dispatch(apiDeleteSubPage(action.params, queueId));
    //   break;

    case SYNC_ACTIONS.SET_PAGES_ORDER:
      dispatch(apiSetPagesOrder(action.params, queueId));
      break;
    default:
      break;
  }
};
