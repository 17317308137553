import React, { ChangeEvent } from 'react';
import s from './InputV1.module.scss';

interface IProps {
  label?: string;
  name?: string;
  setState?: (stateValue: string) => void;
  index?: number;
  value: string;
  maxLength?: number;
  customOnChange?: (e: ChangeEvent<HTMLInputElement>, index: number) => void;
  placeholder?: string;
  width: number;
  type: string;
  allowOnlyNumbers?: boolean;
  onBlur?: (e?: any) => void;
  error?: boolean;
  readonly?: boolean;
}

function InputV1(props: IProps) {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (props.allowOnlyNumbers && isNaN(Number(e.currentTarget.value))) {
      return;
    }

    if (typeof props.setState === 'function') {
      props.setState(e.currentTarget.value);
    }
    if (typeof props.customOnChange === 'function' && props.index) {
      props.customOnChange(e, props.index);
    }
  };

  const handleOnBlur = (e: any) => {
    if (props.onBlur) {
      props.onBlur(e);
    }
  };

  const inputClass = `${s.input} ${props.error ? s.error : ''}`;

  return (
    <div className={s.container}>
      {props.label && <p className={s.label}>{props.label}</p>}
      <input
        autoComplete="off"
        maxLength={props.maxLength}
        name={props.name}
        onChange={handleChange}
        value={props.value}
        readOnly={props.readonly}
        placeholder={props.placeholder}
        className={inputClass}
        style={{ width: `${props.width}px` }}
        type={props.type}
        onBlur={(e: any) => handleOnBlur(e)}
      />
    </div>
  );
}

export default InputV1;
