export const DELETE_CONTACT_TAG = {
  operationName: 'deleteContactTag',
  query: `mutation deleteContactTag(
    $tagId: String!
  ) {
    deleteContactTag(
      tagId: $tagId
    ) {
      message
    }
  }`,
};
