import clsx from 'clsx';
import { useState, useEffect } from 'react';
import { Dropdown, MenuProps } from 'antd';
import { IFunnelTemplate, ITemplateCategory } from 'types';
import { ReactComponent as RemoveIcon } from 'Assets/icons/removeIcon.svg';
import { ReactComponent as ArrowIcon } from 'Assets/icons/arrow.svg';
import { ReactComponent as PlusIcon } from 'Assets/icons/plus.svg';
import { ReactComponent as EditIcon } from 'Assets/icons/edit2.svg';
import { ReactComponent as MoreIcon } from 'Assets/icons/more.svg';
import { TEMPLATES_URL, DATA_URL } from 'Constants';
import { graphQlCall } from 'graphql/utils';
import UngroupCategoryPopup from 'Components/FunnelTemplates/UngroupCategoryPopup/UngroupCategoryPopup';
import AddGroupPopup from 'Components/FunnelTemplates/AddGroupPopup/AddGroupPopup';
import ToggleSwitch from 'UILib/ToggleSwitch/ToggleSwitch';
import burgerSvg from 'Assets/icons/burger.svg';
import SideMenu from 'Components/SideMenu/SideMenu';
import queries from 'graphql/queries';
import Button from 'UILib/Button/Button';
import styles from './FunnelTemplates.module.scss';

const templateMenuItems: MenuProps['items'] = [
  {
    key: 'edit',
    style: { width: '150px' },
    label: 'Edit',
    icon: <EditIcon fill="#a0a0a0" width={16} height={16} />,
  },
  {
    key: 'delete',
    style: { width: '150px' },
    label: 'Delete',
    icon: <RemoveIcon fill="#a0a0a0" width={11} height={11} />,
  },
];

const FunnelTemplates = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [templateCategories, setTemplateCategories] = useState<
    (ITemplateCategory & { open: boolean })[]
  >([]);
  const [templates, setTemplates] = useState<IFunnelTemplate[]>([]);
  const [ungroupCategoryId, setUngroupCategoryId] = useState('');
  const [renamedCategory, setRenamedCategory] = useState<
    ITemplateCategory | undefined
  >();
  const [showAddGroupPopup, setShowAddGroupPopup] = useState(false);
  const [showUngroupPopup, setShowUngroupPopup] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 1000px)');
    if (mediaQuery.matches) {
      setIsSidebarOpen(false);
    } else {
      setIsSidebarOpen(true);
    }

    const handleResize = () => {
      if (window.innerWidth <= 1000) {
        setIsSidebarOpen(false);
      } else {
        setIsSidebarOpen(true);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    graphQlCall({
      queryTemplateObject: queries.GET_OWN_SAAS_TEMPLATES_AND_CATEGORIES,
      headerType: 'USER-AUTH',
    }).then((data) => {
      setTemplateCategories(
        data.categories.map((category: any) => ({ ...category, open: true }))
      );
      setTemplates(data.templates);
    });
  }, []);

  const processTemplateImageUrl = (baseUrl: string) => {
    if (baseUrl.includes('http')) {
      return baseUrl;
    }
    //TODO: remove this legacy code after processing all templates;
    else if (!baseUrl.includes('pageImages')) {
      return `${TEMPLATES_URL}/${baseUrl}`;
    } else {
      return `${DATA_URL}/${baseUrl}`;
    }
  };

  const updateSassCategory = async (
    categoryName: string,
    categoryId?: string
  ) => {
    const response = await graphQlCall({
      queryTemplateObject: queries.UPDATE_SAAS_CATEGORY,
      values: { id: categoryId, name: categoryName },
      headerType: 'USER-AUTH',
    });

    setTemplateCategories((prevCategories) =>
      prevCategories.map((category) =>
        category._id === response._id
          ? { ...response, open: category.open }
          : category
      )
    );
  };

  const createSassCategory = async (categoryName: string) => {
    const response = await graphQlCall({
      queryTemplateObject: queries.CREATE_SAAS_CATEGORY,
      values: { name: categoryName },
      headerType: 'USER-AUTH',
    });

    setTemplateCategories([
      ...templateCategories,
      { ...response, open: false },
    ]);
  };

  const saveSaasCategory = async (
    categoryName: string,
    categoryId?: string
  ) => {
    if (categoryId) {
      await updateSassCategory(categoryName, categoryId);
    } else {
      await createSassCategory(categoryName);
    }

    setRenamedCategory(undefined);
    setShowAddGroupPopup(false);
  };

  const updateTemplateEnabled = (templateId: string, enabled: boolean) => {
    const template = templates.find((template) => template._id === templateId);
    if (!template) return;

    template.enabled = enabled;
    setTemplates(Array.from(templates));
    graphQlCall({
      queryTemplateObject: queries.UPDATE_SAAS_TEMPLATE,
      values: { id: templateId, enabled },
      headerType: 'USER-AUTH',
    }).catch((error) => {
      console.log(error);
      template.enabled = !enabled;
      setTemplates(Array.from(templates));
    });
  };

  const ungroupCategory = () => {
    graphQlCall({
      queryTemplateObject: queries.DELETE_SAAS_CATEGORY,
      values: { id: ungroupCategoryId },
      headerType: 'USER-AUTH',
    }).then(() => {
      setTemplateCategories((prevCategories) =>
        prevCategories.filter((category) => category._id !== ungroupCategoryId)
      );
      setTemplates(
        templates.map((template) => {
          if (template.category === ungroupCategoryId) {
            return {
              ...template,
              category: null,
            };
          }
          return template;
        })
      );
      setUngroupCategoryId('');
    });
  };

  return (
    <div className={styles.pageWrapper}>
      <div
        className={clsx(styles.pageProjectsNavigation, {
          [styles.moveLeft]: !isSidebarOpen,
        })}
      >
        <div
          className={styles.sidebarToggleBtnBlock}
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        >
          <img src={burgerSvg} alt="burger svg" />
        </div>
        <SideMenu />
      </div>
      <div
        className={clsx(styles.pageContentWrapper, {
          [styles.moveLeft]: !isSidebarOpen,
        })}
      >
        <div className={styles.pageContent}>
          <div className={styles.header}>
            <div>
              <div className={styles.title}>Funnel Templates</div>
              <div className={styles.statementsAmount}>
                {templates.length}{' '}
                {templates.length === 1 ? 'template' : 'templates'}
              </div>
            </div>
            <div className={styles.buttonsContainer}>
              <Button
                height={40}
                appearance="stroke"
                postfixIcon={<PlusIcon width={13} height={13} />}
                onClick={() => setShowAddGroupPopup(true)}
              >
                Add Group
              </Button>
              <Button
                height={40}
                appearance="highlighted"
                postfixIcon={<PlusIcon width={13} height={13} />}
                onClick={() =>
                  window.open('/edit/create/funnel-template', '_self')
                }
              >
                New Template
              </Button>
            </div>
          </div>
          {templateCategories.map((category) => (
            <div className={styles.categoryContainer}>
              <div key={category._id} className={styles.category}>
                <div
                  className={clsx(styles.categoryNameContainer, {
                    [styles.open]: category.open,
                  })}
                  onClick={() => {
                    category.open = !category.open;
                    setTemplateCategories(Array.from(templateCategories));
                  }}
                >
                  <div className={styles.categoryName}>{category.name}</div>
                  <ArrowIcon width={12} height={12} />
                </div>
                <Dropdown
                  placement="bottomRight"
                  trigger={['click']}
                  menu={{
                    items: [
                      {
                        key: 'un-group',
                        style: { width: '150px' },
                        label: 'Ungroup',
                        icon: (
                          <RemoveIcon fill="#a0a0a0" width={11} height={11} />
                        ),
                        onClick: () => {
                          setUngroupCategoryId(category._id);
                          setShowUngroupPopup(true);
                        },
                      },
                      {
                        key: 'rename',
                        style: { width: '150px' },
                        label: 'Rename',
                        icon: (
                          <EditIcon fill="#a0a0a0" width={14} height={14} />
                        ),
                        onClick: () => {
                          setRenamedCategory(category);
                          setShowAddGroupPopup(true);
                        },
                      },
                    ],
                  }}
                >
                  <Button
                    width={40}
                    height={30}
                    appearance="stroke"
                    prefixIcon={<MoreIcon />}
                  />
                </Dropdown>
              </div>
              {category.open && (
                <div className={styles.templatesContainer}>
                  {templates
                    .filter((template) => template.category === category._id)
                    .map((template) => (
                      <div key={template._id} className={styles.template}>
                        <div className={styles.templateNameContainer}>
                          <img
                            src={processTemplateImageUrl(template.image)}
                            alt="template"
                          />
                          <div>
                            <div className={styles.templateName}>
                              {template.name}
                            </div>
                            {/* <div className={styles.sectionsInfo}>
                              {template.pages?.reduce(
                                (acc, page) => acc + page.data.blocks.length,
                                0
                              )}{' '}
                              Sections
                            </div> */}
                          </div>
                        </div>
                        <div className={styles.templateActions}>
                          <ToggleSwitch
                            isBinary
                            checkValue={template.enabled}
                            onToggle={(newValue) => {
                              updateTemplateEnabled(template._id, newValue);
                            }}
                          />
                          <Dropdown
                            placement="bottomRight"
                            trigger={['click']}
                            menu={{
                              items: templateMenuItems,
                              onClick: ({ key }) => {
                                if (key === 'delete') {
                                  graphQlCall({
                                    queryTemplateObject:
                                      queries.DELETE_SAAS_TEMPLATE,
                                    values: { id: template._id },
                                    headerType: 'USER-AUTH',
                                  }).then(() => {
                                    setTemplates(
                                      templates.filter(
                                        (temp) => temp._id !== template._id
                                      )
                                    );
                                  });
                                } else if (key === 'edit') {
                                  window.open(
                                    `/edit/edit/funnel-template/${template._id}`,
                                    '_self'
                                  );
                                }
                              },
                            }}
                          >
                            <Button
                              width={40}
                              height={40}
                              appearance="stroke"
                              prefixIcon={<MoreIcon />}
                            />
                          </Dropdown>
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      {showAddGroupPopup && (
        <AddGroupPopup
          onClose={() => {
            setShowAddGroupPopup(false);
            setRenamedCategory(undefined);
          }}
          onAddGroup={saveSaasCategory}
          category={renamedCategory}
        />
      )}
      {showUngroupPopup && (
        <UngroupCategoryPopup
          templates={templates}
          categoryId={ungroupCategoryId}
          onClose={() => {
            setShowUngroupPopup(false);
            setUngroupCategoryId('');
          }}
          onUngroup={ungroupCategory}
        />
      )}
    </div>
  );
};

export default FunnelTemplates;
