import {
  DOMAINS_FETCHED,
  DOMAINS_LOADING,
  DOMAIN_HAS_BEEN_DELETE,
  NEUTRAL_STATUS,
  STATUS_CHANGED,
  SET_DOMAIN_ERROR,
  ADD_DOMAIN_POPUP,
  DOMAIN_CNAME_POPUP,
  REMOVE_DOMAIN_POPUP,
  SELECT_DOMAIN,
  DOMAIN_LOADING,
} from './domainsActions';
import { PayloadAction } from '@reduxjs/toolkit';
import { IDomain } from 'types';

export interface IDomainsState {
  items: IDomain[];
  loading: boolean;
  domainLoading: boolean;
  isDomainBeenDelete: boolean;
  status: string;
  error: string | null;
  selectedDomain?: string;
  openAddDomainPopup: boolean;
  openRemoveDomainPopup: boolean;
  openDomainAddCNAMEPopup: boolean;
}

const initialState: IDomainsState = {
  items: [],
  loading: false,
  domainLoading: false,
  isDomainBeenDelete: false,
  status: NEUTRAL_STATUS,
  error: null,
  openAddDomainPopup: false,
  openRemoveDomainPopup: false,
  openDomainAddCNAMEPopup: false,
};

const domainsReducer = (
  state = initialState,
  action: PayloadAction<IDomain[] | boolean | string>
): IDomainsState => {
  switch (action.type) {
    case DOMAINS_FETCHED:
      const domains = action.payload as IDomain[];
      return {
        ...state,
        items: domains,
        loading: false,
        domainLoading: false,
      };
    case DOMAINS_LOADING:
      const loading = action.payload as boolean;
      return {
        ...state,
        loading,
      };
    case DOMAIN_LOADING:
      const domainLoading = action.payload as boolean;
      return {
        ...state,
        domainLoading,
      };
    case DOMAIN_HAS_BEEN_DELETE:
      const isDomainBeenDelete = action.payload as boolean;
      return {
        ...state,
        isDomainBeenDelete,
      };
    case STATUS_CHANGED:
      const status = action.payload as string;
      return {
        ...state,
        status,
      };
    case SELECT_DOMAIN:
      const selectedDomainId = action.payload as string;
      return {
        ...state,
        selectedDomain: selectedDomainId,
      };
    case SET_DOMAIN_ERROR:
      const error = action.payload as string | null;
      return {
        ...state,
        error,
      };
    case ADD_DOMAIN_POPUP:
      if (typeof action.payload === 'boolean') {
        return {
          ...state,
          openAddDomainPopup: action.payload,
        };
      }
      return state;
    case DOMAIN_CNAME_POPUP:
      if (typeof action.payload === 'boolean') {
        return {
          ...state,
          openDomainAddCNAMEPopup: action.payload,
        };
      }
      return state;
    case REMOVE_DOMAIN_POPUP:
      if (typeof action.payload === 'boolean') {
        return {
          ...state,
          openRemoveDomainPopup: action.payload,
        };
      }
      return state;
    default:
      return state;
  }
};

export { initialState, domainsReducer };
