import { BookleTemplateBlock } from 'types';
import styles from './ImageBlockStyleContainer.module.scss';
import ImageTab from './ImageTab/ImageTab';
import ContainerStyleBlock from '../ContainerStyleBlock/ContainerStyleBlock';

import Tabs from 'Components/Templates/Tabs/Tabs';

interface IProps {
  item: BookleTemplateBlock;
}

const ImageBlockStyleContainer = ({ item }: IProps) => {
  return (
    <div className={styles.container}>
      <Tabs
        className={styles.tabsWrapper}
        tabsHeader={styles.tabsHeader}
        contentClassName={styles.contentContainer}
        titleClassName={styles.titleContainer}
        hideAdditionalLines
        tabs={[
          {
            title: 'Image',
            content: <ImageTab item={item} />,
          },
          {
            title: 'Container',
            content: <ContainerStyleBlock item={item} />,
          },
        ]}
      />
    </div>
  );
};

export default ImageBlockStyleContainer;
