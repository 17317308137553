export const UPDATE_SAAS_DESIGN = {
  operationName: 'updateSaasDesign',
  query: `mutation updateSaasDesign ($colorScheme: String, $alerts: String, $sideBar: String, $accent: String, $name: String) {
              updateSaasDesign (colorScheme: $colorScheme, alerts: $alerts, sideBar: $sideBar, accent: $accent, name: $name) {
                colorScheme
                domain
                logoUrl
                name
                alerts {
                    success
                    successHover
                    error
                    waiting
                }
                sideBar {
                    background
                    appHover
                    appSelected
                    divider
                    text
                    selectedText
                }
                accent {
                    accent
                    accentHover
                    darkAccent
                    darkAccentHover
                }
              }
            }`,
};
