import { PayloadAction } from '@reduxjs/toolkit';
import { BOOKS_FETCHED } from './funnelBuilderActions';
import { IBook } from 'types';

export interface IFunnelBuilderState {
  books: IBook[];
}

const initialState: IFunnelBuilderState = {
  books: [],
};

const funnelBuilderReducer = (
  state = initialState,
  action: PayloadAction<IBook[]>
): IFunnelBuilderState => {
  switch (action.type) {
    case BOOKS_FETCHED:
      const newBooks = action.payload as IBook[];
      return {
        ...state,
        books: newBooks,
      };
    default:
      return state;
  }
};

export { initialState, funnelBuilderReducer };
