export const UPDATE_AI_TEMPLATE = {
  operationName: 'updateAiTemplate',
  query: `mutation updateAiTemplate(
      $id: String!
      $data: String!
    ) {
      updateAiTemplate(
        id: $id
        data: $data
      ) {
        _id
        updatedAt
    }
  }`,
};
