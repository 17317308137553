import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
import { ReactComponent as BackArrowSvg } from '../../Assets/icons/backArrow.svg';
import { ReactComponent as StatisticsVisitsSvg } from '../../Assets/icons/statisticsVisits.svg';
import { ReactComponent as StatisticsUsersSvg } from '../../Assets/icons/statisticsUsers.svg';
import { ReactComponent as StatisticsSalesSvg } from '../../Assets/icons/statisticsSales.svg';
import statsDecor from '../../Assets/icons/statsDecor.svg';
import DatePicker from 'Components/Common/DatePicker/DatePicker';
import AnalyticsService from 'services/analytics.service';
import { IFunnel, IOrder } from 'types';
import { toLocalISOString } from 'utils/helpers';
import { FunnelTotalStats } from 'services/types';
import {
  GraphicStatsPlaceholder,
  TableStatsPlaceholder,
  TotalStatsPlaceholder,
} from 'Components/projects/Placeholders/StatisticsPlaceholders/StatisticsPlaceholders';
import { CategoryData, CategoryGraphData, GraphDataInterval } from './types';
import StatisticsTable, { DataType } from './StatisticsTable/StatisticsTable';
import StatisticGraph from './statisticGraph/StatisticGraph';

import styles from './pageStatistics.module.scss';

interface IProps {
  projectName: string;
  projectId?: string;
  pages: IFunnel[];
  pageOrder: IOrder[];
  pageId: string;
}

const compareToOptions = [
  {
    key: 1,
    label: 'Previous Period',
  },
  {
    key: 2,
    label: 'Nothing',
  },
];

const intervalOptions: { key: GraphDataInterval; label: string }[] = [
  {
    key: 'day',
    label: 'Daily',
  },
  {
    key: 'hour',
    label: 'Hourly',
  },
];

function PageStatistics(props: IProps) {
  const history = useHistory();
  const params: any = useParams();
  const analyticsServiceRef = useRef<any>(null);

  const [pageStatistics, setPageStatistics] = useState<FunnelTotalStats | null>(
    null
  );
  const [tableStats, setTableStats] = useState<DataType[]>([]);
  const [statisticGraphData, setStatisticGraphData] = useState<{
    [category: string]: CategoryGraphData;
  }>({});

  const [loadingSections, setLoadingSections] = useState({
    totalStats: true,
    tableData: true,
    graphicData: true,
  });
  const [interval, setInterval] = useState<{
    key: GraphDataInterval;
    label: string;
  }>(intervalOptions[0]);
  const [comparePreviousPeriod, setComparePreviousPeriod] = useState(
    compareToOptions[0]
  );

  const previousTimeRange = localStorage.getItem('statisticsTimeRange');
  const [statisticPeriodDate, setStatisticPeriodDate] = useState<{
    from: string;
    to: string;
  } | null>(previousTimeRange ? JSON.parse(previousTimeRange) : null);

  const fetchTableData = async (
    time_ranges: {
      from_time: string;
      to_time: string;
    }[]
  ) => {
    const analyticsService: AnalyticsService = analyticsServiceRef.current;
    if (!analyticsService || !props.projectId) return;

    const data = await analyticsService.getTableStats({
      funnel: props.projectId,
      time_ranges,
    });

    const funnelStats: DataType[] = [];
    if (!data || Array.isArray(data)) {
      setTableStats(funnelStats);
      setPageStatistics({ total_leads: 0, total_sales: 0, total_visitors: 0 });
      setLoadingSections((prev) => ({
        ...prev,
        totalStats: false,
        tableData: false,
      }));
      return;
    }

    const pages = props.pages.map((page) => ({
      id: page._id,
      name: page.name,
      image: page.pages[0].image,
    }));

    Object.keys(data)
      .sort(
        (date1, date2) => new Date(date1).getTime() - new Date(date2).getTime()
      )
      .forEach((key, dataIndex) => {
        const timeRangeData = data[key];

        if (
          (dataIndex === 0 && !(comparePreviousPeriod.key === 1)) ||
          (dataIndex === 1 && comparePreviousPeriod.key === 1)
        ) {
          setPageStatistics(timeRangeData.table_stat);
          setLoadingSections((prev) => ({ ...prev, totalStats: false }));
        }

        pages.forEach((page) => {
          const pageData = timeRangeData[page.id];
          const existingPageData = funnelStats.find((f) => f.key === page.id);
          if (existingPageData) {
            existingPageData.viewsAll.push(pageData.VIEW.all);
            existingPageData.viewsUnique.push(pageData.VIEW.unique);
            existingPageData.viewsAvg.push(
              pageData.VIEW.avg === '-' ? '-' : '$' + pageData.VIEW.avg
            );
            existingPageData.leadsTotal.push(pageData.LEAD.total);
            existingPageData.leadsConv.push(pageData.LEAD.conv);
            existingPageData.leadsAvg.push(
              pageData.LEAD.avg === '-' ? '-' : '$' + pageData.LEAD.avg
            );
            existingPageData.salesTotal.push(pageData.SALE.total);
            existingPageData.salesConv.push(pageData.SALE.conv);
            existingPageData.salesAvg.push(
              pageData.SALE.avg === '-' ? '-' : '$' + pageData.SALE.avg
            );
            existingPageData.salesTotalValue.push(
              pageData.SALE.total_value === '-'
                ? '-'
                : '$' + pageData.SALE.total_value
            );
          } else {
            funnelStats.push({
              viewsAll: [pageData.VIEW.all],
              viewsUnique: [pageData.VIEW.unique],
              viewsAvg:
                pageData.VIEW.avg === '-' ? ['-'] : ['$' + pageData.VIEW.avg],
              leadsTotal: [pageData.LEAD.total],
              leadsConv: [pageData.LEAD.conv],
              leadsAvg:
                pageData.LEAD.avg === '-' ? ['-'] : ['$' + pageData.LEAD.avg],
              salesTotal: [pageData.SALE.total],
              salesConv: [pageData.SALE.conv],
              salesAvg:
                pageData.SALE.avg === '-' ? ['-'] : ['$' + pageData.SALE.avg],
              salesTotalValue:
                pageData.SALE.total_value === '-'
                  ? ['-']
                  : ['$' + pageData.SALE.total_value],
              pageName: page.name,
              pageImage: page.image,
              key: page.id,
            });
          }
        });
      });

    const sortedStats: DataType[] = [];
    props.pageOrder.forEach((page) => {
      const pageStat = funnelStats.find((stat) => stat.key === page.id);
      if (pageStat) sortedStats.push(pageStat);
    });
    setTableStats(sortedStats);
    setLoadingSections((prev) => ({ ...prev, tableData: false }));
  };

  const mergeDataObjects = (data: { [key: string]: CategoryData }) => {
    const keys = Object.keys(data).sort();
    const mergedData: { [category: string]: CategoryGraphData } = {};

    Object.keys(data[keys[0]]).forEach((category) => {
      const actualData = data[keys[keys.length - 1]][category];

      const mergedCategoryData: CategoryGraphData = {
        actualStatisticData: {
          data_series: actualData.data_series,
          aggr: actualData.aggr,
          percent_aggr: actualData.percent_aggr,
        },
      };

      if (keys.length > 1) {
        const previousData = data[keys[0]][category];
        mergedCategoryData.previousStatisticData = {
          data_series: previousData.data_series,
          aggr: previousData.aggr,
          percent_aggr: previousData.percent_aggr,
        };
      }

      mergedData[category] = mergedCategoryData;
    });

    return mergedData;
  };

  const fetchGraphData = async (
    time_ranges: {
      from_time: string;
      to_time: string;
    }[]
  ) => {
    const analyticsService: AnalyticsService = analyticsServiceRef.current;
    if (!analyticsService || !props.projectId) return;

    const data = await analyticsService.getGraphStats({
      funnel: props.projectId,
      time_ranges,
      interval: interval.key,
    });
    if (!data) return;

    const formattedData = mergeDataObjects(data);
    setStatisticGraphData(formattedData);
    setLoadingSections((prev) => ({ ...prev, graphicData: false }));
  };

  useEffect(() => {
    if (!statisticPeriodDate) return;

    if (!analyticsServiceRef.current) {
      analyticsServiceRef.current = new AnalyticsService();
    }

    const time_ranges = [
      {
        from_time: statisticPeriodDate.from,
        to_time: statisticPeriodDate.to,
      },
    ];
    if (comparePreviousPeriod.key === 1) {
      const timeDifference =
        new Date(statisticPeriodDate.to).getTime() -
        new Date(statisticPeriodDate.from).getTime();

      time_ranges.push({
        from_time: toLocalISOString(
          new Date(
            new Date(statisticPeriodDate.from).getTime() - timeDifference
          )
        ),
        to_time: statisticPeriodDate.from,
      });
    }

    fetchTableData(time_ranges);
    fetchGraphData(time_ranges);
  }, [statisticPeriodDate, comparePreviousPeriod, interval]);

  const handleBackToFunnelBtn = () => {
    if (params.slug) {
      history.push(`/console/projects/${params.slug}`);
    }
  };

  const handleDateChange = (start: any, end: any) => {
    const date = {
      from: toLocalISOString(start),
      to: toLocalISOString(end),
    };

    localStorage.setItem('statisticsTimeRange', JSON.stringify(date));
    setStatisticPeriodDate(date);
  };

  const handleCompareToSelect = (info: { key: string }) => {
    setComparePreviousPeriod(
      compareToOptions.find((option) => option.key === +info.key)!
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.statisticHeader}>
        <div className={styles.backToFunnelBtn} onClick={handleBackToFunnelBtn}>
          <BackArrowSvg width={16} height={16} /> Back to Funnel
        </div>
        <div className={styles.projectName}>{props.projectName}</div>
      </div>
      <>
        <div className={styles.totalStatsWrapper}>
          {loadingSections.totalStats ? (
            <TotalStatsPlaceholder />
          ) : (
            <div className={styles.textStatisticsInfo}>
              <div className={styles.textStatisticsInfoBlock}>
                <div className={styles.textStatisticsInfoQuantityBlock}>
                  <StatisticsVisitsSvg /> {pageStatistics?.total_visitors}
                </div>
                <div className={styles.textStatisticsInfoBlockLabel}>
                  Total Unique Views
                </div>
              </div>
              <div className={styles.textStatisticsInfoBlock}>
                <div className={styles.textStatisticsInfoQuantityBlock}>
                  <StatisticsUsersSvg /> {pageStatistics?.total_leads}
                </div>
                <div className={styles.textStatisticsInfoBlockLabel}>
                  Total Leads
                </div>
              </div>
              <div className={styles.textStatisticsInfoBlock}>
                <div className={styles.textStatisticsInfoQuantityBlock}>
                  <StatisticsSalesSvg /> {pageStatistics?.total_sales}
                </div>
                <div className={styles.textStatisticsInfoBlockLabel}>
                  Total Sales
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={styles.statsGraphWrapper}>
          {loadingSections.tableData ? (
            <>
              <TableStatsPlaceholder />
              <div style={{ visibility: 'hidden' }}>
                <DatePicker
                  startDate={
                    statisticPeriodDate?.from
                      ? new Date(statisticPeriodDate?.from)
                      : null
                  }
                  endDate={
                    statisticPeriodDate?.to
                      ? new Date(statisticPeriodDate?.to)
                      : null
                  }
                  onChange={handleDateChange}
                  isSmall={false}
                />
              </div>
            </>
          ) : (
            <>
              <div
                className={styles.datePickerBlockAndComparePreviousPeriodBlock}
              >
                <div className={styles.stateHeaderLeftBlock}>
                  <div className={styles.stateHeaderLabel}>
                    <img src={statsDecor} alt="stats decor" />
                    Stats
                  </div>
                  <DatePicker
                    startDate={
                      statisticPeriodDate?.from
                        ? new Date(statisticPeriodDate?.from)
                        : null
                    }
                    endDate={
                      statisticPeriodDate?.to
                        ? new Date(statisticPeriodDate?.to)
                        : null
                    }
                    onChange={handleDateChange}
                    isSmall={false}
                  />
                </div>
                <div className={styles.comparePreviousPeriodBlock}>
                  Compared to
                  <Dropdown
                    menu={{
                      items: compareToOptions,
                      onClick: handleCompareToSelect,
                    }}
                  >
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={(e) => e.preventDefault()}
                    >
                      <Space>
                        {comparePreviousPeriod.label}
                        <DownOutlined />
                      </Space>
                    </div>
                  </Dropdown>
                  <Dropdown
                    menu={{
                      items: intervalOptions,
                      onClick: (info) =>
                        setInterval(
                          intervalOptions.find(
                            (option) => option.key === info.key
                          )!
                        ),
                    }}
                  >
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={(e) => e.preventDefault()}
                    >
                      <Space>
                        {interval.label}
                        <DownOutlined />
                      </Space>
                    </div>
                  </Dropdown>
                </div>
              </div>
              <StatisticsTable data={tableStats} />
            </>
          )}
          <div className={styles.graphsBlockWrapper}>
            {loadingSections.graphicData ? (
              <div>
                <GraphicStatsPlaceholder />
              </div>
            ) : (
              <div className={styles.graphsBlock}>
                <StatisticGraph
                  statisticGraphData={statisticGraphData['visitors']}
                  label="Visitors"
                  isComparePreviousPeriod={comparePreviousPeriod.key === 1}
                  interval={interval.key}
                />
                <StatisticGraph
                  statisticGraphData={statisticGraphData['lead_total']}
                  label="Opt-In Total"
                  isComparePreviousPeriod={comparePreviousPeriod.key === 1}
                  interval={interval.key}
                />
                <StatisticGraph
                  statisticGraphData={statisticGraphData['lead_conv']}
                  label="Opt-in Rate"
                  isComparePreviousPeriod={comparePreviousPeriod.key === 1}
                  interval={interval.key}
                />
                <StatisticGraph
                  statisticGraphData={statisticGraphData['sale_conv']}
                  label="Sales Rate"
                  isComparePreviousPeriod={comparePreviousPeriod.key === 1}
                  interval={interval.key}
                />
                <StatisticGraph
                  statisticGraphData={statisticGraphData['sale_value']}
                  label="Sales Value"
                  isComparePreviousPeriod={comparePreviousPeriod.key === 1}
                  interval={interval.key}
                />
                <StatisticGraph
                  statisticGraphData={statisticGraphData['sale_total']}
                  label="Sales Total"
                  isComparePreviousPeriod={comparePreviousPeriod.key === 1}
                  interval={interval.key}
                />
              </div>
            )}
          </div>
        </div>
      </>
    </div>
  );
}

export default PageStatistics;
