import { useState, useRef, useEffect } from 'react';
import { ReactComponent as CameraIcon } from 'Assets/icons/camera.svg';

import s from './Logo.module.scss';

interface IProps {
  onLogoUpdated: (image: File) => void;
  logoUrl?: string | null;
}

const Logo = (props: IProps) => {
  const [uploadedImage, setUploadedImage] = useState<string | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (props.logoUrl) {
      setUploadedImage(props.logoUrl);
    }
  }, [props.logoUrl]);

  const handleFileUpload = async () => {
    if (!inputRef?.current) {
      return;
    }
    const files = inputRef.current.files;
    if (!files?.length) {
      return;
    }
    let file = files[0];

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = (res) => {
      const content = reader.result;
      const imageSrc = String(content);
      setUploadedImage(imageSrc);
      props.onLogoUpdated(file);
    };
  };

  return (
    <div
      className={s.logoThumbnailWrapper}
      onClick={() => inputRef.current?.click()}
    >
      <input
        type="file"
        style={{ display: 'none' }}
        accept="image/*"
        ref={inputRef}
        onChange={() => handleFileUpload()}
      />
      {uploadedImage ? (
        <>
          <img src={uploadedImage} alt="logo thumbnail" />
          <div className={s.overlay} />
          <CameraIcon fill="#ffffff" className={s.cameraIcon} />
        </>
      ) : (
        <div className={s.imagePlaceholder}>
          <CameraIcon />
          Click to upload agency Image or drag and drop it here
        </div>
      )}
    </div>
  );
};

export default Logo;
