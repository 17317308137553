import { connect } from 'react-redux';
import { RootState } from 'store/rootReducer';
import {
  findItemById,
  updateItem,
} from 'Pages/PageBookleTemplateEdior/Draggable/utils';
import { ReactComponent as ArrowRight } from 'Assets/icons/arrowRight.svg';
import { updateBookleTemplateBlocks } from 'store/books/booksActions';
import { uploadFile } from 'utils/helpers';
import { BookleTemplateBlock } from 'types';
import UrlInput from '../../UrlInput/UrlInput';

import styles from './ImageTab.module.scss';

interface IProps {
  item: BookleTemplateBlock;
  templateBlocks: BookleTemplateBlock[];
  updateBlocks: (payload: BookleTemplateBlock[]) => void;
}

const ImageTab = ({ item, templateBlocks, updateBlocks }: IProps) => {
  const onUpdateItem = (key: string, value: string) => {
    updateItem(key, value, templateBlocks, updateBlocks, item);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      const reader = new FileReader();
      const newArray = [...templateBlocks];
      reader.onloadend = () => {
        const imageItem: BookleTemplateBlock = findItemById(
          newArray,
          item.id as string
        );
        if (imageItem) {
          imageItem.image = reader.result as string;
          updateBlocks(newArray);
        }

        uploadFile(file, (value) => {
          onUpdateItem('image', value as string);
        });

        onUpdateItem('image', reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.urlInput}>
        <UrlInput
          value={item.url}
          onChange={(value) => onUpdateItem('url', value)}
        />
      </div>
      <input
        type="file"
        accept={'image/*'}
        onChange={handleChange}
        id={`image-${item.id}`}
        className={styles.imageInput}
      />
      <div className={styles.buttonContainer}>
        <label htmlFor={`image-${item.id}`} className={styles.imageContent}>
          <div className={styles.button}>
            Upload Image <ArrowRight className={styles.arrowTop} />
          </div>
        </label>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  templateBlocks: state.books.bookleTemplateBlocks,
});

const mapDispatchToProps = {
  updateBlocks: (payload: BookleTemplateBlock[]) =>
    updateBookleTemplateBlocks(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageTab);
