import React, { useState } from 'react';
import { Button } from 'antd';
import QUERIES from 'graphql/queries';
import { graphQlCall } from 'graphql/utils';
import { ReactComponent as TagIcon } from 'Assets/icons/Tag.svg';
import { ReactComponent as CrossIcon } from 'Assets/icons/cross.svg';
import s from '../TagItem/TagItemPopup.module.scss';

interface ITagItemProps {
  setTagAdd: (value: boolean) => void;
  fetchTagCount: () => void;
}
export function TagItemPopup(props: ITagItemProps) {
  const [tagName, setTagName] = useState('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTagName(event.target.value);
  };

  const handleCreateTag = async () => {
    const response = await graphQlCall({
      queryTemplateObject: QUERIES.ADD_CONTACT_TAG,
      headerType: 'USER-AUTH',
      values: {
        tagName: tagName,
      },
    });

    props.fetchTagCount();
    props.setTagAdd(false);
    if (response.ok) {
      const result = await response.json();
      console.log('Tag created:', result.data.addContactTag);
    } else {
      console.error('Error creating tag:', response.statusText);
    }
  };

  const handleClose = () => {
    props.setTagAdd(false);
  };

  return (
    <span className={s.smartCover}>
      <div>
        <div>
          <div>
            <TagIcon />
          </div>
          <div className={s.filterText}>
            <div className={s.filterTopText}>Create a Tag</div>
            <div className={s.filterBottomtext}>based on your tags</div>
          </div>
          <CrossIcon className={s.close} onClick={handleClose} />
        </div>
        <div className={s.hr}></div>
        <p>
          Tags<span>*</span>
        </p>
      </div>
      <div>
        <input
          value={tagName}
          onChange={handleInputChange}
          placeholder="Enter tag name"
          className={s.filterInputTags}
        />
        <div className={s.addedTags}></div>
      </div>
      <div className={s.filterBtns}>
        <Button className={s.whiteBtn} onClick={handleClose}>
          Cancel
        </Button>
        <Button onClick={handleCreateTag} className={s.blackBtn}>
          Create Tag
        </Button>
      </div>
    </span>
  );
}
