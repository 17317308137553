import React from 'react';
import s from './popup.module.scss';
import Modal from 'Components/Common/Modal2/Modal';

interface IProps {
  error: null | string;
  onClose: () => void;
}
function ErrorPopup(props: IProps) {
  return (
    <Modal closeModal={() => props.onClose()} displayModal={!!props.error}>
      <div className={s.domainErrorPopup}>
        <p className={s.error}>{props.error}</p>
      </div>
    </Modal>
  );
}

export default ErrorPopup;
