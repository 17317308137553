import { ReactNode } from 'react';
import { LinkProps, Link as ReactLink } from 'react-router-dom';
import clsx from 'clsx';

import styles from './Link.module.scss';

interface Props extends LinkProps {
  color?: 'primary' | 'secondary';
  theme?: 'dark' | 'light';
  prefixIcon?: ReactNode;
  postfixIcon?: ReactNode;
}

const Link = ({
  color = 'primary',
  theme = 'light',
  className,
  prefixIcon,
  postfixIcon,
  children,
  ...rest
}: Props) => {
  return (
    <ReactLink
      className={clsx(styles.link, styles[color], styles[theme], className)}
      {...rest}
    >
      {prefixIcon}
      {children}
      {postfixIcon}
    </ReactLink>
  );
};

export default Link;
