import { useState, useEffect } from 'react';
import { Formik, Form, ErrorMessage, Field } from 'formik';
import { Tooltip } from 'antd';
import { ReactComponent as LinkIcon } from 'Assets/icons/contextMenu/pageLink.svg';
import clsx from 'clsx';
import * as Yup from 'yup';
import Button from 'UILib/Button/Button';

import s from './LinksControls.module.scss';

interface IProps {
  onChange: (link: string) => void;
  value: string;
}

const LinksControls = (props: IProps) => {
  const [showInput, setShowInput] = useState(false);
  const [currentLink, setCurrentLink] = useState('');

  useEffect(() => {
    setCurrentLink(props.value);
  }, [props.value]);

  const handleAddLink = (link: string) => {
    setShowInput(false);
    setCurrentLink('');
    props.onChange(link);
  };

  const handleOpenInput = () => {
    setShowInput(!showInput);
  };

  const handleDeleteLink = () => {
    handleAddLink('');
  };

  return (
    <div className={s.container}>
      <Tooltip>
        <div
          className={clsx(s.previewButton, { [s.activeButton]: !!props.value })}
          onClick={handleOpenInput}
        >
          <LinkIcon />
        </div>
      </Tooltip>
      <div
        className={s.messageBody}
        style={{ visibility: showInput ? 'visible' : 'hidden' }}
      >
        <Formik
          enableReinitialize={true}
          initialValues={{ link: currentLink }}
          validationSchema={Yup.object({
            link: Yup.string()
              .required('Link is required')
              .matches(
                /^(?:(?:https?|ftp):\/\/)?(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))\.?(:\d{2,5})?(?:[\/?#]\S*)?$/,
                'Please, enter correct link'
              ),
          })}
          onSubmit={(values, { setSubmitting }) => {
            console.log('values', values);
            setCurrentLink(values.link);
            handleAddLink(values.link);
            setSubmitting(false);
          }}
        >
          <Form className={s.form}>
            <div className={s.field}>
              <Field
                autoComplete={0}
                name={'link'}
                placeholder={'Enter Link'}
                type={'text'}
              />
              <p className={s.error}>
                <ErrorMessage name="link" />
              </p>
            </div>
            <div className={s.buttons}>
              <Button type="submit" width={70} height={30}>
                Add
              </Button>
              <Button width={70} height={30} onClick={handleDeleteLink}>
                Delete
              </Button>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default LinksControls;
