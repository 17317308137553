import BookAIImageRerollAction from '../BookAIImageRerollAction/BookAIImageReroll';

import styles from './CoverActions.module.scss';

interface IProps {
  cover: any;
  onChange: (key: string, value: string) => void;
}

const CoverActions = ({ cover, onChange }: IProps) => {
  return (
    <div className={styles.container}>
      <div>
        <BookAIImageRerollAction
          initialImageUrl={cover?.image}
          onChange={(value) => onChange('image', value)}
        />
        <div />
        <div />
      </div>
      <div />
    </div>
  );
};

export default CoverActions;
