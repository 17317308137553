import Input from 'UILib/Input/Input';
import { ReactComponent as LinkIcon } from 'Assets/icons/contextMenu/pageLink.svg';

import styles from './UrlInput.module.scss';

interface IProps {
  onChange: (value: string) => void;
  value?: string;
}

const UrlInput = ({ value, onChange }: IProps) => {
  return (
    <Input
      className={styles.urlInput}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder="https://"
      theme="dark"
      prefixIcon={<LinkIcon />}
      type="url"
    />
  );
};

export default UrlInput;
