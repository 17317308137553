export const GET_PRODUCT = {
  operationName: 'getProduct',
  query: `query getProduct (\
        $id: String!,
        ){
        getProduct(
            id: $id,
        ){
          _id
          name
          description
          currency
          price
          createdAt
          updatedAt
      }
    }
    `,
};
