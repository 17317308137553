import { useEffect, useState } from 'react';
import { graphQlCall } from 'graphql/utils';
import { ReactComponent as Plus } from 'Assets/icons/plusAdd.svg';
import { IPrice, IProduct } from '../constants';
import queries from 'graphql/queries';
import Button from 'UILib/Button/Button';
import AddProductPopup from './AddProductPopup/AddProductPopup';
import ProductItem from './ProductItem/ProductItem';

import styles from './Products.module.scss';

const ProductsTab = () => {
  const [
    isAddProductModalVisible,
    setIsProductModalVisible,
  ] = useState<boolean>(false);
  const [selectedPrice, setSelectedPrice] = useState<{
    index: number;
    item: IPrice;
  }>();
  const [product, setProduct] = useState<IProduct>();
  const [hasProduct, setHasProduct] = useState<boolean>(false);

  const fetchAutoSassProducts = async () => {
    try {
      const response = await graphQlCall({
        queryTemplateObject: queries.GET_MY_SASS_PLAN_PRODUCTS,
        headerType: 'USER-AUTH',
      });
      setProduct(response?.products[0]);
      setHasProduct(!!response?.products?.length);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  useEffect(() => {
    fetchAutoSassProducts();
  }, []);

  const onCloseProductModal = () => {
    setIsProductModalVisible(false);
    setSelectedPrice(undefined);
  };

  return (
    <>
      <AddProductPopup
        isVisible={isAddProductModalVisible}
        onClose={onCloseProductModal}
        item={selectedPrice}
        hasProduct={hasProduct}
        onUpdateData={(item) => setProduct(item)}
      />
      <div className={styles.container}>
        <div className={styles.titleContainer}>
          <div className={styles.titleText}>
            <div className={styles.title}>Products</div>
            <div className={styles.subtitle}>
              Customize your plans to share with audience
            </div>
          </div>
          <Button
            width={220}
            height={40}
            name="Add Price"
            postfixIcon={<Plus />}
            className={styles.addButton}
            onClick={() => setIsProductModalVisible(true)}
          >
            Add Price
          </Button>
        </div>
        <div className={styles.products}>
          {product?.prices.map((price, index) => (
            <ProductItem
              key={index}
              price={price}
              handleClick={(item) => {
                setSelectedPrice({ index, item });
                setIsProductModalVisible(true);
              }}
              index={index}
              onUpdateData={(item) => setProduct(item)}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default ProductsTab;
