import {
  ACTIVE_PROJECT,
  ARCHIVE_PROJECT,
  UPDATE_PROJECT,
  LOADING,
  DRAW_FETCHED,
  PROJECTS_NAME_FETCHED,
  PROJECTS_NAMES_LOADING,
  PROJECT_ADD,
  ACTIVE_PROJECT_DATA,
  PROJECT_NAME_CHANGE,
  PROJECT_DATA_LOADING,
  PROJECT_DUPLICATE,
  PROJECT_DUPLICATE_LOADING,
  PROJECTS_WITH_PAGINATION_FETCHED,
} from './projectsActions';
import { PayloadAction } from '@reduxjs/toolkit';
import { IGeneralProject, IDraw, IGeneralProjectName } from 'types';

export interface IProjectsState {
  items: IGeneralProject[];
  funnels: {
    total: number;
    funnels: IGeneralProject[];
  };
  active: null | IGeneralProject;
  loading: boolean;
  draw: null | IDraw;
  statisticGraphData: any;
  projectsName: IGeneralProjectName[];
  loadingNames: boolean;
  loadingProjectData: boolean;
  loadingProjectDuplicateData: boolean;

  activeProjectId: string | null;
}

const initialState: IProjectsState = {
  items: [],
  funnels: {
    total: 0,
    funnels: [],
  },
  active: null,
  loading: false,
  draw: null,
  statisticGraphData: [],
  projectsName: [],
  loadingNames: false,
  loadingProjectData: true,
  loadingProjectDuplicateData: false,
  activeProjectId: null,
};

const projectsReducer = (
  state = initialState,
  action: PayloadAction<
    IGeneralProject[] | string | IGeneralProject | boolean | IDraw | any
  >
): IProjectsState => {
  switch (action.type) {
    case PROJECTS_NAME_FETCHED:
      const projectsName = action.payload as IGeneralProjectName[];
      return {
        ...state,
        projectsName: projectsName,
        loadingNames: false,
      };

    case PROJECT_ADD:
      const newProject = action.payload as IGeneralProjectName;
      return {
        ...state,
        projectsName: [...state.projectsName, newProject],
        activeProjectId: newProject._id,
      };
    case PROJECT_DUPLICATE:
      const duplicateProject = action.payload as IGeneralProjectName;
      return {
        ...state,
        projectsName: [...state.projectsName, duplicateProject],
      };

    case ACTIVE_PROJECT:
      const activeId = action.payload as string;

      return {
        ...state,
        activeProjectId: activeId,
      };

    case ACTIVE_PROJECT_DATA:
      const activeProjectData = action.payload as any;
      return {
        ...state,
        active: activeProjectData,
      };

    case PROJECTS_WITH_PAGINATION_FETCHED:
      const funnels = action.payload as any;
      return {
        ...state,
        funnels: funnels,
      };

    case ARCHIVE_PROJECT:
      return {
        ...state,
        funnels: {
          total: state.funnels.total - 1,
          funnels: state.funnels.funnels.filter(
            (element) => element._id !== action.payload
          ),
        },
        projectsName: state.projectsName.filter(
          (element) => element._id !== action.payload
        ),
      };

    case PROJECT_NAME_CHANGE:
      const newProjectsName = state.projectsName.map(
        (el: IGeneralProjectName) => {
          if (el._id === action.payload.id) {
            return { ...el, name: action.payload.newName };
          } else {
            return el;
          }
        }
      );
      let newActiveProject = null;
      if (state.activeProjectId === action.payload.id && state.active) {
        newActiveProject = { ...state.active, name: action.payload.newName };
      }
      if (newActiveProject) {
        return {
          ...state,
          projectsName: newProjectsName,
          active: newActiveProject,
        };
      } else {
        return {
          ...state,
          projectsName: newProjectsName,
        };
      }

    case UPDATE_PROJECT:
      const updatingProject = action.payload as IGeneralProject;
      return {
        ...state,
        funnels: {
          ...state.funnels,
          funnels: state.funnels.funnels.map((item) =>
            item._id === updatingProject._id
              ? { ...item, ...updatingProject }
              : item
          ),
        },
        active: updatingProject,
      };

    case LOADING:
      const loading = action.payload as boolean;
      return {
        ...state,
        loading,
      };

    case PROJECTS_NAMES_LOADING:
      const loadingNames = action.payload as boolean;
      return {
        ...state,
        loadingNames,
      };
    case PROJECT_DATA_LOADING:
      const loadingProjectData = action.payload as boolean;
      return {
        ...state,
        loadingProjectData,
      };
    case PROJECT_DUPLICATE_LOADING:
      const loadingProjectDuplicateData = action.payload as boolean;
      return {
        ...state,
        loadingProjectDuplicateData,
      };
    case DRAW_FETCHED:
      const draw = action.payload as IDraw;
      return {
        ...state,
        draw,
      };

    default:
      return state;
  }
};

export { initialState, projectsReducer };
