import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import s from './ProjectSettings.module.scss';

import { UseOnClickOutside } from '../../../utils/UseOnClickOutside';
import { queueUpdateProject } from '../../../store/projects/projectsQueueActions';
import Tabs from '../../Common/Tabs/Tabs';
import TabTitle from '../../Common/Tabs/Tab/Tab';
import GeneralTab from './GeneralTab/GeneralTab';
import IntegrationsTab from './IntegrationsTab/IntegrationsTab';
import ProductsTab from './ProductsTab/ProductsTab';
import Modal from '../../Common/Modal/Modal';
import { setProjectName } from 'store/projects/projectsActions';

const ProjectSettings = (props) => {
  const modalRef = useRef();

  const [name, setName] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [productsInfoList, setProductsInfoList] = useState([
    {
      name: '',
      descriptor: '',
      price: 100,
      currency: 'USD',
    },
  ]); // don't set in useEffect and handleSave

  const [headerScript, setHeaderScript] = useState();
  const [footerScript, setFooterScript] = useState();
  const [customSubDomain, setCustomDomain] = useState();

  useEffect(() => {
    if (props.project) {
      setTitle(props.project.title);
      setDescription(props.project.description);
      setName(props.project.name);
      setHeaderScript(props.project.headerScript);
      setFooterScript(props.project.footerScript);
      setProductsInfoList(props.project.products);
      setCustomDomain(props.project.customSubDomain);
    }
  }, [props.project]);

  UseOnClickOutside(modalRef, () => (props.show ? props.onClose() : null));

  const handleSave = (newName) => {
    props.updateProject(
      {
        name: newName ? newName : name,
        description,
        title,
        headerScript,
        footerScript,
        customSubDomain,
        products: productsInfoList,
      },
      props.project._id
    );
    props.setProjectNameAction(props.project._id, newName ? newName : name);
  };

  return (
    <div>
      <Modal displayModal={props.show}>
        <div className={s.modalBlock} ref={modalRef}>
          <div className={s.modalHeader}>
            <button className={s.closeIcon} onClick={() => props.onClose()} />
            <h1 className={s.modalTitle}>Funnel Settings</h1>
          </div>
          <div className={s.modalBody}>
            {props.project && (
              <div>
                <div className={s.tabs}>
                  <Tabs>
                    <TabTitle title="General">
                      <GeneralTab
                        handleSave={handleSave}
                        name={name}
                        setName={setName}
                        title={title}
                        setTitle={setTitle}
                        description={description}
                        setDescription={setDescription}
                        customSubDomain={customSubDomain}
                        setSubDomain={setCustomDomain}
                        setProjectNameAction={props.setProjectNameAction}
                        onClose={props.onClose}
                        id={props.project._id}
                        favicon={props.project.favicon}
                      />
                    </TabTitle>
                    <TabTitle title="Products">
                      <ProductsTab
                        handleSave={handleSave}
                        productsInfoList={productsInfoList}
                        setProductsInfoList={setProductsInfoList}
                        onClose={props.onClose}
                      />
                    </TabTitle>
                    <TabTitle title="Integrations">
                      <IntegrationsTab
                        handleSave={handleSave}
                        headerScript={headerScript}
                        setHeaderScript={setHeaderScript}
                        footerScript={footerScript}
                        setFooterScript={setFooterScript}
                        onClose={props.onClose}
                      />
                    </TabTitle>
                  </Tabs>
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state.projects.loading,
});

const mapDispatchToProps = (dispatch) => ({
  updateProject: (fields, id) => dispatch(queueUpdateProject(fields, id)),
  setProjectNameAction: (id, newName) => dispatch(setProjectName(id, newName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSettings);
