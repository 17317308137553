export const UPDATE_AI_TEXT_REQUEST = {
  operationName: 'updateAiTextRequest',
  query: `mutation updateAiTextRequest(
      $id: String!
      $hasLiked: Float!
    ) {
      updateAiTextRequest(
        id: $id
        hasLiked: $hasLiked
      ) {
        _id
        request
        requestType
        response
        hasLiked
        createdAt
        updatedAt
    }
  }`,
};
