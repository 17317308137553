export const CREATE_MAIL_DRAFT_MUTATION = {
  operationName: 'createMailDraft',
  query: `mutation createMailDraft($html: String!, $text: String!, $name: String!, $data: String!, $subject: String!) {
              createMailDraft(html: $html, text: $text, name: $name, data: $data, subject: $subject) {
                _id
                html
                text
                name
                data
                createdAt
                updatedAt 
              }
            }`,
};
