export const PUSH_DATA_TO_GENERATION_TASK_MUTATION = {
  operationName: 'pushDataToGenerationTask',
  query: `mutation pushDataToGenerationTask(
            $taskId: String!
            $data: String!
            $path: String!
          ) {
            pushDataToGenerationTask(
              taskId: $taskId
              data: $data
              path: $path
            ) {
                message           
            }
          }`,
};
