import React, { useState } from 'react';
import Modal from 'Components/Common/Modal2/Modal';
import Spinner from 'Components/Common/Spinner/Spinner';
import { graphQlCall } from 'graphql/utils';
import QUERIES from 'graphql/queries';
import s from './popups.module.scss';

interface IProps {
  onClose: () => void;
  token: string;
}

const ResentTokenPopup = (props: IProps) => {
  const [email, setEmail] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    props.onClose();
  };

  const regenerateNewToken = async () => {
    setLoading(true);

    try {
      const result = await graphQlCall({
        queryTemplateObject: QUERIES.REGENERATE_SIGNUP_TOKEN,
        values: {
          token: props.token,
        },
      });
      const newEmail = result.email;
      setEmail(newEmail);
    } catch (e) {
      console.error(e);
    }

    setLoading(false);
  };

  return (
    <Modal displayModal={true} closeModal={() => handleClose()}>
      <div className={s.modal}>
        {!loading && !email && (
          <div>
            <h2 className={s.header}>This link is expired.</h2>
            <p className={s.infoText}>Don't worry we can send you a new one.</p>
            <button className={s.button} onClick={() => regenerateNewToken()}>
              Send new link
            </button>
          </div>
        )}
        {loading && (
          <div className={s.spinnerBlock}>
            <Spinner size={100} />
          </div>
        )}
        {email && (
          <div className={s.infoText}>
            <span>
              new link sent to:
              <br /> <b>{email}</b>
            </span>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ResentTokenPopup;
