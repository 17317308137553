export const UPDATE_BOOK_MUTATION = {
  operationName: 'updateBook',
  query: `mutation updateBook(
        <KEYS>
      ) {
        updateBook(
          <VALUES>
        ) {
          _id
          title
          description
          tone
          chapters{
            title
            text
            comments
          }
          audience
          structure
          coverImageUrl
          size
        }
      }`,
};
