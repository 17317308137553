export const CLONE_BROADCAST = {
  operationName: 'cloneBroadcast',
  query: `mutation cloneBroadcast(
      $id: String!
    ) {
      cloneBroadcast(
        id: $id
      ) {
            _id
            name
            workflow { _id }
            sender { _id }
            mailDraft { _id }
            sendAt
            nextSendAt
            status
            screenshot
            createdAt
            updatedAt
      }
    }`,
};
