import { BookleTemplateBlock } from 'types';

import styles from './DividerBlock.module.scss';

const DividerBlock = ({ item }: { item: BookleTemplateBlock }) => {
  return (
    <div
      className={styles.container}
      style={{
        ...(item.hasColor ? { backgroundColor: item.color } : {}),
        ...(item.hasSpacing
          ? {
              paddingBottom: item.styles?.blockSpacing?.paddingBottom,
              paddingTop: item.styles?.blockSpacing?.paddingTop,
              paddingLeft: item.styles?.blockSpacing?.paddingLeft,
              paddingRight: item.styles?.blockSpacing?.paddingRight,
            }
          : {}),
      }}
    >
      <div
        className={styles.divider}
        style={{
          ...{
            backgroundColor: item.isSpacer
              ? 'transparent'
              : item.styles?.backgroundColor,
          },
          ...(item.styles?.height ? { height: item.styles?.height } : {}),
        }}
      />
    </div>
  );
};

export default DividerBlock;
