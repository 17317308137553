export const DUPLICATE_FUNNEL_TO_OTHER_ACCOUNT = {
  operationName: 'duplicateFunnelToOtherAccount',
  query: `mutation duplicateFunnelToOtherAccount(
      $targetAccountOwner: String!
      $sourceAccountOwner: String!
      $funnelId: String!
    ) {
      duplicateFunnelToOtherAccount(
        targetAccountOwner: $targetAccountOwner
        sourceAccountOwner: $sourceAccountOwner
        funnelId: $funnelId
      ) {
        funnel {
          _id
        }
      }
    }`,
};
