export const TRANSFER_TO_SEPARATE_ACCOUNT = {
  operationName: 'transferToSeparate',
  query: `mutation transferToSeparate(
      $sub: String!
      $email: String!
    ) {
      transferToSeparate(
        sub: $sub
        email: $email
      ) {
        message
      }
    }`,
};
