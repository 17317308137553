export const UPDATE_EPISODE_SECTION_MUTATION = {
  operationName: 'updateEpisodeSection',
  query: `
        mutation updateEpisodeSection(
            $sectionId: String!
            $episodeId: String!
            $notes: String
            $text: String
            $title: String
        ) {
          updateEpisodeSection(
            sectionId: $sectionId
            episodeId: $episodeId
            notes: $notes
            text: $text
            title: $title
          ) {
            _id
            name
            tone
            voice
            prompt
            text
            audioUrl
            coverData
            image
            sections {
                title
                text
                notes
                state
            }
            music {
                id
                introUrl
                outroUrl
                transitionUrl
                introStart
                transitionStart
                transitionEnd
                outroEnd
            }
            createdAt
            updatedAt
          }
        }
      `,
};
