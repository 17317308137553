export const DELETE_AI_TEMPLATE = {
  operationName: 'deleteAiTemplate',
  query: `mutation deleteAiTemplate(
      $id: String!
    ) {
      deleteAiTemplate(
        id: $id
      ) {
        message
    }
  }`,
};
