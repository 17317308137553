import { pushToQueue, SYNC_ACTIONS } from '../sync/syncActions';
import { UPDATE_PROJECT, ARCHIVE_PROJECT } from './projectsActions';
import { loading, activeProject } from './projectsApiActions';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../rootReducer';
import { Action } from 'redux';
import { IOrder } from 'types';

type DispatchType = ThunkDispatch<RootState, void, Action>;
type GetStateType = () => RootState;

export const queueFetchProjects = (projectId: string) => (
  dispatch: DispatchType
) => {
  const action = {
    action: {
      type: SYNC_ACTIONS.FETCH_PAGES,
      params: {
        projectId,
      },
    },
  };
  dispatch(pushToQueue(action));
  dispatch(loading(true));
};

export const queueSetPagesOrder = (projectId: string, order: IOrder[]) => (
  dispatch: DispatchType
) => {
  const action = {
    action: {
      type: SYNC_ACTIONS.SET_PAGES_ORDER,
      params: {
        projectId,
        order,
      },
    },
  };
  dispatch(pushToQueue(action));
};

export const queueEditName = (newName: string) => (
  dispatch: DispatchType,
  getState: GetStateType
) => {
  const state = getState();
  const activeProject = { ...state.projects.active };
  activeProject.name = newName;
  dispatch({
    type: UPDATE_PROJECT,
    payload: activeProject,
  });

  const action = {
    action: {
      type: SYNC_ACTIONS.RENAME_PROJECT,
      params: {
        newName,
        projectId: activeProject._id,
      },
    },
  };
  dispatch(pushToQueue(action));
};

export const queueArchiveProject = (id?: string) => (
  dispatch: DispatchType,
  getState: GetStateType
) => {
  const state = getState();
  const activeProjectId = id ?? state.projects.active?._id;
  if (!activeProject) {
    return;
  }
  dispatch({
    type: ARCHIVE_PROJECT,
    payload: activeProjectId,
  });
  let projectsName = state.projects.projectsName;
  projectsName = projectsName.filter(
    (project) => project._id !== activeProjectId
  );
  let active: undefined | string;
  if (projectsName.length > 0) {
    active = projectsName[0]._id;
  }
  if (active) {
    dispatch(activeProject(active));
  }

  const action = {
    action: {
      type: SYNC_ACTIONS.ARCHIVE_PROJECT,
      params: {
        projectId: activeProjectId,
      },
    },
  };
  dispatch(pushToQueue(action));
};

export const queueArchiveFunnel = (funnelId: string) => (
  dispatch: DispatchType,
  getState: GetStateType
) => {
  const state = getState();
  const activeProject = { ...state.projects.active };
  if (!activeProject || !activeProject.pages) {
    return;
  }
  activeProject.pages = activeProject.pages.filter(
    (page) => page._id !== funnelId
  );
  if (activeProject.order) {
    activeProject.order = activeProject.order.filter(
      (page) => page.id !== funnelId
    );
  }
  dispatch({
    type: UPDATE_PROJECT,
    payload: activeProject,
  });

  const action = {
    action: {
      type: SYNC_ACTIONS.ARCHIVE_FUNNEL,
      params: {
        funnelId,
      },
    },
  };
  dispatch(pushToQueue(action));
};

// export const queueSetDomain = (domain: string) => (dispatch: DispatchType, getState: GetStateType) => {
//   const state = getState();
//   const activeProject = { ...state.projects.active };
//   activeProject.domain = domain;
//   dispatch({
//     type: UPDATE_PROJECT,
//     payload: activeProject,
//   });

//   const action = {
//     action: {
//       type: SYNC_ACTIONS.PROJECT_DOMAIN,
//       params: {
//         projectId: activeProject._id,
//         domain,
//       }
//     }
//   }
//   dispatch(pushToQueue(action));
// };

export const queueUpdateProject = (fields: any, id?: string) => (
  dispatch: DispatchType,
  getState: GetStateType
) => {
  const state = getState();
  let activeProject = { ...state.projects.active };

  activeProject = { ...activeProject, ...fields, ...(id && { _id: id }) };

  dispatch({
    type: UPDATE_PROJECT,
    payload: activeProject,
  });

  const action = {
    action: {
      type: SYNC_ACTIONS.UPDATE_PROJECT,
      params: {
        projectId: id ?? activeProject._id,
        fields,
      },
    },
  };
  dispatch(pushToQueue(action));
};

export const queueUpdatePage = (fields: any, funnelId: string) => (
  dispatch: DispatchType,
  getState: GetStateType
) => {
  const state = getState();
  let activeProject = { ...state.projects.active };

  if (!activeProject || !activeProject.pages) {
    return;
  }

  activeProject.pages = activeProject.pages.map((page) => {
    if (page._id === funnelId) {
      page = {
        ...page,
        ...fields,
      };
    }
    return page;
  });

  dispatch({
    type: UPDATE_PROJECT,
    payload: activeProject,
  });

  const action = {
    action: {
      type: SYNC_ACTIONS.UPDATE_FUNNEL,
      params: {
        funnelId,
        fields,
      },
    },
  };
  dispatch(pushToQueue(action));
};

// export const queueDeleteSubPage = (funnelId: string, subpageName: string) => (dispatch: DispatchType, getState: GetStateType) => {
//   const state = getState();
//   let activeProject = { ...state.projects.active };
//   if (!activeProject?.pages) {
//     return;
//   }
//   activeProject.pages = activeProject.pages.map(page => {
//     if (page._id === funnelId) {
//       page.pages = page.pages.filter(subPage => subPage.name !== subpageName);
//     }
//     return page;
//   });
//   dispatch({
//     type: UPDATE_PROJECT,
//     payload: activeProject
//   });

//   const action = {
//     action: {
//       type: SYNC_ACTIONS.DELETE_SUBPAGE,
//       params: {
//         funnelId,
//         subpageName,
//       }
//     }
//   }
//   dispatch(pushToQueue(action));
// }
