import React, { FC, useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import styles from './dropdownBox.module.scss';
import clsx from 'clsx';

const dropdownBoxRootElement = document.querySelector('#dropdownBox');

interface DropdownBoxProps {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  componentRef: any;
  pointerEvents: boolean;
  shift?: string;
}

const DropdownBox: FC<DropdownBoxProps> = ({
  children,
  isOpen,
  onClose,
  componentRef,
  pointerEvents,
  shift,
}) => {
  const element = useMemo(() => document.createElement('div'), []);
  element.classList.add(styles.dropdownBoxZIndexWrapper);
  const dropdownBoxPosition = {
    x: 0,
    y: 0,
  };

  if (null !== componentRef.current) {
    const blockPosition = componentRef.current.getBoundingClientRect();
    dropdownBoxPosition.x = blockPosition.right;
    dropdownBoxPosition.y = blockPosition.bottom;
  }

  useEffect(() => {
    if (isOpen) {
      dropdownBoxRootElement?.appendChild(element);

      return () => {
        dropdownBoxRootElement?.removeChild(element);
      };
    }
  });

  const pointerEventsStyle = {} as any;
  if (!pointerEvents) {
    pointerEventsStyle.pointerEvents = 'none';
  }

  const shiftStyle = {} as any;
  shiftStyle.left = dropdownBoxPosition.x;
  shiftStyle.top = dropdownBoxPosition.y;
  if (shift) {
    shiftStyle.transform = shift;
  }
  if (isOpen) {
    return createPortal(
      <div
        className={clsx(styles.dropdownBoxWrapper, {
          [styles.dropdownBoxActive]: isOpen,
        })}
        onClick={onClose}
        style={pointerEventsStyle}
      >
        <div
          className={styles.dropdownBoxCard}
          onClick={(e) => e.stopPropagation()}
          style={shiftStyle}
        >
          {children}
        </div>
      </div>,
      element
    );
  }

  return null;
};

export default DropdownBox;
