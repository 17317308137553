import { ReactComponent as EmailIcon } from '../icons/mail.svg';
import { ReactComponent as PersonIcon } from '../icons/createNew.svg';

import s from './popups.module.scss';

interface IProps {
  onChange: (value: string) => void;
  value: string;
  type: 'email' | 'name' | 'workspace';
  placeholder?: string;
  error?: boolean;
}

const Input = (props: IProps) => {
  let placeholder = '';
  switch (props.type) {
    case 'email':
      placeholder = 'Email';
      break;
    case 'name':
      placeholder = 'Name';
      break;
    case 'workspace':
      placeholder = 'Workspace (optional)';
      break;
    default:
      break;
  }
  if (props.placeholder) {
    placeholder = props.placeholder;
  }

  return (
    <div className={s.inputBlock}>
      {props.type === 'name' ? (
        <PersonIcon className={s.icon} />
      ) : (
        <EmailIcon className={s.icon} fill="#a0a0a0" />
      )}
      <input
        className={props.error ? s.error : ''}
        type="text"
        value={props.value}
        onChange={(e) => props.onChange(e.target.value)}
        placeholder={placeholder}
      />
    </div>
  );
};

export default Input;
