export const CREATE_SMART_LIST = {
  operationName: 'createSmartList',
  query: `mutation createSmartList(
    $exact: [String!]
    $name: String!
    $includeAll: [String!]
    $includeAny: [String!]
    $excludeAll: [String!]
    $maxContactAge: Float
    ) {
    createSmartList(
    name: $name
    exact: $exact
    includeAll: $includeAll
    includeAny: $includeAny
    excludeAll: $excludeAll
    maxContactAge: $maxContactAge
    ) {
      _id
      name
      exact {
        _id
        name
      }
      includeAll {
        _id
        name
      }
      includeAny {
        _id
        name
      }
      excludeAll {
        _id
        name
      }
      maxContactAge
      createdAt
    }
  }`,
};
