export const GET_USER_CONTACT_TAGS_WITH_COUNT = {
  operationName: 'getUserContactTagsWithCount',
  query: `query getUserContactTagsWithCount {
        getUserContactTagsWithCount {
            tagList {
                tag {
                    _id
                    name
                    createdAt
                }
                count
            }
        }
    }`,
};
