import { useState } from 'react';
import { Dropdown } from 'antd';
import { MenuInfo } from 'rc-menu/lib/interface';
import { graphQlCall } from 'graphql/utils';
import { IGenerationTemplate } from 'types';
import { ReactComponent as DeleteIcon } from 'Assets/icons/book/x.svg';
import clsx from 'clsx';
import queries from 'graphql/queries';
import DotsIcon from 'Assets/icons/book/dots.svg';
import ConfirmArchivationPopup from 'Components/Domains/ConfirmArchivationPopup';
import styles from './GenerationTemplateCard.module.scss';

interface IProps {
  item: IGenerationTemplate;
  onClick?: (senderId: string) => void;
  onDelete: () => void;
}

enum MenuItems {
  Delete,
}

const GenerationTemplateCard = ({ item, onClick, onDelete }: IProps) => {
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState<boolean>(false);

  const handleMenuOpen = (open: boolean) => {
    setOpenMenu(open);
  };

  const handleDelete = async () => {
    const response = await graphQlCall({
      queryTemplateObject: queries.DELETE_GENERATION_TEMPLATE_MUTATION,
      headerType: 'USER-AUTH',
      values: {
        id: item._id,
      },
    });

    if ((response.message = 'Success')) {
      setIsDeletePopupOpen(false);
      onDelete();
    }
  };

  const handleMenuClick = (e: MenuInfo) => {
    setOpenMenu(false);
    e.domEvent.stopPropagation();
    switch (+e.key) {
      case MenuItems.Delete: {
        setIsDeletePopupOpen(true);
        break;
      }
    }
  };

  const menuItems = [
    {
      label: 'Delete',
      key: MenuItems.Delete,
      icon: <DeleteIcon />,
    },
  ];

  const menuProps = {
    items: menuItems,
    onClick: handleMenuClick,
  };

  return (
    <>
      <ConfirmArchivationPopup
        open={isDeletePopupOpen}
        description="Are you sure you want to delete this email template?"
        onDonePressed={handleDelete}
        onClose={() => setIsDeletePopupOpen(false)}
      />
      <div
        className={clsx(styles.container, { [styles.openedMenu]: openMenu })}
        onClick={() => {
          if (onClick) onClick(item._id);
        }}
      >
        <Dropdown
          menu={menuProps}
          trigger={['click']}
          overlayClassName={'custom'}
          onOpenChange={handleMenuOpen}
        >
          <div
            className={styles.dots}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <img src={DotsIcon} alt="..." />
          </div>
        </Dropdown>
        <div className={styles.thumbnail}>
          {item.thumbnail ? (
            <img
              className={styles.thumbnailImage}
              src={item.thumbnail}
              alt={item.name}
            />
          ) : (
            <div className={styles.thumbnailPlaceholder}></div>
          )}
        </div>

        <div className={styles.label}>{item.name}</div>
      </div>
    </>
  );
};

export default GenerationTemplateCard;
