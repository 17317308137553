import React, { useState, useRef, useEffect } from 'react';
import { ReactComponent as CloseSvg } from '../../Assets/icons/close.svg';
import { ReactComponent as ClockSvg } from '../../Assets/icons/clockNotFill.svg';
import { ReactComponent as RefreshDomainSvg } from '../../Assets/icons/refreshDomain.svg';
import { ReactComponent as DomainSelectFunnelArrowSvg } from '../../Assets/icons/domainSelectFunnelArrow.svg';
import { ReactComponent as CheckMarkSvg } from '../../Assets/icons/checkMark.svg';

import s from './domainItem.module.scss';
import DropdownBox from 'Components/Common/dropdownBox/DropdownBox';
import DeleteDomainPopup from './DeleteDomainPopup';
import { RootState } from 'store/rootStore';
import { connect } from 'react-redux';
import {
  deleteDomain,
  selectFunnel,
  refreshDomain,
} from 'store/domains/domainsActions';
import { IGeneralProjectName } from 'types';
import DomainButton from 'Components/Common/DomainButton/DomainButton';

interface IProps {
  name: string;
  status: string;
  userId: string;
  id: string;
  projectId?: string;
  deleteDomain: (id: string) => void;
  funnels: IGeneralProjectName[];
  selectFunnel: (id: string, projectId: string) => void;
  refreshDomain: (id: string) => void;
  isLoading?: boolean;
}
interface IOption {
  value: string;
  label: string;
}
const DomainItem = (props: IProps) => {
  const [isPointsBlockOpen, setIsPointsBlockOpen] = useState(false);
  const [isSelectFunnelOpen, setIsSelectFunnelOpen] = useState(false);
  const [deleteDomainPopupOpened, setDeleteDomainPopupOpened] = useState(false);
  const [dropdownHeight, setDropdownHeight] = useState(400);

  const pointsBlock = useRef(null);
  const selectFunnelBlock = useRef<HTMLDivElement | null>(null);
  const handleRemoveDomain = () => {
    props.deleteDomain(props.id);
  };
  const [options, setOptions] = useState<IOption[]>([]);

  const [selectedOption, setSelectedOption] = useState<IOption | undefined>(
    undefined
  );

  useEffect(() => {
    const newOptions = props.funnels.map((funnel) => ({
      value: funnel._id,
      label: funnel.name,
    }));
    setOptions(newOptions);

    props.funnels.forEach((funnel) => {
      if (funnel._id === props.projectId) {
        setSelectedOption({ value: funnel._id, label: funnel.name });
      }
    });
  }, [props.funnels, props.projectId]);

  useEffect(() => {
    if (selectFunnelBlock && selectFunnelBlock.current) {
      const elementRect = selectFunnelBlock.current.getBoundingClientRect();
      const distanceToBottom = window.innerHeight - elementRect.top;
      setDropdownHeight(distanceToBottom - 100);
    }
  }, [selectFunnelBlock]);

  const handleSelectFunnel = (value: IOption) => {
    setSelectedOption(value);
    props.selectFunnel(props.id, value.value);
  };
  return (
    <>
      <div className={s.domainItemBlock}>
        <div className={s.domainItemBlockName}>{props.name}</div>
        <div
          className={
            props.status === 'Waiting CNAME'
              ? s.domainItemBlockStatus
              : s.domainItemBlockStatusSuccess
          }
        >
          {props.status === 'Waiting CNAME' && <ClockSvg />}
          {props.status}
        </div>
        <div
          className={s.domainItemBlockFunnel}
          ref={selectFunnelBlock}
          onClick={() => {
            if (!isSelectFunnelOpen) {
              setIsSelectFunnelOpen(true);
            }
          }}
        >
          <div className={s.selectedOption}>
            {selectedOption?.label ? selectedOption.label : 'Select funnel'}{' '}
            <DomainSelectFunnelArrowSvg
              className={s.domainSelectFunnelArrowSvg}
            />
          </div>
          <DropdownBox
            isOpen={isSelectFunnelOpen}
            onClose={() => {
              setIsSelectFunnelOpen(false);
            }}
            pointerEvents={true}
            componentRef={selectFunnelBlock}
          >
            <div
              className={s.selectFunnelOptionsBlock}
              style={{ maxHeight: `${dropdownHeight}px` }}
            >
              {options.map((option: IOption) => (
                <div
                  className={s.selectFunnelOptionItem}
                  onClick={() => {
                    handleSelectFunnel(option);
                    setIsSelectFunnelOpen(false);
                  }}
                  key={option.label}
                >
                  {option.label === selectedOption?.label && <CheckMarkSvg />}
                  {option.label}
                </div>
              ))}
            </div>
          </DropdownBox>
        </div>

        <DropdownBox
          isOpen={isPointsBlockOpen}
          onClose={() => setIsPointsBlockOpen(false)}
          pointerEvents={true}
          componentRef={pointsBlock}
        >
          <div
            className={s.removeBlock}
            onClick={() => {
              setDeleteDomainPopupOpened(true);
              setIsPointsBlockOpen(false);
            }}
          >
            <CloseSvg /> Remove
          </div>
        </DropdownBox>

        <div>
          <DomainButton
            onClick={() => {
              props.refreshDomain(props.id);
            }}
            borderRadius={25}
            width={130}
            height={40}
          >
            <RefreshDomainSvg className={s.refreshDomainSvg} /> Refresh
          </DomainButton>
          <div
            className={s.pointsBlock}
            onClick={() => {
              setIsPointsBlockOpen(true);
            }}
            ref={pointsBlock}
          >
            <svg
              width="14"
              height="3"
              viewBox="0 0 14 3"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 1.5C0 0.671573 0.671573 0 1.5 0C2.32843 0 3 0.671573 3 1.5C3 2.32843 2.32843 3 1.5 3C0.671573 3 0 2.32843 0 1.5Z"
                fill="black"
              />
              <path
                d="M5.5 1.5C5.5 0.671573 6.17157 0 7 0C7.82843 0 8.5 0.671573 8.5 1.5C8.5 2.32843 7.82843 3 7 3C6.17157 3 5.5 2.32843 5.5 1.5Z"
                fill="black"
              />
              <path
                d="M11 1.5C11 0.671573 11.6716 0 12.5 0C13.3284 0 14 0.671573 14 1.5C14 2.32843 13.3284 3 12.5 3C11.6716 3 11 2.32843 11 1.5Z"
                fill="black"
              />
            </svg>
          </div>
        </div>
      </div>
      <DeleteDomainPopup
        onClose={() => setDeleteDomainPopupOpened(false)}
        open={deleteDomainPopupOpened}
        onDonePressed={handleRemoveDomain}
        isLoading={props.isLoading}
      />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = {
  deleteDomain: (id: string) => deleteDomain(id),
  selectFunnel: (id: string, projectId: string) => selectFunnel(id, projectId),
  refreshDomain: (id: string) => refreshDomain(id),
};

export default connect(mapStateToProps, mapDispatchToProps)(DomainItem);
