import {
  useState,
  useEffect,
  useRef,
  ChangeEvent,
  DetailedHTMLProps,
  forwardRef,
  useImperativeHandle,
} from 'react';
import clsx from 'clsx';

import styles from './AutoExpandTextarea.module.scss';

interface IProps
  extends Omit<
    DetailedHTMLProps<
      React.TextareaHTMLAttributes<HTMLTextAreaElement>,
      HTMLTextAreaElement
    >,
    'onChange'
  > {
  onChange: (value: string) => void;
  onBlur?: () => void;
  value?: string;
  theme: 'light' | 'dark';
}

type AutoExpandTextareaHandle = {
  focus: () => void;
};

const AutoExpandTextarea = forwardRef<AutoExpandTextareaHandle, IProps>(
  (props, ref) => {
    const { theme, value, onChange, onBlur } = props;
    const [text, setText] = useState(value);
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    useImperativeHandle(
      ref,
      () => ({
        focus: () => {
          //need this 'setTimeout' hack so it will properly do focus on button click.
          setTimeout(() => textareaRef.current?.focus(), 1);
        },
      }),
      []
    );

    useEffect(() => {
      adjustTextareaHeight();
    }, [text]);

    const adjustTextareaHeight = () => {
      const textarea = textareaRef.current;
      if (!textarea) return;
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    };

    const handleBlur = () => {
      if (onBlur) {
        onBlur();
      }
    };

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      const newValue = event.target.value;
      setText(newValue);
      onChange(newValue);
    };

    return (
      <textarea
        ref={textareaRef}
        className={clsx(styles.textarea, {
          [styles.lightMode]: theme === 'light',
          [styles.darkMode]: theme === 'dark',
        })}
        value={text}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    );
  }
);

export default AutoExpandTextarea;
