import { connect } from 'react-redux';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { UserInfo } from 'classes/UserInfo';
import { DispatchType, RootState } from 'store/rootReducer';
import { IAutoSassDesign } from 'store/autosaas/autosaasReducer';
import { fetchAgencyData } from 'store/user/userActions';
import { graphQlCall } from 'graphql/utils';
import { AUTH_USER_TOKEN_KEY } from 'Constants';
import {
  storeSubRefreshToken,
  storeActiveSubId,
  storeSubToken,
  username,
} from 'utils/Utils';
import queries from 'graphql/queries';
import OnboardingLogo from 'Components/Common/OnboardingLogo/OnboardingLogo';

import s from './PageLogin.module.scss';

interface IProps {
  autoSassDesign?: IAutoSassDesign;
  fetchAgencyDetails: () => void;
}

const AutoLogin = ({ autoSassDesign, fetchAgencyDetails }: IProps) => {
  const history = useHistory();

  useEffect(() => {
    processLogin();
  }, []);

  const processLogin = async () => {
    const cognitoUser = Document.prototype.cognitoUser;
    if (!cognitoUser) {
      console.error('no stored cognito user');
      history.replace({ pathname: '/console/login' });
    }

    const email = cognitoUser?.attributes?.email;
    const owner = username(
      cognitoUser?.signInUserSession?.accessToken?.jwtToken
    );
    localStorage.setItem('USER_EMAIL', email);
    const user = await graphQlCall({
      queryTemplateObject: queries.GET_USER_DETAILS_QUERY,
      values: {
        owner,
      },
    });
    localStorage.setItem('USER_ID', user._id);
    localStorage.setItem('USER_ROLE', user.role);
    if (user.subdomain) {
      localStorage.setItem('USER_SUBDOMAIN', user.subdomain);
    }
    localStorage.setItem(
      AUTH_USER_TOKEN_KEY,
      cognitoUser.signInUserSession.accessToken.jwtToken
    );
    fetchAgencyDetails();
    await setInitialSubAccount(owner, user._id);
    await checkSubscriptionAndRedirect();
  };

  const checkSubscriptionAndRedirect = async () => {
    const userInfo = await UserInfo.getInstance().getInfo();
    let { funnel, bookle, copyCraft } = await getUserAccess();
    bookle = bookle || userInfo.scopes.includes('BOOKLE_GENERAL_ACCESS');
    let location = '/console/settings';
    if (funnel) {
      location = '/console/projects';
    } else if (bookle) {
      location = '/console/books';
    } else if (copyCraft) {
      location = '/console/copycraft';
    }
    history.replace({ pathname: location });
  };

  const getUserAccess = async () => {
    //TODO: make getUserAccess more general and part of USerInfo
    return await graphQlCall({
      queryTemplateObject: queries.CHECK_SUBSCRIPTION_ACCESS,
      headerType: 'AGENCY_AUTH',
    });
  };

  const setInitialSubAccount = async (agencyOwner: string, userId: string) => {
    const { token, refreshToken } = await graphQlCall({
      queryTemplateObject: queries.CREATE_TOKEN,
      headerType: 'AGENCY_AUTH',
      values: {
        sub: agencyOwner,
      },
    });
    storeActiveSubId(userId);
    storeSubRefreshToken(refreshToken);
    storeSubToken(token);
  };

  return (
    <div className={s.container}>
      <OnboardingLogo />
      <div className={s.innerContainer}>
        <div className={s.header}>
          <p>Welcome to {autoSassDesign?.name ?? 'AutoFunnel'}!</p>
          <p>Login Processing . . . </p>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  autoSassDesign: state.autosaas.autoSassDesign,
});

const mapDispatchToProps = (dispatch: DispatchType) => ({
  fetchAgencyDetails: () => dispatch(fetchAgencyData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AutoLogin);
