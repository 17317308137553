export const GET_TEMPLATES_WITH_CATEGORIES = {
  operationName: 'getTemplatesWithCategories',
  query: `query getTemplatesWithCategories {
            getTemplatesWithCategories {
                  _id
                  type 
                  name
                  image
                  category
                  funnel 
                  page 
            }
      }`,
};
