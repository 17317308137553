import React, { useState } from 'react';
import Input from './Input';
import Button from './Button';
import BigConnectIcon from '../icons/bigConnect.svg';
import Popup from 'UILib/Popup/Popup';
import s from './popups.module.scss';

interface IProps {
  onClose: () => void;
  onSubmit: (email: string) => void;
}

const ConnectExistedPopup = (props: IProps) => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);

  const handleClose = () => {
    clearState();
    props.onClose();
  };

  const clearState = () => {
    setEmail('');
    setEmailError(false);
  };

  const handleSubmit = () => {
    setEmailError(false);
    const regex = /^(\w|\d)+(\.(\w|\d)+)?@(\w|\d)+(\.(\w|\d)+)+$/gi;
    if (!regex.test(email)) {
      setEmailError(true);
      return;
    }
    props.onSubmit(email);
    clearState();
  };

  return (
    <Popup onClose={handleClose}>
      <div className={s.modalBody}>
        <img src={BigConnectIcon} className={s.personIcon} />
        <h2 className={s.popupHeader}>Connect Existing</h2>
        <p className={s.popupText}>
          Enter the email address of the account you want to connect!
        </p>
        <Input
          type="email"
          onChange={setEmail}
          value={email}
          error={emailError}
        />
        <Button name="Connect" onClick={() => handleSubmit()} />
      </div>
    </Popup>
  );
};

export default ConnectExistedPopup;
