import clsx from 'clsx';
import {
  useRef,
  ReactNode,
  useEffect,
  HTMLAttributes,
  DetailedHTMLProps,
  useState,
  useMemo,
} from 'react';

import styles from './toolbarManipulatorPopup.module.scss';

interface IProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  popupContent: ReactNode;
  toolbarHeight: number;
  type: 'sm' | 'lg';
}

const ToolbarManipulatorPopup = ({
  popupContent,
  toolbarHeight,
  type,
}: IProps) => {
  const divRef = useRef<HTMLDivElement | null>(null);
  const [isAtTop, setIsAtTop] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ offsetX: 0, offsetY: 0 });
  const [isDragged, setIsDragged] = useState(false);

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    if (type === 'lg') {
      setIsDragged(true);
      setDragStart({
        offsetX: e.clientX - (position.x || 0),
        offsetY: e.clientY - (position.y || 0),
      });
      setIsDragging(true);
    }
  };

  useEffect(() => {
    const handleWindowMouseMove = (e: MouseEvent) => {
      if (isDragging) {
        setPosition({
          x: e.clientX - dragStart.offsetX,
          y: e.clientY - dragStart.offsetY,
        });
      }
    };

    const handleWindowMouseUp = () => {
      setIsDragging(false);
    };

    window.addEventListener('mousemove', handleWindowMouseMove);
    window.addEventListener('mouseup', handleWindowMouseUp);

    return () => {
      window.removeEventListener('mousemove', handleWindowMouseMove);
      window.removeEventListener('mouseup', handleWindowMouseUp);
    };
  }, [isDragging, dragStart]);

  useEffect(() => {
    if (!divRef.current) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        setPosition({
          x: -280,
          y: entry.boundingClientRect.top > 420 ? -300 : toolbarHeight + 10,
        });
        setIsAtTop(entry.boundingClientRect.top > 420);
      },
      {
        root: null,
        rootMargin: '0px 0px -100% 0px',
        threshold: [1],
      }
    );

    observer.observe(divRef.current);

    return () => {
      if (divRef.current) observer.unobserve(divRef.current);
    };
  }, [divRef]);

  const popupStyle = useMemo(() => {
    if (type === 'sm') {
      return { top: 50 };
    }

    if (!position.x || !position.y || !isDragged) {
      return isAtTop ? { bottom: toolbarHeight + 10 } : { top: 50 };
    }

    return {
      top: `${position.y}px`,
      left: `${position.x}px`,
    };
  }, [type, position, isDragged, isAtTop, toolbarHeight]);

  return (
    <div
      className={clsx(styles.container, {
        [styles.smallContainer]: type === 'sm',
      })}
      ref={divRef}
      style={popupStyle}
    >
      {type === 'lg' && (
        <div className={styles.draggableHeader} onMouseDown={handleMouseDown} />
      )}
      {popupContent}
    </div>
  );
};

export default ToolbarManipulatorPopup;
