import {
  MutableRefObject,
  CSSProperties,
  LegacyRef,
  ReactNode,
  useEffect,
  useRef,
} from 'react';
import ReactDOM from 'react-dom';

interface IProps {
  open: boolean;
  content: ReactNode;
  targetRef: MutableRefObject<any>;
  position: 'top' | 'bottom' | 'left' | 'right';
  onOutsideClick?: () => void;
}

const Tooltip = ({
  open,
  content,
  targetRef,
  onOutsideClick,
  position = 'top',
}: IProps) => {
  const tooltipRef: LegacyRef<HTMLDivElement> = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        tooltipRef.current &&
        !tooltipRef.current.contains(event.target) &&
        !targetRef.current.contains(event.target)
      ) {
        if (onOutsideClick) onOutsideClick();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onOutsideClick, targetRef]);

  if (!targetRef.current || !open) return null;

  const targetRect = targetRef.current.getBoundingClientRect();

  const style: CSSProperties = {
    position: 'absolute',
    zIndex: 1000,
    borderRadius: '6px',
    whiteSpace: 'nowrap',
    overflow: 'auto',
    boxShadow:
      '0 4px 15px 0 rgba(0, 0, 0, 0.2), 0 6px 34px 0 rgba(0, 0, 0, 0.19)',
  };

  switch (position) {
    case 'top':
      style.top = `${targetRect.top - targetRect.height}px`;
      style.left = `${targetRect.left + targetRect.width / 2}px`;
      style.transform = 'translate(-50%, -100%)';
      break;
    case 'bottom':
      style.top = `${targetRect.bottom}px`;
      style.left = `${targetRect.left + targetRect.width / 2}px`;
      style.transform = 'translate(-50%, 0)';
      break;
    case 'left':
      style.top = `${targetRect.top + targetRect.height / 2}px`;
      style.left = `${targetRect.left - targetRect.width}px`;
      style.transform = 'translate(-100%, -50%)';
      break;
    case 'right':
      style.top = `${targetRect.top + targetRect.height / 2}px`;
      style.left = `${targetRect.right + 15}px`;
      style.transform = 'translate(0, -50%)';
      break;
    default:
      style.top = `${targetRect.top - targetRect.height}px`;
      style.left = `${targetRect.left + targetRect.width / 2}px`;
      style.transform = 'translate(-50%, -100%)';
  }

  return ReactDOM.createPortal(
    <div ref={tooltipRef} style={style}>
      {content}
    </div>,
    document.body
  );
};

export default Tooltip;
