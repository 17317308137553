export const DELETE_PODCAST = {
  operationName: 'deletePodcast',
  query: `mutation deletePodcast(
        $id: String!
      ) {
        deletePodcast(
          id: $id
        ) {
          message
        }
      }`,
};
