import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { BookleTemplateBlock, IUserProduct } from 'types';
import { RootState } from '../rootReducer';
import {
  IBookleTemplateBlockStyles,
  IBookleTemplateEditor,
} from './booksReducer';

export const UPDATE_SETTINGS_POPUP = 'UPDATE_POPUP';
export const UPDATE_BOOKLE_TEMPLATE_BLOCKS = 'UPDATE_BOOKLE_TEMPLATE_BLOCKS';
export const UPDATE_BOOKLE_TEMPLATE_HOVERED_BLOCK =
  'UPDATE_BOOKLE_TEMPLATE_HOVERED_BLOCK';
export const UPDATE_BOOKLE_TEMPLATE_TEXT_EDITOR =
  'UPDATE_BOOKLE_TEMPLATE_TEXT_EDITOR';
export const UPDATE_BOOKLE_TEMPLATE_BLOCK_STYLES =
  'UPDATE_BOOKLE_TEMPLATE_BLOCK_STYLES';
export const UPDATE_ACTIVE_BOOKLE_TEMPLATE = 'UPDATE_ACTIVE_BOOKLE_TEMPLATE';

type DispatchType = ThunkDispatch<RootState, void, Action>;

export const bookSettingsPopupAction = (payload: {
  open?: boolean;
  initialState?: IUserProduct;
}) => async (dispatch: DispatchType) => {
  const stateAction = {
    type: UPDATE_SETTINGS_POPUP,
    payload,
  };
  dispatch(stateAction);
};

export const updateBookleTemplateBlocks = (
  payload: BookleTemplateBlock[]
) => async (dispatch: DispatchType) => {
  const stateAction = {
    type: UPDATE_BOOKLE_TEMPLATE_BLOCKS,
    payload,
  };
  dispatch(stateAction);
};

export const updateBookleTemplateHoveredBlock = (
  payload: BookleTemplateBlock | undefined
) => async (dispatch: DispatchType) => {
  const stateAction = {
    type: UPDATE_BOOKLE_TEMPLATE_HOVERED_BLOCK,
    payload,
  };
  dispatch(stateAction);
};

export const updateBookleTemplateTextEditor = (
  payload: IBookleTemplateEditor
) => async (dispatch: DispatchType) => {
  const stateAction = {
    type: UPDATE_BOOKLE_TEMPLATE_TEXT_EDITOR,
    payload,
  };
  dispatch(stateAction);
};

export const updateBookleTemplateBlockStyles = (
  payload: IBookleTemplateBlockStyles
) => async (dispatch: DispatchType) => {
  const stateAction = {
    type: UPDATE_BOOKLE_TEMPLATE_BLOCK_STYLES,
    payload,
  };
  dispatch(stateAction);
};

export const updateActiveTemplate = (payload: string | null) => async (
  dispatch: DispatchType
) => {
  const stateAction = {
    type: UPDATE_ACTIVE_BOOKLE_TEMPLATE,
    payload,
  };
  dispatch(stateAction);
};
