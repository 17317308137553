import React, { useEffect, useState } from 'react';
import Button from '../../Components/Common/DomainButton/DomainButton';
import closeSvg from '../../Assets/icons/close.svg';
import thumbUpSvg from '../../Assets/icons/thumbUp.svg';
import s from './popup.module.scss';
import { IGeneralProject, IGeneralProjectName } from 'types';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

interface IProps {
  onClose: () => void;
  open: boolean;
  domainName: string;
  hasFunnel?: boolean;
  funnels: IGeneralProjectName[];
  onAssociate: (funnelId: string) => void;
}

interface IOption {
  value: string;
  label: string;
}

const WooHooPopup = (props: IProps) => {
  const [options, setOptions] = useState<IOption[]>([]);
  const [selectedOption, setSelectedOption] = useState<IOption | undefined>(
    undefined
  );

  const handleAssociate = () => {
    if (selectedOption) {
      props.onAssociate(selectedOption.value);
    }
  };

  useEffect(() => {
    const newOptions = props.funnels.map((funnel) => ({
      value: funnel._id,
      label: funnel.name,
    }));
    setOptions(newOptions);
  }, [props.funnels]);

  return props.open ? (
    <div className={s.wrapper}>
      <div className={s.modal}>
        <img
          className={s.closeIcon}
          src={closeSvg}
          alt="x"
          onClick={() => props.onClose()}
        />
        <img className={s.worldIcon} src={thumbUpSvg} alt="+" />
        <h1>Woo hoo!</h1>
        {props.hasFunnel ? (
          <div>
            <p>
              Great job, you did it! Your domain {props.domainName} successfully
              connected, associated with current flow and ready to use.
            </p>
            <div className={s.buttonsBlock}>
              <Button
                name="Back to Dashboard"
                onClick={() => props.onClose()}
              />
            </div>
          </div>
        ) : (
          <div>
            <p className={s.text}>
              Great job, you did it! Your domain {props.domainName} successfully
              connected!
              <br />
              Select a funnel to associate with your custom domain!
            </p>
            <div className={s.wooHooPopupSelectFunnel}>
              <Dropdown
                options={options}
                onChange={(value) => setSelectedOption(value as IOption)}
                value={selectedOption}
                placeholder="Select funnel"
              />
            </div>
            <div className={s.buttonsBlock}>
              <Button name="Associate Later" onClick={() => props.onClose()} />
              <Button
                name="Associate Funnel"
                onClick={() => handleAssociate()}
                isBlackBtn={true}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  ) : null;
};

export default WooHooPopup;
