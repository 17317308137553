export const GET_SAAS_TEMPLATES_AND_CATEGORIES = {
  operationName: 'getSaasTemplatesAndCategories',
  query: `query getSaasTemplatesAndCategories {
            getSaasTemplatesAndCategories {
                categories {
                    _id
                    name
                }
                templates {
                    _id
                    name
                    type
                    image
                    category
                    page
                }
            }
        }`,
};
