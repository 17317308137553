import React from 'react';
import { IFunnel } from 'types';
import s from './ContentEditableDiv.module.scss';

interface IProps {
  setIsTouched?: (isTouched: boolean) => void;
  onChange: (name: string) => void;
  placeholder?: string;
  autofocus?: boolean;
  name: string;
  fontSize: number;
  lineHeight: number;
  fontWeight: number;
  width?: number;
  height: number;
  onEdit?: (value: string, pageId?: string, pageEnabled?: boolean) => void;
  page?: IFunnel;
  allowEmpty?: boolean;
}

function ContentEditableDiv({
  setIsTouched,
  onChange,
  placeholder,
  autofocus,
  name,
  fontSize,
  lineHeight,
  fontWeight,
  width,
  height,
  onEdit,
  page,
  allowEmpty,
}: IProps) {
  const handleEditing = (e: React.FormEvent<HTMLInputElement>) => {
    if (onEdit) {
      if (page) {
        onEdit(e.currentTarget.value, page._id, page.enabled);
      } else {
        onEdit(e.currentTarget.value);
      }
      //TODO: delete eventually this commented code
      // e.currentTarget.setSelectionRange(0, 0) //THIS line is creating issue for Safari when you can not loose focus on any text filed  https://app.asana.com/0/1204161070205149/1205117458093106/f
    }
    // Check for an empty value
    if (e.currentTarget.value === '') {
      const emptyText = allowEmpty ? '' : 'Untitled';
      if (onChange) {
        onChange(emptyText);
      }
      if (onEdit) {
        if (page) {
          onEdit(emptyText, page._id, page.enabled);
        } else {
          onEdit(emptyText);
        }
        //TODO: delete eventually this commented code
        // e.currentTarget.setSelectionRange(0, 0) //THIS line is creating issue for Safari when you can not loose focus on any text filed  https://app.asana.com/0/1204161070205149/1205117458093106/f
      }
    }
  };

  return (
    <div className={s.container}>
      <input
        placeholder={placeholder}
        autoFocus={autofocus && true}
        className={s.name}
        value={name}
        onFocus={() => setIsTouched && setIsTouched(false)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.currentTarget.blur();
          }
        }}
        onBlur={(e) => {
          handleEditing(e);
        }}
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => {
          onChange && onChange(e.currentTarget.value);
        }}
        style={{
          width: width ? `${width}px` : '100%',
          height: `${height}px`,
          fontSize: `${fontSize}px`,
          lineHeight: `${lineHeight}px`,
          fontWeight: `${fontWeight}`,
        }}
      />
    </div>
  );
}

export default ContentEditableDiv;
