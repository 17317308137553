import {
  FieldProps,
  fieldWrapper,
} from 'Components/Common/FieldWrapper/FieldWrapper';
import React from 'react';
import { classnames } from 'utils/fuctions';
import style from './input.module.scss';

export interface IProps extends FieldProps {
  type?: 'text' | 'password' | 'number';
  placeholder?: string;
}
export const Input: React.FC<IProps> = ({
  onChange,
  value,
  onBlur,
  name,
  type = 'text',
  placeholder,
  error,
}) => {
  return (
    <div className={style.root}>
      <input
        type={type}
        onChange={onChange}
        value={value}
        onBlur={onBlur}
        name={name}
        placeholder={placeholder}
        className={classnames(error && style.error)}
      />
    </div>
  );
};

export const FormInput = fieldWrapper(Input, { changeEvent: true });
