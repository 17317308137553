import React from 'react';
import s from './popups.module.scss';

interface IProps {
  onClick: () => void;
  name: string;
}

const Button = (props: IProps) => {
  return (
    <button className={s.button} onClick={() => props.onClick()}>
      {props.name}
    </button>
  );
};

export default Button;
