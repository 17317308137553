// development constants
export const AUTH_USER_TOKEN_KEY = 'LanderAmplify.TokenKey';
export const API_ENDPOINT = 'http://localhost:3001/';
export const API_VIDEO_UPLOAD = 'http://localhost:3001/upload';
export const API_VIDEO_STATUS = 'http://localhost:3001/status';
export const TEMPLATES_URL = 'https://cdn.autofunnel.ai/templates';
export const STRIPE_KEY =
  'pk_test_51MvOYPIw2eOqESjK2EDqovAs6SiTymX8aRzmyHrSNsJKKKcjrYKe5DIDQ5tvG9McWzKzU2nxuMOdvE060hUxIIgV00fwYtHlIT';
export const DATA_URL = 'https://s3.amazonaws.com/shomio.data.images';
export const AMPLIFY_CLIENT_ID = '25mo5sl4ophb4hi6bbqn9rgn2p';
export const AMPLIFY_POOL_ID = 'us-east-1_14cjA4Cug';
export const STRIPE_SUCCESS_URL =
  'http://app.dev.mech.com/console/success-subscribed';
export const STRIPE_CANCEL_URL =
  'http://app.dev.mech.com/console/cancel-subscribe';
export const ENVIRONMENT: 'DEV' | 'PROD' = 'DEV';
export const CONSULT_URL = 'https://app.dev.autofunnel.ai';
export const PAGECRAFT_API_URL = 'https://pagecraft-api-dev.autofunnel.ai';
export const PAGECRAFT_ANALYTICS_API_URL =
  'https://pagecraft-analytics-dev.autofunnel.ai';
export const SUPER_ADMIN_PASSWORD = 'T5S8mFagftVGnY5h';
export const StripeProducts = {
  Basic: {
    monthly: {
      '1000': { id: 'price_1Mvr2SIw2eOqESjKaT9wDnWk', price: '693' },
      '750': { id: 'price_1Mvr1wIw2eOqESjKM1Z5XX9u', price: '520' },
      '500': { id: 'price_1Mvr1oIw2eOqESjKQtFNo8FY', price: '346' },
      '250': { id: 'price_1Mvr1fIw2eOqESjKGArOBN3x', price: '173' },
      '100': { id: 'price_1Mvr1WIw2eOqESjKQPoVjlib', price: '149' },
    },
    annually: {
      '1000': { id: 'price_1Mvr1DIw2eOqESjKDshD6V16', price: '6648' },
      '750': { id: 'price_1Mvr0KIw2eOqESjKIfH0oe5O', price: '4992' },
      '500': { id: 'price_1MvqzwIw2eOqESjKeTjPGhE4', price: '3324' },
      '250': { id: 'price_1MvqzdIw2eOqESjKIppe8WHP', price: '1668' },
      '100': { id: 'price_1MvpuOIw2eOqESjKBbZgI8Hf', price: '1428' },
    },
    annuallyDiscount: {
      '100': { id: 'price_1MzIPWIw2eOqESjKiyR6N8Xk', price: '600' },
    },
  },
  Plus: {
    monthly: {
      '250': { id: 'price_1Mvr8dIw2eOqESjKf5vCejfG', price: '199' },
      '750': { id: 'price_1Mvr8lIw2eOqESjKFNfCBHm6', price: '332' },
      '1250': { id: 'price_1Mvr8sIw2eOqESjKIPMzEBsS', price: '497' },
      '1750': { id: 'price_1Mvr90Iw2eOqESjKh5MTJ1aZ', price: '695' },
      '2500': { id: 'price_1Mvr9nIw2eOqESjKLVKovI1Q', price: '995' },
    },
    annually: {
      '250': { id: 'price_1Mvr5AIw2eOqESjKjMFeUNz6', price: '1908' },
      '750': { id: 'price_1Mvr5WIw2eOqESjKD03KyvC6', price: '3180' },
      '1250': { id: 'price_1Mvr5hIw2eOqESjKaaXHFDy3', price: '4764' },
      '1750': { id: 'price_1Mvr5sIw2eOqESjKcYoFhLP8', price: '6672' },
      '2500': { id: 'price_1Mvr8BIw2eOqESjKp5adN4ht', price: '9552' },
    },
    annuallyDiscount: {
      '250': { id: 'price_1MzIPWIw2eOqESjKOTMupWcm', price: '1200' },
    },
  },
  Pro: {
    monthly: {
      '500': { id: 'price_1MvrCIIw2eOqESjKiOCOqyFx', price: '299' },
      '1250': { id: 'price_1MvrCOIw2eOqESjKXtHCeQI7', price: '374' },
      '2500': { id: 'price_1MvrCVIw2eOqESjKFl8iyzuw', price: '747' },
      '3750': { id: 'price_1MvrCcIw2eOqESjKYIvGadtc', price: '1121' },
      '5000': { id: 'price_1MvrCjIw2eOqESjKdCdTGCz0', price: '1495' },
    },
    annually: {
      '500': { id: 'price_1MvrB1Iw2eOqESjKi25C1aOZ', price: '2868' },
      '1250': { id: 'price_1MvrBJIw2eOqESjKo13xrbrU', price: '3588' },
      '2500': { id: 'price_1MvrBUIw2eOqESjKOW3iD5DT', price: '7164' },
      '3750': { id: 'price_1MvrBlIw2eOqESjK0bJhMfxt', price: '10764' },
      '5000': { id: 'price_1MvrBwIw2eOqESjKNf789EgS', price: '14340' },
    },
    annuallyDiscount: {
      '250': { id: 'price_1MzIPWIw2eOqESjKtdbFtEAs', price: '1800' },
    },
  },
};

export const AI_COPY_CRAFT_EMAIL_URL =
  'wss://text-ai.autofunnel.ai/api/v3/stream';
export const SOCKET_URL = 'wss://pagecraft-api-dev.autofunnel.ai';
export const SENTRY_DNS =
  'https://841f1bc4950d9348bfbb99cfc2e23c51@o4506429919920128.ingest.sentry.io/4506434832629760';
export const NO_SOCKET_CREDENTIALS = false;
export const ENCRYPTION_KEY = 'ChgBzwAeLtQmXG2knPcV5J8S4UrMjp';

export const BOOKLE_PLANES = {
  monthly: [
    {
      name: 'Free',
      descriptions: 'For Hobbyists just getting started',
      features: 'Only Small Books',
      value: 0,
      creditsCount: 1,
      pageCount: 30,
      stripePriceId: 'price_1Or2acIw2eOqESjKgvLRXXoz',
      stripeProductId: 'prod_PgPPbmeRmFT1f7',
      type: 'Small',
      currentPlan: false,
    },
    {
      name: 'Plus',
      descriptions: 'For Individuals and Small Teams',
      features: 'Up to Medium Books',
      value: 49,
      creditsCount: 25,
      pageCount: 150,
      stripePriceId: 'price_1OsWKyIw2eOqESjKHOc9Yck6',
      stripeProductId: 'prod_PgPPbmeRmFT1f7',
      type: 'Medium',
      currentPlan: false,
    },
    {
      name: 'Pro',
      descriptions: 'For Businesses & Agencies',
      features: 'Up to Large Books',
      value: 149,
      creditsCount: 100,
      pageCount: 200,
      stripePriceId: 'price_1OsWLDIw2eOqESjK2RE6HYU8',
      stripeProductId: 'prod_PgPPbmeRmFT1f7',
      type: 'Large',
      currentPlan: false,
    },
  ],
  annually: [
    {
      name: 'Free',
      descriptions: 'For Hobbyists just getting started',
      features: 'Only Small Books',
      value: 0,
      creditsCount: 1,
      pageCount: 30,
      stripePriceId: 'price_1Or2acIw2eOqESjKgvLRXXoz',
      stripeProductId: 'prod_PgPPbmeRmFT1f7',
      type: 'Small',
      currentPlan: false,
    },
    {
      name: 'Plus Annual',
      descriptions: 'For Individuals and Small Teams',
      features: 'Up to Medium Books',
      value: 349,
      creditsCount: 310,
      pageCount: 150,
      stripePriceId: 'price_1OtwRNIw2eOqESjK4wKHwzaV',
      stripeProductId: 'prod_PgPPbmeRmFT1f7',
      type: 'Medium',
      currentPlan: false,
    },
    {
      name: 'Pro Annual',
      descriptions: 'For Businesses & Agencies',
      features: 'Up to Large Books',
      value: 999,
      creditsCount: 1300,
      pageCount: 200,
      stripePriceId: 'price_1OvoQbIw2eOqESjKBaqioQa7',
      stripeProductId: 'prod_PgPPbmeRmFT1f7',
      type: 'Large',
      currentPlan: false,
    },
  ],
  upsells: {
    Plus: {
      name: 'Plus Discount Annual',
      descriptions: 'For Individuals and Small Teams',
      features: 'Up to Medium Books',
      promoTextHeader: 'Want 50% OFF of Bookle... Forever?',
      promoText: [
        "Because you're a new customer, we're giving you a one time offer to get Bookle 50% OFF if you upgrade to an annual plan.",
        "This means you'll just pay <b>$294</b> per year (which averages only <b>$24/month!</b>)",
      ],
      value: 249,
      creditsCount: 310,
      pageCount: 150,
      stripePriceId: 'price_1Ov43DIw2eOqESjKzFyfhJeE',
      stripeProductId: 'prod_PgPPbmeRmFT1f7',
      type: 'Medium',
      currentPlan: false,
    },
    Pro: {
      name: 'Pro Discount Annual',
      descriptions: 'For Businesses & Agencies',
      features: 'Up to Large Books',
      promoTextHeader: 'Want 70% OFF of Bookle... Forever?',
      promoText: [
        "Because you're a new customer, we're giving you a one time offer to get Bookle 70% OFF if you upgrade to an annual plan.",
        "That means you'll just pay <b>$540</b> per year (which averages only <b>$45/month</b>).",
      ],
      value: 540,
      creditsCount: 1300,
      pageCount: 200,
      stripePriceId: 'price_1Ov43iIw2eOqESjK83PEo1wB',
      stripeProductId: 'prod_PgPPbmeRmFT1f7',
      type: 'Large',
      currentPlan: false,
    },
  },
};
export const SSE_SERVER = 'https://ai-cuno.autofunnel.ai/email/';
export const AI_COPY_CRAFT_TEST_EMAIL_URL =
  'https://ai-cuno.autofunnel.ai/email';
export const AI_COPY_CRAFT_TEST_SALES_URL =
  'https://ai-cindy.autofunnel.ai/sales/';
export const AI_COPY_CRAFT_TEST_BRAIN_URL = 'http://129.213.130.208:7861';
export const EMAIL_DOMAIN = 'nikforce.com';
