export const GET_SUB_ACCOUNTS = {
  operationName: 'getAgencySubAccounts',
  query: `query getAgencySubAccounts {
      getAgencySubAccounts {
        _id
        name
        email
        notes
        thumbnail
        owner
        requestedAgencyId
        agencyId
        workspace
      }
    }`,
};
