export const BOOKS_SEARCH = {
  operationName: 'booksSearch',
  query: `query booksSearch(
        $text: String!
      ) {
        booksSearch(
          text: $text
        ) {
          _id
          title
          coverImageUrl
          pdfFile
          progress
          createdAt
          updatedAt
        }
      }`,
};
