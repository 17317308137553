export const DELETE_AI_PAGE = {
  operationName: 'deleteAiPage',
  query: `mutation deleteAiPage (
        $id: String!,
        ) {
        deleteAiPage (
            id: $id,
        ) {
        message
      }
    }
    `,
};
