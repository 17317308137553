import { ElementType } from 'react';

export enum BroadcastMenuType {
  templateMenu = 'templateMenu',
  draggableComponentMenu = 'draggableComponentMenu',
}

export type EmailTemplatesGroup = {
  title: string;
  icon: ElementType;
  color: string;
  templates: {
    id?: string;
    label: string;
    image: string;
  }[];
};
