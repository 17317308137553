export const GET_BOOKS_FOR_FUNNEL_BUILDER = {
  operationName: 'getBooksWithPagination',
  query: `query getBooksWithPagination($skip: Float!, $limit: Float!) {
          getBooksWithPagination(skip: $skip, limit: $limit) {
            books {
              _id
              title
              description
            }
            total
          }
        }`,
};
