import { SET_SUBSCRIPTIONS, SET_EMAIL } from './stripeActions';
import { PayloadAction } from '@reduxjs/toolkit';
import { IStripeSubscription } from 'types';

export interface IStripeState {
  subscriptions: IStripeSubscription[];
  email: string | null;
}

const initialState: IStripeState = {
  subscriptions: [],
  email: null,
};

const stripeReducer = (
  state = initialState,
  action: PayloadAction<string | IStripeSubscription[]>
) => {
  switch (action.type) {
    case SET_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptions: action.payload as IStripeSubscription[],
      };
    case SET_EMAIL:
      return {
        ...state,
        email: action.payload as string,
      };
    default:
      return state;
  }
};

export { initialState, stripeReducer };
