export const DELETE_BOOK = {
  operationName: 'deleteBook',
  query: `mutation deleteBook(
      $id: String!
    ) {
      deleteBook(
        id: $id
      ) {
        message
      }
    }`,
};
