import React from 'react';
import s from './Modal.module.scss';
import xSvg from 'Assets/icons/copyCraft/x2.svg';

interface IProps {
  closeModal?: () => void;
  displayModal: boolean;
  children: JSX.Element[] | JSX.Element;
  width?: number;
  height?: number;
}

const Modal = (props: IProps) => {
  function closeModal(e: React.MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
    if (props.closeModal) {
      props.closeModal();
    }
  }

  const contentStyles: any = {};
  const closeStyles: any = {};
  if (props.width) {
    contentStyles.width = `${props.width}px`;
    contentStyles.left = `calc(50% - ${props.width / 2}px)`;
    closeStyles.right = `calc(50% - ${props.width / 2 - 10}px)`;
  }
  if (props.height) {
    contentStyles.height = `${props.height}px`;
  }

  return (
    <div
      className={s.modal}
      onClick={closeModal}
      style={{ display: props.displayModal ? 'block' : 'none' }}
    >
      <div className={s.closeIcon} onClick={closeModal} style={closeStyles}>
        <img src={xSvg} alt="x" />
      </div>
      <div
        className={s.modalContent}
        onClick={(e) => e.stopPropagation()}
        style={contentStyles}
      >
        {props.children}
      </div>
    </div>
  );
};

export default Modal;
