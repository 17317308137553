import whitePaperImage from 'Assets/images/Book/white-paper.png';
import caseStudiesImage from 'Assets/images/Book/case-studies.png';
import marketGuidesImage from 'Assets/images/Book/market-guides.png';
import industryReportsImage from 'Assets/images/Book/industry-reports.png';
import monthlyNewslettersImage from 'Assets/images/Book/monthly-newsletters.png';
import { ReactComponent as BookIcon } from 'Assets/icons/book-icon.svg';
import { ReactComponent as GoToSuccessIcon } from 'Assets/icons/goToSuccess.svg';
import { EmailTemplatesGroup } from '../types';

export const emailTemplatesGroups: EmailTemplatesGroup[] = [
  {
    title: 'Your Templates',
    icon: BookIcon,
    color: '#4957d8',
    templates: [],
  },
  {
    title: 'Marketing Emails',
    icon: GoToSuccessIcon,
    color: '#25bb73',
    templates: [
      {
        id: '672a055e980031f7267c142d',
        label: 'Message',
        image:
          'https://cdn.autofunnel.ai/generation/thumbnail/672a055e980031f7267c142d/efbda5f9-e227-40f3-9e6e-165bb35e6f8d',
      },
      {
        id: '6729d6e4980031f7267ba7ab',
        label: 'About',
        image:
          'https://cdn.autofunnel.ai/generation/thumbnail/6729d6e4980031f7267ba7ab/336ee0bd-d54c-41ff-8880-5ce542261f63',
      },
      {
        id: '672a0016980031f7267c071a',
        label: 'Call To Action',
        image:
          'https://cdn.autofunnel.ai/generation/thumbnail/672a0016980031f7267c071a/18a6b400-9b40-4983-a44e-d944c4900416',
      },
      {
        id: '672a0d1c980031f7267c2695',
        label: 'News',
        image:
          'https://cdn.autofunnel.ai/generation/thumbnail/672a0d1c980031f7267c2695/ccff8122-4b48-4f15-bf6b-b9670ded549a',
      },
      {
        id: '672a073d980031f7267c17ce',
        label: 'Products',
        image:
          'https://cdn.autofunnel.ai/generation/thumbnail/672a073d980031f7267c17ce/5b1ae9f5-f429-4c69-bce0-7402afb54a9c',
      },
    ],
  },
];
