export const GET_USER_DOMAINS_QUERY = {
  operationName: 'getUserDomains',
  query: `query getUserDomains {
      getUserDomains {
        name
        projectId
        status
        userId
        _id
      }
    }`,
};
