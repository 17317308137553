import clsx from 'clsx';
import { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { graphQlCall } from 'graphql/utils';
import { DispatchType, RootState } from 'store/rootReducer';
import { ReactComponent as Logo } from 'Assets/icons/changePageLogo.svg';
import { ReactComponent as MailIcon } from 'Assets/icons/mail.svg';
import { ReactComponent as ViewsIcon } from 'Assets/icons/statsViews.svg';
import { ReactComponent as FingerIcon } from 'Assets/icons/finger.svg';
import { ReactComponent as PersonIcon } from 'Assets/icons/person.svg';
import { ReactComponent as Rename } from 'Assets/icons/rename.svg';
import { ReactComponent as SmartListIcon } from 'Assets/icons/textEditor/list-ul-solid.svg';
import { ReactComponent as DeleteIcon } from '../../Accounts/icons/delete.svg';
import { ReactComponent as DuplicateIcon } from 'Assets/icons/clone.svg';
import { ReactComponent as ClockSvg } from 'Assets/icons/clockNotFill.svg';
import { IBroadcast } from 'types';
import moment from 'moment';
import queries from 'graphql/queries';
import OptionMenu from 'Components/OptionMenu/OptionMenu';
import EditableLabel from 'UILib/EditableLabel/EditableLabel';
import ConfirmArchivationPopup from 'Components/Domains/ConfirmArchivationPopup';

import styles from './BroadcastItem.module.scss';

enum MenuItems {
  DELETE,
  EDIT,
  CLONE,
  VIEW,
  RENAME,
}

const menuItems = [
  {
    label: 'Rename',
    key: MenuItems.RENAME,
    icon: <Rename />,
  },
  {
    label: 'Clone',
    key: MenuItems.CLONE,
    icon: <DuplicateIcon />,
  },
  {
    label: 'Delete',
    key: MenuItems.DELETE,
    icon: <DeleteIcon />,
  },
];

interface IBroadcastItemProps {
  item: IBroadcast;
  stats: { delivered: number; opened: number; clicked: number };
  fetchBroadcasts: () => void;
}

const BroadcastItem = (props: IBroadcastItemProps) => {
  const [url, setUrl] = useState<string>('');
  const [itemName, setItemName] = useState(props.item.name);
  const [isRename, setIsRename] = useState<boolean>(false);
  const [archiveFunnelOpen, setArchiveFunnelOpen] = useState<boolean>(false);
  const history = useHistory();

  const handleNavigate = () => {
    if (props.item.status === 'IDLE') {
      history.push(`/console/broadcasts/${props.item._id}/edit`);
    }
  };

  const deleteBroadcast = async () => {
    try {
      await graphQlCall({
        queryTemplateObject: queries.DELETE_BROADCAST,
        values: {
          id: props.item._id,
        },
        headerType: 'USER-AUTH',
      });
      props.fetchBroadcasts();
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdateName = async () => {
    setIsRename(false);
    if (itemName === props.item.name) return;

    await graphQlCall({
      queryTemplateObject: queries.UPDATE_BROADCAST_MUTATION,
      values: {
        id: props.item._id,
        name: itemName,
      },
      headerType: 'USER-AUTH',
    });
  };

  const handleMenuClick = useCallback(
    (e: any) => {
      const cloneBroadcast = async () => {
        try {
          await graphQlCall({
            queryTemplateObject: queries.CLONE_BROADCAST,
            values: {
              id: props.item._id,
            },
            headerType: 'USER-AUTH',
          });
          props.fetchBroadcasts();
        } catch (error) {
          console.error(error);
        }
      };

      e.domEvent.stopPropagation();
      switch (+e.key) {
        case MenuItems.DELETE: {
          setArchiveFunnelOpen(true);
          break;
        }
        case MenuItems.CLONE: {
          cloneBroadcast();
          break;
        }
        case MenuItems.RENAME: {
          setIsRename(true);
          break;
        }
      }
    },
    [props]
  );

  const menuProps = useMemo(() => {
    return {
      items: menuItems,
      onClick: handleMenuClick,
    };
  }, [handleMenuClick]);

  return (
    <>
      <ConfirmArchivationPopup
        open={archiveFunnelOpen}
        description="Are you sure you want to delete this broadcast?"
        onDonePressed={deleteBroadcast}
        onClose={() => setArchiveFunnelOpen(false)}
      />

      <div className={styles.container} onClick={handleNavigate}>
        <div className={styles.image}>
          {props.item.image ? (
            <img
              className={styles.broadcastPreviewImage}
              src={props.item.image}
              alt="preview"
            />
          ) : (
            <Logo className={styles.logo} />
          )}
        </div>
        <div className={styles.info}>
          <div className={styles.line}>
            <div className={styles.name}>
              {' '}
              <EditableLabel
                size="large"
                value={itemName}
                onChange={setItemName}
                onOutsideClick={handleUpdateName}
                isInputActive={isRename}
              />
            </div>
          </div>
          {props.item.sender?.name && (
            <div className={styles.line}>
              <PersonIcon fill="#a0a0a0" width={16} height={16} />
              <div className={styles.sender}>{props.item.sender?.name}</div>
            </div>
          )}
          {props.item.smartList?.name && (
            <div className={styles.line}>
              <SmartListIcon fill="#a0a0a0" width={16} height={16} />
              <div className={styles.list}>{props.item.smartList?.name}</div>
            </div>
          )}
        </div>
        {props.item.status === 'COMPLETED' && (
          <div className={styles.stats}>
            <div className={styles.stat}>
              <MailIcon />
              <div
                className={clsx(styles.statAmount, {
                  [styles.emptyStat]: props.stats?.delivered === undefined,
                })}
              >
                {props.stats.delivered ?? '-'}
              </div>
            </div>
            <div className={styles.stat}>
              <ViewsIcon />
              <div
                className={clsx(styles.statAmount, {
                  [styles.emptyStat]: props.stats?.opened === undefined,
                })}
              >
                {props.stats?.opened ?? '-'}
              </div>
            </div>
            <div className={styles.stat}>
              <FingerIcon />
              <div
                className={clsx(styles.statAmount, {
                  [styles.emptyStat]: props.stats?.clicked === undefined,
                })}
              >
                {props.stats?.clicked ?? '-'}
              </div>
            </div>
          </div>
        )}
        {props.item.status === 'WAITING' && (
          <div className={styles.scheduleContent}>
            <div className={styles.scheduleText}>
              <ClockSvg className={styles.clockIcon} />{' '}
              <span className={styles.text}>Scheduled for</span>
            </div>
            <div>{moment(Number(props.item.sendAt)*1000).format('DD MMMM hh:mm A')}</div>
          </div>
        )}
        <div className={styles.options}>
          <OptionMenu menuProps={menuProps} />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = (dispatch: DispatchType) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(BroadcastItem);
