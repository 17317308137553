export const DELETE_CONTACT = {
  operationName: 'deleteContact',
  query: `mutation deleteContact(
    $id: String!
  ) {
    deleteContact(
      id: $id
    ) {
      message
    }
  }`,
};
