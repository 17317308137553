export const GET_USER_BROADCASTS = {
  operationName: 'getUserBroadcasts',
  query: `query getUserBroadcasts {
          getUserBroadcasts {
            _id
            name
            smartList { 
              _id 
              name
            }
            workflow { _id }
            sender { 
              _id
              name
            }
            mailDraft { _id }
            image
            sendAt
            nextSendAt
            status
            createdAt
            updatedAt
          }
        }`,
};
