export const GET_TOTAL_PAGE_VIEWS = {
  operationName: 'getTotalPageViews',
  query: `query getTotalPageViews(
      $limit: Float!
      $skip: Float!
    ) {
      getTotalPageViews(
        limit: $limit
        skip: $skip
      ) {
        count
        statistic {
          _id
          pageId
          total
        }
      }
    }`,
};
