import clsx from 'clsx';
import {
  useMemo,
  useState,
  ReactNode,
  HTMLAttributes,
  DetailedHTMLProps,
  useRef,
  useEffect,
} from 'react';
import { ReactComponent as Arrow } from 'Assets/icons/arrow.svg';
import { ReactComponent as CheckMarkIcon } from 'Assets/icons/checkMark.svg';
import useElementClickedOutside from 'Hooks/useElementClickedOutside';

import styles from './Dropdown.module.scss';

interface IProps<T>
  extends Omit<
    DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    'onChange'
  > {
  value?: T;
  label: string | ReactNode;
  type?: 'stroke' | 'one-line';
  theme?: 'light' | 'dark';
  size?: 'large' | 'medium' | 'small';
  options: { label: ReactNode; value: T }[];
  onChange: (newValue: T) => void;
  hasErrors?: boolean;
  disabled?: boolean;
}

const Dropdown = <T extends ReactNode>({
  hasErrors,
  className,
  onChange,
  options,
  size = 'large',
  theme = 'light',
  label,
  value,
  type = 'one-line',
  disabled,
  ...rest
}: IProps<T>) => {
  const space = 20;
  const [open, setOpen] = useState(false);
  const [openDirection, setOpenDirection] = useState<'down' | 'up'>('down');
  const dropdownRef = useRef<HTMLDivElement>(null);

  const { ref } = useElementClickedOutside(() => setOpen(false));

  const toggleDropdown = () => {
    if (!disabled) {
      setOpen(!open);
    }
  };

  useEffect(() => {
    if (open) {
      const dropdownRect = dropdownRef.current?.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      if (dropdownRect) {
        const spaceBelow = windowHeight - dropdownRect.bottom;
        const spaceAbove = dropdownRect.top;

        if (spaceBelow < 200 && spaceAbove > spaceBelow) {
          setOpenDirection('up');
        } else {
          setOpenDirection('down');
        }
      }
    }
  }, [open]);

  const handleSelect = (option: T) => {
    setOpen(false);
    onChange(option);
  };

  const selectedOptionLabel = useMemo(() => {
    const option = options.find((opt) => opt.value === value);
    if (option) return option.label;

    return value;
  }, [value, options]);

  return (
    <div ref={ref} className={clsx(styles.dropdown, className)} {...rest}>
      <span
        ref={dropdownRef}
        className={clsx(
          styles.selected,
          { [styles.openState]: open },
          { [styles.error]: hasErrors },
          styles[`border-${type}`],
          styles[theme],
          styles[size]
        )}
        onClick={toggleDropdown}
      >
        {selectedOptionLabel ? (
          <span className={styles.value}>{selectedOptionLabel}</span>
        ) : (
          <span className={styles.label}>{label}</span>
        )}{' '}
        <Arrow className={styles.openIcon} />
      </span>
      {open && (
        <ul
          className={clsx(styles.optionsList, styles[theme], styles[size])}
          style={{
            bottom: openDirection === 'up' ? space : 'auto',
            top: openDirection === 'down' ? space : 'auto',
          }}
        >
          {options.map((option, index) =>
            option.label !== '<split>' ? (
              <li
                key={index}
                onClick={() => handleSelect(option.value)}
                // style={{ height }}
              >
                {option.value === value && (
                  <CheckMarkIcon className={styles.checkMarkIcon} />
                )}
                {option.label}
              </li>
            ) : (
              <div className={styles.optionSplit} key={`split-${index}`}></div>
            )
          )}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
