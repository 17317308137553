import React, { useState } from 'react';
import Button from '../../Components/Common/DomainButton/DomainButton';
import Input from '../../Components/Common/Inputs/InputV1/InputV1';
import closeSvg from '../../Assets/icons/close.svg';
import worldSvg from '../../Assets/icons/world.svg';
import s from './popup.module.scss';
import { Formik, Form } from 'formik';
import { FormInput } from 'Components/Common/Inputs/FormInput/Input';
import * as Yup from 'yup';
import Spinner from 'Components/Common/Spinner/Spinner';

export interface IValues {
  domainName: string;
}
interface IProps {
  onClose: () => void;
  open: boolean;
  isLoading?: boolean;
  onSubmit: (values: IValues) => void;
}

const AddDomainPopup = (props: IProps) => {
  return props.open ? (
    <div className={s.addDomainWrapper}>
      <div className={s.modal}>
        <img
          className={s.closeIcon}
          src={closeSvg}
          alt="x"
          onClick={() => props.onClose()}
        />
        <img className={s.worldIcon} src={worldSvg} alt="O" />
        <h1 className={s.headerText}>Add your Own Domain</h1>
        <p className={s.addDomainText}>
          Enter the domain name you already own below and we will take care of
          the rest.
        </p>
        <Formik
          enableReinitialize={true}
          initialValues={{ domainName: '' }}
          validationSchema={Yup.object({
            domainName: Yup.string()
              .matches(
                /^((http\w?):\/\/)?(www.)?[a-z0-9-]+(\.[a-z0-9-]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                'Enter correct url!'
              )
              .required('Please enter website'),
          })}
          validateOnBlur
          onSubmit={(values) => {
            if (props.onSubmit) {
              props.onSubmit(values);
            }
          }}
        >
          <Form className={''}>
            <div className={s.addDomainInputBlock}>
              <FormInput
                name="domainName"
                placeholder="For Example: www.yourname.com"
              />
            </div>
            <div className={s.buttonBlock}>
              {props.isLoading ? (
                <Spinner size={50} />
              ) : (
                <Button type="submit" isBlackBtn={true}>
                  Add Domain
                </Button>
              )}
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  ) : null;
};

export default AddDomainPopup;
