import { PayloadAction } from '@reduxjs/toolkit';
import {
  SET_TEMPLATES,
  SET_CATEGORIES,
  SET_FUNNEL_IDS,
} from './templatesActions';
import { ICategory, ITemplateItem } from 'types';

export interface ITemplatesState {
  items: ITemplateItem[];
  categories: ICategory[];
  funnelIds: number[];
}

const initialState: ITemplatesState = {
  items: [],
  categories: [],
  funnelIds: [],
};

const templateReducer = (
  state = initialState,
  action: PayloadAction<ITemplateItem[] | ICategory[] | number[]>
) => {
  switch (action.type) {
    case SET_TEMPLATES:
      return {
        ...state,
        items: action.payload as ITemplateItem[],
      };
    case SET_CATEGORIES:
      return {
        ...state,
        categories: action.payload as ICategory[],
      };
    case SET_FUNNEL_IDS:
      return {
        ...state,
        funnelIds: action.payload as number[],
      };
    default:
      return state;
  }
};

export { initialState, templateReducer };
