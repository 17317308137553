import React from 'react';
import s from './ErrorMessage.module.scss';

interface IProps {
  message?: string;
}

const ErrorMessage = (props: IProps) => {
  return (
    <div className={s.errorBlock}>
      <span>{props.message}</span>
    </div>
  );
};

export default ErrorMessage;
