import { BookleTemplateBlock } from 'types';
import Tabs from 'Components/Templates/Tabs/Tabs';
import ButtonStyleTab from './ButtonStyleTab/ButtonStyleTab';
import ContainerStyleBlock from '../ContainerStyleBlock/ContainerStyleBlock';

import styles from './ButtonBlockStylesContainer.module.scss';

const ButtonBlockStylesContainer = ({
  item,
}: {
  item: BookleTemplateBlock;
}) => {
  return (
    <div className={styles.container}>
      <Tabs
        className={styles.tabsWrapper}
        tabsHeader={styles.tabsHeader}
        contentClassName={styles.contentContainer}
        titleClassName={styles.titleContainer}
        hideAdditionalLines
        tabs={[
          {
            title: 'Style',
            content: <ButtonStyleTab item={item} />,
          },
          {
            title: 'Container',
            content: <ContainerStyleBlock item={item} showAlignment />,
          },
        ]}
      />
    </div>
  );
};

export default ButtonBlockStylesContainer;
