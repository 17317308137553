import { useEffect, useMemo, useRef, useState } from 'react';
import Dropdown from 'UILib/Dropdown/Dropdown';
import Carousel from 'UILib/Carousel/Carousel';
import Input from 'UILib/Input/Input';

import styles from './ChooseStyleForm.module.scss';

const tones = [
  { label: 'Professional', value: 'professional' },
  { label: 'Semi-Professional', value: 'semi-professional' },
  { label: 'Casual', value: 'casual' },
  { label: 'Friendly', value: 'friendly' },
  { label: 'Inspirational', value: 'inspirational' },
  { label: 'Humorous', value: 'humorous' },
  { label: 'Urgent', value: 'urgent' },
  { label: 'Confident', value: 'confident' },
  { label: 'Empathetic', value: 'emphatic' },
  { label: 'Playful', value: 'playful' },
  { label: 'Authoritative', value: 'Authoritative' },
  { label: 'Custom', value: 'custom' },
];

const styleCards = [
  {
    id: 0,
    label: '3D Cartoon',
    image: 'https://cdn.autofunnel.ai/covers/3D-Cartoon.jpg',
  },
  {
    id: 1,
    label: 'Bohemian',
    image: 'https://cdn.autofunnel.ai/covers/Bohemian.jpg',
  },

  {
    id: 2,
    label: 'Flat Design',
    image: 'https://cdn.autofunnel.ai/covers/Flat-Design.jpg',
  },
  {
    id: 3,
    label: 'Isometric',
    image: 'https://cdn.autofunnel.ai/covers/Isometric.jpg',
  },
  {
    id: 4,
    label: 'Monochrome',
    image: 'https://cdn.autofunnel.ai/covers/Monochrome.jpg',
  },
  {
    id: 5,
    label: 'Pop Art Minimal',
    image: 'https://cdn.autofunnel.ai/covers/Pop-Art-Minimal.jpg',
  },
  {
    id: 6,
    label: 'Synthwave',
    image: 'https://cdn.autofunnel.ai/covers/Synthwave.jpg',
  },
  {
    id: 7,
    label: 'Tropical Minimalism',
    image: 'https://cdn.autofunnel.ai/covers/Tropical-Minimalism.jpg',
  },
  {
    id: 8,
    label: 'Vintage Retro',
    image: 'https://cdn.autofunnel.ai/covers/Vintage-Retro.jpg',
  },
];

interface IProps {
  data: Record<string, string | number | boolean>;
  validationFailed: boolean;
  onValueChange: (key: string, value: string | number | boolean) => void;
  fields: Record<string, { label: string; name: string; required?: boolean }>;
}

const ChooseStyleForm = ({
  data,
  fields,
  validationFailed,
  onValueChange,
}: IProps) => {
  const tone = data[fields.tone?.name];
  const [isCustomSelected, setIsCustomSelected] = useState(
    !tones.some((el) => el.value === tone) && !!tone
  );
  const toneRef = useRef(tone);

  const selectedIndex = useMemo(() => {
    let index = styleCards.findIndex(
      (card) => card.label === data[fields.style?.name]
    );
    if (index >= 0) return index;

    index = 0;
    onValueChange(fields.style?.name, styleCards[index].label); //TODO: not sure if we need this one
    return index;
  }, [data, fields.style?.name, onValueChange]);

  useEffect(() => {
    toneRef.current = tone;
  }, [tone]);

  useEffect(() => {
    return () => {
      if (!toneRef.current) {
        onValueChange(fields.tone?.name, 'semi-professional');
      }
    };
  }, []);

  const handleChangeSelect = (value: string) => {
    const isCustom = value === 'custom';
    setIsCustomSelected(isCustom);
    onValueChange(fields.tone?.name, !isCustom ? value : '');
  };

  return (
    <div className={styles.container}>
      <Dropdown
        label={fields.tone?.label}
        onChange={(newValue) => handleChangeSelect(newValue as string)}
        value={isCustomSelected ? 'Custom' : tone}
        options={tones}
        hasErrors={validationFailed && fields.tone?.required && !tone}
      />
      {isCustomSelected && (
        <Input
          className={styles.customToneInput}
          value={tone as string}
          placeholder="Custom Tone"
          onChange={(e) => onValueChange(fields.tone?.name, e.target.value)}
        />
      )}
      <div className={styles.stylePreview}>
        <Carousel
          cards={styleCards}
          selectedCardIndex={selectedIndex}
          onCardSelect={(index) => {
            const card = styleCards[index];
            onValueChange(fields.style?.name, card.label);
          }}
        />
      </div>
    </div>
  );
};

export default ChooseStyleForm;
