export const ADD_DOMAIN_MUTATION = {
  operationName: 'addDomain',
  query: `mutation addDomain(
      <KEYS>
    ) {
      addDomain(
        <VALUES>
      ) {
        _id
        name
        projectId
        status
      }
    }`,
};
