import { useEffect, useState } from 'react';
import { getBySocket } from 'utils/Utils';
import Loader from 'UILib/Loader/Loader';
import { ReactComponent as CloseSvg } from 'Assets/icons/book/close.svg';
import { ReactComponent as OpenBookSvg } from 'Assets/icons/book/open-book.svg';

import styles from './ExportBookPopup.module.scss';

interface IProps {
  bookId: string;
  exportFormat: string;
  onClose: () => void;
  onComplete: (link: string) => void;
  initialLink: null | string;
}

const ExportBookPopup = (props: IProps) => {
  const [loading, setLoading] = useState(true);
  const [url, setUrl] = useState('');

  const handleClose = () => {
    props.onClose();
  };

  const getGeneratedLink = async () => {
    let resultLink: string;
    if (props.initialLink) {
      setLoading(false);
      resultLink = props.initialLink;
    } else {
      setLoading(true);
      const result: any = await getBySocket({
        emitEventName: 'export-book',
        resultEventName: 'book-was-exported',
        payload: {
          bookId: props.bookId,
          exportFormat: props.exportFormat,
        },
      });
      setLoading(false);
      resultLink = result.link;
    }
    setUrl(resultLink);
    props.onComplete(resultLink);
  };

  useEffect(() => {
    getGeneratedLink();

    return () => {
      setLoading(true);
      setUrl('');
    };
  }, []);

  return (
    <div className={styles.popup}>
      <div className={styles.overlay} onClick={handleClose} />
      <div className={styles.wrapper}>
        <CloseSvg className={styles.closeIcon} onClick={handleClose} />
        <div className={styles.mainContent}>
          <OpenBookSvg />
          <div className={styles.title}>Download Link</div>
          <div className={styles.hintMessage}>
            {loading
              ? 'Once the file is created, the download will start automatically'
              : 'If your download does not start automatically, please click the link below:'}
          </div>
          {loading ? (
            <div className={styles.loader}>
              <Loader />
              <div className={styles.loadingMessage}>Export In progress...</div>
            </div>
          ) : (
            <div className={styles.exportContainer}>
              <button
                className={styles.downloadButton}
                onClick={() => props.onComplete(url)}
              >
                Download
              </button>
              <div className={styles.or}>or</div>
              <a
                href={url}
                className={styles.exportLink}
                download
                target="_blanc"
              >
                {url}
              </a>
            </div>
          )}
        </div>
        {!loading && (
          <div className={styles.noteMessage}>
            Note: This link will remain active for 12 hours.
          </div>
        )}
      </div>
    </div>
  );
};

export default ExportBookPopup;
