export const FETCH_FUNNELS_NAME_QUERY = {
  operationName: 'getFunnelNames',
  query: `query getFunnelNames {
      getFunnelNames {
        _id
        name
        position
        domain
      }
    }`,
};
