export const GET_USER_CONTACT_TAGS_WITH_PAGINATION = {
  operationName: 'getUserContactTagsWithPagination',
  query: `query getUserContactTagsWithPagination($limit: Float!, $skip: Float!, $sortAsc: Boolean, $sortBy: String, $search: String) {
  getUserContactTagsWithPagination(limit: $limit, skip: $skip, sortAsc: $sortAsc, sortBy: $sortBy, search: $search) {
    tagList {
       tag {
       _id
       name
       createdAt
       }
      count
    }
    total
    }
  }`,
};
