import { RootState } from '../rootReducer';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
export const ADD_ALERT = 'ADD_ALERT';
export const DELETE_ALERT = 'DELETE_ALERT';

export const pushAlert = (
  alertType: string,
  text: string,
  description: string = ''
) => (dispatch: ThunkDispatch<RootState, void, Action>) => {
  console.error(alertType, text, description);
};
