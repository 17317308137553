export const GET_AI_TOPIC_QUERY = {
  operationName: 'getAiTextTopicsByUser',
  query: `query getAiTextTopicsByUser($id: String!) {
      getAiTextTopicsByUser(id: $id) {
        _id
        name
        createdAt
        updatedAt
      }
    }`,
};
