export const STRIPE_SESSION_INFO_QUERY = {
  operationName: 'getSessionInfo',
  query: `query getSessionInfo(
      $sessionId: String!
    ) {
      getSessionInfo(
        sessionId: $sessionId
      ) {
        user
      }
    }`,
};
