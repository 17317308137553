export const GET_ALL_AI_TEXT_TOPICS = {
  operationName: 'getAllAiTextTopicsByUser',
  query: `query getAllAiTextTopicsByUser {
      getAllAiTextTopicsByUser {
        _id
        name
        createdAt
        updatedAt
      }
    }`,
};
