export const GET_CONTACT = {
  operationName: 'getContact',
  query: `getContact {
  getContact (id: _id ) {
    _id
    user { _id }
    funnel { _id }
    page { _id }
    email
    phone
    tags
    data
    createdAt
    updatedAt
  }`,
};
