import React, { useState } from 'react';
import Input from 'UILib/Input/Input';

type TimeInputProps = React.ComponentProps<typeof Input> & {
  onTimeChange?: (time: string) => void;
};

export const isValid = (val: string) => {
  const regexp = /^\d{0,2}?\:?\d{0,2}$/;

  const [hoursStr, minutesStr] = val.split(':');

  if (!regexp.test(val)) {
    return false;
  }

  const hours = Number(hoursStr);
  const minutes = Number(minutesStr);

  const isValidHour = (hour: number) =>
    Number.isInteger(hour) && hour >= 0 && hour < 24;

  const isValidMinutes = (minutes: number) =>
    (Number.isInteger(minutes) && hours >= 0 && hours < 24) ||
    Number.isNaN(minutes);

  if (!isValidHour(hours) || !isValidMinutes(minutes)) {
    return false;
  }

  if (minutes < 10 && Number(minutesStr[0]) > 5) {
    return false;
  }

  const valArr = val.indexOf(':') !== -1 ? val.split(':') : [val];

  if (
    valArr[0] &&
    valArr[0].length &&
    (parseInt(valArr[0], 10) < 0 || parseInt(valArr[0], 10) > 23)
  ) {
    return false;
  }

  if (
    valArr[1] &&
    valArr[1].length &&
    (parseInt(valArr[1], 10) < 0 || parseInt(valArr[1], 10) > 59)
  ) {
    return false;
  }

  return true;
};

const TimeInput = ({
  value,
  onTimeChange,
  placeholder = 'HH:MM',
  ...rest
}: TimeInputProps) => {
  const [time, setTime] = useState(value || '');

  let lastVal = '';

  const onChangeHandler = (val: string) => {
    if (val === time) {
      return;
    }
    if (isValid(val)) {
      if (val.length === 2 && lastVal.length !== 3 && val.indexOf(':') === -1) {
        val = val + ':';
      }

      if (val.length === 2 && lastVal.length === 3) {
        val = val.slice(0, 1);
      }

      if (val.length > 5) {
        return false;
      }

      lastVal = val;

      setTime(val);

      if (val.length === 5) {
        if (onTimeChange) onTimeChange(val);
      }
    }
  };

  return (
    <Input
      type="tel"
      border="stroke"
      placeholder={placeholder}
      value={time}
      onChange={(e) => onChangeHandler(e.target.value)}
      {...rest}
    />
  );
};

export default TimeInput;
