export const GET_USER_CONTACTS_WITH_PAGINATION = {
  operationName: 'getUserContactsWithPagination',
  query: `query getUserContactsWithPagination($limit: Float!, $skip: Float!, $sortAsc: Boolean, $sortBy: String, $search: String, $smartListId: String) {
    getUserContactsWithPagination (skip: $skip, limit: $limit, sortAsc: $sortAsc, sortBy: $sortBy, search: $search, smartListId: $smartListId) {
      contacts {
        _id
        user { _id }
        funnel { 
          _id
          name 
        }
        page {
          _id
          title
        }
        email
        phone
        tags {
          _id
          name
        }
        data
        createdAt
        updatedAt
      }
      total
    }
  }`,
};
