export const GET_ALL_GENERATION_TEMPLATES = {
  operationName: 'getAllGenerationTemplates',
  query: `query getAllGenerationTemplates {
          getAllGenerationTemplates {
            _id
            createdAt
            updatedAt
          }
        }`,
};
