import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import clsx from 'clsx';
import closeSvg from 'Assets/icons/close.svg';
import { RootState } from 'store/rootReducer';
import { selectFunnel } from 'store/domains/domainsActions';
import { IDomain, IGeneralProject, IGeneralProjectName } from 'types';
import Button1 from 'Styled/Button1';

import 'react-dropdown/style.css';
import s from './popup.module.scss';

interface IProps {
  onClose: () => void;
  open: boolean;
  activeProject: IGeneralProject | null;
  projects: IGeneralProject[];
  domains: IDomain[];
  funnels: IGeneralProjectName[];
  selectFunnel: (id: string, projectId: string) => void;
}

const AllDomainsPopup = (props: IProps) => {
  const handleAssociateFunnel = (domainId: string) => {
    if (props.activeProject) {
      props.selectFunnel(domainId, props.activeProject?._id);
      props.onClose();
    }
  };

  const isDomainUsed = (domainId: string): boolean => {
    const domain = props.domains.find((domain) => domain._id === domainId);
    return !!domain?.projectId;
  };

  const getFunnelName = (projectId: string | undefined): string => {
    if (!projectId) return '';
    const funnel = props.funnels.find((funnel) => funnel._id === projectId);
    return funnel ? funnel.name : 'Unknown Funnel';
  };

  return props.open ? (
    <div
      className={s.allDomainPopupWrapper}
      onClick={(e: any) => {
        if (e.target.className === s.allDomainPopupWrapper) {
          props.onClose();
        }
      }}
    >
      <div
        className={s.modal}
        style={props.domains.length ? {} : { height: '330px' }}
      >
        <img
          className={s.closeIcon}
          src={closeSvg}
          alt="x"
          onClick={() => props.onClose()}
        />
        {props.domains.length ? (
          <>
            <div className={s.allDomainsPopupHeaderText}>
              Select domain to connect
            </div>
            <div className={s.allDomainsBlock}>
              {props.domains.map((domain: IDomain) => (
                <div
                  key={domain._id}
                  onClick={() => {
                    if (!domain.projectId) {
                      handleAssociateFunnel(domain._id);
                    }
                  }}
                  className={clsx(s.allDomainsPopupItem, {
                    [s.usedDomain]: isDomainUsed(domain._id),
                  })}
                >
                  <div>{domain.name}</div>
                  {domain.projectId && (
                    <div className={s.hoverText}>
                      Domain is in use by {getFunnelName(domain.projectId)}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </>
        ) : (
          <div className={s.allDomainsPopupWithoutDomains}>
            <h2 className={s.allDomainsPopupHeaderText}>
              You don't have any domains connected yet
            </h2>
            <p>Head on to settings and connect your domain:</p>
            <div className={s.allDomainsPopupWithoutDomainsBtn}>
              <Link to="/console/settings/domains">
                <Button1 height={50} width={250} fontSize={20}>
                  Connect Domain
                </Button1>
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  ) : null;
};

const mapStateToProps = (state: RootState) => ({
  projects: state.projects.items,
  domains: state.domains.items,
  activeProject: state.projects.active,
  funnels: state.projects.projectsName,
});

const mapDispatchToProps = {
  selectFunnel: (id: string, projectId: string) => selectFunnel(id, projectId),
};

export default connect(mapStateToProps, mapDispatchToProps)(AllDomainsPopup);
