import clsx from 'clsx';
import { ReactComponent as SearchIcon } from 'Assets/icons/search.svg';
import { ReactComponent as RemoveIcon } from 'Assets/icons/remove-icon.svg';

import styles from './SearchInput.module.scss';

interface IProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  theme?: 'light' | 'dark';
  border?: 'stroke' | 'one-line';
  onChange: (value: string) => void;
}

const SearchInput = ({
  theme = 'light',
  border = 'one-line',
  className,
  placeholder,
  onChange,
  ...rest
}: IProps) => {
  return (
    <div
      className={clsx(
        styles.container,
        styles[theme],
        {
          [styles[`border-${border}`]]: border,
        },
        className
      )}
    >
      <input
        className={styles.input}
        placeholder={placeholder ?? 'Type to Search'}
        onChange={(e) => onChange(e.target.value)}
        {...rest}
      />
      {rest.value && rest.value.toString().length > 0 ? (
        <RemoveIcon className={styles.xIcon} onClick={() => onChange('')} />
      ) : (
        <SearchIcon className={styles.searchIcon} />
      )}
    </div>
  );
};

export default SearchInput;
