export const CREATE_USER_MUTATION = {
  operationName: 'createUser',
  query: `mutation createUser(
      $email: String!
      $name: String!
      $subdomain: String!
    ) {
      createUser(
        email: $email
        name: $name
        subdomain: $subdomain
      ) {
        _id
        name
        owner
        email
        role
      }
    }`,
};
