export const CREATE_CONTACT = {
  operationName: 'createContact',
  query: `mutation createContact(
    $funnelId: String!
    $pageId: String!
    $email: String!
    $phone: String
    $tagsId: [String!]!
    $data: String
  ) {
    createContact(
      funnelId: $funnelId
      pageId: $pageId
      email: $email
      phone: $phone
      tagsId: $tagsId
      data: $data
    ) {
      _id
      user { _id }
      funnel { _id }
      page { _id }
      email
      phone
      tags {
        _id
        name
      }
      data
      createdAt
      updatedAt
    }
  }`,
};
