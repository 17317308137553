import clsx from 'clsx';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useLayoutEffect, useState, useEffect, useRef } from 'react';
import { fetchUserAccess } from 'store/user/userActions';
import { DispatchType, RootState } from 'store/rootReducer';
import {
  setActiveAccountAction,
  fetchAccountsAction,
} from 'store/accounts/accountsActions';
import {
  isAdmin,
  getUserId,
  getUserName,
  getActiveSubId,
  switchSubAccount,
} from 'utils/Utils';
import { ReactComponent as ChangePageSettingSvg } from 'Assets/icons/changePageSetting.svg';
import { ReactComponent as ChangePageLogoutSvg } from 'Assets/icons/changePageLogout.svg';
import { ReactComponent as ChangePageHelpSvg } from 'Assets/icons/changePageHelpCenter.svg';
import { ReactComponent as SettingsIcon } from 'Assets/icons/contextMenu/settingsIcon.svg';
import { ReactComponent as AccountIcon } from 'Assets/icons/blankThumbnail.svg';
import { ReactComponent as RocketIcon } from 'Assets/icons/rocket.svg';
import { ReactComponent as DotsSvg } from 'Assets/icons/copyCraft/dots.svg';
import { ReactComponent as GoBack } from 'Assets/icons/sideMenu/go-back.svg';
import LogoSvg from 'Assets/icons/sideMenu/logo.svg';
import { IAccount } from 'Components/Accounts/types';
import AgenciesDropDown from 'Components/Accounts/AgenciesDropDown/AgenciesDropDown';
import { truncateString } from 'utils/helpers';
import { UseOnClickOutside } from 'utils/UseOnClickOutside';
import { menuItems, pageTypes, submenuItems } from './constants';
import { IProps, ActiveSettingPageType, ActivePageType } from './types';

import s from './SideMenu.module.scss';

const SideMenu = (props: IProps) => {
  const subAccounts = props.accounts;

  const history = useHistory();
  const location = useLocation();
  const [activePage, setActivePage] = useState<
    ActivePageType | ActiveSettingPageType | null
  >(null);
  const [accountAccessComplete, setAccountAccessComplete] = useState<boolean>(
    false
  );
  const [subMenu, setSubMenu] = useState<'outreach' | 'settings'>();
  const [openAgencyMenu, setOpenAgencyMenu] = useState(false);
  const [activeAccount, setActiveAccount] = useState<{
    name?: string;
    thumbnail?: string;
  }>();
  const modalRef = useRef<HTMLDivElement>(null);
  UseOnClickOutside(modalRef, (e) => setOpenAgencyMenu(false));

  const hasAutoSaasAccess = props.userDetails.scopes?.includes('SAAS_OWNER');
  const hasAgencyAccess =
    props.userDetails.scopes?.includes('AGENCY_OWNER') ||
    props.userDetails.scopes?.includes('ADMIN');

  useLayoutEffect(() => {
    const init = async () => {
      for (const e of pageTypes) {
        const path = location.pathname.split('/');
        if (path[path.length - 1] === e) {
          setActivePage(e as ActivePageType | ActiveSettingPageType);
          break;
        }
      }
      await props.getAccountAccess();
      setAccountAccessComplete(true);
    };

    init();
  }, []);

  useEffect(() => {
    const id = getActiveSubId();

    const account = subAccounts.find((e) => e._id === id);

    setActiveAccount(
      account
        ? { name: account.name, thumbnail: account.thumbnail }
        : {
            name: props.userDetails.name,
            thumbnail: props.userDetails.thumbnail,
          }
    );
  }, [subAccounts, props.userDetails]);

  const fetchSubAccounts = async () => {
    props.getAccounts();
  };

  const getUserAccess = async () => {
    let access = {
      ...props.userAccess,
    };

    const location = window.location.pathname;

    if (
      (!access.bookle && location.includes('books')) ||
      (!access.funnel && location.includes('projects')) ||
      (!access.copyCraft && location.includes('copy-craft')) ||
      (!access.agency && location.includes('agency')) ||
      (!props.userDetails.scopes?.includes('SAAS_OWNER') &&
        location.includes('autosaas')) ||
      (!access.podcast && location.includes('podcaster'))
    ) {
      window.open('/console/settings', '_self');
    }

    if (access.agency) {
      await fetchSubAccounts();
    }
    if (
      !access.bookle &&
      !access.copyCraft &&
      !access.funnel &&
      // !access.contacts &&
      props.onShowNoSubscriptionAlert
    ) {
      props.onShowNoSubscriptionAlert();
    }
  };

  useEffect(() => {
    if (accountAccessComplete) {
      getUserAccess();
    }
  }, [
    props.userAccess,
    props.onShowNoSubscriptionAlert,
    accountAccessComplete,
  ]);

  const handleLinkClick = (
    link: string,
    newActivePage?: ActivePageType | ActiveSettingPageType
  ) => {
    history.push(link);
    if (newActivePage) {
      setActivePage(newActivePage);
    }
  };

  const handleCLickManage = () => {
    history.push('/console/agency');
  };

  const handleSwitchAccount = async (account: IAccount) => {
    props.setActiveAccount(account);
  };

  const handleSwitchToAgencyAccount = async () => {
    const id = getUserId() || '';
    const owner = getUserName();
    await switchSubAccount(id, owner);
    const link = `/console/projects`;
    window.open(link, '_self');
  };

  useEffect(() => {
    if (!activePage) return;

    if (
      [
        'domains-senders',
        'domains',
        'billing-settings',
        'billing',
        'zapier',
      ].includes(activePage)
    ) {
      setSubMenu('settings');
    } else if (['outreach', 'templates', 'broadcasts'].includes(activePage)) {
      setSubMenu('outreach');
    } else if (activePage.includes('funnel-templates')) {
      setActivePage('projects');
    }
  }, [activePage]);

  const handleOpenAgencyMenu = (
    event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ) => {
    event.stopPropagation();
    event.preventDefault();

    setOpenAgencyMenu(true);
  };

  return (
    <div className={s.menuWrapper}>
      <div className={s.topItem}>
        <div className={s.logo}>
          <img
            src={props.autosaasLogo || LogoSvg}
            onClick={() => history.push('/console')}
            alt="logo"
          />
        </div>
        <div className={s.menuItems}>
          {subMenu ? (
            <>
              <div
                className={clsx(s.menuItem, s.topItem)}
                onClick={() => setSubMenu(undefined)}
              >
                <div className={s.menuIcon}>
                  <GoBack />
                </div>
                <span>Go Back</span>
              </div>
              {submenuItems[subMenu].map(
                (e, index) =>
                  !!e.access && (
                    <div
                      className={clsx(s.menuItem, s.topItem, {
                        [s.activeItem]:
                          (activePage === subMenu && index === 0) ||
                          activePage === e.key,
                      })}
                      onClick={() =>
                        handleLinkClick(
                          e.redirectUrl,
                          (e.key as ActiveSettingPageType) || undefined
                        )
                      }
                      key={e.key}
                    >
                      <div className={s.menuIcon}>{e.icon}</div>
                      <span>{e.name}</span>
                    </div>
                  )
              )}
            </>
          ) : (
            <>
              {hasAutoSaasAccess ? (
                <div
                  className={clsx(s.menuItem, {
                    [s.activeItem]: activePage === 'autosaas',
                  })}
                  onClick={() =>
                    handleLinkClick('/console/autosaas', 'autosaas')
                  }
                >
                  <RocketIcon />
                  <span>AutoSaas</span>
                </div>
              ) : (
                hasAgencyAccess && (
                  <div
                    className={clsx(s.menuItem, s.agencyItem, {
                      [s.activeItem]: activePage === 'agency',
                    })}
                    onClick={() => handleLinkClick('/console/agency', 'agency')}
                  >
                    <div className={s.agencyImage}>
                      {activeAccount?.thumbnail ? (
                        <img
                          src={activeAccount?.thumbnail}
                          className={s.agencyLogo}
                          alt="agency logo"
                        />
                      ) : (
                        <span className={s.thumbnailBox}>
                          <AccountIcon className={s.photoPlaceholder} />
                        </span>
                      )}
                      <span>{truncateString(activeAccount?.name, 25)}</span>
                    </div>
                    <DotsSvg
                      onClick={handleOpenAgencyMenu}
                      className={s.dots}
                    />
                  </div>
                )
              )}
              {(hasAgencyAccess || hasAutoSaasAccess) && (
                <div ref={modalRef} className={s.agency}>
                  {openAgencyMenu && (
                    <AgenciesDropDown
                      accounts={subAccounts}
                      onSwitchAccount={handleSwitchAccount}
                      onManageClick={() => handleCLickManage()}
                      onSwitchToAgencyAccount={() =>
                        handleSwitchToAgencyAccount()
                      }
                      userDetails={props.userDetails}
                    />
                  )}
                </div>
              )}
              {menuItems(props.userAccess, isAdmin()).map(
                (e, index) =>
                  !!e.access && (
                    <div
                      className={clsx(s.menuItem, s.topItem, {
                        [s.activeItem]: activePage === e.key,
                      })}
                      onClick={() =>
                        handleLinkClick(
                          e.redirectUrl,
                          (e.key as ActivePageType) || undefined
                        )
                      }
                      key={index}
                    >
                      {e.icon}
                      <span className={s.pageName}>
                        {props.autoSassAppsNames?.find(
                          (item) =>
                            item?.key
                              ?.toLocaleLowerCase()
                              .toLocaleUpperCase() ===
                            e.autosaas?.key
                              ?.toLocaleLowerCase()
                              .toLocaleUpperCase()
                        )?.name || e.name}
                      </span>
                      {!!e.autosaas?.redirectUrl && hasAutoSaasAccess && (
                        <SettingsIcon
                          className={s.settingsIcon}
                          onClick={(ev) => {
                            ev.stopPropagation();
                            ev.preventDefault();
                            handleLinkClick(
                              e?.autosaas?.redirectUrl as string,
                              (e.key as ActivePageType) || undefined
                            );
                          }}
                        />
                      )}
                    </div>
                  )
              )}
            </>
          )}
        </div>
      </div>
      <div className={s.bottomItems}>
        <div
          className={clsx(s.menuItem)}
          onClick={() => handleLinkClick('/console/logout')}
        >
          <ChangePageLogoutSvg />
          <span>Log Out</span>
        </div>
        <div
          className={clsx(s.menuItem)}
          onClick={() => window.open('https://help.autofunnel.ai/')}
        >
          <ChangePageHelpSvg />
          <span>Info Center</span>
        </div>
        <div
          className={clsx(s.menuItem, {
            [s.activeItem]: activePage === 'settings',
          })}
          onClick={() => setSubMenu('settings')}
        >
          <ChangePageSettingSvg />
          <span>Settings</span>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  accounts: state.accounts.accounts,
  loading: state.accounts.loading,
  activeAccount: state.accounts.activeAccount,
  userAccess: state.user.userAccess,
  autosaasLogo: state.autosaas.logoUrl,
  userDetails: state.user.agencyDetails,
  autoSassAppsNames: state.autosaas.appsNames,
});

const mapDispatchToProps = (dispatch: DispatchType) => ({
  getAccounts: () => dispatch(fetchAccountsAction()),
  setActiveAccount: (account: IAccount | null) =>
    dispatch(setActiveAccountAction(account)),
  getAccountAccess: () => dispatch(fetchUserAccess()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);
