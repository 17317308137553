export const GET_PASSWORD_RECOVERY_TOKEN_INFO = {
  operationName: 'getPasswordRecoveryTokenInfo',
  query: `query getPasswordRecoveryTokenInfo(
      $token: String!
    ) {
      getPasswordRecoveryTokenInfo(
        token: $token
      ) {
        email
        name
        userId
      }
    }`,
};
