export const GET_PODCASTS_WITH_PAGINATION = {
  operationName: 'getPodcastsWithPagination',
  query: `query getPodcastsWithPagination ($skip: Float!, $limit: Float!, $search: String, $sortBy: String, $sortAsc: Boolean) {
    getPodcastsWithPagination(skip: $skip, limit: $limit, search: $search, sortBy: $sortBy, sortAsc: $sortAsc) {
      podcasts {
      _id
      name
      hostName
      image
      coverData
      hostName
      description
      createdAt
      updatedAt
      episodesCount
      }
      total
    }
  }`,
};
