import React, { useEffect, useRef } from 'react';
import s from './Zapier.module.scss';
import { fetchUserCustomData } from '../../store/user/userActions';
import { RootState } from '../../store/rootStore';
import { connect } from 'react-redux';
import { IUserDetails } from 'types';
import { ReactComponent as ZapierLogoSvg } from '../../Assets/icons/zapierLogo.svg';
import { ReactComponent as DuplicateSvg } from '../../Assets/icons/zapierDuplicate.svg';
import CopyToClipboard from 'react-copy-to-clipboard';

interface IProps {
  userDetails: IUserDetails;
  fetchUserDetails: () => void;
}

const Zapier = (props: IProps) => {
  useEffect(() => {
    props.fetchUserDetails();
  }, []);

  return (
    <div className={s.wrapper}>
      <div className={s.container}>
        <h2 className={s.contentHeader}>API Key</h2>
        <ZapierLogoSvg className={s.zapierLogo} />
        <h3 className={s.keyHeader}>Key for Zapier</h3>
        <div className={s.infoPanel}>
          <div className={s.keyBlock}>
            {props.userDetails._id ? (
              <>
                <span>{props.userDetails._id}</span>{' '}
                <CopyToClipboard text={props.userDetails._id}>
                  <DuplicateSvg />
                </CopyToClipboard>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  userDetails: state.user.details,
});

const mapDispatchToProps = {
  fetchUserDetails: () => fetchUserCustomData(),
};

export default connect(mapStateToProps, mapDispatchToProps)(Zapier);
