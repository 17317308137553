import React, { useEffect } from 'react';
import { Layout } from 'antd';
import { useHistory } from 'react-router-dom';

const CancelSubscription = () => {
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      history.replace('/console/purchase');
    }, 1500);
  });

  return (
    <Layout>
      <h2>Subscription canceled</h2>
    </Layout>
  );
};

export default CancelSubscription;
