import clsx from 'clsx';
import { connect } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { useState } from 'react';
import {
  deletePodcastEpisodeAction,
  fetchPodcastEpisodesAction,
} from 'store/podcasts/podcastActions';
import { RootState } from 'store/rootReducer';
import { validateUser } from 'utils/Utils';
import SideMenu from 'Components/SideMenu/SideMenu';
import PagePodcastDashboard from './PagePodcastDashboard/PagePodcastDashboard';
import PodcastPage from './PodcastPage/PodcastPage';
import burgerSvg from 'Assets/icons/burger.svg';

import s from './PagePodcast.module.scss';

const PagePodcast = () => {
  const { podcastId } = useParams<{ podcastId: string }>();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const userAuthed = validateUser();
  if (!userAuthed) {
    return <Redirect to={{ pathname: '/console/login' }} />;
  }

  return (
    <div className={s.podcastPage}>
      <div
        className={clsx(s.pagePodcastNavigationWrapper, {
          [s.moveLeft]: !isSidebarOpen,
        })}
      >
        <SideMenu />
        <div
          className={s.sidebarToggleBtnBlock}
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        >
          <img src={burgerSvg} alt="burger svg" />
        </div>
      </div>
      <main
        className={clsx(
          s.podcasterContainerContent,
          isSidebarOpen ? s.podcastContentRight : s.podcastContent
        )}
      >
        {!podcastId ? (
          <PagePodcastDashboard />
        ) : (
          <PodcastPage podcastId={podcastId} />
        )}
      </main>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  podcasts: state.podcasts.items,
  episodes: state.podcasts.podcastEpisodes,
  podcastsLoading: state.podcasts.podcastsLoading,
  episodesLoading: state.podcasts.episodesLoading,
});

const mapDispatchToProps = {
  fetchEpisodes: (podcastId: string) => fetchPodcastEpisodesAction(podcastId),
  deleteEpisode: (episodeId: string) => deletePodcastEpisodeAction(episodeId),
};

export default connect(mapStateToProps, mapDispatchToProps)(PagePodcast);
