export const GET_STATISTIC_QUERY = {
  operationName: 'getStatistic',
  query: `query getStatistic(
      $funnelId: String!
    ) {
      getStatistic(
        funnelId: $funnelId
      ) {
        statistic
      }
    }`,
};
