export const AF_CREATE_SETUP_INTENT_PAYMENT_UPDATE = {
  operationName: 'afCreateSetupIntentPaymentUpdate',
  query: `mutation afCreateSetupIntentPaymentUpdate(
        $owner: String!
      ) {
        afCreateSetupIntentPaymentUpdate(
            owner: $owner
        ) {
            client_secret
        }
      }`,
};
