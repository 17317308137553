import { useMemo } from 'react';
import { Descendant } from 'slate';
import { ReactComponent as ListBullets } from 'Assets/icons/ListBullets.svg';
import NodeTextEditor from 'Editors/NodeTextEditor/NodeTextEditor';
import TemplateNode, { INode, INodeEvent } from './Node';
import { convertBlocksToString, convertTextToBlocks } from '../helpers';

import styles from './Node.module.scss';

export interface INodeGenerateList extends INode {
  prompt: string;
}

interface IProps {
  node: INodeGenerateList;
  variables?: string[];
  onChange: (node: INode) => void;
  onEvent: (event: INodeEvent) => void;
}

const GenerateList = (props: IProps) => {
  const initialPromptValue: Descendant[] = useMemo(() => {
    return convertTextToBlocks(props.node.prompt);
  }, [props.node.prompt]);

  const handleTextChange = (blocks: Descendant[]) => {
    const finalText = convertBlocksToString(blocks);

    const newNode = { ...props.node, prompt: finalText };
    props.onChange(newNode);
  };

  return (
    <TemplateNode
      node={props.node}
      onChange={props.onChange}
      onEvent={props.onEvent}
      headerColor="#e09531"
      icon={<ListBullets />}
    >
      <NodeTextEditor
        editorClassName={styles.textEditor}
        tagsList={props.variables ?? []}
        initialValue={initialPromptValue}
        onChange={handleTextChange}
      />
    </TemplateNode>
  );
};

export default GenerateList;
