import React from 'react';
import Modal from 'Components/Common/Modal2/Modal';
import s from './Popups.module.scss';

interface IProps {
  open: boolean;
  onClose: () => void;
}

const NoSubscriptionPopup = (props: IProps) => {
  const handleClose = () => {
    props.onClose();
  };

  return (
    <Modal displayModal={props.open} closeModal={() => handleClose()}>
      <div className={s.modal}>
        <h2 className={s.header}>No active subscription</h2>
        <p className={s.additionalText}>
          Please resubscribe <a href="https://autofunnel.ai">here</a> or contact
          us to update your billing info at support@autofunnel.ai
        </p>
      </div>
    </Modal>
  );
};

export default NoSubscriptionPopup;
