export const GET_AI_TEMPLATE = {
  operationName: 'getOneAiTemplate',
  query: `query getOneAiTemplate(
      $id: String!
    ) {
      getOneAiTemplate(
        id: $id
      ) {
        _id
        name
        settings {
          chaptGptModel
          imageServer
        }
        questions {
          id
          label
        }
        prompts {
          id
          type
          prompt
        }
        sections {
          templateName
          content {
            aiField
            content
          }
        }
        createdAt
        updatedAt
        thumbnail
    }
  }`,
};
