export const GET_BOOK_FOR_EDIT_BY_ID = {
  operationName: 'getBookByIdForEdit',
  query: `query getBookByIdForEdit (
        $id: String!
      ) {
        getBookByIdForEdit (
          id: $id
        ) {
          title
          _id
          chapters {
            title
            text
            comments
          }
          data
          coverData
          description
          tone
          audience
          structure
          coverImageUrl
          generating
        }
      }`,
};
