import styled from 'styled-components';

interface IProps {
  disabled?: boolean;
  height?: number;
  width?: number;
  fontSize?: number;
}

export default styled.button`
  width: ${(props: IProps) => (props.width ? props.width : 158)}px;
  max-width: 100%;
  height: ${(props: IProps) => (props.height ? props.height : 50)}px;
  background: #4957d8;
  border-radius: 2px;

  font-style: normal;
  font-weight: 500;
  font-size: ${(props: IProps) => (props.fontSize ? props.fontSize : 16)}px;

  border: none;
  border-radius: 4px;
  color: #fff;

  cursor: ${(props: IProps) => (props.disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    background: #ff5a44;
  }
`;
