export const GET_SIGNUP_TOKEN_INFO = {
  operationName: 'getSignupTokenInfo',
  query: `query getSignupTokenInfo(
      $token: String!
    ) {
      getSignupTokenInfo(
        token: $token
      ) {
        email
        name
      }
    }`,
};
