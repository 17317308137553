export const UPDATE_CONTACT = {
  operationName: 'updateContact',
  query: `mutation updateContact(
    $id: String!
    $email: String
    $phone: String
    $tagsId: [String!]
    $data: String
  ) {
    updateContact(
      id: $id
      email: $email
      phone: $phone
      tagsId: $tagsId
      data: $data
    ) {
      _id
      user { _id }
      funnel { _id }
      page { _id }
      email
      phone
      tags {
        name
        _id
        createdAt
      }
      data
      createdAt
      updatedAt
    }
  }`,
};
