export const GET_MAIL_DOMAINS_QUERY = {
  operationName: 'getMailDomains',
  query: `query getMailDomains {
        getMailDomains {
            _id
            name
            status
            createdAt
            updatedAt
            dnsRecords {
                cached
                name
                priority
                record_type
                valid
                value
            }
        }
    }`,
};
