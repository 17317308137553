export const UPDATE_DOMAIN_MUTATION = {
  operationName: 'updateDomain',
  query: `mutation updateDomain(
      <KEYS>
    ) {
      updateDomain(
        <VALUES>
      ) {
        _id
        name
        projectId
        status
      }
    }`,
};
