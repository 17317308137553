export const CREATE_AI_TEMPLATE = {
  operationName: 'createAiTemplate',
  query: `mutation createAiTemplate(
      $data: String!
    ) {
      createAiTemplate(
        data: $data
      ) {
        _id
        name
        settings {
          chaptGptModel
          imageServer
        }
    }
  }`,
};
