import React from 'react';
import { Redirect } from 'react-router-dom';
import { validateUser } from '../utils/Utils';

const PageHome = () => {
  const redirect = validateUser() ? '/console/projects' : '/console/purchase';
  return <Redirect to={{ pathname: redirect }} />;
};

export default PageHome;
