export const STRIPE_SUBSCRIBE_MUTATION = {
  operationName: 'subscribeCurrentUser',
  query: `mutation subscribeCurrentUser(
      $priceId: String!
      $successUrl: String!
      $cancelUrl: String!
    ) {
      subscribeCurrentUser(
        priceId: $priceId
        successUrl: $successUrl
        cancelUrl: $cancelUrl
      ) {
        sessionId
      }
    }`,
};
