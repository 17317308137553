export const CONFIRM_TRANSFER_TO_AGENCY = {
  operationName: 'confirmTransferToAgency',
  query: `mutation confirmTransferToAgency(
      $token: String!
      $initiator: String!
    ) {
      confirmTransferToAgency(
        token: $token
        initiator: $initiator
      ) {
        _id
        name
        owner
        email
        notes
        thumbnail
        requestedAgencyId
      }
    }`,
};
