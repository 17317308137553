export const CREATE_PODCAST_MUTATION = {
  operationName: 'createPodcast',
  query: `mutation createPodcast(
        $name: String!
        $imageUrl: String
        $colorsSchemeType: String
        $templateType: String
        $hostName: String!
      ) {
        createPodcast(
          name: $name
          imageUrl: $imageUrl
          colorsSchemeType: $colorsSchemeType
          templateType: $templateType
          hostName: $hostName
        ) {
          _id
          name
          image
          colorsSchemeType
          templateType
          coverData
          image
          description
          createdAt
          updatedAt
        }
      }`,
};
