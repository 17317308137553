import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { graphQlCall } from 'graphql/utils';
import { api, getToken } from 'utils/Utils';
import { PAGECRAFT_API_URL } from 'Constants';
import { convertCamelToKebab, transformAutoSaasData } from 'utils/helpers';
import { ReactComponent as Arrow } from 'Assets/icons/arrow.svg';
import Button from 'UILib/Button/Button';
import queries from 'graphql/queries';
import Dropdown from 'UILib/Dropdown/Dropdown';
import ColorSelector from 'Components/ColorSelector/ColorSelector';
import {
  advancedSchemeDefaultData,
  ColorScheme,
  defaultColorSchemes,
} from '../constants';

import styles from './Settings.module.scss';

interface IProps {
  favicon: string;
  colors: ColorScheme;
  setColors: (color: ColorScheme) => void;
  selectedOption: string;
  setSelectedOption: (value: string) => void;
}

const Settings = ({
  favicon,
  colors,
  setColors,
  setSelectedOption,
  selectedOption,
}: IProps) => {
  const [showColors, setShowColors] = useState<boolean>(false);
  const [initialOption, setInitialOption] = useState<string>(selectedOption);
  const [uploadedFavicon, setUploadedIFavicon] = useState<string | null>(null);

  const initialColors = useRef<ColorScheme>(
    transformAutoSaasData(advancedSchemeDefaultData, 'default')
  );
  const isColorsSaved = useRef(false);
  const uploadFaviconInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setInitialOption(initialOption);
    initialColors.current = colors;

    return () => {
      if (isColorsSaved.current) return;
      setSelectedOption(initialOption);
      setColors(initialColors.current);
      const kebabCaseColors = Object.entries(initialColors.current).reduce(
        (acc, [parentKey, value]) => ({
          ...acc,
          ...Object.entries(value).reduce(
            (subAcc, [subKey, subValue]) => ({
              ...subAcc,
              [`${convertCamelToKebab(parentKey)}-${convertCamelToKebab(
                subKey
              )}`]: subValue,
            }),
            {}
          ),
        }),
        {}
      );

      Object.entries(kebabCaseColors).forEach(([key, color]) => {
        document.documentElement.style.setProperty(`--${key}`, color as string);
      });
    };
  }, []);

  const handleSelectChange = (value: string) => {
    const transformedColors = transformAutoSaasData(
      advancedSchemeDefaultData,
      value
    );
    Object.entries(transformedColors).forEach(([key, value]) => {
      Object.entries(value).forEach(([subKey, subValue]) => {
        document.documentElement.style.setProperty(
          `--${convertCamelToKebab(key)}-${convertCamelToKebab(subKey)}`,
          subValue
        );
      });
    });
    setColors(transformedColors);
    setSelectedOption(value);
  };

  const onColorChange = (key: string, colorName: string, value: string) => {
    const combinedKey = `${convertCamelToKebab(key)}-${convertCamelToKebab(
      colorName
    )}`;
    document.documentElement.style.setProperty(`--${combinedKey}`, value);
    isColorsSaved.current = false;

    setColors({
      ...colors,
      [key]: {
        ...colors?.[key],
        [colorName]: value,
      },
    });
  };

  const handleUpdateAutoSaasLogo = async (image: File) => {
    const request = new FormData();
    request.append('file', image);
    request.append('isFavicon', 'true');
    const headers = {
      authorization: getToken(),
    };
    const response = await api(
      `${PAGECRAFT_API_URL}/saas/upload-saas-logo`,
      'POST',
      request,
      headers
    );
    setTimeout(() => {
      const linkIcon = document.querySelector('link[rel="icon"]');
      if (linkIcon) {
        linkIcon.setAttribute(
          'href',
          response.url + '?' + new Date().getTime()
        );
      }
    }, 2000);
  };

  const handleFileUpload = async () => {
    if (!uploadFaviconInputRef?.current) {
      return;
    }
    const files = uploadFaviconInputRef.current.files;
    if (!files?.length) {
      return;
    }
    let file = files[0];

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = (res) => {
      const content = reader.result;
      const imageSrc = String(content);
      setUploadedIFavicon(imageSrc);
      handleUpdateAutoSaasLogo(file);
    };
  };

  const saveDesignChanges = async () => {
    await graphQlCall({
      queryTemplateObject: queries.UPDATE_SAAS_DESIGN,
      values: {
        colorScheme: selectedOption,
        alerts: JSON.stringify(colors.alerts),
        sideBar: JSON.stringify(colors.sideBar),
        accent: JSON.stringify(colors.accent),
      },
      headerType: 'USER-AUTH',
    });

    const kebabCaseColors = Object.entries(colors).reduce(
      (acc, [parentKey, value]) => ({
        ...acc,
        ...Object.entries(value).reduce(
          (subAcc, [subKey, subValue]) => ({
            ...subAcc,
            [`${convertCamelToKebab(parentKey)}-${convertCamelToKebab(
              subKey
            )}`]: subValue,
          }),
          {}
        ),
      }),
      {}
    );

    const cssContent = `
    :root {
      ${Object.entries(kebabCaseColors)
        .map(([key, value]) => `--${key}: ${value};`)
        .join('\n')}
    }`;

    const blob = new Blob([cssContent], { type: 'text/css' });
    const request = new FormData();
    request.append('file', blob);

    const response = await api(
      `${PAGECRAFT_API_URL}/saas/upload-css-styles`,
      'POST',
      request,
      {
        Authorization: getToken(),
      }
    );
    document
      .getElementById('global-styles')
      ?.setAttribute('href', response.url);
    initialColors.current = colors;
    isColorsSaved.current = true;
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>Settings</div>
      <div className={styles.faviconUpload}>
        <div className={styles.faviconUploadTitle}>
          {uploadedFavicon || favicon ? (
            <img src={uploadedFavicon || favicon} alt="favicon" />
          ) : (
            <div className={styles.faviconPlaceholder} />
          )}
          Favicon
        </div>
        <div className={styles.uploadButtonWrapper}>
          <input
            type="file"
            style={{ display: 'none' }}
            accept="image/*"
            ref={uploadFaviconInputRef}
            onChange={handleFileUpload}
          />
          <Button
            width={158}
            height={40}
            appearance="stroke"
            onClick={() => uploadFaviconInputRef.current?.click()}
          >
            Upload Image
          </Button>
          <div className={styles.infoText}>
            Formats: ICO/PNG/JPEG; Recommended size: 128x128 px.
          </div>
        </div>
      </div>
      <div className={styles.dropdownContainer}>
        <div className={styles.dropdownTitle}>Select Color Scheme</div>
        <Dropdown
          label="Select a book"
          onChange={handleSelectChange}
          className={styles.dropdown}
          value={selectedOption}
          options={defaultColorSchemes.map((item) => ({
            value: item.value,
            label: (
              <div className={styles.optionLabel}>
                <div>{item.label}</div>
                <div className={styles.colors}>
                  {item.colors.map((color) => (
                    <div
                      style={{ backgroundColor: color }}
                      className={styles.color}
                      key={color}
                    />
                  ))}
                </div>
              </div>
            ),
          }))}
          hasErrors={false}
          type="stroke"
        />
      </div>
      <div
        className={styles.advancedScheme}
        onClick={() => setShowColors(!showColors)}
      >
        Advanced Scheme Settings{' '}
        <Arrow className={clsx(styles.arrow, { [styles.show]: showColors })} />
      </div>
      <div
        className={clsx(styles.schemeContainer, {
          [styles.show]: showColors,
        })}
      >
        {advancedSchemeDefaultData.map((scheme) => (
          <div
            key={scheme.key}
            className={clsx(styles.schemeItem, styles?.[scheme.key])}
          >
            <div className={styles.schemeTitle}>{scheme.title}</div>
            <div className={styles.schemeSubtitle}>{scheme.subtitle}</div>
            <div className={styles.schemeItems}>
              {scheme.items.map((item) => (
                <div key={item.key} className={styles.schemeItem}>
                  <div className={styles.itemTextContainer}>
                    <div className={styles.schemeTitle}>{item.title}</div>
                    <div className={styles.schemeItemSubtitle}>
                      {item.subtitle}
                    </div>
                  </div>
                  <div className={styles.colorContainer}>
                    <div>{colors?.[scheme.key]?.[item.key]}</div>
                    <div className={styles.colorPicker}>
                      <ColorSelector
                        color={colors?.[scheme.key]?.[item.key]}
                        onChange={(color) => {
                          onColorChange(scheme.key, item.key, color);
                        }}
                        pickerClassName={styles.picker}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <Button
        height={50}
        width={220}
        appearance="highlighted"
        onClick={saveDesignChanges}
      >
        Save
      </Button>
    </div>
  );
};

export default Settings;
