export const GET_USER_SMART_LISTS = {
  operationName: 'getUserSmartLists',
  query: `query getUserSmartLists {
    getUserSmartLists {
      _id
      name
      exact {
        _id
        name
      }
      includeAll {
        _id
        name
      }
      includeAny {
        _id
        name
      }
      excludeAll {
        _id
        name
      }
      maxContactAge
      createdAt
    }
  }`,
};
