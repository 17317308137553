import {
  MutableRefObject,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { connect } from 'react-redux';
import { RootState } from 'store/rootStore';
import { IUserProduct } from 'types';
import { productPopupAction } from 'store/products/productActions';
import ProductPopup from 'Components/Products/ProductPopup/ProductPopup';
import currencies from 'Assets/data/currencies.json';
import Dropdown from 'UILib/Dropdown/Dropdown';
import TextArea from 'UILib/TextArea/TextArea';
import Loader from 'UILib/Loader/Loader';
import Input from 'UILib/Input/Input';

import styles from './BookForm.module.scss';

interface IProps {
  data: Record<string, string | number | boolean>;
  validationFailed: boolean;
  onValueChange: (key: string, value: string | number | boolean) => void;
  triggerPopup: (payload: {
    open?: boolean;
    initialState?: IUserProduct;
  }) => void;
  fields: Record<string, { label: string; name: string; required?: boolean }>;
  products: IUserProduct[];
  productsLoading: boolean;
}

const ChooseProduct = ({
  data,
  fields,
  products,
  productsLoading,
  validationFailed,
  onValueChange,
  triggerPopup,
}: IProps) => {
  const bookProductName = data[fields.bookProductName?.name];
  const bookProductPrice = data[fields.bookProductPrice?.name];
  const bookProductCurrency = data[fields.bookProductCurrency?.name];
  const bookProductDescription = data[fields.bookProductDescription?.name];

  const [triggerAutoSelection, setTriggerAutoSelection] = useState(false);

  const productsAmountRef: MutableRefObject<number | undefined> = useRef();

  useEffect(() => {
    onValueChange(fields.bookProductCurrency?.name, currencies[0].name);
  }, [onValueChange, fields.bookProductCurrency?.name]);

  useEffect(() => {
    if (
      productsAmountRef.current &&
      products.length - productsAmountRef.current === 1 &&
      triggerAutoSelection
    ) {
      handleProductSelect(products[products.length - 1]._id);
      setTriggerAutoSelection(false);
    }
  }, [triggerAutoSelection, products]);

  const productOptions = useMemo(() => {
    const options: {
      label: ReactNode;
      value: string | undefined;
    }[] = products.map((product) => ({
      label: product.name,
      value: product._id,
    }));
    options.push({
      label: (
        <div
          onClick={() => {
            triggerPopup({ open: true });
            productsAmountRef.current = products.length;
          }}
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          + Add
        </div>
      ),
      value: 'add_product',
    });

    return options;
  }, [products]);

  const handleProductSelect = (newValue?: string) => {
    if (!newValue) return;

    const product = products.find((product) => product._id === newValue);
    if (!product) return;
    onValueChange(fields.productId?.name, product._id!);
    onValueChange(fields.bookProductName?.name, product.name);
    onValueChange(fields.bookProductPrice?.name, product.price ?? 0);
    onValueChange(fields.bookProductCurrency?.name, product.currency ?? 'usd');
    onValueChange(fields.bookProductDescription?.name, product.description);
  };

  return (
    <div className={styles.container}>
      {productsLoading ? (
        <div className={styles.loaderContainer}>
          <Loader />
        </div>
      ) : (
        <>
          <div className={styles.firstRow}>
            <div className={styles.bookDropdown}>
              <Dropdown
                label={fields.bookProductName?.label}
                onChange={handleProductSelect}
                value={
                  typeof bookProductName === 'string' ? bookProductName : ''
                }
                options={productOptions}
                hasErrors={
                  validationFailed &&
                  fields.bookProductName?.required &&
                  !bookProductName
                }
              />
            </div>
            <Input
              className={styles.priceInput}
              hideArrows
              border="one-line"
              placeholder={fields.bookProductPrice?.label}
              value={
                bookProductPrice && typeof bookProductPrice !== 'boolean'
                  ? bookProductPrice
                  : ''
              }
              onChange={(event) =>
                onValueChange(fields.bookProductPrice?.name, event.target.value)
              }
              error={
                validationFailed &&
                fields.bookProductPrice?.required &&
                !bookProductPrice
                  ? 'Required Field'
                  : undefined
              }
            />
            <div className={styles.currencyDropDown}>
              <Dropdown
                label={fields.bookProductCurrency?.label}
                onChange={(newValue) =>
                  onValueChange(fields.bookProductCurrency?.name, newValue)
                }
                value={bookProductCurrency}
                options={currencies.map((currency) => ({
                  label: currency.name,
                  value: currency.name,
                }))}
                hasErrors={
                  validationFailed &&
                  fields.bookProductCurrency?.required &&
                  !bookProductCurrency
                }
              />
            </div>
          </div>
          <TextArea
            value={
              typeof bookProductDescription === 'string'
                ? bookProductDescription
                : ''
            }
            placeholder={fields.bookProductDescription?.label}
            onChange={(newValue) =>
              onValueChange(fields.bookProductDescription?.name, newValue)
            }
            autoComplete="off"
            height={180}
            maxLength={5000}
            hasErrors={
              validationFailed &&
              fields.bookProductDescription?.required &&
              !bookProductDescription
            }
          />
        </>
      )}
      <ProductPopup />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  products: state.products.items,
  productsLoading: state.products.loading,
});

const mapDispatchToProps = {
  triggerPopup: (payload: { open?: boolean; initialState?: IUserProduct }) =>
    productPopupAction(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(ChooseProduct);
