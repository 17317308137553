export const REGENERATE_SIGNUP_TOKEN = {
  operationName: 'regenerateSignUpToken',
  query: `mutation regenerateSignUpToken(
      $token: String!
    ) {
      regenerateSignUpToken(
        token: $token
      ) {
        email
      }
    }`,
};
