import React, { useState, useEffect, useRef } from 'react';
import * as calendar from './calendarCalculations';
import arrow from '../../../../Assets/icons/arrow.svg';
import './Calendar.module.scss';
import s from './Calendar.module.scss';
import { getDaysInMonth, getMonthStartsOn } from './calendarCalculations';
const today = new Date();

const Calendar = (props) => {
  const [date, setDate] = useState(props.date);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedStartDate, setSelectedStartDate] = useState(props.startDate);
  const [selectedEndDate, setSelectedEndDate] = useState(props.endDate);
  const calendarRef = useRef(null);

  const handlePrevMonthButtonClick = () => {
    const prevMonth = new Date(date.getFullYear(), date.getMonth() - 1);
    setDate(prevMonth);
  };

  const handleNextMonthButtonClick = () => {
    const prevMonth = new Date(date.getFullYear(), date.getMonth() + 1);
    setDate(prevMonth);
  };

  const handleDayClick = (clickDate) => {
    const date = clickDate > today ? today : clickDate;
    if (props.selectionType === 'single') {
      if (props.setDate) {
        setSelectedStartDate(date);
        props.setDate(date);
        props.onClose();
      }
      return;
    }

    if (!selectedStartDate) {
      setSelectedStartDate(date);
    } else if (!selectedEndDate) {
      if (date >= selectedStartDate) {
        setSelectedEndDate(date);
        if (props.setDate && date !== selectedStartDate) {
          if (selectedStartDate.toString() === date.toString())
            props.setDate(
              selectedStartDate,
              new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate(),
                23,
                59,
                59
              )
            );
          else props.setDate(selectedStartDate, date);
          props.onClose();
        }
      } else {
        setSelectedEndDate(selectedStartDate);
        setSelectedStartDate(date);
        if (props.setDate && date !== selectedStartDate) {
          if (selectedStartDate.toString() === date.toString())
            props.setDate(
              date,
              new Date(
                selectedStartDate.getFullYear(),
                selectedStartDate.getMonth(),
                selectedStartDate.getDate(),
                23,
                59,
                59
              )
            );
          else props.setDate(date, selectedStartDate);
          props.onClose();
        }
      }
    } else {
      setSelectedStartDate(date);
      setSelectedEndDate(null);
    }
  };

  const { monthNames, weekDayNames } = props;
  const finalDateString =
    monthNames[date.getMonth()] + ' ' + date.getFullYear();
  const monthData = calendar.getMonthData(
    date.getFullYear(),
    date.getMonth(),
    date.getMonth() - 1
  );

  const monthStartsOn = getMonthStartsOn(date.getFullYear(), date.getMonth());
  const currMonth = new Date(date.getFullYear(), date.getMonth());
  const daysInMonth = getDaysInMonth(currMonth);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!props.isOpen) {
        return;
      }
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        props.onClose();
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [props.isOpen]);

  if (props.isOpen) {
    return (
      <div className={s.calendar} ref={calendarRef}>
        <header>
          <button onClick={handlePrevMonthButtonClick}>
            <img src={arrow} alt={'left'} />
          </button>
          <p>{finalDateString}</p>
          <button onClick={handleNextMonthButtonClick}>
            <img src={arrow} alt={'right'} />
          </button>
        </header>

        <table style={{ borderSpacing: '5px 2px' }}>
          <thead>
            <tr>
              {weekDayNames.map((name) => (
                <th key={name}>{name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {monthData.map((week, index) => (
              <tr key={index} className={s.week}>
                {week.map((date, innerIndex) =>
                  date ? (
                    <td
                      key={innerIndex}
                      className={`
                      ${s.day}
                      ${calendar.areEqual(date, currentDate) && s.today}
                      ${
                        calendar.areEqual(date, selectedStartDate) && s.selected
                      }
                      ${
                        selectedEndDate &&
                        calendar.areEqual(date, selectedEndDate) &&
                        s.selected
                      }
                      ${
                        date > selectedStartDate &&
                        date < selectedEndDate &&
                        s.between
                      }
                      ${index === 0 && innerIndex < monthStartsOn && s.prev}
                      ${date.getMonth() > currMonth.getMonth() && s.next}
                    `}
                      onClick={() => {
                        if (index === 0 && innerIndex < monthStartsOn) {
                          handlePrevMonthButtonClick();
                        }
                        if (date.getMonth() > currMonth.getMonth()) {
                          handleNextMonthButtonClick();
                        }
                        handleDayClick(date);
                      }}
                    >
                      {date.getDate()}
                    </td>
                  ) : (
                    <td key={innerIndex} />
                  )
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  } else {
    return null;
  }
};

Calendar.defaultProps = {
  date: new Date(),
  years: [2018, 2019, 2020, 2021, 2022],
  monthNames: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  weekDayNames: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
  onChange: Function.prototype,
};

export default Calendar;
