import { api, getToken } from '../../utils/Utils';
import { PAGECRAFT_API_URL } from '../../Constants';
import { pushAlert } from '../alerts/alertsActions';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { ISectionMenu, ISection, IMenuItem } from 'types';
import { RootState } from '../rootReducer';

export const SET_SECTIONS = 'SET_SECTIONS';
export const SET_SECTIONS_MENU = 'SET_SECTIONS_MENU';

type GetStateType = () => RootState;
type DispatchType = ThunkDispatch<RootState, void, Action>;

const headers = () => ({
  authorization: getToken(),
});

export const fetchSectionsAction = () => (dispatch: DispatchType) => {
  api(`${PAGECRAFT_API_URL}/section`, 'GET')
    .then((data: any) => {
      dispatch({
        type: SET_SECTIONS,
        payload: data as ISection[],
      });
    })
    .catch((error) => {
      dispatch(pushAlert('error', 'API error', error));
    });
};

export const fetchSectionsMenuAction = () => (dispatch: DispatchType) => {
  api(`${PAGECRAFT_API_URL}/section-menu`, 'GET')
    .then((data: any) => {
      dispatch({
        type: SET_SECTIONS_MENU,
        payload: data as ISectionMenu,
      });
    })
    .catch((error) => {
      dispatch(pushAlert('error', 'API error', error));
    });
};

export const updateSectionsMenuAction = (items: IMenuItem[]) => (
  dispatch: DispatchType,
  getState: GetStateType
) => {
  const request = {
    items,
  };
  const state = getState();
  const menu = { ...state.sections.menu, items };
  dispatch({
    type: SET_SECTIONS_MENU,
    payload: menu,
  });

  api(`${PAGECRAFT_API_URL}/section-menu`, 'PUT', request, headers()).catch(
    (error) => {
      dispatch(pushAlert('error', 'API error', error));
    }
  );
};

export interface ICreateSectionPayload {
  image: string;
  name: string;
  data: any;
}

export const createSectionAction = ({
  image,
  name,
  data,
}: ICreateSectionPayload) => (
  dispatch: DispatchType,
  getState: GetStateType
) => {
  const request = new FormData();
  if (image) {
    request.append('file', image);
  }
  if (name) {
    request.append('name', name);
  }
  request.append('data', JSON.stringify(data));

  api(`${PAGECRAFT_API_URL}/section`, 'POST', request, headers())
    .then((data: any) => {
      const state = getState();
      let sections = [...state.sections.items];
      sections.push(data);
      dispatch({
        type: SET_SECTIONS,
        payload: sections,
      });
    })
    .catch((error) => {
      dispatch(pushAlert('error', 'API error', error));
    });
};

export interface IUpdateSectionPayload {
  id: string;
  fields: {
    name?: string;
    image?: File;
    data?: any;
  };
}

export const updateSectionAction = ({ id, fields }: IUpdateSectionPayload) => (
  dispatch: DispatchType,
  getState: GetStateType
) => {
  const state = getState();
  const { name, image, data } = fields;
  const request = new FormData();
  if (data) {
    request.append('data', JSON.stringify(data));
  }
  if (name) {
    request.append('name', name);
  }
  if (image) {
    request.append('file', image);
  }

  const sections = state.sections.items.map((section) => {
    if (section._id === id) {
      const updateData: any = { ...fields };
      delete updateData.image;
      section = {
        ...section,
        ...updateData,
      };
      section.image = fields.image
        ? URL.createObjectURL(fields.image)
        : section.image;
    }
    return section;
  });
  dispatch({
    type: SET_SECTIONS,
    payload: sections,
  });

  api(`${PAGECRAFT_API_URL}/section/${id}`, 'PATCH', request, headers()).catch(
    (error) => {
      dispatch(pushAlert('error', 'API error', error));
    }
  );
};

export const deleteSectionAction = (id: string) => (
  dispatch: DispatchType,
  getState: GetStateType
) => {
  const state = getState();
  const sections = state.sections.items.filter((section) => section._id !== id);
  dispatch({
    type: SET_SECTIONS,
    payload: sections,
  });

  api(`${PAGECRAFT_API_URL}/section/${id}`, 'DELETE', null, headers()).catch(
    (error) => {
      dispatch(pushAlert('error', 'API error', error));
    }
  );
};

export const updateSectionMenuImage = (image: File, index: string) => (
  dispatch: DispatchType,
  getState: GetStateType
) => {
  const state = getState();
  const request = new FormData();
  request.append('index', index);
  request.append('file', image);
  request.append('items', JSON.stringify(state.sections.menu.items));
  api(`${PAGECRAFT_API_URL}/section-menu`, 'PATCH', request, headers())
    .then((data: any) => {
      dispatch({
        type: SET_SECTIONS_MENU,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch(pushAlert('error', 'API error', error));
    });
};
