export const GET_MAIL_SENDERS_QUERY = {
  operationName: 'getMailSenders',
  query: `query getMailSenders {
        getMailSenders {
            _id
            name
            email
            status
            mailDomain {
                _id
            }
        }
      }`,
};
